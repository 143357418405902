import React from 'react'
import { Text, View } from 'react-native'
import { TextStyles, Layout } from '../constants'

export default class FormHeaderTitle extends React.Component {
    render = () => {
        return (
            <View
                style={[
                    this.props.headerStyle,
                    {
                        //flexDirection: 'row',
                        paddingBottom: Layout.screenPaddingMedium,
                    },
                ]}
            >
                <Text
                    style={[TextStyles.Heading2, this.props.headerTitleStyle]}
                >
                    {this.props.headerTitle}
                </Text>
            </View>
        )
    }
}
