import React from 'react'
import {
    KeyboardAvoidingView,
    LayoutAnimation,
    Platform,
    ScrollView,
    StyleSheet,
    StatusBar,
    Text,
    TextInput,
    View,
} from 'react-native'
import { SafeAreaView } from 'react-navigation'
import { Feather } from '@expo/vector-icons'
import { Colors, Icons, TextStyles, Layout } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import FormHeaderTitle from '../../components/FormHeaderTitle'
import FormFooterOptions from '../../components/FormFooterOptions'
import Button from '../../components/Button'
import Footer from '../../components/Footer'
import StudioFooter from '../../components/StudioFooter'

export default class AuthForgotPasswordScreen extends React.Component {
    componentDidMount = async () => {
        await this.context.resetUserForms()
        this.props.navigation.setParams({ active: true })
    }

    renderInputEmail = ({ sectionTitle, email, emailValid }) => {
        return (
            <View
                style={{
                    //flex: 1,
                    paddingBottom: 48,
                }}
            >
                <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Overline2,
                        {
                            flex: 1,
                            paddingBottom: 16,
                        },
                    ]}
                >
                    {sectionTitle.toUpperCase()}
                </Text>

                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <TextInput
                        editable={true}
                        keyboardAppearance={'dark'}
                        style={[
                            TextStyles.Paragraph,
                            {
                                flex: 1,
                                height: 48,
                                color: Colors.primaryText,
                            },
                        ]}
                        value={email}
                        placeholder="email@example.com"
                        placeholderTextColor={Colors.disabled}
                        selectionColor={Colors.accentDeepsea}
                        onChangeText={async text => {
                            await this.context.setEmail(text)
                            this.context.validateEmail(text)
                        }}
                        onFocus={() => {}}
                        onEndEditing={text => {
                            this.context.validateEmail(text)
                        }}
                        maxLength={64}
                        keyboardType="email-address"
                        autoCapitalize="none"
                        returnKeyType="next"
                        blurOnSubmit={true}
                        autoCorrect={false}
                    />
                    <Feather
                        style={{
                            alignSelf: 'center',
                        }}
                        color={
                            email.length && emailValid
                                ? Colors.accentDeepsea
                                : Colors.transparentBackground
                        }
                        size={Icons.small}
                        name={'check'}
                    />
                </View>
                {this.renderSeparator()}
                {Layout.isMediumDevice ? (
                    <View />
                ) : (
                    <Button
                        iconRight={true}
                        iconName="arrow-right"
                        iconColor={Colors.white}
                        style={{
                            alignSelf: 'center',
                            paddingTop: Layout.screenPaddingLarge,
                            paddingBottom: Layout.screenPaddingMedium,
                        }}
                        textStyle={[
                            TextStyles.Button,
                            {
                                color: Colors.white,
                            },
                        ]}
                        buttonStyle={{
                            backgroundColor: Colors.tintColor,
                            //borderWidth: 1,
                            //borderColor: Colors.tintColor,
                        }}
                        title={'Send password reset'}
                        disabled={!this.context.emailValid}
                        onPress={() => this.context.onPasswordResetPress()}
                    />
                )}
            </View>
        )
    }

    renderSeparator() {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                    paddingTop: 16,
                }}
            />
        )
    }

    render() {
        // LayoutAnimation.easeInEaseOut()

        return (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View
                            style={{
                                flex: 1,
                            }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <KeyboardAvoidingView
                                behavior={
                                    Platform.OS == 'ios' ? 'padding' : null
                                }
                                keyboardVerticalOffset={
                                    Layout.TAB_DEFAULT_HEIGHT + 8
                                }
                                style={{
                                    flex: 1,
                                }}
                            >
                                <ScrollView
                                    style={{
                                        flex: 1,
                                        backgroundColor: Colors.gray0,
                                        paddingTop: Layout.isMediumDevice
                                            ? Layout.topNavHeightMobile
                                            : Layout.topNavHeightDesktop,
                                    }}
                                >
                                    <View style={styles.sectionContainer}>
                                        <View style={styles.bodyContent}>
                                            <FormHeaderTitle
                                                headerTitle={
                                                    'Forgot your password?'
                                                }
                                                headerTitleStyle={[
                                                    Layout.isMediumDevice
                                                        ? TextStyles.Heading2
                                                        : TextStyles.Heading1,
                                                ]}
                                            />
                                            <Text
                                                style={[
                                                    TextStyles.Paragraph,
                                                    {
                                                        paddingBottom: 36,
                                                        lineHeight: 26,
                                                    },
                                                ]}
                                            >
                                                Enter your email to reset your
                                                password.
                                            </Text>
                                            {this.renderInputEmail({
                                                sectionTitle: 'Email',
                                                email: context.email,
                                                emailValid: context.emailValid,
                                            })}
                                        </View>
                                    </View>
                                </ScrollView>
                                {Layout.isMediumDevice ? (
                                    <FormFooterOptions
                                        displayRightButton={true}
                                        extendedSection={false}
                                        navigation={this.props.navigation}
                                        loading={context.loading}
                                        displayLeftButton={true}
                                        leftButtonTitle={'Close'}
                                        onLeftButtonPress={() =>
                                            this.props.navigation.goBack()
                                        }
                                        rightButtonTitle={'Send reset'}
                                        onRightButtonPress={() =>
                                            context.onPasswordResetPress()
                                        }
                                        rightButtonDisabled={
                                            !context.emailValid
                                        }
                                    />
                                ) : context.studioClient ? (
                                    <StudioFooter
                                        navigation={this.props.navigation}
                                    />
                                ) : (
                                    <Footer
                                        navigation={this.props.navigation}
                                    />
                                )}
                                {/* <FormFooterOptions
                                        displayCenterButton={true}
                                        navigation={this.props.navigation}
                                        loading={context.loading}
                                        //displayLeftButton={true}
                                        centerButtonTitle={'Close'}
                                        onCenterButtonPress={() =>
                                            this.props.navigation.goBack()
                                        }
                                        //centerButtonTitle={'Send'}
                                        // onRightButtonPress={() =>
                                        //     context.onPasswordResetPress()
                                        // }
                                        // rightButtonDisabled={
                                        //     !context.emailValid
                                        // }
                                    /> */}
                            </KeyboardAvoidingView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        alignItems: 'center',
        paddingVertical: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        paddingVertical: Layout.screenPaddingLarge,
        paddingHorizontal: Layout.screenPaddingLarge,
    },

    stickyButton: {
        zIndex: 100,
        position: 'absolute',
        top: 0,
        right: 0,
        paddingVertical: Layout.screenPaddingMedium,
        paddingHorizontal: Layout.isMediumDevice
            ? Layout.screenPaddingMedium
            : Layout.screenPaddingLarge,
    },
})
