import React from 'react'
import { ScrollView, StyleSheet, Image, Text, View } from 'react-native'
import { Video } from 'expo-av'
import { Colors, Icons, TextStyles, Layout } from '../constants'
import ButtonIcon from './ButtonIcon'

export default class ProductFeatures extends React.Component {
    renderFeatureVideo = ({ theme, description, video, poster }) => {
        return (
            <View
                style={{
                    flex: 1,
                    alignSelf: 'center',
                    alignItems: 'center',
                    minWidth: Layout.isMediumDevice
                        ? '100%'
                        : Layout.SCREEN_WIDTH * 0.3333,
                    paddingBottom: Layout.screenPaddingXL,
                }}
            >
                <View
                    style={{
                        backgroundColor: Colors.gray3,
                        paddingVertical: 8,
                        paddingHorizontal: 8,
                        borderRadius: 40,
                        // borderWidth: 3,
                        // borderColor: Colors.gray3,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <View
                        style={{
                            position: 'absolute',
                            top: 0,
                            zIndex: 10,
                            backgroundColor: Colors.gray3,
                            width: 140,
                            height: 32,
                            borderBottomStartRadius: 12,
                            borderBottomEndRadius: 12,
                        }}
                    />
                    <Video
                        source={{ uri: video }}
                        style={{
                            backgroundColor: Colors.gray3,
                            borderRadius: 32,
                            width: 270,
                            height: 585,
                        }}
                        resizeMode={'contain'}
                        isMuted
                        volume={0}
                        usePoster={true}
                        posterSource={{ uri: poster }}
                        posterStyle={{ zIndex: -10 }}
                        //useNativeControls={false}
                        //shouldPlay={true}
                        //isLooping={true}
                        useNativeControls={Layout.isMediumDevice ? true : false}
                        shouldPlay={Layout.isMediumDevice ? false : true}
                        isLooping={Layout.isMediumDevice ? false : true}
                    />
                </View>
                <View
                    style={{
                        flex: 1,
                        alignSelf: 'center',
                        alignItems: 'center',
                        maxWidth: Layout.isMediumDevice
                            ? Layout.SCREEN_WIDTH
                            : Layout.SCREEN_WIDTH * 0.3333,
                        paddingHorizontal: Layout.isMediumDevice
                            ? Layout.screenPaddingLarge
                            : Layout.screenPaddingXL,
                        paddingVertical: Layout.screenPaddingLarge,
                    }}
                >
                    <Text
                        style={[
                            TextStyles.Heading3,
                            {
                                paddingBottom: Layout.screenPaddingSmall,
                                textAlign: 'center',
                            },
                        ]}
                    >
                        {theme}
                    </Text>
                    <Text
                        style={[
                            TextStyles.Paragraph,
                            {
                                lineHeight: 26,
                                textAlign: 'center',
                            },
                        ]}
                    >
                        {description}
                    </Text>
                </View>
            </View>
        )
    }

    renderRule = lineColor => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 2,
                    borderBottomColor: lineColor || Colors.tintColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSeparator = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border, //lineColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    render = () => {
        return (
            <View style={[styles.sectionContainer]}>
                <View style={styles.container}>
                    <ButtonIcon
                        activityColor={Colors.white}
                        iconName={'chevron-left'}
                        iconColor={Colors.white}
                        style={{
                            display: Layout.isMediumDevice ? 'none' : 'flex',
                            position: 'absolute',
                            left: 0,
                            zIndex: 100,
                            //bottom: Layout.isMediumDevice ? 0 : null,
                        }}
                        buttonStyle={{
                            width: Layout.isMediumDevice
                                ? Layout.SCREEN_WIDTH * 0.5
                                : 72,
                            height: Layout.isMediumDevice ? 48 : 72,
                            paddingVertical: Layout.isMediumDevice
                                ? Layout.screenPaddingMedium
                                : Layout.screenPaddingLarge,
                            paddingVertical: Layout.isMediumDevice
                                ? Layout.screenPaddingMedium
                                : Layout.screenPaddingLarge,
                            borderRadius: 0,
                            backgroundColor: Colors.tintColor,
                        }}
                        loading={false}
                        disabled={false}
                        onPress={() => {
                            this.scroll.scrollTo({
                                x: -Layout.SCREEN_WIDTH,
                            })
                        }}
                    />
                    <ScrollView
                        ref={node => (this.scroll = node)}
                        //onScroll={event => this.onScroll(event)}
                        decelerationRate="fast"
                        style={{ flex: 1 }}
                        horizontal
                        //persistentScrollbar={true}
                        showsHorizontalScrollIndicator={true}
                        scrollIndicatorInsets={{
                            left: Layout.screenPaddingLarge,
                            right: Layout.screenPaddingLarge,
                        }}
                        pagingEnabled
                    >
                        {this.renderFeatureVideo({
                            video: require('../assets/videos/RPReplay_Final1568170295.mp4'),
                            poster: require('../assets/images/Frame-23-09-2019-11-54-38.jpg'),
                            theme: 'Create unique events',
                            description:
                                'Cover everything from special events to everyday dining experiences',
                        })}
                        {this.renderFeatureVideo({
                            video: require('../assets/videos/RPReplay_Final1568167446.mp4'),
                            poster: require('../assets/images/Frame-24-09-2019-12-12-36.jpg'),
                            theme: 'Author enriching stories',
                            description:
                                'Get inspiration from our themes to generate immersive video stories',
                        })}
                        {this.renderFeatureVideo({
                            video: require('../assets/videos/RPReplay_Final1568168051.mp4'),
                            poster: require('../assets/images/Frame-23-09-2019-11-55-24.jpg'),
                            theme: 'Capture team’s perspective',
                            description:
                                'Collaborate with your team to make stories that are automatically merged',
                        })}
                        {this.renderFeatureVideo({
                            video: require('../assets/videos/RPReplay_Final1568171159.mp4'),
                            poster: require('../assets/images/Frame-23-09-2019-11-52-21.jpg'),
                            theme: 'Spread the word to guests',
                            description:
                                'Publish to Instagram, Twitter, and more to inform and inspire guests',
                        })}
                    </ScrollView>
                    <View
                        style={{
                            display: Layout.isMediumDevice ? 'flex' : 'none',
                            overflow: 'hidden',
                            position: 'absolute',
                            bottom: Layout.screenPaddingLarge,
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: Layout.screenPaddingSmall,
                        }}
                    >
                        <Image
                            //source={require('../assets/svgs/expand-horizontal.svg')}
                            source={require('../assets/svgs/expand-horizontal.svg')}
                            style={{
                                width: Icons.XL,
                                height: Icons.XL,
                            }}
                            resizeMode={'cover'}
                        />
                    </View>

                    <ButtonIcon
                        activityColor={Colors.white}
                        iconName={'chevron-right'}
                        iconColor={Colors.white}
                        style={{
                            display: Layout.isMediumDevice ? 'none' : 'flex',
                            position: 'absolute',
                            right: 0,
                            zIndex: 100,
                            //bottom: Layout.isMediumDevice ? 0 : null,
                        }}
                        buttonStyle={{
                            width: Layout.isMediumDevice
                                ? Layout.SCREEN_WIDTH * 0.5
                                : 72,
                            height: Layout.isMediumDevice ? 48 : 72,
                            paddingVertical: Layout.isMediumDevice
                                ? Layout.screenPaddingMedium
                                : Layout.screenPaddingLarge,
                            paddingVertical: Layout.isMediumDevice
                                ? Layout.screenPaddingMedium
                                : Layout.screenPaddingLarge,
                            borderRadius: 0,
                            backgroundColor: Colors.tintColor,
                        }}
                        loading={false}
                        disabled={false}
                        onPress={() =>
                            this.scroll.scrollTo({
                                x: Layout.SCREEN_WIDTH * 4,
                            })
                        }
                    />
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        alignItems: 'center',
        backgroundColor: Colors.gray0,
        paddingVertical: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
    },
    container: {
        flex: 1,
        width: Layout.SCREEN_WIDTH,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
})
