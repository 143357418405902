import React from 'react'
import { Text, View, ImageBackground, StyleSheet } from 'react-native'
import { Colors, Layout, TextStyles, Icons } from '../constants'
import { UserProfileConsumer } from '../context/UserProfileContext'
import Button from './Button'
import ButtonIcon from './ButtonIcon'
import BadgeIcon from '../components/BadgeIcon'

export default class UserProfile extends React.Component {
    renderSeparator() {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                    paddingTop: Layout.screenPaddingLarge,
                }}
            />
        )
    }

    render = () => {
        return (
            <UserProfileConsumer>
                {context => (
                    <View
                        style={styles.container}
                        ref={ref => {
                            this.context = context
                        }}
                    >
                        <View
                            style={{
                                flex: 1,
                                alignItems: 'center',
                                paddingTop: Layout.isMediumDevice
                                    ? 0
                                    : Layout.screenPaddingLarge,
                                paddingHorizontal: Layout.screenPaddingMedium,
                            }}
                        >
                            <View
                                style={{
                                    paddingHorizontal:
                                        Layout.screenPaddingMedium,
                                    marginBottom: Layout.screenPaddingMedium,
                                }}
                            >
                                <ImageBackground
                                    source={{
                                        uri: context.avatarImage,
                                    }}
                                    style={{
                                        height: Icons.XL * 2,
                                        width: Icons.XL * 2,
                                        borderRadius: Icons.XL,
                                        overflow: 'hidden',
                                        backgroundColor: Colors.gray3,

                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                    resizeMode={'cover'}
                                >
                                    {context.avatarImage === 'https://' ? (
                                        <Text
                                            style={[
                                                TextStyles.Heading1,
                                                {
                                                    textAlign: 'center',
                                                    textTransform: 'capitalize',
                                                    color:
                                                        Colors.inverseTextDisabled,
                                                },
                                            ]}
                                            numberOfLines={1}
                                        >
                                            {context.fullName === null ? (
                                                <Text />
                                            ) : (
                                                context.fullName.substring(0, 1)
                                            )}
                                        </Text>
                                    ) : (
                                        <View />
                                    )}
                                </ImageBackground>
                            </View>

                            <View
                                style={{
                                    paddingHorizontal: 24,
                                    marginBottom: Layout.screenPaddingSmall,
                                }}
                            >
                                <Text
                                    style={[
                                        Layout.isMediumDevice
                                            ? TextStyles.Heading3
                                            : TextStyles.Heading2,
                                        {
                                            textAlign: 'center',
                                            lineHeight: 36,
                                        },
                                    ]}
                                    maxLength={64}
                                    numberOfLines={2}
                                >
                                    {context.fullName}
                                </Text>

                                <BadgeIcon
                                    iconName="check"
                                    iconColor={Colors.white}
                                    iconSize={16}
                                    style={{
                                        position: 'absolute',
                                        top: -8,
                                        right: 0,
                                    }}
                                    burstBackgroundColor={{
                                        backgroundColor: context.emailVerified
                                            ? Colors.accentDeepsea
                                            : Colors.gray2,
                                    }}
                                />
                            </View>

                            <View
                                style={{
                                    flex: 1,
                                }}
                            >
                                <Text
                                    style={[
                                        TextStyles.Paragraph,
                                        {
                                            textAlign: 'center',
                                            lineHeight: 24,
                                            paddingHorizontal:
                                                Layout.screenPaddingLarge,
                                        },
                                    ]}
                                >
                                    {context.email}
                                </Text>
                            </View>
                        </View>
                    </View>
                )}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingVertical: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
        paddingHorizontal: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
    },
})
