import React from 'react'
import {
    ImageBackground,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import { Colors, TextStyles, Layout } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import FormHeaderTitle from '../../components/FormHeaderTitle'
import Button from '../../components/Button'
import StudioFooter from '../../components/StudioFooter'
import { DrawerActions } from 'react-navigation-drawer'

export default class StudioHome extends React.Component {
    componentDidMount = async () => {
        //await this.context.setAuthInitialState()
        this.props.navigation.setParams({ active: true })

        this.context.initializeUserAccount()
        //window.addEventListener('scroll', this.onScroll)
    }

    componentDidUpdate = async prevProps => {
        if (prevProps.isFocused !== this.props.isFocused) {
            //await this.context.setAuthInitialState()
            this.props.navigation.setParams({ active: true })

            this.context.initializeUserAccount()
            //window.removeEventListener('scroll', this.onScroll)
            //this.props.navigation.goBack()
        }
    }

    onScroll = async event => {
        const pageYOffset = await event.nativeEvent.contentOffset.y

        if (pageYOffset >= 88) {
            this.props.navigation.setParams({ active: true })
        } else {
            this.props.navigation.setParams({ active: false })
        }
    }

    renderSeparator() {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                }}
            />
        )
    }

    render() {
        return (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View
                            style={{
                                flex: 1,
                            }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <ScrollView
                                onScroll={event => this.onScroll(event)}
                                scrollEventThrottle={16}
                                style={{
                                    flex: 1,
                                }}
                            >
                                {Layout.isMediumDevice ? (
                                    <View
                                        style={{
                                            flex: 1,
                                        }}
                                    >
                                        <ImageBackground
                                            //source={{}}
                                            source={require('../../assets/images/fabian-irsara-_SwLdgVGfVY-unsplash.jpg')}
                                            style={[
                                                styles.imageContainer,
                                                styles.mainView,
                                            ]}
                                            resizeMode={'cover'}
                                        />

                                        <View
                                            style={[
                                                styles.headerContainer,
                                                {
                                                    minHeight:
                                                        Layout.SCREEN_HEIGHT *
                                                        0.5,
                                                    padding:
                                                        Layout.screenPaddingLarge,
                                                    alignItems: 'center',
                                                    justifyContent: 'center',
                                                    // padding: Layout.isSmallDevice
                                                    //     ? Layout.screenPaddingLarge
                                                    //     : Layout.screenPaddingLarge,
                                                    // paddingBottom: Layout.isSmallDevice
                                                    //     ? Layout.screenPaddingXL
                                                    //     : Layout.screenPaddingXL,
                                                },
                                            ]}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,

                                                    // marginTop:
                                                    //     -Layout.SCREEN_HEIGHT *
                                                    //     0.125,
                                                    alignSelf: 'center',
                                                }}
                                            >
                                                <FormHeaderTitle
                                                    // headerTitle={
                                                    //     //context.tokenExists ||
                                                    //     context.userExists
                                                    //         ? 'Studio × noname'
                                                    //         : 'Welcome to Studio'
                                                    // }
                                                    headerTitle={
                                                        'Welcome to Studio'
                                                    }
                                                    headerTitleStyle={[
                                                        Layout.isSmallDevice
                                                            ? TextStyles.Heading1
                                                            : TextStyles.Heading1,
                                                        // {
                                                        //     paddingBottom:
                                                        //         Layout.screenPaddingMedium,
                                                        // },
                                                    ]}
                                                />
                                                {context.userExists ? (
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                                paddingBottom:
                                                                    Layout.screenPaddingMedium,
                                                            },
                                                        ]}
                                                    >
                                                        A visionary
                                                        collaboration between
                                                        noname and Master Chef
                                                        Chef
                                                    </Text>
                                                ) : (
                                                    <View />
                                                )}
                                                {context.userExists ? (
                                                    <Button
                                                        iconRight={true}
                                                        iconName={'arrow-right'}
                                                        iconColor={
                                                            Colors.tintColor
                                                        }
                                                        style={{
                                                            flex: 1,
                                                            width: Layout.isMediumDevice
                                                                ? '100%'
                                                                : null,
                                                            paddingTop:
                                                                Layout.screenPaddingMedium,
                                                            alignSelf:
                                                                'flex-start',
                                                        }}
                                                        buttonStyle={{
                                                            borderWidth: 1,
                                                            borderColor:
                                                                Colors.tintColor,
                                                            backgroundColor:
                                                                'transparent',
                                                        }}
                                                        textStyle={[
                                                            TextStyles.Button,
                                                        ]}
                                                        onPress={() => {
                                                            this.props.navigation.navigate(
                                                                'StudioBrand'
                                                            )
                                                        }}
                                                        title={
                                                            'Where we started'
                                                        }
                                                    />
                                                ) : (
                                                    <Button
                                                        iconRight={true}
                                                        iconName={'arrow-right'}
                                                        iconColor={
                                                            Colors.tintColor
                                                        }
                                                        style={{
                                                            flex: 1,
                                                            width: Layout.isMediumDevice
                                                                ? '100%'
                                                                : null,
                                                            paddingTop:
                                                                Layout.screenPaddingMedium,
                                                            alignSelf:
                                                                'flex-start',
                                                        }}
                                                        buttonStyle={{
                                                            borderWidth: 1,
                                                            borderColor:
                                                                Colors.tintColor,
                                                            backgroundColor:
                                                                'transparent',
                                                        }}
                                                        textStyle={[
                                                            TextStyles.Button,
                                                        ]}
                                                        onPress={() => {
                                                            this.props.navigation.dispatch(
                                                                DrawerActions.toggleDrawer()
                                                            )
                                                        }}
                                                        title={
                                                            context.tokenExists
                                                                ? 'Sign up now'
                                                                : 'Log in'
                                                        }
                                                    />
                                                )}
                                            </View>
                                        </View>
                                    </View>
                                ) : (
                                    <ImageBackground
                                        // source={
                                        //     context.tokenExists
                                        //         ? require('../../assets/images/larm-rmah--AI-n49WVwY-unsplash.jpg')
                                        //         : require('../../assets/images/larm-rmah--AI-n49WVwY-unsplash.jpg')
                                        // }
                                        source={require('../../assets/images/fabian-irsara-_SwLdgVGfVY-unsplash.jpg')}
                                        style={[
                                            styles.mainView,
                                            {
                                                height: Layout.SCREEN_HEIGHT,
                                                flexDirection: 'row',
                                            },
                                        ]}
                                        resizeMode={'cover'}
                                    >
                                        <View
                                            style={[
                                                styles.headerContainer,
                                                {
                                                    padding:
                                                        Layout.screenPaddingXL,
                                                },
                                            ]}
                                        >
                                            <View
                                                style={[
                                                    styles.bodyContent,
                                                    { flexDirection: 'row' },
                                                ]}
                                            >
                                                <View
                                                    style={{
                                                        flex: 1,
                                                        alignSelf: 'center',
                                                        paddingRight:
                                                            Layout.screenPaddingLarge,
                                                        maxWidth: 960,
                                                        // paddingBottom:
                                                        //     Layout.screenPaddingXL,
                                                    }}
                                                >
                                                    <FormHeaderTitle
                                                        // headerTitle={
                                                        //     //context.tokenExists ||
                                                        //     context.userExists
                                                        //         ? 'Studio × noname'
                                                        //         : 'Welcome to Studio'
                                                        // }
                                                        headerTitle={
                                                            'Welcome to Studio'
                                                        }
                                                        headerTitleStyle={[
                                                            TextStyles.Heading0,
                                                            // {
                                                            //     paddingBottom:
                                                            //         Layout.screenPaddingMedium,
                                                            // },
                                                        ]}
                                                    />
                                                    {context.userExists ? (
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 26,
                                                                    paddingBottom:
                                                                        Layout.screenPaddingMedium,
                                                                },
                                                            ]}
                                                        >
                                                            A visionary
                                                            collaboration
                                                            between noname and
                                                            Master Chef
                                                        </Text>
                                                    ) : (
                                                        <View />
                                                    )}
                                                    {context.userExists ? (
                                                        <Button
                                                            iconRight={true}
                                                            iconName={
                                                                'arrow-right'
                                                            }
                                                            iconColor={
                                                                Colors.gray0
                                                            }
                                                            style={{
                                                                flex: 1,
                                                                paddingTop:
                                                                    Layout.screenPaddingMedium,
                                                                alignSelf:
                                                                    'flex-start',
                                                            }}
                                                            buttonStyle={{
                                                                backgroundColor:
                                                                    Colors.tintColor,
                                                            }}
                                                            textStyle={[
                                                                TextStyles.ButtonInverse,
                                                            ]}
                                                            title={
                                                                'Where we started'
                                                            }
                                                            onPress={() => {
                                                                this.props.navigation.navigate(
                                                                    'StudioBrand'
                                                                )
                                                            }}
                                                        />
                                                    ) : (
                                                        <Button
                                                            iconRight={true}
                                                            iconName={
                                                                'arrow-right'
                                                            }
                                                            iconColor={
                                                                Colors.gray0
                                                            }
                                                            style={{
                                                                flex: 1,
                                                                paddingTop:
                                                                    Layout.screenPaddingMedium,
                                                                alignSelf:
                                                                    'flex-start',
                                                            }}
                                                            buttonStyle={{
                                                                backgroundColor:
                                                                    Colors.tintColor,
                                                            }}
                                                            textStyle={[
                                                                TextStyles.ButtonInverse,
                                                            ]}
                                                            onPress={() => {
                                                                this.props.navigation.dispatch(
                                                                    DrawerActions.toggleDrawer()
                                                                )
                                                            }}
                                                            title={
                                                                context.tokenExists
                                                                    ? 'Sign up now'
                                                                    : 'Log in'
                                                            }
                                                        />
                                                    )}
                                                </View>
                                            </View>
                                        </View>
                                    </ImageBackground>
                                )}

                                {/*                                 
                                <View
                                    style={[
                                        styles.sectionContainer,
                                        { backgroundColor: Colors.gray0 },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingBottom: Layout.isMediumDevice
                                                        ? Layout.screenPaddingLarge
                                                        : 0,
                                                }}
                                            >
                                                <Text
                                                    style={TextStyles.Heading2}
                                                >
                                                    Authentic lifestyle brands
                                                    that reach people is our
                                                    specialty
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 36,
                                                        },
                                                    ]}
                                                >
                                                    We are a team of creatives
                                                    and technologists with
                                                    experience launching top
                                                    Food and Hospitality, Tech,
                                                    and Fashion brands. Here to
                                                    help you connect with each
                                                    generation of cultural
                                                    consumers.
                                                </Text>
                                                {context.userExists ? (
                                                    <Button
                                                        iconRight={false}
                                                        iconName={'arrow-right'}
                                                        iconColor={
                                                            Colors.tintColor
                                                        }
                                                        onPress={() => {
                                                            this.props.navigation.navigate(
                                                                'StudioTeam'
                                                            )
                                                        }}
                                                        style={{
                                                            flex: 1,
                                                            alignSelf:
                                                                'flex-start',
                                                            paddingVertical:
                                                                Layout.screenPaddingLarge,
                                                        }}
                                                        buttonStyle={{
                                                            borderWidth: 1,
                                                            backgroundColor:
                                                                Colors.transparentBackground,
                                                            borderColor:
                                                                Colors.tintColor,
                                                        }}
                                                        textStyle={[
                                                            TextStyles.Button,
                                                            {
                                                                color:
                                                                    Colors.tintColor,
                                                            },
                                                        ]}
                                                        title={'Get to know us'}
                                                    />
                                                ) : (
                                                    <View />
                                                )}
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <StudioFooter
                                    navigation={this.props.navigation}
                                /> */}
                            </ScrollView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    mainView: {
        borderTopWidth: 6,
        borderTopColor: Colors.tintColor,
        backgroundColor: Colors.tintColor,
    },

    headerContainer: {
        flex: 1,
        backgroundColor: Layout.isMediumDevice ? Colors.aesop : 'transparent',
    },

    imageContainer: {
        //flex: 1,
        height: Layout.SCREEN_HEIGHT * 0.5,
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        padding: Layout.screenPaddingLarge,
    },

    sectionContainer: {
        alignItems: 'center',
        backgroundColor: Colors.gray0,
        paddingVertical: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        paddingVertical: Layout.screenPaddingLarge,
        paddingHorizontal: Layout.screenPaddingLarge,
    },

    stickyButton: {
        flex: 1,
        position: 'absolute',
        bottom: 0,
        right: 0,
        padding: Layout.isMediumDevice
            ? Layout.screenPaddingMedium
            : Layout.screenPaddingLarge,
    },
})
