import React from 'react'
import {
    ImageBackground,
    Image,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import { Feather, Entypo } from '@expo/vector-icons'
import { Colors, TextStyles, Layout, Icons } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import FormHeaderTitle from '../../components/FormHeaderTitle'
import Button from '../../components/Button'
import StudioFooter from '../../components/StudioFooter'
import GetInTouch from '../../components/GetInTouch'
import ProductFeatures from '../../components/ProductFeaturesCarousel'
import ProductAttributes from '../../components/ProductAttributes'
import ProductExperienceRules from '../../components/ProductExperienceRules'
import BadgeBeta from '../../components/BadgeBeta'
import StudioFootwear from '../../components/StudioFootwear'
import StudioApparel from '../../components/StudioApparel'
import StudioApparelOuter from '../../components/StudioApparelOuter'
import StudioAccessories from '../../components/StudioAccessories'
import ButtonIcon from '../../components/ButtonIcon'

export default class StudioDesign extends React.Component {
    onScroll = async event => {
        const pageYOffset = await event.nativeEvent.contentOffset.y

        if (pageYOffset >= 88) {
            this.props.navigation.setParams({ active: true })
        } else {
            this.props.navigation.setParams({ active: false })
        }
    }

    renderMilestone = ({ text, textColor, containerColor }) => {
        return (
            <View
                style={{
                    paddingRight: Layout.isMediumDevice
                        ? 0
                        : Layout.screenPaddingMedium,
                    paddingVertical: Layout.screenPaddingSmall,
                    width: Layout.isMediumDevice ? '100%' : null,
                }}
            >
                <View
                    style={{
                        padding: Layout.screenPaddingMedium,
                        borderRadius: Layout.borderRadiusMedium,
                        backgroundColor: containerColor,
                    }}
                >
                    <Text
                        style={[
                            TextStyles.Paragraph,
                            {
                                color: textColor,
                                lineHeight: 26,
                            },
                        ]}
                    >
                        {text}
                    </Text>
                </View>
            </View>
        )
    }

    renderFeatureImage = ({ theme, description, image, video }) => {
        return (
            <View
                style={{
                    flex: 1,
                    alignSelf: 'center',
                    alignItems: 'center',
                    minWidth: Layout.isMediumDevice
                        ? '100%'
                        : Layout.SCREEN_WIDTH * 0.3333,
                    paddingVertical: Layout.screenPaddingMedium,
                }}
            >
                <View
                    style={{
                        backgroundColor: Colors.white,
                        paddingVertical: 8,
                        paddingHorizontal: 8,
                        borderRadius: 40,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <View
                        style={{
                            position: 'absolute',
                            top: 0,
                            zIndex: 10,
                            backgroundColor: Colors.white,
                            width: 84,
                            height: 32,
                            borderBottomStartRadius: 12,
                            borderBottomEndRadius: 12,
                        }}
                    />

                    <Image
                        source={image}
                        style={{
                            backgroundColor: Colors.black,
                            borderRadius: 32,
                            width: 270,
                            height: 585,
                        }}
                        resizeMode={'cover'}
                    />
                </View>
            </View>
        )
    }

    renderSlider = () => {
        return (
            <View style={{ height: 9 }}>
                <View
                    style={{
                        flex: 1,
                        borderRadius: 18,
                        backgroundColor: Colors.gray3,
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            width: '75%',
                            borderRadius: 18,
                            backgroundColor: Colors.tintColor,
                        }}
                    />
                </View>
            </View>
        )
    }

    renderSliderMobile = () => {
        return (
            <View
                style={{
                    width: 9,
                    marginRight: Layout.screenPaddingLarge,
                    paddingTop: Layout.screenPaddingSmall,
                    paddingBottom: Layout.screenPaddingLarge,
                }}
            >
                <View
                    style={{
                        flex: 1,
                        borderRadius: 18,
                        backgroundColor: Colors.gray3,
                    }}
                >
                    <View
                        style={{
                            height: '75%',
                            borderRadius: 18,
                            backgroundColor: Colors.tintColor,
                        }}
                    />
                </View>
            </View>
        )
    }

    renderRule = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 2,
                    borderBottomColor: Colors.tintColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSeparator = borderColor => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: borderColor
                        ? borderColor
                        : Colors.border,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSpacer() {
        return (
            <View
                style={{
                    //flex: 1,
                    width: '100%',
                    height: Layout.screenPaddingLarge,
                }}
            />
        )
    }

    render() {
        return (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View
                            style={{
                                flex: 1,
                            }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <ScrollView
                                ref={node => (this.scroll = node)}
                                onScroll={event => this.onScroll(event)}
                                scrollEventThrottle={16}
                                style={{
                                    flex: 1,
                                }}
                                pagingEnabled={
                                    Layout.isMediumDevice ? false : true
                                }
                            >
                                <ImageBackground
                                    source={require('../../assets/images/jc-gellidon-u6-PZMaAoYw-unsplash.jpg')}
                                    style={{
                                        borderTopWidth: 6,
                                        borderTopColor: Colors.tintColor,
                                        backgroundColor: Colors.tintColor,
                                        minHeight: Layout.SCREEN_HEIGHT,
                                        //alignItems: 'center',
                                        //justifyContent: 'center',
                                    }}
                                    resizeMode={'cover'}
                                >
                                    <View
                                        style={{
                                            flex: 1,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor:
                                                Colors.mediumBackground,
                                        }}
                                    >
                                        <View
                                            style={{
                                                //flex: 1,
                                                maxWidth: 960,
                                                paddingHorizontal: Layout.isMediumDevice
                                                    ? Layout.screenPaddingLarge
                                                    : Layout.screenPaddingXL,
                                                // paddingVertical: Layout.isMediumDevice
                                                //     ? Layout.screenPaddingXL * 2
                                                //     : Layout.screenPaddingXL *
                                                //       4,
                                            }}
                                        >
                                            <Text
                                                style={[
                                                    TextStyles.Overline2Inverse,
                                                    {
                                                        alignSelf: Layout.isMediumDevice
                                                            ? 'center'
                                                            : 'center',
                                                        paddingBottom:
                                                            Layout.screenPaddingMedium,
                                                        textAlign: Layout.isMediumDevice
                                                            ? 'auto'
                                                            : 'center',
                                                        textTransform:
                                                            'uppercase',
                                                    },
                                                ]}
                                            >
                                                Design
                                            </Text>
                                            <Text
                                                style={[
                                                    Layout.isMediumDevice
                                                        ? TextStyles.Heading1Inverse
                                                        : TextStyles.Heading0Inverse,
                                                    {
                                                        alignSelf: Layout.isMediumDevice
                                                            ? 'center'
                                                            : 'center',
                                                        paddingBottom:
                                                            Layout.screenPaddingMedium,
                                                        textAlign: Layout.isMediumDevice
                                                            ? 'auto'
                                                            : 'center',
                                                    },
                                                ]}
                                            >
                                                Studio design concepts
                                            </Text>
                                            {/* 
                                            <Button
                                                iconRight={false}
                                                iconName={'arrow-right'}
                                                iconColor={Colors.tintColor}
                                                onPress={() => {
                                                    this.scroll.scrollTo({
                                                        y: Layout.SCREEN_HEIGHT,
                                                    })
                                                }}
                                                style={{
                                                    //flex: 1,
                                                    alignSelf: Layout.isMediumDevice
                                                        ? 'center'
                                                        : 'center',
                                                    paddingTop:
                                                        Layout.screenPaddingMedium,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    borderColor:
                                                        Colors.inverseText,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                }}
                                                textStyle={[
                                                    TextStyles.Button,
                                                    {
                                                        color:
                                                            Colors.inverseText,
                                                    },
                                                ]}
                                                title={'Take a tour'}
                                            /> */}
                                            <ButtonIcon
                                                iconName={'arrow-down'}
                                                iconColor={Colors.inverseText}
                                                onPress={() => {
                                                    this.scroll.scrollTo({
                                                        y: Layout.SCREEN_HEIGHT,
                                                    })
                                                }}
                                                style={{
                                                    alignSelf: Layout.isMediumDevice
                                                        ? 'center'
                                                        : 'center',
                                                    paddingTop:
                                                        Layout.screenPaddingMedium,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    borderColor:
                                                        Colors.inverseText,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                }}
                                            />
                                        </View>
                                    </View>
                                </ImageBackground>

                                {/* <ImageBackground
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            backgroundColor: Colors.gray4,
                                            height: Layout.SCREEN_HEIGHT * 0.5,
                                        },
                                    ]}
                                    source={require('../../assets/images/jc-gellidon-h-WrruqCeGs-unsplash.jpg')}
                                    resizeMode={'cover'}
                                /> */}

                                <View style={styles.sectionContainer}>
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                maxWidth: 720,
                                                alignSelf: 'flex-start',
                                            }}
                                        >
                                            {/* <FormHeaderTitle
                                                headerTitle={
                                                    'Evolving a design framework'
                                                }
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                    {
                                                        paddingBottom:
                                                            Layout.screenPaddingMedium,
                                                    },
                                                ]}
                                            /> */}

                                            <FormHeaderTitle
                                                headerTitle={
                                                    'We channeled our brand values into a design framework, which helped guide our design explorations.'
                                                }
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                    {
                                                        paddingBottom:
                                                            Layout.screenPaddingMedium,
                                                    },
                                                ]}
                                            />

                                            {/* <Text
                                                style={[
                                                    TextStyles.Heading2,
                                                    {
                                                        paddingBottom:
                                                            Layout.screenPaddingXL,
                                                        lineHeight: 36,
                                                    },
                                                ]}
                                            >
                                                Our brand values are channeled
                                                into a meaningful design
                                                framework
                                            </Text> */}
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.sectionContainer}>
                                    <View
                                        style={[
                                            styles.cardContent,
                                            {
                                                maxWidth: 1440,
                                            },
                                        ]}
                                    >
                                        <Text
                                            style={[
                                                TextStyles.Overline2,
                                                {
                                                    color: Colors.primaryText,
                                                    textAlign: 'center',
                                                    textTransform: 'uppercase',
                                                    paddingVertical:
                                                        Layout.screenPaddingSmall,
                                                },
                                            ]}
                                        >
                                            Studio design framework
                                        </Text>
                                        {this.renderRule()}
                                        <View
                                            style={{
                                                //flex: 1,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                alignItems: 'flex-start',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,
                                                    width: '100%',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                        },
                                                    ]}
                                                >
                                                    Identity
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                        },
                                                    ]}
                                                >
                                                    Brief description about this
                                                    idea.
                                                </Text>
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Logomark
                                                    </Text>
                                                </View>
                                                {this.renderSeparator(
                                                    Colors.gray1
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Graphics
                                                    </Text>
                                                </View>
                                                {this.renderSeparator(
                                                    Colors.gray1
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Language and tone
                                                    </Text>
                                                </View>
                                                {this.renderSeparator(
                                                    Colors.gray1
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Colors
                                                    </Text>
                                                </View>
                                                {this.renderSeparator(
                                                    Colors.gray1
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Packaging
                                                    </Text>
                                                </View>
                                                {this.renderSeparator(
                                                    Colors.gray1
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Messaging and voice
                                                    </Text>
                                                </View>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,
                                                    width: '100%',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                        },
                                                    ]}
                                                >
                                                    Wearability
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                        },
                                                    ]}
                                                >
                                                    Brief description about this
                                                    idea.
                                                </Text>
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Footwear
                                                    </Text>
                                                </View>
                                                {this.renderSeparator(
                                                    Colors.gray1
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Apparel
                                                    </Text>
                                                </View>
                                                {this.renderSeparator(
                                                    Colors.gray1
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Accessories
                                                    </Text>
                                                </View>
                                                {this.renderSeparator(
                                                    Colors.gray1
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Portability
                                                    </Text>
                                                </View>
                                                {this.renderSeparator(
                                                    Colors.gray1
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Connectivity
                                                    </Text>
                                                </View>
                                                {this.renderSeparator(
                                                    Colors.gray1
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Technology
                                                    </Text>
                                                </View>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,
                                                    width: '100%',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                        },
                                                    ]}
                                                >
                                                    Metanarrative
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                        },
                                                    ]}
                                                >
                                                    Brief description about this
                                                    idea.
                                                </Text>
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Humanity
                                                    </Text>
                                                </View>
                                                {this.renderSeparator(
                                                    Colors.gray1
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Spectrums of expression
                                                    </Text>
                                                </View>
                                                {this.renderSeparator(
                                                    Colors.gray1
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Seasonality
                                                    </Text>
                                                </View>
                                                {this.renderSeparator(
                                                    Colors.gray1
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Materiality
                                                    </Text>
                                                </View>
                                                {this.renderSeparator(
                                                    Colors.gray1
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Geographies
                                                    </Text>
                                                </View>
                                                {this.renderSeparator(
                                                    Colors.gray1
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Cultural artifacts
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.sectionContainer}>
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                maxWidth: 720,
                                                alignSelf: 'center',
                                                // paddingHorizontal:
                                                //     Layout.screenPaddingLarge,
                                            }}
                                        >
                                            {/* <FormHeaderTitle
                                                headerTitle={
                                                    'Our design approach'
                                                }
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                    {
                                                        textAlign: 'center',
                                                        paddingBottom:
                                                            Layout.screenPaddingMedium,
                                                    },
                                                ]}
                                            /> */}

                                            <Text
                                                style={[
                                                    TextStyles.Heading2,
                                                    {
                                                        textAlign: 'center',
                                                        lineHeight: 36,
                                                    },
                                                ]}
                                            >
                                                Our design approach involves
                                                exploring various spectrums of
                                                expression to observe what
                                                happens at the intersections
                                            </Text>
                                        </View>
                                        <View
                                            style={{
                                                //alignItems: 'flex-start',
                                                // paddingVertical: Layout.isMediumDevice
                                                //     ? 0
                                                //     : Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingHorizontal: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingMedium,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        padding: Layout.isMediumDevice
                                                            ? 0
                                                            : Layout.screenPaddingLarge,
                                                        minWidth: Layout.isMediumDevice
                                                            ? Layout.SCREEN_WIDTH *
                                                              0.75
                                                            : 360,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/images/MatrixBody.png')}
                                                        style={{
                                                            width: Layout.isMediumDevice
                                                                ? '100%'
                                                                : 360,
                                                            height: 360,
                                                        }}
                                                        resizeMode={'contain'}
                                                    />
                                                </View>

                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            textAlign: 'center',
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    Body Matrix
                                                </Text>

                                                {/* <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            textAlign: 'center',
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    Brief description
                                                </Text> */}
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingHorizontal: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingMedium,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        padding: Layout.isMediumDevice
                                                            ? 0
                                                            : Layout.screenPaddingLarge,
                                                        minWidth: Layout.isMediumDevice
                                                            ? Layout.SCREEN_WIDTH *
                                                              0.75
                                                            : 360,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/images/MatrixEnvironment.png')}
                                                        style={{
                                                            width: Layout.isMediumDevice
                                                                ? '100%'
                                                                : 360,
                                                            height: 360,
                                                        }}
                                                        resizeMode={'contain'}
                                                    />
                                                </View>

                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            textAlign: 'center',
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    Environment Matrix
                                                </Text>

                                                {/* <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            textAlign: 'center',
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    Brief description
                                                </Text> */}
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingHorizontal: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingMedium,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        alignSelf: 'center',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        padding: Layout.isMediumDevice
                                                            ? 0
                                                            : Layout.screenPaddingLarge,
                                                        minWidth: Layout.isMediumDevice
                                                            ? Layout.SCREEN_WIDTH *
                                                              0.75
                                                            : 360,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/images/MatrixConcept.png')}
                                                        style={{
                                                            width: Layout.isMediumDevice
                                                                ? '100%'
                                                                : 360,
                                                            height: 360,
                                                        }}
                                                        resizeMode={'contain'}
                                                    />
                                                </View>

                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            textAlign: 'center',
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    Concept Matrix
                                                </Text>

                                                {/* <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            textAlign: 'center',
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    Brief description
                                                </Text> */}
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                {/* 
                                <View style={styles.sectionContainer}>
                                    <View
                                        style={[
                                            styles.bodyContent,
                                            {
                                                paddingBottom: Layout.isMediumDevice
                                                    ? null
                                                    : Layout.screenPaddingXL *
                                                      2,
                                            },
                                        ]}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                maxWidth: 720,
                                                alignSelf: 'center',
                                                paddingVertical: Layout.isMediumDevice
                                                    ? Layout.screenPaddingMedium
                                                    : Layout.screenPaddingXL,
                                            }}
                                        >
                                            <Text
                                                style={[
                                                    Layout.isMediumDevice
                                                        ? TextStyles.Heading1
                                                        : TextStyles.Heading0,
                                                    {
                                                        textAlign: Layout.isMediumDevice
                                                            ? 'left'
                                                            : 'center',
                                                    },
                                                ]}
                                            >
                                                Studio for You
                                            </Text>
                                        </View>
                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    maxWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    paddingVertical:
                                                        Layout.screenPaddingMedium,
                                                    marginRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingXL,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading2,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                        },
                                                    ]}
                                                >
                                                    Inspired footwear, apparel
                                                    and accessories, made for
                                                    you
                                                </Text>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Informed by the real
                                                        world design challenges
                                                        of the people of Food
                                                        and Hospitality
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Inspired by the orbit of
                                                        Creative professionals
                                                        that overlap with the
                                                        world of Master Chef
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Designed to meet the
                                                        lifestyle needs of all
                                                        people who are honing
                                                        their craft
                                                    </Text>
                                                </View>
                                            </View>

                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    paddingVertical: Layout.isMediumDevice
                                                        ? Layout.screenPaddingMedium
                                                        : null,
                                                }}
                                            >
                                                <Image
                                                    source={require('../../assets/images/remy-penet-KlplStZsUeI-unsplash.jpg')}
                                                    style={{
                                                        // borderRadius: 1000,
                                                        // overflow: 'hidden',
                                                        alignSelf: 'flex-end',
                                                        width: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_HEIGHT *
                                                              0.618
                                                            : 480,
                                                    }}
                                                    resizeMode={'cover'}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </View>
 */}

                                <View style={styles.sectionContainer}>
                                    <ImageBackground
                                        style={{ width: '100%' }}
                                        source={{}}
                                        //source={require('../../assets/images/jc-gellidon-c61FgpW3cXM-unsplash.jpg')}
                                        resizeMode={'cover'}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                // width: '100%',
                                                // height:
                                                //     Layout.SCREEN_HEIGHT *
                                                //     0.382,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                // backgroundColor:
                                                //     Colors.mediumBackground,
                                                padding:
                                                    Layout.screenPaddingLarge,
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={'Footwear'}
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                    {
                                                        //color: Colors.gray0,
                                                        alignSelf: 'center',
                                                        textAlign: 'center',
                                                    },
                                                ]}
                                            />
                                        </View>
                                    </ImageBackground>
                                    <StudioFootwear />
                                </View>

                                <View style={styles.sectionContainer}>
                                    <ImageBackground
                                        style={{ width: '100%' }}
                                        source={{}}
                                        //source={require('../../assets/images/ian-simmonds-pNG31nypHWE-unsplash.jpg')}
                                        resizeMode={'cover'}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                // width: '100%',
                                                // height:
                                                //     Layout.SCREEN_HEIGHT *
                                                //     0.382,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                // backgroundColor:
                                                //     Colors.mediumBackground,
                                                padding:
                                                    Layout.screenPaddingLarge,
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={'Tops and Pants'}
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                    {
                                                        //color: Colors.gray0,
                                                        alignSelf: 'center',
                                                        textAlign: 'center',
                                                    },
                                                ]}
                                            />
                                        </View>
                                    </ImageBackground>
                                    <StudioApparel />
                                </View>

                                <View style={styles.sectionContainer}>
                                    <ImageBackground
                                        style={{ width: '100%' }}
                                        source={{}}
                                        //source={require('../../assets/images/fabian-irsara-_SwLdgVGfVY-unsplash.jpg')}
                                        resizeMode={'cover'}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                // width: '100%',
                                                // height:
                                                //     Layout.SCREEN_HEIGHT *
                                                //     0.382,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                // backgroundColor:
                                                //     Colors.mediumBackground,
                                                padding:
                                                    Layout.screenPaddingLarge,
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={
                                                    'Coats and Jackets'
                                                }
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                    {
                                                        //color: Colors.gray0,
                                                        alignSelf: 'center',
                                                        textAlign: 'center',
                                                    },
                                                ]}
                                            />
                                        </View>
                                    </ImageBackground>
                                    <StudioApparelOuter />
                                </View>

                                <View style={styles.sectionContainer}>
                                    <ImageBackground
                                        style={{ width: '100%' }}
                                        source={{}}
                                        //source={require('../../assets/images/ray-fragapane-PkKIVq1CUuY-unsplash.jpg')}
                                        resizeMode={'cover'}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                // width: '100%',
                                                // height:
                                                //     Layout.SCREEN_HEIGHT *
                                                //     0.382,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                // backgroundColor:
                                                //     Colors.mediumBackground,
                                                padding:
                                                    Layout.screenPaddingLarge,
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={'Accessories'}
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                    {
                                                        //color: Colors.gray0,
                                                        alignSelf: 'center',
                                                        textAlign: 'center',
                                                    },
                                                ]}
                                            />
                                        </View>
                                    </ImageBackground>
                                    <StudioAccessories />
                                </View>

                                {/* 
                                <View
                                    style={[
                                        styles.sectionContainer,
                                        { backgroundColor: Colors.gray0 },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    maxWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    paddingVertical:
                                                        Layout.screenPaddingMedium,
                                                    marginRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingXL,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading2,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                        },
                                                    ]}
                                                >
                                                    How restaurants use Indvstry
                                                </Text>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Recurring dining
                                                        experiences
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Special events, pop-ups
                                                        and holidays
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Chef's table, group and
                                                        communal dining
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Prepaid service with
                                                        beverage pairings
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Wine and beer tastings
                                                    </Text>
                                                </View>

                                                <Button
                                                    iconRight={false}
                                                    iconName={'arrow-right'}
                                                    iconColor={Colors.tintColor}
                                                    onPress={() => {
                                                        this.props.navigation.navigate(
                                                            'GetCreatorApp'
                                                        )
                                                    }}
                                                    style={{
                                                        flex: 1,
                                                        alignSelf: 'flex-start',
                                                        paddingVertical:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                    buttonStyle={{
                                                        borderWidth: 1,
                                                        backgroundColor:
                                                            Colors.transparentBackground,
                                                        borderColor:
                                                            Colors.tintColor,
                                                    }}
                                                    textStyle={[
                                                        TextStyles.Button,
                                                        {
                                                            color:
                                                                Colors.tintColor,
                                                        },
                                                    ]}
                                                    title={
                                                        'Get the Indvstry App'
                                                    }
                                                />
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    paddingVertical:
                                                        Layout.screenPaddingMedium,
                                                }}
                                            >
                                                <Image
                                                    source={require('../../assets/images/kayleigh-harrington-yhn4okt6ci0-unsplash.jpg')}
                                                    style={{
                                                        width: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_HEIGHT *
                                                              0.618
                                                            : 480,
                                                    }}
                                                    resizeMode={'cover'}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                 */}

                                {/* 
                                <View
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            backgroundColor: Colors.aesop,
                                        },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                flex: 1,
                                                maxWidth: 720,
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={
                                                    'Powered by your people'
                                                }
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                    { textAlign: 'center' },
                                                ]}
                                            />
                                            <Text
                                                style={[
                                                    TextStyles.Paragraph,
                                                    {
                                                        lineHeight: 26,
                                                        textAlign: 'center',
                                                    },
                                                ]}
                                            >
                                                Supports an inclusive and
                                                collaborative workplace culture
                                            </Text>
                                        </View>
                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                alignItems: 'stretch',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={[
                                                    styles.cardContent,
                                                    {
                                                        padding: 0,
                                                        marginVertical: 0,
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                    },
                                                ]}
                                            >
                                                <Image
                                                    source={require('../../assets/images/jeff-siepman-kyuPjZudBKs-unsplash.jpg')}
                                                    style={{
                                                        width: '100%',
                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_HEIGHT *
                                                              0.5
                                                            : '100%',
                                                    }}
                                                    resizeMode={'cover'}
                                                />
                                            </View>

                                            <View
                                                style={[
                                                    styles.cardContent,
                                                    {
                                                        marginVertical: 0,
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingSmall,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Overline2,
                                                            {
                                                                color:
                                                                    Colors.primaryText,
                                                                paddingVertical:
                                                                    Layout.screenPaddingSmall,
                                                                textAlign:
                                                                    'center',
                                                                textTransform:
                                                                    'uppercase',
                                                            },
                                                        ]}
                                                    >
                                                        Exclusively Featuring
                                                    </Text>
                                                    {this.renderRule()}
                                                </View>
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Hosts and servers
                                                    </Text>
                                                    {/* <Text
                                                        style={
                                                            TextStyles.Heading1
                                                        }
                                                    >
                                                        5,089,620
                                                    </Text> 
                                                </View>
                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Chefs and cooks
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}

                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Bartenders and baristas
                                                    </Text>
                                                </View>
                                                {this.renderSeparator()}

                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Sommeliers
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}

                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Managers
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View> */}

                                {/* <View
                                    style={[
                                        styles.sectionContainer,
                                        { backgroundColor: Colors.gray0 },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                flex: 1,
                                                maxWidth: 720,
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={'The perfect pair'}
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                    { textAlign: 'center' },
                                                ]}
                                            />
                                            <Text
                                                style={[
                                                    TextStyles.Paragraph,
                                                    {
                                                        lineHeight: 26,
                                                        textAlign: 'center',
                                                        color: 'red',
                                                    },
                                                ]}
                                            >
                                                Cosmic's and Master Chef are a
                                                great partnership match based on
                                                this main idea.
                                            </Text>
                                        </View>

                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    padding:
                                                        Layout.screenPaddingMedium,
                                                }}
                                            >
                                                <Image
                                                    source={require('../../assets/images/eric-ward-LsPw-DE_GAs-unsplash.jpg')}
                                                    style={{
                                                        borderRadius: 1000,
                                                        overflow: 'hidden',
                                                        width: Layout.isMediumDevice
                                                            ? Layout.SCREEN_WIDTH *
                                                              0.75
                                                            : 480,
                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_WIDTH *
                                                              0.75
                                                            : 480,
                                                    }}
                                                    resizeMode={'cover'}
                                                />
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    padding:
                                                        Layout.screenPaddingMedium,
                                                }}
                                            >
                                                <Image
                                                    source={require('../../assets/images/manuel-vincent-KRQYcW1sXlc-unsplash.jpg')}
                                                    style={{
                                                        borderRadius: 1000,
                                                        overflow: 'hidden',
                                                        width: Layout.isMediumDevice
                                                            ? Layout.SCREEN_WIDTH *
                                                              0.75
                                                            : 480,
                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_WIDTH *
                                                              0.75
                                                            : 480,
                                                    }}
                                                    resizeMode={'cover'}
                                                />
                                            </View>
                                        </View>

                                        <View
                                            style={{
                                                flex: 1,
                                                alignSelf: 'center',
                                                minWidth: Layout.isMediumDevice
                                                    ? '100%'
                                                    : 720,
                                                maxWidth: Layout.isMediumDevice
                                                    ? '100%'
                                                    : 720,
                                                paddingVertical:
                                                    Layout.screenPaddingMedium,
                                            }}
                                        >
                                            <Text
                                                style={[
                                                    TextStyles.Heading2,
                                                    {
                                                        paddingBottom:
                                                            Layout.screenPaddingLarge,
                                                        color: 'red',
                                                    },
                                                ]}
                                            >
                                                How Cosmic's and Master Chef's
                                                values are aligned and on the
                                                same page
                                            </Text>

                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Feather
                                                    style={{
                                                        alignSelf: 'center',
                                                        paddingRight: 12,
                                                    }}
                                                    color={Colors.tintColor}
                                                    size={Icons.small}
                                                    name={'check'}
                                                />
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                            color: 'red',
                                                        },
                                                    ]}
                                                >
                                                    Partnership alignment or
                                                    opportunity reason 1
                                                </Text>
                                            </View>

                                            {this.renderSeparator(Colors.gray3)}
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Feather
                                                    style={{
                                                        alignSelf: 'center',
                                                        paddingRight: 12,
                                                    }}
                                                    color={Colors.tintColor}
                                                    size={Icons.small}
                                                    name={'check'}
                                                />
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                            color: 'red',
                                                        },
                                                    ]}
                                                >
                                                    Partnership alignment or
                                                    opportunity reason 2
                                                </Text>
                                            </View>

                                            {this.renderSeparator(Colors.gray3)}
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Feather
                                                    style={{
                                                        alignSelf: 'center',
                                                        paddingRight: 12,
                                                    }}
                                                    color={Colors.tintColor}
                                                    size={Icons.small}
                                                    name={'check'}
                                                />
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                            color: 'red',
                                                        },
                                                    ]}
                                                >
                                                    Partnership alignment or
                                                    opportunity reason 3
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View> */}

                                {/* 
                                <ImageBackground
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            minHeight: Layout.SCREEN_HEIGHT,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        },
                                    ]}
                                    source={require('../../assets/images/denys-argyriou-220305-unsplash.jpg')}
                                    resizeMode={'cover'}
                                >
                                    <View
                                        style={[
                                            styles.bodyContent,
                                            {
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                // paddingVertical:
                                                //     Layout.screenPaddingXL * 2,
                                            },
                                        ]}
                                    >
                                        <View
                                            style={[
                                                styles.cardContent,
                                                {
                                                    alignSelf: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 720,
                                                },
                                            ]}
                                        >
                                            <Text
                                                style={[
                                                    TextStyles.Heading2,
                                                    {
                                                        paddingBottom:
                                                            Layout.screenPaddingLarge,
                                                    },
                                                ]}
                                            >
                                                Bringing the vision to life
                                            </Text>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    // paddingBottom:
                                                    //     Layout.screenPaddingLarge,
                                                    flexDirection: Layout.isMediumDevice
                                                        ? 'row'
                                                        : 'column',
                                                }}
                                            >
                                                {Layout.isMediumDevice
                                                    ? this.renderSliderMobile()
                                                    : this.renderSlider()}
                                                <View
                                                    style={{
                                                        flex: 1,
                                                        paddingVertical: Layout.isMediumDevice
                                                            ? 0
                                                            : Layout.screenPaddingMedium,

                                                        flexDirection: Layout.isMediumDevice
                                                            ? 'column'
                                                            : 'row',
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            flex: 1,
                                                        }}
                                                    >
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 36,
                                                                },
                                                            ]}
                                                        >
                                                            Ideating
                                                        </Text>
                                                        <Text
                                                            style={[
                                                                TextStyles.Caption,
                                                                {
                                                                    paddingBottom:
                                                                        Layout.screenPaddingMedium,
                                                                },
                                                            ]}
                                                        >
                                                            Winter 2019
                                                        </Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            flex: 1,
                                                            alignItems: Layout.isMediumDevice
                                                                ? 'flex-start'
                                                                : 'center',
                                                        }}
                                                    >
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    textAlign:
                                                                        'center',
                                                                    lineHeight: 36,
                                                                },
                                                            ]}
                                                        >
                                                            Concept sketching
                                                        </Text>
                                                        <Text
                                                            style={[
                                                                TextStyles.Caption,
                                                                {
                                                                    textAlign:
                                                                        'center',
                                                                    paddingBottom:
                                                                        Layout.screenPaddingMedium,
                                                                },
                                                            ]}
                                                        >
                                                            Summer 2019
                                                        </Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            flex: 1,
                                                            alignItems: Layout.isMediumDevice
                                                                ? 'flex-start'
                                                                : 'flex-end',
                                                        }}
                                                    >
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 36,
                                                                },
                                                            ]}
                                                        >
                                                            Prototyping
                                                        </Text>
                                                        <Text
                                                            style={[
                                                                TextStyles.Caption,
                                                                {
                                                                    paddingBottom:
                                                                        Layout.screenPaddingMedium,
                                                                },
                                                            ]}
                                                        >
                                                            Winter 2020
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>

                                            <Button
                                                iconRight={false}
                                                iconName={'arrow-right'}
                                                iconColor={Colors.tintColor}
                                                onPress={() => {
                                                    this.props.navigation.navigate(
                                                        'StudioContact'
                                                    )
                                                }}
                                                style={{
                                                    //flex: 1,
                                                    width: Layout.isMediumDevice
                                                        ? '100%'
                                                        : null,
                                                    alignSelf: Layout.isMediumDevice
                                                        ? 'center'
                                                        : 'flex-start',
                                                    paddingTop:
                                                        Layout.screenPaddingMedium,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                    borderColor:
                                                        Colors.tintColor,
                                                }}
                                                textStyle={[
                                                    TextStyles.Button,
                                                    {
                                                        color:
                                                            Colors.primaryText,
                                                    },
                                                ]}
                                                title={'Let’s work together'}
                                            />
                                        </View>
                                    </View>
                                </ImageBackground> */}

                                <ImageBackground
                                    style={styles.sectionContainer}
                                    source={require('../../assets/images/jc-gellidon-h-WrruqCeGs-unsplash.jpg')}
                                    resizeMode={'cover'}
                                >
                                    <View
                                        style={[
                                            styles.bodyContent,
                                            {
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                // paddingVertical:
                                                //     Layout.screenPaddingXL * 2,
                                            },
                                        ]}
                                    >
                                        <View
                                            style={[
                                                styles.cardContent,
                                                {
                                                    alignSelf: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                },
                                            ]}
                                        >
                                            <View
                                                style={{
                                                    //flex: 1,
                                                    paddingBottom:
                                                        Layout.screenPaddingLarge,
                                                }}
                                            >
                                                {/* {this.renderRule()} */}

                                                <Text
                                                    style={[
                                                        TextStyles.Heading2,
                                                        {
                                                            // textAlign: Layout.isMediumDevice
                                                            //     ? 'center'
                                                            //     : 'left',
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                        },
                                                    ]}
                                                >
                                                    The people behind Studio
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            // textAlign: Layout.isMediumDevice
                                                            //     ? 'center'
                                                            //     : 'left',
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    We are a collective of
                                                    creatives with a heart for
                                                    Hospitality.
                                                </Text>
                                            </View>

                                            <Button
                                                iconRight={true}
                                                iconName={'arrow-right'}
                                                iconColor={Colors.tintColor}
                                                onPress={() => {
                                                    this.props.navigation.navigate(
                                                        'StudioTeam'
                                                    )
                                                }}
                                                style={{
                                                    //flex: 1,
                                                    width: Layout.isMediumDevice
                                                        ? '100%'
                                                        : null,
                                                    alignSelf: Layout.isMediumDevice
                                                        ? 'center'
                                                        : 'flex-start',
                                                    // paddingTop:
                                                    //     Layout.screenPaddingSmall,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                    borderColor:
                                                        Colors.tintColor,
                                                }}
                                                textStyle={[
                                                    TextStyles.Button,
                                                    {
                                                        color:
                                                            Colors.primaryText,
                                                    },
                                                ]}
                                                title={'Meet the team'}
                                            />
                                        </View>
                                    </View>
                                </ImageBackground>

                                {/* <GetInTouch
                                    navigation={this.props.navigation}
                                    headerTitle={'Let’s work together'}
                                    message={
                                        'Talk to a Studio team member who can take you through a demo of everything Master Chef has envisioned.'
                                    }
                                    buttonTitle={'Get involved'}
                                /> */}

                                {/* <StudioFooter
                                    navigation={this.props.navigation}
                                /> */}
                            </ScrollView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    mainView: {
        flex: 1,
        borderTopWidth: 6,
        paddingTop: 88,
        borderTopColor: Colors.tintColor,
        backgroundColor: Colors.gray5,
    },

    sectionContainer: {
        height: Layout.isMediumDevice ? null : Layout.SCREEN_HEIGHT,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.gray0,
    },

    cardContent: {
        //flex: 1,
        maxWidth: 1440, // Normally 720
        marginVertical: Layout.screenPaddingMedium,
        backgroundColor: Colors.white,
        padding: Layout.screenPaddingLarge,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: Layout.screenPaddingLarge,
    },

    imageContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        padding: Layout.screenPaddingLarge,
    },
})
