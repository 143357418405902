import React from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { Colors, TextStyles, Layout, Icons } from '../constants'
import FormHeaderTitle from './FormHeaderTitle'

export default class ProductExperienceRules extends React.Component {
    renderMilestone = ({ text, textColor, containerColor }) => {
        return (
            <View
                style={{
                    paddingRight: Layout.isMediumDevice
                        ? 0
                        : Layout.screenPaddingMedium,
                    paddingVertical: Layout.screenPaddingSmall,
                    width: Layout.isMediumDevice ? '100%' : null,
                }}
            >
                <View
                    style={{
                        padding: Layout.screenPaddingMedium,
                        borderRadius: Layout.borderRadiusMedium,
                        backgroundColor: containerColor,
                    }}
                >
                    <Text
                        style={[
                            TextStyles.Paragraph,
                            {
                                color: textColor,
                                lineHeight: 26,
                            },
                        ]}
                    >
                        {text}
                    </Text>
                </View>
            </View>
        )
    }

    renderRule = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 2,
                    borderBottomColor: Colors.tintColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSeparator = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    render() {
        return (
            <View
                style={[
                    styles.sectionContainer,
                    {
                        backgroundColor: Colors.gray2,
                        paddingVertical: Layout.isMediumDevice
                            ? Layout.screenPaddingLarge
                            : Layout.screenPaddingXL * 2,
                    },
                ]}
            >
                <View style={styles.bodyContent}>
                    <View
                        style={{
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                            paddingBottom: Layout.isMediumDevice
                                ? Layout.screenPaddingLarge
                                : Layout.screenPaddingXL,
                            justifyContent: 'center',
                            alignItems: 'center',
                        }}
                    >
                        <View
                            style={{
                                flex: 1,
                                minWidth: Layout.isMediumDevice ? '100%' : null,

                                //maxWidth: 720,
                                //alignSelf: 'flex-start',
                                //alignItems: 'center',
                            }}
                        >
                            <FormHeaderTitle
                                headerTitle={'Experience still rules'}
                                headerTitleStyle={[
                                    TextStyles.Heading1,
                                    // { textAlign: 'center' },
                                ]}
                            />
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        lineHeight: 26,
                                        // textAlign: 'center',
                                        maxWidth: 480,
                                    },
                                ]}
                            >
                                Restaurant guests consume in-person dining
                                experiences way more than food delivery, but
                                will increasingly pursue mobile apps for access
                                to both.
                            </Text>
                        </View>
                        <View
                            style={{
                                flex: 1,
                                minWidth: Layout.isMediumDevice ? '100%' : null,

                                // alignItems: 'center',
                            }}
                        >
                            <Image
                                source={require('../assets/images/FoodExperienceVsDelivery.png')}
                                style={{
                                    alignSelf: 'flex-start',
                                    width: Layout.isMediumDevice ? '100%' : 400,
                                    height: 400,
                                }}
                                resizeMode={'contain'}
                            />
                        </View>
                    </View>

                    {this.renderRule()}
                    <View
                        style={{
                            alignItems: 'flex-start',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                        }}
                    >
                        <View
                            style={{
                                flex: 1,
                                paddingRight: Layout.isMediumDevice
                                    ? 0
                                    : Layout.screenPaddingLarge,
                                paddingVertical: Layout.screenPaddingMedium,
                                minWidth: Layout.isMediumDevice ? '100%' : 360,
                            }}
                        >
                            <Text
                                style={[
                                    TextStyles.Heading3,
                                    {
                                        paddingBottom:
                                            Layout.screenPaddingSmall,
                                    },
                                ]}
                            >
                                Mobile content in 2019
                            </Text>

                            {this.renderMilestone({
                                text:
                                    'Social media and influencers curently rank as the 1st influence lever at 39%, for discerning consumers. This is followed by Magazines (both traditional and digital) and Brands (websites and apps).',
                                textColor: Colors.tintColor,
                                containerColor: Colors.gray0,
                            })}
                        </View>
                        <View
                            style={{
                                flex: 1,
                                paddingRight: Layout.isMediumDevice
                                    ? 0
                                    : Layout.screenPaddingLarge,
                                paddingVertical: Layout.screenPaddingMedium,
                                minWidth: Layout.isMediumDevice ? '100%' : 360,
                            }}
                        >
                            <Text
                                style={[
                                    TextStyles.Heading3,
                                    {
                                        paddingBottom:
                                            Layout.screenPaddingSmall,
                                    },
                                ]}
                            >
                                By 2021
                            </Text>

                            {this.renderMilestone({
                                text:
                                    'Video consumption on smartphones will continue growing across the globe and account for up to 80% of all mobile data traffic.',
                                textColor: Colors.tintColor,
                                containerColor: Colors.gray0,
                            })}
                        </View>
                        <View
                            style={{
                                flex: 1,
                                paddingRight: Layout.isMediumDevice
                                    ? 0
                                    : Layout.screenPaddingLarge,
                                paddingVertical: Layout.screenPaddingMedium,
                                minWidth: Layout.isMediumDevice ? '100%' : 360,
                            }}
                        >
                            <Text
                                style={[
                                    TextStyles.Heading3,
                                    {
                                        paddingBottom:
                                            Layout.screenPaddingSmall,
                                    },
                                ]}
                            >
                                By 2024
                            </Text>

                            {this.renderMilestone({
                                text:
                                    'Discerning consumers will make up to 90% of purchases on a mobile device vs laptop.',
                                textColor: Colors.tintColor,
                                containerColor: Colors.gray0,
                            })}
                        </View>
                    </View>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        alignItems: 'center',
        backgroundColor: Colors.gray0,
        paddingVertical: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        paddingVertical: Layout.screenPaddingLarge,
        paddingHorizontal: Layout.screenPaddingLarge,
    },
})
