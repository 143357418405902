import Colors from './Colors'

const regular = 'neuzeit-s'
const bold = 'neuzeit-s-bold'
const logotype = 'mattone-regular'

const shadowColor = '#000000'
const shadowOffset = { width: 0, height: 1 }
const shadowOpacity = 0.2
const shadowRadius = 2

export default {
    LogotypeLarge: {
        fontFamily: logotype,
        fontSize: 26,
        color: Colors.primaryText,
        letterSpacing: -0.15,
        textTransform: 'uppercase',
    },
    Logotype: {
        fontFamily: logotype,
        fontSize: 20,
        color: Colors.primaryText,
        letterSpacing: -0.15,
        textTransform: 'uppercase',
    },
    Heading0: {
        fontFamily: regular,
        fontSize: 88,
        letterSpacing: -1.5,
        color: Colors.primaryText,
    },
    Heading1: {
        fontFamily: regular,
        fontSize: 44, //36
        letterSpacing: -1,
        color: Colors.primaryText,
    },
    Heading2: {
        fontFamily: bold,
        fontSize: 32, //28
        letterSpacing: -0.5,
        color: Colors.primaryText,
    },
    Heading3: {
        fontFamily: bold,
        fontSize: 22, //21
        color: Colors.primaryText,
        letterSpacing: -0.25,
    },
    Heading4: {
        fontFamily: bold,
        fontSize: 18,
        color: Colors.primaryText,
        //letterSpacing: -0.25,
    },
    Subtitle: {
        fontFamily: regular,
        fontSize: 18,
        color: Colors.secondaryText,
    },
    Paragraph: {
        fontFamily: regular,
        fontSize: 18,
        color: Colors.primaryText,
        //lineHeight: 28,
    },
    Caption: {
        fontFamily: regular,
        fontSize: 14,
        color: Colors.secondaryText,
        letterSpacing: 0.5,
    },
    CaptionBold: {
        fontFamily: bold,
        fontSize: 14,
        color: Colors.secondaryText,
    },
    Overline1: {
        fontFamily: regular,
        fontSize: 15,
        color: Colors.primaryText,
        letterSpacing: 1.75,
    },
    Overline2: {
        fontFamily: bold,
        fontSize: 12,
        color: Colors.primaryText,
        letterSpacing: 2.5,
    },
    Overline3: {
        fontFamily: regular,
        fontSize: 12,
        color: Colors.primaryText,
        letterSpacing: 0.75,
    },
    Anchor: {
        fontFamily: bold,
        fontSize: 18,
        color: Colors.primaryText,
        borderBottom: 1,
        borderBottomColor: Colors.tintColor,
    },

    Button: {
        fontFamily: bold,
        fontSize: 16,
        color: Colors.primaryText,
    },

    ButtonRegular: {
        fontFamily: regular,
        fontSize: 16,
        color: Colors.primaryText,
    },

    // Inverse text with shadow
    LogotypeLargeInverse: {
        fontFamily: logotype,
        fontSize: 26, //34
        color: Colors.inverseText,
        letterSpacing: -0.15,
        textTransform: 'uppercase',
    },

    LogotypeInverse: {
        fontFamily: logotype,
        fontSize: 20,
        color: Colors.inverseText,
        letterSpacing: -0.15,
        textTransform: 'uppercase',
    },

    Heading0Inverse: {
        fontFamily: regular,
        fontSize: 88,
        letterSpacing: -1.5,
        color: Colors.inverseText,
    },

    Heading1Inverse: {
        fontFamily: regular,
        fontSize: 44, //36
        letterSpacing: -1,
        color: Colors.inverseText,

        // shadowColor: shadowColor,
        // shadowOffset: shadowOffset,
        // shadowOpacity: shadowOpacity,
        // shadowRadius: shadowRadius,
    },

    Heading2Inverse: {
        fontFamily: bold,
        fontSize: 32, //28
        letterSpacing: -0.5,
        color: Colors.inverseText,

        // shadowColor: shadowColor,
        // shadowOffset: shadowOffset,
        // shadowOpacity: shadowOpacity,
        // shadowRadius: shadowRadius,
    },

    Heading3Inverse: {
        fontFamily: bold,
        fontSize: 22, //21
        color: Colors.inverseText,
        letterSpacing: -0.25,

        // shadowColor: shadowColor,
        // shadowOffset: shadowOffset,
        // shadowOpacity: shadowOpacity,
        // shadowRadius: shadowRadius,
    },

    Heading4Inverse: {
        fontFamily: bold,
        fontSize: 18,
        color: Colors.inverseText,

        shadowColor: shadowColor,
        shadowOffset: shadowOffset,
        shadowOpacity: shadowOpacity,
        shadowRadius: shadowRadius,
    },

    ParagraphInverse: {
        fontFamily: regular,
        fontSize: 18,
        color: Colors.inverseText,

        // shadowColor: shadowColor,
        // shadowOffset: shadowOffset,
        // shadowOpacity: shadowOpacity,
        // shadowRadius: shadowRadius,
    },

    CaptionBoldInverse: {
        fontFamily: bold,
        fontSize: 14,
        color: Colors.inverseText,

        // shadowColor: shadowColor,
        // shadowOffset: shadowOffset,
        // shadowOpacity: shadowOpacity,
        // shadowRadius: shadowRadius,
    },

    CaptionInverse: {
        fontFamily: regular,
        fontSize: 14,
        color: Colors.inverseText,
        letterSpacing: 0.5,

        // shadowColor: shadowColor,
        // shadowOffset: shadowOffset,
        // shadowOpacity: shadowOpacity,
        // shadowRadius: shadowRadius,
    },
    Overline1Inverse: {
        fontFamily: regular,
        fontSize: 15,
        color: Colors.inverseText,
        letterSpacing: 1.75,

        // shadowColor: shadowColor,
        // shadowOffset: shadowOffset,
        // shadowOpacity: shadowOpacity,
        // shadowRadius: shadowRadius,
    },

    Overline2Inverse: {
        fontFamily: bold,
        fontSize: 12,
        color: Colors.inverseText,
        letterSpacing: 2.5,

        // shadowColor: shadowColor,
        // shadowOffset: shadowOffset,
        // shadowOpacity: shadowOpacity,
        // shadowRadius: shadowRadius,
    },

    Overline3Inverse: {
        fontFamily: regular,
        fontSize: 12,
        color: Colors.inverseText,
        letterSpacing: 0.75,

        // shadowColor: shadowColor,
        // shadowOffset: shadowOffset,
        // shadowOpacity: shadowOpacity,
        // shadowRadius: shadowRadius,
    },

    ButtonInverse: {
        fontFamily: bold,
        fontSize: 16,
        color: Colors.inverseText,

        // shadowColor: shadowColor,
        // shadowOffset: shadowOffset,
        // shadowOpacity: shadowOpacity,
        // shadowRadius: shadowRadius,
    },
}
