import React from 'react'
import {
    ImageBackground,
    Image,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import { Feather, Entypo } from '@expo/vector-icons'
import { Colors, TextStyles, Layout, Icons } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import FormHeaderTitle from '../../components/FormHeaderTitle'
import Button from '../../components/Button'
import Footer from '../../components/Footer'
import GetInTouch from '../../components/GetInTouch'
import ProductFeatures from '../../components/ProductFeaturesCarousel'
import ProductAttributes from '../../components/ProductAttributes'
import ProductExperienceRules from '../../components/ProductExperienceRules'
import BadgeBeta from '../../components/BadgeBeta'

export default class Restaurants extends React.Component {
    onScroll = async event => {
        const pageYOffset = await event.nativeEvent.contentOffset.y

        if (pageYOffset >= 88) {
            this.props.navigation.setParams({ active: true })
        } else {
            this.props.navigation.setParams({ active: false })
        }
    }

    renderMilestone = ({ text, textColor, containerColor }) => {
        return (
            <View
                style={{
                    paddingRight: Layout.isMediumDevice
                        ? 0
                        : Layout.screenPaddingMedium,
                    paddingVertical: Layout.screenPaddingSmall,
                    width: Layout.isMediumDevice ? '100%' : null,
                }}
            >
                <View
                    style={{
                        padding: Layout.screenPaddingMedium,
                        borderRadius: Layout.borderRadiusMedium,
                        backgroundColor: containerColor,
                    }}
                >
                    <Text
                        style={[
                            TextStyles.Paragraph,
                            {
                                color: textColor,
                                lineHeight: 26,
                            },
                        ]}
                    >
                        {text}
                    </Text>
                </View>
            </View>
        )
    }

    renderFeatureImage = ({ theme, description, image, video }) => {
        return (
            <View
                style={{
                    flex: 1,
                    alignSelf: 'center',
                    alignItems: 'center',
                    minWidth: Layout.isMediumDevice
                        ? '100%'
                        : Layout.SCREEN_WIDTH * 0.3333,
                    paddingVertical: Layout.screenPaddingMedium,
                }}
            >
                <View
                    style={{
                        backgroundColor: Colors.white,
                        paddingVertical: 8,
                        paddingHorizontal: 8,
                        borderRadius: 40,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <View
                        style={{
                            position: 'absolute',
                            top: 0,
                            zIndex: 10,
                            backgroundColor: Colors.white,
                            width: 84,
                            height: 32,
                            borderBottomStartRadius: 12,
                            borderBottomEndRadius: 12,
                        }}
                    />

                    <Image
                        source={image}
                        style={{
                            backgroundColor: Colors.black,
                            borderRadius: 32,
                            width: 270,
                            height: 585,
                        }}
                        resizeMode={'cover'}
                    />
                </View>
            </View>
        )
    }

    renderRule = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 2,
                    borderBottomColor: Colors.tintColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSeparator = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSpacer() {
        return (
            <View
                style={{
                    //flex: 1,
                    width: '100%',
                    height: Layout.screenPaddingLarge,
                }}
            />
        )
    }

    render() {
        return (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View
                            style={{
                                flex: 1,
                            }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <ScrollView
                                onScroll={event => this.onScroll(event)}
                                scrollEventThrottle={16}
                                style={{
                                    flex: 1,
                                }}
                            >
                                <ImageBackground
                                    source={require('../../assets/images/jonathan-borba-uB7q7aipU2o-unsplash.jpg')}
                                    style={{
                                        borderTopWidth: 6,
                                        borderTopColor: Colors.tintColor,
                                        backgroundColor: Colors.tintColor,
                                    }}
                                    resizeMode={'cover'}
                                >
                                    <View
                                        style={{
                                            flex: 1,
                                            paddingVertical: 88,
                                            alignItems: 'center',
                                            backgroundColor:
                                                Colors.mediumBackground,
                                        }}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                maxWidth: 960,
                                                paddingHorizontal: Layout.isMediumDevice
                                                    ? Layout.screenPaddingLarge
                                                    : Layout.screenPaddingXL,
                                                paddingVertical: Layout.isMediumDevice
                                                    ? Layout.screenPaddingXL
                                                    : Layout.screenPaddingXL *
                                                      2,
                                            }}
                                        >
                                            <Text
                                                style={[
                                                    Layout.isMediumDevice
                                                        ? TextStyles.Heading1Inverse
                                                        : TextStyles.Heading0Inverse,
                                                    {
                                                        paddingBottom:
                                                            Layout.screenPaddingMedium,
                                                    },
                                                ]}
                                            >
                                                Craft stories as distinctive as
                                                your dishes
                                            </Text>

                                            <Button
                                                iconRight={false}
                                                iconName={'arrow-right'}
                                                iconColor={Colors.tintColor}
                                                onPress={() => {
                                                    this.props.navigation.navigate(
                                                        'GetCreatorApp'
                                                    )
                                                }}
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'flex-start',
                                                    paddingTop:
                                                        Layout.screenPaddingMedium,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    borderColor:
                                                        Colors.inverseText,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                }}
                                                textStyle={[
                                                    TextStyles.Button,
                                                    {
                                                        color:
                                                            Colors.inverseText,
                                                    },
                                                ]}
                                                title={'Get the Indvstry App'}
                                            />
                                        </View>
                                    </View>
                                </ImageBackground>

                                <View style={styles.sectionContainer}>
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                paddingVertical: Layout.isMediumDevice
                                                    ? Layout.screenPaddingLarge
                                                    : Layout.screenPaddingXL,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                // alignItems: 'center',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    maxWidth: 720,
                                                    alignSelf: 'center',
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading1,
                                                        {
                                                            textAlign: Layout.isMediumDevice
                                                                ? 'left'
                                                                : 'center',
                                                        },
                                                    ]}
                                                >
                                                    Indvstry is a dedicated
                                                    space for restaurants to
                                                    craft events, create
                                                    collaborative stories, and
                                                    invite guests to culinary
                                                    experiences.
                                                </Text>
                                            </View>
                                            {/* <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isSmallDevice
                                                        ? 260
                                                        : 360,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 36,
                                                        },
                                                    ]}
                                                >
                                                    Join a collaborative
                                                    storytelling platform that
                                                    uniquely connects video
                                                    stories to events. We
                                                    empower Food and Hospitality
                                                    professionals to seamlessly
                                                    create content and foster
                                                    trust with restaurant
                                                    guests.
                                                    Imagine a new way to reach
                                                    your guests—through your
                                                    team. Create collaborative
                                                    stories on Indvstry to go
                                                    deeper with your audience.
                                                </Text>
                                            </View>  */}
                                        </View>
                                    </View>
                                </View>

                                <ProductFeatures />

                                <ProductExperienceRules />

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        { backgroundColor: Colors.gray0 },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    maxWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    paddingVertical:
                                                        Layout.screenPaddingMedium,
                                                    marginRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingXL,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading2,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                        },
                                                    ]}
                                                >
                                                    How restaurants use Indvstry
                                                </Text>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Recurring dining
                                                        experiences
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Special events, pop-ups
                                                        and holidays
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Chef's table, group and
                                                        communal dining
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Prepaid service with
                                                        beverage pairings
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Wine and beer tastings
                                                    </Text>
                                                </View>

                                                <Button
                                                    iconRight={false}
                                                    iconName={'arrow-right'}
                                                    iconColor={Colors.tintColor}
                                                    onPress={() => {
                                                        this.props.navigation.navigate(
                                                            'GetCreatorApp'
                                                        )
                                                    }}
                                                    style={{
                                                        flex: 1,
                                                        alignSelf: 'flex-start',
                                                        paddingVertical:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                    buttonStyle={{
                                                        borderWidth: 1,
                                                        backgroundColor:
                                                            Colors.transparentBackground,
                                                        borderColor:
                                                            Colors.tintColor,
                                                    }}
                                                    textStyle={[
                                                        TextStyles.Button,
                                                        {
                                                            color:
                                                                Colors.tintColor,
                                                        },
                                                    ]}
                                                    title={
                                                        'Get the Indvstry App'
                                                    }
                                                />
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    paddingVertical:
                                                        Layout.screenPaddingMedium,
                                                }}
                                            >
                                                <Image
                                                    source={require('../../assets/images/kayleigh-harrington-yhn4okt6ci0-unsplash.jpg')}
                                                    style={{
                                                        width: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_HEIGHT *
                                                              0.618
                                                            : 480,
                                                    }}
                                                    resizeMode={'cover'}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            backgroundColor: Colors.aesop,
                                        },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                flex: 1,
                                                maxWidth: 720,
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={
                                                    'Powered by your people'
                                                }
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                    { textAlign: 'center' },
                                                ]}
                                            />
                                            <Text
                                                style={[
                                                    TextStyles.Paragraph,
                                                    {
                                                        lineHeight: 26,
                                                        textAlign: 'center',
                                                    },
                                                ]}
                                            >
                                                Supports an inclusive and
                                                collaborative workplace culture
                                            </Text>
                                        </View>
                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                alignItems: 'stretch',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={[
                                                    styles.cardContent,
                                                    {
                                                        padding: 0,
                                                        marginVertical: 0,
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                    },
                                                ]}
                                            >
                                                <Image
                                                    source={require('../../assets/images/jeff-siepman-kyuPjZudBKs-unsplash.jpg')}
                                                    style={{
                                                        width: '100%',
                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_HEIGHT *
                                                              0.5
                                                            : '100%',
                                                    }}
                                                    resizeMode={'cover'}
                                                />
                                            </View>

                                            <View
                                                style={[
                                                    styles.cardContent,
                                                    {
                                                        marginVertical: 0,
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingSmall,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Overline2,
                                                            {
                                                                color:
                                                                    Colors.primaryText,
                                                                paddingVertical:
                                                                    Layout.screenPaddingSmall,
                                                                textAlign:
                                                                    'center',
                                                                textTransform:
                                                                    'uppercase',
                                                            },
                                                        ]}
                                                    >
                                                        Exclusively Featuring
                                                    </Text>
                                                    {this.renderRule()}
                                                </View>
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Hosts and servers
                                                    </Text>
                                                    {/* <Text
                                                        style={
                                                            TextStyles.Heading1
                                                        }
                                                    >
                                                        5,089,620
                                                    </Text> */}
                                                </View>
                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Chefs and cooks
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}

                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Bartenders and baristas
                                                    </Text>
                                                </View>
                                                {this.renderSeparator()}

                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Sommeliers
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}

                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Managers
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        { backgroundColor: Colors.gray0 },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    maxWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    paddingVertical:
                                                        Layout.screenPaddingMedium,
                                                    marginRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingXL,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading2,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                        },
                                                    ]}
                                                >
                                                    Why restaurants love
                                                    Indvstry
                                                </Text>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Streamlines Marketing
                                                        output and effectiveness
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Enhances direct
                                                        relationship with guests
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Delivers measurable ROI,
                                                        performance and
                                                        predictive insights on
                                                        content
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Amplifies the personal
                                                        brand and status of
                                                        employees
                                                    </Text>
                                                </View>

                                                {/* <Button
                                                    iconRight={false}
                                                    iconName={'arrow-right'}
                                                    iconColor={Colors.tintColor}
                                                    onPress={() => {
                                                        this.props.navigation.navigate(
                                                            'Roadmap'
                                                        )
                                                    }}
                                                    style={{
                                                        flex: 1,
                                                        alignSelf: 'flex-start',
                                                        paddingVertical:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                    buttonStyle={{
                                                        borderWidth: 1,
                                                        backgroundColor:
                                                            Colors.transparentBackground,
                                                        borderColor:
                                                            Colors.tintColor,
                                                    }}
                                                    textStyle={[
                                                        TextStyles.Button,
                                                        {
                                                            color:
                                                                Colors.tintColor,
                                                        },
                                                    ]}
                                                    title={'Our Company Vision'}
                                                /> */}
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    paddingVertical:
                                                        Layout.screenPaddingMedium,
                                                }}
                                            >
                                                <Image
                                                    source={require('../../assets/images/chris-fuller--FVz4FcYNiI-unsplash.jpg')}
                                                    style={{
                                                        width: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_HEIGHT *
                                                              0.618
                                                            : 480,
                                                    }}
                                                    resizeMode={'cover'}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <ProductAttributes />

                                <ImageBackground
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            backgroundColor: Colors.gray5,
                                        },
                                    ]}
                                    source={require('../../assets/images/mathias-adam-JKHUw0Xujf8-unsplash.jpg')}
                                    resizeMode={'cover'}
                                >
                                    <View
                                        style={[
                                            styles.bodyContent,
                                            {
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            },
                                        ]}
                                    >
                                        <View
                                            style={[
                                                styles.cardContent,
                                                {
                                                    //flex: 1,
                                                    alignSelf: 'center',
                                                    // alignItems: Layout.isMediumDevice
                                                    //     ? 'center'
                                                    //     : 'flex-start',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                },
                                            ]}
                                        >
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    paddingBottom:
                                                        Layout.screenPaddingMedium,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        overflow: 'hidden',
                                                        backgroundColor:
                                                            Colors.tintColor,
                                                        borderRadius: 20,
                                                        // alignSelf: Layout.isMediumDevice
                                                        //     ? 'center'
                                                        //     : 'flex-start',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        //marginBottom: Layout.screenPaddingMedium,
                                                        padding:
                                                            Layout.screenPaddingSmall,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/svgs/indvstry-icon-inverse.svg')}
                                                        style={{
                                                            width: Icons.XL,
                                                            height: Icons.XL,
                                                        }}
                                                        resizeMode={'cover'}
                                                    />
                                                </View>
                                                <BadgeBeta />
                                            </View>
                                            <View
                                                style={{
                                                    //flex: 1,
                                                    paddingBottom:
                                                        Layout.screenPaddingLarge,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading2,
                                                        // {
                                                        //     textAlign: Layout.isMediumDevice
                                                        //         ? 'center'
                                                        //         : 'left',
                                                        // },
                                                    ]}
                                                >
                                                    Join Early Access to
                                                    Indvstry
                                                </Text>
                                                {this.renderRule()}
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        // {
                                                        //     textAlign: Layout.isMediumDevice
                                                        //         ? 'center'
                                                        //         : 'left',
                                                        // },
                                                    ]}
                                                >
                                                    Start streamlining your
                                                    Events and Marketing output
                                                    today.
                                                </Text>
                                            </View>

                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Entypo
                                                    style={{
                                                        alignSelf: 'center',
                                                        paddingRight: 12,
                                                    }}
                                                    color={Colors.tintColor}
                                                    size={Icons.small}
                                                    name={'app-store'}
                                                />
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    App Store
                                                </Text>
                                            </View>

                                            {this.renderSeparator()}

                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Entypo
                                                    style={{
                                                        alignSelf: 'center',
                                                        paddingRight: 12,
                                                    }}
                                                    color={Colors.tintColor}
                                                    size={Icons.small}
                                                    name={'google-play'}
                                                />
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    Google Play
                                                </Text>
                                            </View>
                                            {this.renderSeparator()}

                                            <Text
                                                style={[
                                                    TextStyles.Caption,
                                                    {
                                                        // textAlign: Layout.isMediumDevice
                                                        //     ? 'center'
                                                        //     : 'left',
                                                        paddingBottom:
                                                            Layout.screenPaddingMedium,
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Caption,
                                                        {
                                                            color:
                                                                Colors.accentDeepsea,
                                                        },
                                                    ]}
                                                >
                                                    •
                                                </Text>
                                                {'  '}
                                                App now available
                                            </Text>

                                            <Button
                                                iconLeft={false}
                                                iconName={'smartphone'}
                                                iconColor={Colors.accentDeepsea}
                                                onPress={() => {
                                                    this.props.navigation.navigate(
                                                        'GetCreatorApp'
                                                    )
                                                }}
                                                style={{
                                                    //flex: 1,
                                                    width: Layout.isMediumDevice
                                                        ? '100%'
                                                        : null,
                                                    alignSelf: Layout.isMediumDevice
                                                        ? 'center'
                                                        : 'flex-start',
                                                    paddingTop:
                                                        Layout.screenPaddingLarge,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                    borderColor:
                                                        Colors.tintColor,
                                                }}
                                                textStyle={[
                                                    TextStyles.Button,
                                                    {
                                                        color:
                                                            Colors.primaryText,
                                                    },
                                                ]}
                                                title={'Get the Indvstry App'}
                                            />
                                        </View>

                                        {this.renderFeatureImage({
                                            image: require('../../assets/images/IMG_4345.jpg'),
                                        })}
                                    </View>
                                </ImageBackground>

                                <GetInTouch
                                    navigation={this.props.navigation}
                                />

                                <Footer navigation={this.props.navigation} />
                            </ScrollView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    mainView: {
        flex: 1,
        borderTopWidth: 6,
        paddingTop: 88,
        borderTopColor: Colors.tintColor,
        backgroundColor: Colors.gray5,
    },

    sectionContainer: {
        alignItems: 'center',
        backgroundColor: Colors.gray0,
        paddingVertical: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
    },

    cardContent: {
        flex: 1,
        //maxWidth: 720,  // Normally 720
        marginVertical: Layout.screenPaddingMedium,
        backgroundColor: Colors.white,
        padding: Layout.screenPaddingLarge,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        paddingVertical: Layout.screenPaddingLarge,
        paddingHorizontal: Layout.screenPaddingLarge,
    },

    imageContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        padding: Layout.screenPaddingLarge,
    },
})
