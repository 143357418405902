import React from 'react'
import { StyleSheet, Text, View } from 'react-native'
import { Colors, TextStyles } from '../constants'

export default class BadgeBeta extends React.Component {
    render() {
        let style = [styles.container]
        if (this.props.disabled) {
            style.push(styles.disabledIconContainer)
        }
        return (
            <View style={[styles.container, this.props.style]}>
                <View style={styles.iconContainer}>
                    <Text
                        style={[
                            TextStyles.Overline2,

                            {
                                textTransform: 'uppercase',
                                color: Colors.accentDeepsea,
                            },
                        ]}
                    >
                        Beta
                    </Text>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    disabledIconContainer: {
        backgroundColor: Colors.disabled,
    },

    container: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 100,
        height: 24,
        width: 24,
        //backgroundColor: 'blue',
    },

    iconContainer: {
        width: 48,
        height: 16,
        position: 'absolute',
        top: 8,
        left: 4,
    },
})
