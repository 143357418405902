import React from 'react'
import { StyleSheet, Text, Linking, View } from 'react-native'
import { Colors, TextStyles, Layout } from '../constants'
import { UserProfileConsumer } from '../context/UserProfileContext'
import Button from './Button'
import FormHeaderTitle from './FormHeaderTitle'

export default class GetInTouch extends React.Component {
    renderSpacer() {
        return (
            <View
                style={{
                    width: '100%',
                    height: Layout.screenPaddingLarge,
                }}
            />
        )
    }

    render() {
        return (
            <UserProfileConsumer>
                {context => (
                    <View
                        style={styles.container}
                        ref={ref => {
                            this.context = context
                        }}
                    >
                        <View
                            style={[
                                styles.sectionContainer,
                                {
                                    //backgroundColor: Colors.aesop,
                                    paddingTop: Layout.isMediumDevice
                                        ? Layout.screenPaddingLarge
                                        : Layout.screenPaddingXL,
                                },
                            ]}
                        >
                            <View style={styles.bodyContent}>
                                <FormHeaderTitle
                                    headerTitle={
                                        this.props.headerTitle
                                            ? this.props.headerTitle
                                            : 'Let’s start a conversation'
                                    }
                                    headerTitleStyle={[
                                        TextStyles.Heading1,
                                        {
                                            textAlign: 'center',
                                        },
                                    ]}
                                />
                                {this.props.message ? (
                                    <Text
                                        style={[
                                            TextStyles.Paragraph,
                                            {
                                                lineHeight: 36,
                                                maxWidth: 720,
                                                textAlign: 'center',
                                            },
                                        ]}
                                    >
                                        {this.props.message}
                                    </Text>
                                ) : (
                                    <Text
                                        style={[
                                            TextStyles.Paragraph,
                                            {
                                                lineHeight: 36,
                                                maxWidth: 720,
                                                textAlign: 'center',
                                            },
                                        ]}
                                    >
                                        Talk to an Indvstry team member who can
                                        take you through a demo of everything
                                        Indvstry has to offer. No hassle and no
                                        commitement.
                                    </Text>
                                )}
                                {this.renderSpacer()}
                                <Button
                                    iconName={'arrow-right'}
                                    iconColor={Colors.tintColor}
                                    onPress={() => {
                                        context.userExists
                                            ? context.isStudio
                                                ? this.props.navigation.navigate(
                                                      'StudioContact'
                                                  )
                                                : this.props.navigation.navigate(
                                                      'Contact'
                                                  )
                                            : context.isStudio
                                            ? this.props.navigation.navigate(
                                                  'StudioContact'
                                              )
                                            : this.props.navigation.navigate(
                                                  'RequestInfo'
                                              )
                                    }}
                                    style={{
                                        alignSelf: 'center',
                                    }}
                                    buttonStyle={{
                                        borderWidth: 1,
                                        backgroundColor:
                                            Colors.transparentBackground,
                                        borderColor: Colors.tintColor,
                                    }}
                                    textStyle={[
                                        TextStyles.Button,
                                        {
                                            color: Colors.tintColor,
                                        },
                                    ]}
                                    title={
                                        this.props.buttonTitle
                                            ? this.props.buttonTitle
                                            : 'Get in touch'
                                    }
                                />
                            </View>
                        </View>
                    </View>
                )}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        alignItems: 'center',
        backgroundColor: Colors.gray0,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        paddingVertical: Layout.screenPaddingLarge,
        paddingHorizontal: Layout.screenPaddingLarge,
    },
})
