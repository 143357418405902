import React from 'react'
import {
    ImageBackground,
    Image,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import { Feather, Entypo } from '@expo/vector-icons'
import { Colors, TextStyles, Layout, Icons } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import FormHeaderTitle from '../../components/FormHeaderTitle'
import Button from '../../components/Button'
import StudioFooter from '../../components/StudioFooter'
import GetInTouch from '../../components/GetInTouch'
import ProductFeatures from '../../components/ProductFeaturesCarousel'
import ProductAttributes from '../../components/ProductAttributes'
import ProductExperienceRules from '../../components/ProductExperienceRules'
import BadgeBeta from '../../components/BadgeBeta'
import StudioFootwear from '../../components/StudioFootwear'
import StudioApparel from '../../components/StudioApparel'
import StudioApparelOuter from '../../components/StudioApparelOuter'
import StudioAccessories from '../../components/StudioAccessories'
import ButtonIcon from '../../components/ButtonIcon'

export default class StudioBrand extends React.Component {
    onScroll = async event => {
        const pageYOffset = await event.nativeEvent.contentOffset.y

        if (pageYOffset >= 88) {
            this.props.navigation.setParams({ active: true })
        } else {
            this.props.navigation.setParams({ active: false })
        }
    }

    renderMilestone = ({ text, textColor, containerColor }) => {
        return (
            <View
                style={{
                    paddingRight: Layout.isMediumDevice
                        ? 0
                        : Layout.screenPaddingMedium,
                    paddingVertical: Layout.screenPaddingSmall,
                    width: Layout.isMediumDevice ? '100%' : null,
                }}
            >
                <View
                    style={{
                        padding: Layout.screenPaddingMedium,
                        borderRadius: Layout.borderRadiusMedium,
                        backgroundColor: containerColor,
                    }}
                >
                    <Text
                        style={[
                            TextStyles.Paragraph,
                            {
                                color: textColor,
                                lineHeight: 26,
                            },
                        ]}
                    >
                        {text}
                    </Text>
                </View>
            </View>
        )
    }

    renderFeatureImage = ({ theme, description, image, video }) => {
        return (
            <View
                style={{
                    flex: 1,
                    alignSelf: 'center',
                    alignItems: 'center',
                    minWidth: Layout.isMediumDevice
                        ? '100%'
                        : Layout.SCREEN_WIDTH * 0.3333,
                    paddingVertical: Layout.screenPaddingMedium,
                }}
            >
                <View
                    style={{
                        backgroundColor: Colors.white,
                        paddingVertical: 8,
                        paddingHorizontal: 8,
                        borderRadius: 40,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <View
                        style={{
                            position: 'absolute',
                            top: 0,
                            zIndex: 10,
                            backgroundColor: Colors.white,
                            width: 84,
                            height: 32,
                            borderBottomStartRadius: 12,
                            borderBottomEndRadius: 12,
                        }}
                    />

                    <Image
                        source={image}
                        style={{
                            backgroundColor: Colors.black,
                            borderRadius: 32,
                            width: 270,
                            height: 585,
                        }}
                        resizeMode={'cover'}
                    />
                </View>
            </View>
        )
    }

    renderSlider = () => {
        return (
            <View style={{ height: 9 }}>
                <View
                    style={{
                        flex: 1,
                        borderRadius: 18,
                        backgroundColor: Colors.gray3,
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            width: '33%',
                            borderRadius: 18,
                            backgroundColor: Colors.tintColor,
                        }}
                    />
                </View>
            </View>
        )
    }

    renderSliderMobile = () => {
        return (
            <View
                style={{
                    width: 9,
                    marginRight: Layout.screenPaddingLarge,
                    paddingTop: Layout.screenPaddingSmall,
                    paddingBottom: Layout.screenPaddingLarge,
                }}
            >
                <View
                    style={{
                        flex: 1,
                        borderRadius: 18,
                        backgroundColor: Colors.gray3,
                    }}
                >
                    <View
                        style={{
                            height: '33%',
                            borderRadius: 18,
                            backgroundColor: Colors.tintColor,
                        }}
                    />
                </View>
            </View>
        )
    }

    renderRule = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 2,
                    borderBottomColor: Colors.tintColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSeparator = borderColor => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: borderColor
                        ? borderColor
                        : Colors.border,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSpacer() {
        return (
            <View
                style={{
                    //flex: 1,
                    width: '100%',
                    height: Layout.screenPaddingLarge,
                }}
            />
        )
    }

    render() {
        return (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View
                            style={{
                                flex: 1,
                            }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <ScrollView
                                ref={node => (this.scroll = node)}
                                onScroll={event => this.onScroll(event)}
                                scrollEventThrottle={16}
                                style={{
                                    flex: 1,
                                }}
                                pagingEnabled={
                                    Layout.isMediumDevice ? false : true
                                }
                            >
                                <ImageBackground
                                    source={require('../../assets/images/eric-ward-LsPw-DE_GAs-unsplash.jpg')}
                                    style={{
                                        borderTopWidth: 6,
                                        borderTopColor: Colors.tintColor,
                                        backgroundColor: Colors.tintColor,
                                        minHeight: Layout.SCREEN_HEIGHT,
                                        //alignItems: 'center',
                                        //justifyContent: 'center',
                                    }}
                                    resizeMode={'cover'}
                                >
                                    <View
                                        style={{
                                            flex: 1,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor:
                                                Colors.mediumBackground,
                                        }}
                                    >
                                        <View
                                            style={{
                                                //flex: 1,
                                                maxWidth: 960,
                                                paddingHorizontal: Layout.isMediumDevice
                                                    ? Layout.screenPaddingLarge
                                                    : Layout.screenPaddingXL,
                                                // paddingVertical: Layout.isMediumDevice
                                                //     ? Layout.screenPaddingXL * 2
                                                //     : Layout.screenPaddingXL *
                                                //       4,
                                            }}
                                        >
                                            <Text
                                                style={[
                                                    TextStyles.Overline2Inverse,
                                                    {
                                                        alignSelf: Layout.isMediumDevice
                                                            ? 'center'
                                                            : 'center',
                                                        paddingBottom:
                                                            Layout.screenPaddingMedium,
                                                        textAlign: Layout.isMediumDevice
                                                            ? 'auto'
                                                            : 'center',
                                                        textTransform:
                                                            'uppercase',
                                                    },
                                                ]}
                                            >
                                                Brand
                                            </Text>

                                            <Text
                                                style={[
                                                    Layout.isMediumDevice
                                                        ? TextStyles.Heading1Inverse
                                                        : TextStyles.Heading0Inverse,
                                                    {
                                                        alignSelf: Layout.isMediumDevice
                                                            ? 'center'
                                                            : 'center',
                                                        paddingBottom:
                                                            Layout.screenPaddingMedium,
                                                        textAlign: Layout.isMediumDevice
                                                            ? 'auto'
                                                            : 'center',
                                                    },
                                                ]}
                                            >
                                                Master Chef, what do you want to
                                                do?
                                            </Text>

                                            <ButtonIcon
                                                iconName={'arrow-down'}
                                                iconColor={Colors.inverseText}
                                                onPress={() => {
                                                    this.scroll.scrollTo({
                                                        y: Layout.SCREEN_HEIGHT,
                                                    })
                                                }}
                                                style={{
                                                    alignSelf: Layout.isMediumDevice
                                                        ? 'center'
                                                        : 'center',
                                                    paddingTop:
                                                        Layout.screenPaddingMedium,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    borderColor:
                                                        Colors.inverseText,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                }}
                                            />
                                        </View>
                                    </View>
                                </ImageBackground>

                                <ImageBackground
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        },
                                    ]}
                                    source={require('../../assets/images/denys-argyriou-220305-unsplash.jpg')}
                                    resizeMode={'cover'}
                                >
                                    <View
                                        style={[
                                            styles.bodyContent,
                                            {
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            },
                                        ]}
                                    >
                                        <View
                                            style={[
                                                styles.cardContent,
                                                {
                                                    alignSelf: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 720,
                                                },
                                            ]}
                                        >
                                            <Text
                                                style={[
                                                    TextStyles.Heading2,
                                                    {
                                                        paddingBottom:
                                                            Layout.screenPaddingLarge,
                                                    },
                                                ]}
                                            >
                                                Bringing an idea to life
                                            </Text>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    // paddingBottom:
                                                    //     Layout.screenPaddingLarge,
                                                    flexDirection: Layout.isMediumDevice
                                                        ? 'row'
                                                        : 'column',
                                                }}
                                            >
                                                {Layout.isMediumDevice
                                                    ? this.renderSliderMobile()
                                                    : this.renderSlider()}
                                                <View
                                                    style={{
                                                        flex: 1,
                                                        paddingVertical: Layout.isMediumDevice
                                                            ? 0
                                                            : Layout.screenPaddingMedium,

                                                        flexDirection: Layout.isMediumDevice
                                                            ? 'column'
                                                            : 'row',
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            flex: 1,
                                                        }}
                                                    >
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 36,
                                                                },
                                                            ]}
                                                        >
                                                            Research
                                                        </Text>
                                                        <Text
                                                            style={[
                                                                TextStyles.Caption,
                                                                {
                                                                    paddingBottom:
                                                                        Layout.screenPaddingMedium,
                                                                },
                                                            ]}
                                                        >
                                                            Spring 2019
                                                        </Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            flex: 1,
                                                            alignItems: Layout.isMediumDevice
                                                                ? 'flex-start'
                                                                : 'center',
                                                        }}
                                                    >
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    textAlign:
                                                                        'center',
                                                                    lineHeight: 36,
                                                                },
                                                            ]}
                                                        >
                                                            Ideation
                                                        </Text>
                                                        <Text
                                                            style={[
                                                                TextStyles.Caption,
                                                                {
                                                                    textAlign:
                                                                        'center',
                                                                    paddingBottom:
                                                                        Layout.screenPaddingMedium,
                                                                },
                                                            ]}
                                                        >
                                                            Summer 2019
                                                        </Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            flex: 1,
                                                            alignItems: Layout.isMediumDevice
                                                                ? 'flex-start'
                                                                : 'flex-end',
                                                        }}
                                                    >
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 36,
                                                                },
                                                            ]}
                                                        >
                                                            Concept Design
                                                        </Text>
                                                        <Text
                                                            style={[
                                                                TextStyles.Caption,
                                                                {
                                                                    paddingBottom:
                                                                        Layout.screenPaddingMedium,
                                                                },
                                                            ]}
                                                        >
                                                            Fall 2019
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>

                                            {/* <Button
                                                iconRight={false}
                                                iconName={'arrow-right'}
                                                iconColor={Colors.tintColor}
                                                onPress={() => {
                                                    this.props.navigation.navigate(
                                                        'StudioContact'
                                                    )
                                                }}
                                                style={{
                                                    //flex: 1,
                                                    width: Layout.isMediumDevice
                                                        ? '100%'
                                                        : null,
                                                    alignSelf: Layout.isMediumDevice
                                                        ? 'center'
                                                        : 'flex-start',
                                                    paddingTop:
                                                        Layout.screenPaddingMedium,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                    borderColor:
                                                        Colors.tintColor,
                                                }}
                                                textStyle={[
                                                    TextStyles.Button,
                                                    {
                                                        color:
                                                            Colors.primaryText,
                                                    },
                                                ]}
                                                title={'Let’s work together'}
                                            /> */}
                                        </View>
                                    </View>
                                </ImageBackground>

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        { justifyContent: 'space-between' },
                                    ]}
                                >
                                    <ImageBackground
                                        style={{
                                            width: '100%',
                                            height: Layout.SCREEN_HEIGHT * 0.3,
                                        }}
                                        source={require('../../assets/images/jc-gellidon-c61FgpW3cXM-unsplash.jpg')}
                                        resizeMode={'cover'}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor:
                                                    Colors.darkBackground,
                                                paddingHorizontal:
                                                    Layout.screenPaddingLarge,
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={
                                                    '1 in 3 employed teens work in the restaurant industry'
                                                }
                                                headerTitleStyle={[
                                                    TextStyles.Heading2,
                                                    {
                                                        color: Colors.gray0,
                                                        alignSelf: 'center',
                                                        textAlign: 'center',
                                                    },
                                                ]}
                                            />
                                        </View>
                                    </ImageBackground>

                                    <ImageBackground
                                        style={{
                                            width: '100%',
                                            height: Layout.SCREEN_HEIGHT * 0.3,
                                        }}
                                        source={require('../../assets/images/jc-gellidon-c61FgpW3cXM-unsplash.jpg')}
                                        resizeMode={'cover'}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor:
                                                    Colors.darkBackground,
                                                paddingHorizontal:
                                                    Layout.screenPaddingLarge,
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={
                                                    'Restaurants employ more women managers than any other industry'
                                                }
                                                headerTitleStyle={[
                                                    TextStyles.Heading2,
                                                    {
                                                        color: Colors.gray0,
                                                        alignSelf: 'center',
                                                        textAlign: 'center',
                                                    },
                                                ]}
                                            />
                                        </View>
                                    </ImageBackground>

                                    <ImageBackground
                                        style={{
                                            width: '100%',
                                            height: Layout.SCREEN_HEIGHT * 0.3,
                                        }}
                                        source={require('../../assets/images/jc-gellidon-c61FgpW3cXM-unsplash.jpg')}
                                        resizeMode={'cover'}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor:
                                                    Colors.darkBackground,
                                                paddingHorizontal:
                                                    Layout.screenPaddingLarge,
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={
                                                    'Restaurants employ more minority managers than any other industry'
                                                }
                                                headerTitleStyle={[
                                                    TextStyles.Heading2,
                                                    {
                                                        color: Colors.gray0,
                                                        alignSelf: 'center',
                                                        textAlign: 'center',
                                                    },
                                                ]}
                                            />
                                        </View>
                                    </ImageBackground>
                                </View>

                                <View style={styles.sectionContainer}>
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    maxWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    paddingVertical:
                                                        Layout.screenPaddingMedium,
                                                    marginRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingXL,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading2,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                        },
                                                    ]}
                                                >
                                                    Economic impact of the
                                                    Restaurant Industry
                                                </Text>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Sales are projected to
                                                        total $863B in 2019
                                                    </Text>
                                                </View>

                                                {this.renderSeparator(
                                                    Colors.gray3
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Salses will equal 4% of
                                                        the U.S. gross domestic
                                                        product
                                                    </Text>
                                                </View>

                                                {this.renderSeparator(
                                                    Colors.gray3
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Expected to add 1.6M
                                                        jobs over the next
                                                        decade, with employment
                                                        reaching 16.9M by 2029
                                                    </Text>
                                                </View>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    paddingVertical:
                                                        Layout.screenPaddingMedium,
                                                }}
                                            >
                                                <Image
                                                    source={require('../../assets/images/tanja-heffner-584866-unsplash.jpg')}
                                                    style={{
                                                        //borderRadius: 1000,
                                                        //overflow: 'hidden',
                                                        width: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_HEIGHT *
                                                              0.618
                                                            : 480,
                                                    }}
                                                    resizeMode={'cover'}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.sectionContainer}>
                                    <View style={styles.bodyContent}>
                                        {/* <View
                                            style={{
                                                maxWidth: 720,
                                                alignSelf: 'center',
                                                paddingVertical: Layout.isMediumDevice
                                                    ? Layout.screenPaddingLarge
                                                    : Layout.screenPaddingXL,
                                            }}
                                        >
                                            {/* <FormHeaderTitle
                                            headerTitle={'Company Vision'}
                                            headerTitleStyle={[
                                                TextStyles.Heading1,
                                                {
                                                    paddingBottom:
                                                        Layout.screenPaddingMedium,
                                                },
                                            ]}
                                        /> 

                                            <Text
                                                style={[
                                                    TextStyles.Heading1,
                                                    {
                                                        textAlign: Layout.isMediumDevice
                                                            ? 'left'
                                                            : 'center',
                                                    },
                                                ]}
                                            >
                                                Our brand values
                                            </Text>
                                            </View> */}

                                        <View
                                            style={{
                                                alignItems: 'flex-start',
                                                paddingVertical: Layout.isMediumDevice
                                                    ? 0
                                                    : Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,

                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                {this.renderRule()}
                                                <View
                                                    style={{
                                                        overflow: 'hidden',
                                                        backgroundColor:
                                                            Colors.gray2,
                                                        borderRadius: 96,
                                                        alignSelf: 'flex-start',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        padding:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/svgs/chef-gear-apron.svg')}
                                                        style={{
                                                            width: Icons.XL,
                                                            height: Icons.XL,
                                                        }}
                                                        resizeMode={'cover'}
                                                    />
                                                </View>

                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                            paddingRight: Layout.isMediumDevice
                                                                ? 0
                                                                : Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    Maxine Thompson
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.Overline2,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    ]}
                                                >
                                                    Chef
                                                </Text>

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    “I was working in a
                                                    restaurant in Tasmania in
                                                    2013 and we did 100 covers a
                                                    night with the choice of an
                                                    eight- or 10-course tasting
                                                    menu that changed every
                                                    day,” she explains. “With
                                                    only three of us in the
                                                    kitchen, the days were long,
                                                    hard, and fast, and I could
                                                    not, for the life of me,
                                                    find a pair of trousers that
                                                    were cool, comfortable, and
                                                    durable enough to work in
                                                    for 14 hours a day.”
                                                </Text>
                                            </View>
                                            {/* 
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,

                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                {this.renderRule()}
                                                <View
                                                    style={{
                                                        overflow: 'hidden',
                                                        backgroundColor:
                                                            Colors.tintColor,
                                                        borderRadius: 96,
                                                        alignSelf: 'flex-start',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        padding:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/svgs/renewable-energy-paper-turbine.svg')}
                                                        style={{
                                                            width: Icons.XL,
                                                            height: Icons.XL,
                                                        }}
                                                        resizeMode={'cover'}
                                                    />
                                                </View>

                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                            paddingRight: Layout.isMediumDevice
                                                                ? 0
                                                                : Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    Master Chef
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.Overline2,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    ]}
                                                >
                                                    Chef
                                                </Text>

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                            color: 'red',
                                                        },
                                                    ]}
                                                >
                                                    “I was working in a
                                                    restaurant in Tasmania in
                                                    2013 and we did 100 covers a
                                                    night with the choice of an
                                                    eight- or 10-course tasting
                                                    menu that changed every
                                                    day,” she explains. “With
                                                    only three of us in the
                                                    kitchen, the days were long,
                                                    hard, and fast, and I could
                                                    not, for the life of me,
                                                    find a pair of trousers that
                                                    were cool, comfortable, and
                                                    durable enough to work in
                                                    for 14 hours a day.”
                                                </Text>
                                            </View> */}

                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,

                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                {this.renderRule()}
                                                <View
                                                    style={{
                                                        overflow: 'hidden',
                                                        backgroundColor:
                                                            Colors.gray2,
                                                        borderRadius: 96,
                                                        alignSelf: 'flex-start',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        padding:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/svgs/chef-gear-hat.svg')}
                                                        style={{
                                                            width: Icons.XL,
                                                            height: Icons.XL,
                                                        }}
                                                        resizeMode={'cover'}
                                                    />
                                                </View>

                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                            paddingRight: Layout.isMediumDevice
                                                                ? 0
                                                                : Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    Darrell
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.Overline2,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    ]}
                                                >
                                                    Chef
                                                </Text>

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    “Here in the UK the industry
                                                    seems to ignore chefs
                                                    problems with there feet. I
                                                    recently had a sit down with
                                                    my owner and he was
                                                    concerned that me limping
                                                    around (from plantar
                                                    fasciitis) was making him
                                                    look bad. ”
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        { justifyContent: 'space-between' },
                                    ]}
                                >
                                    <ImageBackground
                                        style={{
                                            width: '100%',
                                            height: Layout.SCREEN_HEIGHT * 0.33,
                                        }}
                                        source={require('../../assets/images/ray-fragapane-PkKIVq1CUuY-unsplash.jpg')}
                                        resizeMode={'cover'}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                alignItems: 'flex-start',
                                                justifyContent: 'center',
                                                backgroundColor:
                                                    Colors.mediumBackground,
                                                paddingHorizontal:
                                                    Layout.screenPaddingLarge,
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={'Food &'}
                                                headerTitleStyle={[
                                                    Layout.isMediumDevice
                                                        ? TextStyles.Heading1Inverse
                                                        : TextStyles.Heading0Inverse,
                                                ]}
                                            />
                                        </View>
                                    </ImageBackground>

                                    <ImageBackground
                                        style={{
                                            width: '100%',
                                            height: Layout.SCREEN_HEIGHT * 0.33,
                                        }}
                                        source={require('../../assets/images/ray-fragapane-PkKIVq1CUuY-unsplash.jpg')}
                                        resizeMode={'cover'}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                alignItems: 'flex-start',
                                                justifyContent: 'center',
                                                backgroundColor:
                                                    Colors.mediumBackground,
                                                paddingHorizontal:
                                                    Layout.screenPaddingLarge,
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={'Culture &'}
                                                headerTitleStyle={[
                                                    Layout.isMediumDevice
                                                        ? TextStyles.Heading1Inverse
                                                        : TextStyles.Heading0Inverse,
                                                ]}
                                            />
                                        </View>
                                    </ImageBackground>

                                    <ImageBackground
                                        style={{
                                            width: '100%',
                                            height: Layout.SCREEN_HEIGHT * 0.33,
                                        }}
                                        source={require('../../assets/images/ray-fragapane-PkKIVq1CUuY-unsplash.jpg')}
                                        resizeMode={'cover'}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                alignItems: 'flex-start',
                                                justifyContent: 'center',
                                                backgroundColor:
                                                    Colors.mediumBackground,
                                                paddingHorizontal:
                                                    Layout.screenPaddingLarge,
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={'Lifestyle'}
                                                headerTitleStyle={[
                                                    Layout.isMediumDevice
                                                        ? TextStyles.Heading1Inverse
                                                        : TextStyles.Heading0Inverse,
                                                ]}
                                            />
                                        </View>
                                    </ImageBackground>
                                </View>

                                <ImageBackground
                                    style={styles.sectionContainer}
                                    source={require('../../assets/images/joao-silas-fuJww1eBhps-unsplash.jpg')}
                                    resizeMode={'cover'}
                                >
                                    <View
                                        style={[
                                            styles.bodyContent,
                                            {
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            },
                                        ]}
                                    >
                                        <View
                                            style={[
                                                styles.cardContent,
                                                {
                                                    alignSelf: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    maxWidth: 480,
                                                },
                                            ]}
                                        >
                                            <View
                                                style={{
                                                    //flex: 1,
                                                    paddingBottom:
                                                        Layout.screenPaddingLarge,
                                                }}
                                            >
                                                {/* {this.renderRule()} */}

                                                <Text
                                                    style={[
                                                        TextStyles.Heading2,
                                                        {
                                                            // textAlign: Layout.isMediumDevice
                                                            //     ? 'center'
                                                            //     : 'left',
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                        },
                                                    ]}
                                                >
                                                    Studio for All
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            // textAlign: Layout.isMediumDevice
                                                            //     ? 'center'
                                                            //     : 'left',
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    We see fashion as a vehicle
                                                    of empowerment to amplify
                                                    the voices of people in Food
                                                    and Hospitality, and for
                                                    all.
                                                </Text>
                                            </View>

                                            <Button
                                                iconRight={true}
                                                iconName={'arrow-right'}
                                                iconColor={Colors.tintColor}
                                                onPress={() => {
                                                    this.props.navigation.navigate(
                                                        'StudioVision'
                                                    )
                                                }}
                                                style={{
                                                    //flex: 1,
                                                    width: Layout.isMediumDevice
                                                        ? '100%'
                                                        : null,
                                                    alignSelf: Layout.isMediumDevice
                                                        ? 'center'
                                                        : 'flex-start',
                                                    // paddingTop:
                                                    //     Layout.screenPaddingSmall,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                    borderColor:
                                                        Colors.tintColor,
                                                }}
                                                textStyle={[
                                                    TextStyles.Button,
                                                    {
                                                        color:
                                                            Colors.primaryText,
                                                    },
                                                ]}
                                                title={'See the Vision'}
                                            />
                                        </View>
                                    </View>
                                </ImageBackground>
                            </ScrollView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    mainView: {
        flex: 1,
        borderTopWidth: 6,
        paddingTop: 88,
        borderTopColor: Colors.tintColor,
        backgroundColor: Colors.gray5,
    },

    sectionContainer: {
        height: Layout.isMediumDevice ? null : Layout.SCREEN_HEIGHT,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.gray0,
    },

    cardContent: {
        flex: 1,
        marginVertical: Layout.screenPaddingMedium,
        backgroundColor: Colors.white,
        padding: Layout.screenPaddingLarge,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: Layout.screenPaddingLarge,
    },

    imageContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        padding: Layout.screenPaddingLarge,
    },
})
