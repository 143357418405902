import { createSwitchNavigator, createAppContainer } from 'react-navigation'

import AuthLoadingScreen from '../screens/auth/AuthLoadingScreen'
import PublicDrawerNavigator from './PublicDrawerNavigator'
import ExplorerDrawerNavigator from './ExplorerDrawerNavigator'
import CreatorDrawerNavigator from './CreatorDrawerNavigator'
import InvestorDrawerNavigator from './InvestorDrawerNavigator'
import StudioDrawerNavigator from './StudioDrawerNavigator'
import StudioPublicDrawerNavigator from './StudioPublicDrawerNavigator'

const Authenticate = AuthLoadingScreen
const Public = PublicDrawerNavigator
const Explorer = ExplorerDrawerNavigator
const Creator = CreatorDrawerNavigator
const Investor = InvestorDrawerNavigator
const Studio = StudioDrawerNavigator
const StudioPublic = StudioPublicDrawerNavigator

const navigationRoutes = {
    Authenticate,
    Public,
    Explorer,
    Creator,
    Investor,
    Studio,
    StudioPublic,
}

const options = {
    initialRouteName: 'Authenticate',
}

const switchNavigator = createSwitchNavigator(navigationRoutes, options)

export default createAppContainer(switchNavigator)
