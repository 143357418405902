import React from 'react'
import { Image, StyleSheet, Text, View, TouchableOpacity } from 'react-native'
import { DrawerActions } from 'react-navigation-drawer'
import { Colors, Icons, TextStyles, Layout } from '../constants'

export default class PublicNavigationTabs extends React.Component {
    render() {
        // LayoutAnimation.easeInEaseOut()

        return (
            <View
                style={[
                    styles.container,
                    {
                        flexShrink: 1,
                        backgroundColor: Colors.aesop,
                    },
                ]}
            >
                {/* <Text
                    style={[
                        TextStyles.Heading3,
                        {
                            paddingVertical: Layout.screenPaddingMedium,
                            //textTransform: 'capitalize',
                        },
                    ]}
                    onPress={() =>
                        this.props.navigation.navigate('AuthHome') &&
                        this.props.navigation.dispatch(
                            DrawerActions.toggleDrawer()
                        )
                    }
                >
                    Indvstry
                </Text> */}
                <TouchableOpacity
                    style={{
                        overflow: 'hidden',
                        backgroundColor: Colors.tintColor,
                        borderRadius: 96,
                        alignSelf: 'flex-start',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginBottom: Layout.screenPaddingMedium,
                        padding: Layout.screenPaddingSmall,
                    }}
                    onPress={() =>
                        this.props.navigation.navigate('AuthHome') &&
                        this.props.navigation.dispatch(
                            DrawerActions.toggleDrawer()
                        )
                    }
                >
                    <Image
                        source={require('../assets/svgs/indvstry-icon-inverse.svg')}
                        style={{
                            width: Icons.XL,
                            height: Icons.XL,
                        }}
                        resizeMode={'cover'}
                    />
                </TouchableOpacity>

                <Text
                    style={[
                        TextStyles.Overline1,
                        {
                            paddingVertical: Layout.screenPaddingMedium,
                            textTransform: 'uppercase',

                            //    paddingHorizontal:
                            //        Layout.screenPaddingLarge,
                        },
                    ]}
                    onPress={() =>
                        this.props.navigation.navigate('About') &&
                        this.props.navigation.dispatch(
                            DrawerActions.toggleDrawer()
                        )
                    }
                >
                    About
                </Text>
                <Text
                    style={[
                        TextStyles.Overline1,
                        {
                            paddingVertical: Layout.screenPaddingMedium,
                            textTransform: 'uppercase',

                            //    paddingHorizontal:
                            //        Layout.screenPaddingLarge,
                        },
                    ]}
                    onPress={() =>
                        this.props.navigation.navigate('RequestInfo') &&
                        this.props.navigation.dispatch(
                            DrawerActions.toggleDrawer()
                        )
                    }
                >
                    Interested in Indvstry?
                </Text>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        backgroundColor: Colors.aesop,
        paddingVertical: Layout.screenPaddingXL,
        paddingHorizontal: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
    },

    stickyButton: {
        zIndex: 100,
        position: 'absolute',
        top: 0,
        right: 0,
        paddingVertical: Layout.screenPaddingMedium,
        paddingHorizontal: Layout.isMediumDevice
            ? Layout.screenPaddingMedium
            : Layout.screenPaddingLarge,
    },
})
