import React from 'react'
import {
    ImageBackground,
    Image,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import { Feather } from '@expo/vector-icons'
import { Colors, TextStyles, Layout, Icons } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import FormHeaderTitle from '../../components/FormHeaderTitle'
import Button from '../../components/Button'
import moment from 'moment'
import Footer from '../../components/Footer'
import GetInTouch from '../../components/GetInTouch'

export default class Roadmap extends React.Component {
    onScroll = async event => {
        const pageYOffset = await event.nativeEvent.contentOffset.y

        if (pageYOffset >= 88) {
            this.props.navigation.setParams({ active: true })
        } else {
            this.props.navigation.setParams({ active: false })
        }
    }

    renderItem = ({ text, textColor, containerColor }) => {
        return (
            <View
                style={{
                    paddingRight: Layout.isMediumDevice
                        ? 0
                        : Layout.screenPaddingMedium,
                    paddingVertical: Layout.screenPaddingSmall,
                    width: Layout.isMediumDevice ? '100%' : null,
                }}
            >
                <View
                    style={{
                        padding: Layout.screenPaddingMedium,
                        borderRadius: Layout.borderRadiusMedium,
                        backgroundColor: containerColor,
                    }}
                >
                    <Text
                        style={[
                            TextStyles.Paragraph,
                            {
                                color: textColor,
                                lineHeight: 26,
                            },
                        ]}
                    >
                        {text}
                    </Text>
                </View>
            </View>
        )
    }

    renderSlider = () => {
        return (
            <View style={{ height: 9 }}>
                <View
                    style={{
                        flex: 1,
                        borderRadius: 18,
                        backgroundColor: Colors.gray3,
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            width: '75%',
                            borderRadius: 18,
                            backgroundColor: Colors.accentDeepsea,
                        }}
                    />
                </View>
            </View>
        )
    }

    renderSliderMobile = () => {
        return (
            <View
                style={{
                    width: 9,
                    marginRight: Layout.screenPaddingLarge,
                    paddingTop: Layout.screenPaddingSmall,
                    paddingBottom: Layout.screenPaddingLarge,
                }}
            >
                <View
                    style={{
                        flex: 1,
                        borderRadius: 18,
                        backgroundColor: Colors.gray3,
                    }}
                >
                    <View
                        style={{
                            height: '75%',
                            borderRadius: 18,
                            backgroundColor: Colors.accentDeepsea,
                        }}
                    />
                </View>
            </View>
        )
    }

    renderRule = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 2,
                    borderBottomColor: Colors.tintColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSeparator = lineColor => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: lineColor ? lineColor : Colors.border,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSpacer() {
        return (
            <View
                style={{
                    //flex: 1,
                    width: '100%',
                    height: Layout.screenPaddingLarge,
                }}
            />
        )
    }

    render() {
        // LayoutAnimation.easeInEaseOut()

        const timestampLabel = moment().format('dddd, MMMM D, YYYY')

        return (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View
                            style={{
                                flex: 1,
                            }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <ScrollView
                                onScroll={event => this.onScroll(event)}
                                scrollEventThrottle={16}
                                //contentContainerStyle={{ flex: 1 }}
                                //automaticallyAdjustContentInsets={false}
                                style={{
                                    flex: 1,
                                }}
                            >
                                <ImageBackground
                                    source={require('../../assets/images/mens-fashion-in-restaurant-booth.jpg')}
                                    style={{
                                        borderTopWidth: 6,
                                        borderTopColor: Colors.tintColor,
                                        backgroundColor: Colors.tintColor,
                                        //paddingVertical: 88,
                                        //alignItems: 'center',
                                    }}
                                    resizeMode={'cover'}
                                >
                                    <View
                                        style={{
                                            flex: 1,
                                            paddingVertical: 88,
                                            alignItems: 'center',
                                            backgroundColor:
                                                Colors.mediumBackground,
                                        }}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                maxWidth: 960,
                                                paddingHorizontal: Layout.isMediumDevice
                                                    ? Layout.screenPaddingLarge
                                                    : Layout.screenPaddingXL,
                                                paddingVertical: Layout.isMediumDevice
                                                    ? Layout.screenPaddingXL
                                                    : Layout.screenPaddingXL *
                                                      2,
                                            }}
                                        >
                                            <Text
                                                style={[
                                                    Layout.isMediumDevice
                                                        ? TextStyles.Heading1Inverse
                                                        : TextStyles.Heading0Inverse,
                                                    {
                                                        paddingBottom:
                                                            Layout.screenPaddingMedium,
                                                    },
                                                ]}
                                            >
                                                Empowering Food and Hospitality
                                                professionals
                                            </Text>
                                            <Button
                                                iconRight={false}
                                                iconName={'arrow-right'}
                                                iconColor={Colors.tintColor}
                                                onPress={() => {
                                                    this.props.navigation.navigate(
                                                        'Contact'
                                                    )
                                                }}
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'flex-start',
                                                    paddingTop:
                                                        Layout.screenPaddingMedium,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                    borderColor:
                                                        Colors.inverseText,
                                                }}
                                                textStyle={[
                                                    TextStyles.Button,
                                                    {
                                                        color:
                                                            Colors.inverseText,
                                                    },
                                                ]}
                                                title={'Get involved'}
                                            />
                                        </View>
                                    </View>
                                </ImageBackground>

                                <View style={styles.sectionContainer}>
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                maxWidth: 720,
                                                alignSelf: 'center',
                                                paddingVertical: Layout.isMediumDevice
                                                    ? Layout.screenPaddingLarge
                                                    : Layout.screenPaddingXL,
                                            }}
                                        >
                                            {/* <FormHeaderTitle
                                            headerTitle={'Company Vision'}
                                            headerTitleStyle={[
                                                TextStyles.Heading1,
                                                {
                                                    paddingBottom:
                                                        Layout.screenPaddingMedium,
                                                },
                                            ]}
                                        /> */}

                                            <Text
                                                style={[
                                                    TextStyles.Heading1,
                                                    {
                                                        textAlign: Layout.isMediumDevice
                                                            ? 'left'
                                                            : 'center',
                                                    },
                                                ]}
                                            >
                                                Our vision is to shape the
                                                future of Hospitality
                                                experiences, starting at the
                                                intersection of Food events and
                                                short-form content
                                            </Text>
                                        </View>

                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                justifyContent: 'center',
                                            }}
                                        >
                                            <Image
                                                source={
                                                    Layout.isMediumDevice
                                                        ? require('../../assets/images/RoadmapIntersectMobile.png')
                                                        : require('../../assets/images/RoadmapIntersectDesktop.png')
                                                }
                                                style={{
                                                    alignSelf: 'center',
                                                    width: '100%',
                                                    height: 400,
                                                }}
                                                resizeMode={'contain'}
                                            />
                                        </View>

                                        <View
                                            style={{
                                                alignItems: 'flex-start',
                                                paddingVertical: Layout.isMediumDevice
                                                    ? 0
                                                    : Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,

                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                {this.renderRule()}
                                                <View
                                                    style={{
                                                        overflow: 'hidden',
                                                        backgroundColor:
                                                            Colors.gray2,
                                                        borderRadius: 96,
                                                        alignSelf: 'flex-start',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        padding:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/svgs/wine-restaurant.svg')}
                                                        style={{
                                                            width: Icons.XL,
                                                            height: Icons.XL,
                                                        }}
                                                        resizeMode={'cover'}
                                                    />
                                                </View>

                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                            paddingRight: Layout.isMediumDevice
                                                                ? 0
                                                                : Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    For Restaurants
                                                </Text>

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    Streamline Marketing output
                                                    and effectiveness
                                                </Text>
                                                {this.renderSeparator()}

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    Enhance direct relationship
                                                    with guests
                                                </Text>
                                                {this.renderSeparator()}

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    Deliver content performance
                                                    and predictive insights
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,

                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                {this.renderRule()}
                                                <View
                                                    style={{
                                                        overflow: 'hidden',
                                                        backgroundColor:
                                                            Colors.gray2,
                                                        borderRadius: 96,
                                                        alignSelf: 'flex-start',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        padding:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/svgs/chef-gear-hat.svg')}
                                                        style={{
                                                            width: Icons.XL,
                                                            height: Icons.XL,
                                                        }}
                                                        resizeMode={'cover'}
                                                    />
                                                </View>

                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                            paddingRight: Layout.isMediumDevice
                                                                ? 0
                                                                : Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    For Employees
                                                </Text>

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    Promote inclusive and
                                                    sustainable workplace
                                                    culture
                                                </Text>
                                                {this.renderSeparator()}

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    Amplify personal brand and
                                                    status
                                                </Text>
                                                {this.renderSeparator()}

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    Empower career mobility and
                                                    autonomy
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,

                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                {this.renderRule()}
                                                <View
                                                    style={{
                                                        overflow: 'hidden',
                                                        backgroundColor:
                                                            Colors.gray2,
                                                        borderRadius: 96,
                                                        alignSelf: 'flex-start',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        padding:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/svgs/restaurant-menu.svg')}
                                                        style={{
                                                            width: Icons.XL,
                                                            height: Icons.XL,
                                                        }}
                                                        resizeMode={'cover'}
                                                    />
                                                </View>

                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                            paddingRight: Layout.isMediumDevice
                                                                ? 0
                                                                : Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    For Diners
                                                </Text>

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    Establish trust through
                                                    employee-generated-content
                                                </Text>
                                                {this.renderSeparator()}

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    Discover food events through
                                                    up-to-date, compelling video
                                                    stories
                                                </Text>
                                                {this.renderSeparator()}

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    Seamlessly unite inspiration
                                                    and booking
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            backgroundColor: Colors.gray2,
                                        },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <FormHeaderTitle
                                            headerTitle={'Market opportunity'}
                                            headerTitleStyle={[
                                                TextStyles.Heading1,
                                                {
                                                    alignSelf: 'center',
                                                    paddingBottom:
                                                        Layout.screenPaddingLarge,
                                                },
                                            ]}
                                        />

                                        <View
                                            style={[
                                                // styles.cardContent,
                                                {
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    paddingVertical:
                                                        Layout.screenPaddingMedium,
                                                    // backgroundColor:
                                                    //     Colors.tintColor,
                                                },
                                            ]}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,

                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingXL,
                                                }}
                                            >
                                                <Image
                                                    source={require('../../assets/images/RoadmapMarketSize.png')}
                                                    style={{
                                                        width: '100%',
                                                        height: Layout.isMediumDevice
                                                            ? 400
                                                            : 570,
                                                    }}
                                                    resizeMode={'contain'}
                                                />
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                    maxWidth: 720,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading2,
                                                        {
                                                            paddingTop: Layout.isMediumDevice
                                                                ? Layout.screenPaddingLarge
                                                                : 0,
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    By 2028, global expenditure
                                                    on the total Experience
                                                    Economy will reach $8.2T
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 36,
                                                        },
                                                    ]}
                                                >
                                                    Over $1.4T* will be spent
                                                    within “Experiential
                                                    Hospitality,” which includes
                                                    Food events at restaurants
                                                    like prix-fixe dinners,
                                                    kitchen tables and pop-ups.
                                                    There is also potential to
                                                    capture a broader set of
                                                    events-based concepts like
                                                    culinary tours, cooking
                                                    classes, wineries,
                                                    nightlife, coffee and tea
                                                    tastings, and even
                                                    happenings at museums.
                                                </Text>

                                                {/* <View
                                                    style={{
                                                        flex: 1,
                                                        paddingTop:
                                                            Layout.screenPaddingXL,
                                                    }}
                                                >
                                                    {this.renderSeparator()}

                                                    <Text
                                                        style={[
                                                            TextStyles.Caption,
                                                            {
                                                                lineHeight: 24,
                                                            },
                                                        ]}
                                                    >
                                                        *$1.4T is an
                                                        approximation of the
                                                        global Full-service
                                                        restaurant market. This
                                                        is based on the 22% U.S.
                                                        share of total global
                                                        spending, where 40% of
                                                        U.S. spending was in
                                                        Full-service
                                                        restaurants.
                                                    </Text>
                                                </View> */}
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <ImageBackground
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            backgroundColor: Colors.gray4,
                                            height:
                                                Layout.SCREEN_HEIGHT * 0.382,
                                        },
                                    ]}
                                    source={require('../../assets/images/shangyou-shi-VdRex9AiBOc-unsplash.jpg')}
                                    resizeMode={'cover'}
                                />

                                <View style={styles.sectionContainer}>
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                flex: 1,
                                                maxWidth: 720,
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={
                                                    'Plans and pricing model'
                                                }
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                    { textAlign: 'center' },
                                                ]}
                                            />
                                            <Text
                                                style={[
                                                    TextStyles.Paragraph,
                                                    {
                                                        lineHeight: 26,
                                                        textAlign: 'center',
                                                    },
                                                ]}
                                            >
                                                Our creator tools are free for
                                                restaurants. We generate revenue
                                                from booking transactions and
                                                enterprise features.
                                            </Text>
                                        </View>

                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                alignItems: 'stretch',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={[
                                                    styles.cardContent,
                                                    {
                                                        //alignSelf: 'stretch',
                                                        borderRadius:
                                                            Layout.borderRadiusLarge,
                                                        marginRight: Layout.isMediumDevice
                                                            ? 0
                                                            : Layout.screenPaddingLarge,
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 360,
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingSmall,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading2,
                                                            {
                                                                color:
                                                                    Colors.primaryText,
                                                            },
                                                        ]}
                                                    >
                                                        Stories
                                                    </Text>
                                                    {this.renderRule()}
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        A digital stories
                                                        platform for producing
                                                        original content and
                                                        expanding your brand.
                                                        For businesses with
                                                        regular or one-time
                                                        events.
                                                    </Text>
                                                </View>

                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.accentDeepsea
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={
                                                                TextStyles.Paragraph
                                                            }
                                                        >
                                                            Events creator on
                                                            mobile
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator()}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.accentDeepsea
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={
                                                                TextStyles.Paragraph
                                                            }
                                                        >
                                                            Collaborative team
                                                            video-stories
                                                            creator
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator()}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.accentDeepsea
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={
                                                                TextStyles.Paragraph
                                                            }
                                                        >
                                                            Individual
                                                            video-story digests
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator()}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.accentDeepsea
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={
                                                                TextStyles.Paragraph
                                                            }
                                                        >
                                                            Sharing to Social
                                                            and Messaging apps
                                                        </Text>
                                                    </View>
                                                </View>
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading2,
                                                            {
                                                                paddingBottom: 8,
                                                            },
                                                        ]}
                                                    >
                                                        Free
                                                    </Text>

                                                    <Text
                                                        style={
                                                            TextStyles.Caption
                                                        }
                                                    >
                                                        Core features included
                                                    </Text>
                                                </View>
                                                <Button
                                                    // iconLeft={true}
                                                    // iconName={'smartphone'}
                                                    // iconColor={Colors.tintColor}
                                                    // onPress={() =>
                                                    //     this.props.navigation.navigate(
                                                    //         'Product'
                                                    //     )
                                                    // }
                                                    onPress={() => {
                                                        this.props.navigation.navigate(
                                                            'Product'
                                                        )
                                                    }}
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                    buttonStyle={{
                                                        borderWidth: 1,
                                                        backgroundColor:
                                                            Colors.transparentBackground,
                                                        borderColor:
                                                            Colors.tintColor,
                                                    }}
                                                    textStyle={[
                                                        TextStyles.Button,
                                                        {
                                                            color:
                                                                Colors.tintColor,
                                                        },
                                                    ]}
                                                    title={'Now available'}
                                                />
                                            </View>
                                            <View
                                                style={[
                                                    styles.cardContent,
                                                    {
                                                        //alignSelf: 'stretch',
                                                        borderRadius:
                                                            Layout.borderRadiusLarge,
                                                        marginRight: Layout.isMediumDevice
                                                            ? 0
                                                            : Layout.screenPaddingLarge,
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 360,
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingSmall,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading2,
                                                            {
                                                                color:
                                                                    Colors.primaryText,
                                                            },
                                                        ]}
                                                    >
                                                        Stories Plus
                                                    </Text>
                                                    {this.renderRule()}
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        An integrated Events and
                                                        Marketing solution for
                                                        producing original
                                                        content and booking
                                                        guests. For businesses
                                                        with regular events
                                                        across multiple
                                                        properties.
                                                    </Text>
                                                </View>
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.accentDeepsea
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={[
                                                                TextStyles.Heading4,
                                                                // {
                                                                //     color:
                                                                //         Colors.accentDeepsea,
                                                                // },
                                                            ]}
                                                        >
                                                            Everything in
                                                            Stories
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator()}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.accentDeepsea
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={
                                                                TextStyles.Paragraph
                                                            }
                                                        >
                                                            In-app booking and
                                                            payments
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator()}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.accentDeepsea
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={
                                                                TextStyles.Paragraph
                                                            }
                                                        >
                                                            General admissions
                                                            tickets
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator()}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.accentDeepsea
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={
                                                                TextStyles.Paragraph
                                                            }
                                                        >
                                                            Variable ticket
                                                            pricing
                                                        </Text>
                                                    </View>
                                                </View>
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading2,
                                                            {
                                                                paddingBottom: 8,
                                                            },
                                                        ]}
                                                    >
                                                        3%{' '}
                                                        <Text
                                                            style={
                                                                TextStyles.Paragraph
                                                            }
                                                        >
                                                            per transaction
                                                        </Text>
                                                    </Text>

                                                    <Text
                                                        style={
                                                            TextStyles.Caption
                                                        }
                                                    >
                                                        + $0.99 per-ticket
                                                    </Text>
                                                </View>
                                                <Button
                                                    disabled={true}
                                                    iconLeft={false}
                                                    iconName={'smartphone'}
                                                    iconColor={Colors.tintColor}
                                                    onPress={() =>
                                                        this.props.navigation.navigate(
                                                            'Product'
                                                        )
                                                    }
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                    buttonStyle={{
                                                        borderWidth: 1,
                                                        backgroundColor:
                                                            Colors.transparentBackground,
                                                        borderColor:
                                                            Colors.tintColor,
                                                    }}
                                                    textStyle={[
                                                        TextStyles.Button,
                                                        {
                                                            color:
                                                                Colors.tintColor,
                                                        },
                                                    ]}
                                                    title={'Coming next'}
                                                />
                                            </View>
                                            <View
                                                style={[
                                                    styles.cardContent,
                                                    {
                                                        //alignSelf: 'stretch',
                                                        borderRadius:
                                                            Layout.borderRadiusLarge,
                                                        marginRight: Layout.isMediumDevice
                                                            ? 0
                                                            : Layout.screenPaddingLarge,
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 360,
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingSmall,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading2,
                                                            {
                                                                color:
                                                                    Colors.primaryText,
                                                            },
                                                        ]}
                                                    >
                                                        Enterprise
                                                    </Text>
                                                    {this.renderRule()}
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        For large-scale,
                                                        multi-location events
                                                        needing custom
                                                        integrations, dedicated
                                                        support, and advanced
                                                        tools.
                                                    </Text>
                                                </View>

                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.accentDeepsea
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={[
                                                                TextStyles.Heading4,
                                                            ]}
                                                        >
                                                            Everything in
                                                            Stories Plus
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator()}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.accentDeepsea
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={
                                                                TextStyles.Paragraph
                                                            }
                                                        >
                                                            Custom App
                                                            integrations
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator()}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.accentDeepsea
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={
                                                                TextStyles.Paragraph
                                                            }
                                                        >
                                                            Dedicated account
                                                            manager
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator()}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.accentDeepsea
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={
                                                                TextStyles.Paragraph
                                                            }
                                                        >
                                                            Customized roles and
                                                            permissions
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator()}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.accentDeepsea
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={
                                                                TextStyles.Paragraph
                                                            }
                                                        >
                                                            Studio custom
                                                            content services
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator()}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.accentDeepsea
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={
                                                                TextStyles.Paragraph
                                                            }
                                                        >
                                                            Access to Studio
                                                            Collections
                                                        </Text>
                                                    </View>
                                                </View>
                                                <Button
                                                    disabled={true}
                                                    iconLeft={false}
                                                    iconName={'smartphone'}
                                                    iconColor={Colors.tintColor}
                                                    onPress={() =>
                                                        this.props.navigation.navigate(
                                                            'Product'
                                                        )
                                                    }
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                    buttonStyle={{
                                                        borderWidth: 1,
                                                        backgroundColor:
                                                            Colors.transparentBackground,
                                                        borderColor:
                                                            Colors.tintColor,
                                                    }}
                                                    textStyle={[
                                                        TextStyles.Button,
                                                        {
                                                            color:
                                                                Colors.tintColor,
                                                        },
                                                    ]}
                                                    title={'Coming later'}
                                                />
                                            </View>
                                        </View>
                                        <View
                                            style={{
                                                flex: 1,
                                                maxWidth: 720,
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                                paddingVertical: Layout.isMediumDevice
                                                    ? Layout.screenPaddingLarge
                                                    : Layout.screenPaddingXL,
                                            }}
                                        >
                                            {/* <Text
                                            style={[
                                                TextStyles.Heading2,
                                                {
                                                    textAlign: Layout.isMediumDevice
                                                        ? 'left'
                                                        : 'center',
                                                },
                                            ]}
                                        >
                                            “Digital ordering may be growing by
                                            double digits, but about 90% of
                                            restaurant occasions are still at
                                            brick-and-mortar locations.”
                                        </Text> */}
                                            <Text
                                                style={[
                                                    TextStyles.Heading2,
                                                    {
                                                        textAlign: Layout.isMediumDevice
                                                            ? 'left'
                                                            : 'center',
                                                        paddingBottom:
                                                            Layout.screenPaddingMedium,
                                                    },
                                                ]}
                                            >
                                                At 3% per-transaction + $0.99
                                                per-ticket, we earn $2.85 per
                                                meal.
                                            </Text>
                                            <Text
                                                style={[
                                                    TextStyles.Paragraph,
                                                    {
                                                        textAlign: Layout.isMediumDevice
                                                            ? 'left'
                                                            : 'center',
                                                        lineHeight: 26,
                                                    },
                                                ]}
                                            >
                                                This earning is based on average
                                                cover of $62 at the Top 100
                                                Independent Restaurants. In
                                                2018, they served over 35
                                                million meals.
                                            </Text>
                                        </View>
                                    </View>
                                </View>

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            backgroundColor: Colors.aesop,
                                        },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                flex: 1,
                                                maxWidth: 720,
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={'People powered'}
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                    { textAlign: 'center' },
                                                ]}
                                            />
                                            <Text
                                                style={[
                                                    TextStyles.Paragraph,
                                                    {
                                                        lineHeight: 26,
                                                        textAlign: 'center',
                                                    },
                                                ]}
                                            >
                                                Over 5 million creators, ready
                                                for the stage
                                            </Text>
                                        </View>
                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                alignItems: 'stretch',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={[
                                                    styles.cardContent,
                                                    {
                                                        padding: 0,
                                                        marginVertical: 0,
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                    },
                                                ]}
                                            >
                                                <Image
                                                    source={require('../../assets/images/jeff-siepman-kyuPjZudBKs-unsplash.jpg')}
                                                    style={{
                                                        width: '100%',
                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_HEIGHT *
                                                              0.5
                                                            : '100%',
                                                    }}
                                                    resizeMode={'cover'}
                                                />
                                            </View>

                                            <View
                                                style={[
                                                    styles.cardContent,
                                                    {
                                                        marginVertical: 0,
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingSmall,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Overline2,
                                                            {
                                                                color:
                                                                    Colors.primaryText,
                                                                paddingVertical:
                                                                    Layout.screenPaddingSmall,
                                                                textAlign:
                                                                    'center',
                                                                textTransform:
                                                                    'uppercase',
                                                            },
                                                        ]}
                                                    >
                                                        U.S. FOOD and DRINK
                                                        INDUSTRY 2018
                                                    </Text>
                                                    {this.renderRule()}
                                                </View>
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        U.S. Restaurant services
                                                        employees
                                                    </Text>
                                                    <Text
                                                        style={
                                                            TextStyles.Heading1
                                                        }
                                                    >
                                                        5,089,620
                                                    </Text>
                                                </View>
                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        U.S. share of global
                                                        consumer spending
                                                    </Text>
                                                    <Text
                                                        style={
                                                            TextStyles.Heading1
                                                        }
                                                    >
                                                        22%
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}

                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Food-service
                                                        expenditures annual
                                                        growth
                                                    </Text>
                                                    <Text
                                                        style={
                                                            TextStyles.Heading1
                                                        }
                                                    >
                                                        6.4%
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                {/* Omit stats for now */}

                                {/* 
                                <View
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            backgroundColor: Colors.gray0,
                                        },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                flex: 1,
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={
                                                    'Our current stats'
                                                }
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                    { textAlign: 'center' },
                                                ]}
                                            />
                                            <Text
                                                style={[
                                                    TextStyles.Caption,
                                                    {
                                                        textAlign: Layout.isMediumDevice
                                                            ? 'center'
                                                            : 'auto',
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Caption,
                                                        {
                                                            color:
                                                                Colors.accentDeepsea,
                                                        },
                                                    ]}
                                                >
                                                    •
                                                </Text>
                                                {'  '}
                                                Last updated: {timestampLabel}
                                            </Text>
                                            {this.renderSpacer()}
                                        </View>
                                        <View
                                            style={{
                                                alignItems: 'stretch',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={[
                                                    styles.cardContent,
                                                    {
                                                        marginRight: Layout.isMediumDevice
                                                            ? 0
                                                            : Layout.screenPaddingLarge,
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 360,
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingSmall,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Overline2,
                                                            {
                                                                color:
                                                                    Colors.primaryText,
                                                                paddingVertical:
                                                                    Layout.screenPaddingSmall,
                                                                textAlign:
                                                                    'center',
                                                                textTransform:
                                                                    'uppercase',
                                                            },
                                                        ]}
                                                    >
                                                        EARLY ACCESS
                                                    </Text>
                                                    {this.renderRule()}
                                                </View>
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Total active venues
                                                    </Text>
                                               
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                color:
                                                                    Colors.disabled,
                                                            },
                                                        ]}
                                                    >
                                                        Coming soon
                                                    </Text>
                                                </View>
                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Monthly active
                                                        professionals
                                                    </Text>
                                                   
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                color:
                                                                    Colors.disabled,
                                                            },
                                                        ]}
                                                    >
                                                        Coming soon
                                                    </Text>
                                                </View>
                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Monthly original stories
                                                    </Text>
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                color:
                                                                    Colors.disabled,
                                                            },
                                                        ]}
                                                    >
                                                        Coming soon
                                                    </Text>
                                                </View>
                                            </View>

                                            <View
                                                style={[
                                                    styles.cardContent,
                                                    {
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 360,
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingSmall,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Overline2,
                                                            {
                                                                color:
                                                                    Colors.primaryText,
                                                                paddingVertical:
                                                                    Layout.screenPaddingSmall,
                                                                textAlign:
                                                                    'center',
                                                                textTransform:
                                                                    'uppercase',
                                                            },
                                                        ]}
                                                    >
                                                        Up Next
                                                    </Text>
                                                    {this.renderRule()}
                                                </View>
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Total monthly shares
                                                    </Text>

                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                color:
                                                                    Colors.disabled,
                                                            },
                                                        ]}
                                                    >
                                                        Coming soon
                                                    </Text>
                                                </View>
                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Monthly active consumers
                                                    </Text>

                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                color:
                                                                    Colors.disabled,
                                                            },
                                                        ]}
                                                    >
                                                        Coming soon
                                                    </Text>
                                                </View>
                                                {this.renderSeparator()}

                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Monthly consumer
                                                        bookmarks
                                                    </Text>

                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                color:
                                                                    Colors.disabled,
                                                            },
                                                        ]}
                                                    >
                                                        Coming soon
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
 */}

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            backgroundColor: Colors.gray0,
                                        },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                maxWidth: 720,
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={'Road to growth'}
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                    {
                                                        paddingBottom:
                                                            Layout.screenPaddingMedium,
                                                    },
                                                ]}
                                            />

                                            <Text
                                                style={[
                                                    TextStyles.Heading2,
                                                    {
                                                        paddingBottom:
                                                            Layout.screenPaddingXL,
                                                        lineHeight: 36,
                                                    },
                                                ]}
                                            >
                                                We are building an unparalleled
                                                product experience and growing
                                                the number of people on the
                                                platform
                                            </Text>
                                        </View>

                                        {/* <Text
                                            style={[
                                                TextStyles.Heading3,
                                                {
                                                    paddingBottom:
                                                        Layout.screenPaddingLarge,
                                                },
                                            ]}
                                        >
                                            Current stage
                                        </Text> */}
                                        {/*                                         
                                        <View
                                            style={{
                                                flex: 1,
                                                paddingBottom:
                                                    Layout.screenPaddingLarge,
                                                flexDirection: Layout.isMediumDevice
                                                    ? 'row'
                                                    : 'column',
                                            }}
                                        >
                                            {Layout.isMediumDevice
                                                ? this.renderSliderMobile()
                                                : this.renderSlider()}
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingVertical: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingMedium,

                                                    flexDirection: Layout.isMediumDevice
                                                        ? 'column'
                                                        : 'row',
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        flex: 1,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 36,
                                                            },
                                                        ]}
                                                    >
                                                        Bootstrapping
                                                    </Text>
                                                    <Text
                                                        style={[
                                                            TextStyles.Caption,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingMedium,
                                                            },
                                                        ]}
                                                    >
                                                        Winter 2018/19
                                                    </Text>
                                                </View>
                                                <View
                                                    style={{
                                                        flex: 1,
                                                        alignItems: Layout.isMediumDevice
                                                            ? 'flex-start'
                                                            : 'center',
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                textAlign:
                                                                    'center',
                                                                lineHeight: 36,
                                                            },
                                                        ]}
                                                    >
                                                        Early Access
                                                    </Text>
                                                    <Text
                                                        style={[
                                                            TextStyles.Caption,
                                                            {
                                                                textAlign:
                                                                    'center',
                                                                paddingBottom:
                                                                    Layout.screenPaddingMedium,
                                                            },
                                                        ]}
                                                    >
                                                        Summer 2019
                                                    </Text>
                                                </View>
                                                <View
                                                    style={{
                                                        flex: 1,
                                                        alignItems: Layout.isMediumDevice
                                                            ? 'flex-start'
                                                            : 'flex-end',
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 36,
                                                            },
                                                        ]}
                                                    >
                                                        Pre-seed
                                                    </Text>
                                                    <Text
                                                        style={[
                                                            TextStyles.Caption,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingMedium,
                                                            },
                                                        ]}
                                                    >
                                                        Fall 2019
                                                    </Text>
                                                </View>
                                            </View>
                                        </View> */}

                                        <View
                                            style={[
                                                styles.cardContent,
                                                {
                                                    maxWidth: 1440,
                                                },
                                            ]}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Overline2,
                                                        {
                                                            color:
                                                                Colors.primaryText,
                                                            textAlign: 'center',
                                                            textTransform:
                                                                'uppercase',
                                                            paddingVertical:
                                                                Layout.screenPaddingSmall,
                                                        },
                                                    ]}
                                                >
                                                    Projected funding allocation
                                                </Text>
                                                {this.renderRule()}
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                    alignItems: 'flex-start',
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        flex: 1,
                                                        paddingRight: Layout.isMediumDevice
                                                            ? 0
                                                            : Layout.screenPaddingLarge,
                                                        paddingVertical:
                                                            Layout.screenPaddingLarge,
                                                        width: '100%',
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 360,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Growth
                                                    </Text>
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                                paddingBottom:
                                                                    Layout.screenPaddingLarge,
                                                            },
                                                        ]}
                                                    >
                                                        Effectively facilitate
                                                        venue and customer
                                                        acquisition, positioned
                                                        for revenue
                                                    </Text>
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.tintColor
                                                            }
                                                            size={Icons.small}
                                                            name={'plus'}
                                                        />
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 26,
                                                                },
                                                            ]}
                                                        >
                                                            Onboard select
                                                            restaurants and
                                                            employees
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator(
                                                        Colors.gray1
                                                    )}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.tintColor
                                                            }
                                                            size={Icons.small}
                                                            name={'plus'}
                                                        />
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 26,
                                                                },
                                                            ]}
                                                        >
                                                            Sign up restaurant
                                                            guests
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator(
                                                        Colors.gray1
                                                    )}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.tintColor
                                                            }
                                                            size={Icons.small}
                                                            name={'plus'}
                                                        />
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 26,
                                                                },
                                                            ]}
                                                        >
                                                            Book and process
                                                            covers
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator(
                                                        Colors.gray1
                                                    )}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.tintColor
                                                            }
                                                            size={Icons.small}
                                                            name={'plus'}
                                                        />
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 26,
                                                                },
                                                            ]}
                                                        >
                                                            Support travel and
                                                            outreach
                                                        </Text>
                                                    </View>
                                                </View>
                                                <View
                                                    style={{
                                                        flex: 1,
                                                        paddingRight: Layout.isMediumDevice
                                                            ? 0
                                                            : Layout.screenPaddingLarge,
                                                        paddingVertical:
                                                            Layout.screenPaddingLarge,
                                                        width: '100%',
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 360,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        Technology
                                                    </Text>
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                                paddingBottom:
                                                                    Layout.screenPaddingLarge,
                                                            },
                                                        ]}
                                                    >
                                                        Develop robust, scalable
                                                        tech with emerging
                                                        processes in place
                                                    </Text>
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.tintColor
                                                            }
                                                            size={Icons.small}
                                                            name={'plus'}
                                                        />
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 26,
                                                                },
                                                            ]}
                                                        >
                                                            Implement a
                                                            next-generation
                                                            marketplace with
                                                            advanced tooling
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator(
                                                        Colors.gray1
                                                    )}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.tintColor
                                                            }
                                                            size={Icons.small}
                                                            name={'plus'}
                                                        />
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 26,
                                                                },
                                                            ]}
                                                        >
                                                            Scale Cloud
                                                            infrastructure,
                                                            integrations and
                                                            insights
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator(
                                                        Colors.gray1
                                                    )}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.tintColor
                                                            }
                                                            size={Icons.small}
                                                            name={'plus'}
                                                        />
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 26,
                                                                },
                                                            ]}
                                                        >
                                                            Build Payments and
                                                            Booking, with
                                                            performance insights
                                                        </Text>
                                                    </View>
                                                </View>
                                                <View
                                                    style={{
                                                        flex: 1,
                                                        paddingRight: Layout.isMediumDevice
                                                            ? 0
                                                            : Layout.screenPaddingLarge,
                                                        paddingVertical:
                                                            Layout.screenPaddingLarge,
                                                        width: '100%',
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 360,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading3,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                            },
                                                        ]}
                                                    >
                                                        People
                                                    </Text>
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                                paddingBottom:
                                                                    Layout.screenPaddingLarge,
                                                            },
                                                        ]}
                                                    >
                                                        Gather an inclusive,
                                                        nimble team with
                                                        complementary skillsets
                                                    </Text>
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.tintColor
                                                            }
                                                            size={Icons.small}
                                                            name={'plus'}
                                                        />
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 26,
                                                                },
                                                            ]}
                                                        >
                                                            2 Co-founders
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator(
                                                        Colors.gray1
                                                    )}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.tintColor
                                                            }
                                                            size={Icons.small}
                                                            name={'plus'}
                                                        />
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 26,
                                                                },
                                                            ]}
                                                        >
                                                            1 Engineer,
                                                            full-time employee
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator(
                                                        Colors.gray1
                                                    )}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.tintColor
                                                            }
                                                            size={Icons.small}
                                                            name={'plus'}
                                                        />
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 26,
                                                                },
                                                            ]}
                                                        >
                                                            Legal and
                                                            administrative
                                                            support
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator(
                                                        Colors.gray1
                                                    )}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.tintColor
                                                            }
                                                            size={Icons.small}
                                                            name={'plus'}
                                                        />
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 26,
                                                                },
                                                            ]}
                                                        >
                                                            Sales and Marketing,
                                                            Creative, and
                                                            Customer support
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>

                                            {Layout.isMediumDevice
                                                ? this.renderRule()
                                                : this.renderSeparator()}

                                            <View
                                                style={{
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,
                                                    flexDirection:
                                                        'row-reverse',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                {/*
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                    paddingBottom: Layout.isMediumDevice
                                                        ? Layout.screenPaddingLarge
                                                        : 0,
                                                    alignItems: Layout.isMediumDevice
                                                        ? 'center'
                                                        : 'flex-end',
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading1,
                                                        {
                                                            textAlign: Layout.isMediumDevice
                                                                ? 'center'
                                                                : 'right',
                                                            paddingRight: Layout.isMediumDevice
                                                                ? null
                                                                : Layout.screenPaddingLarge,
                                                        },
                                                    ]}
                                                >
                                                    $150K pre-seed
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.Heading4,
                                                        {
                                                            textAlign: Layout.isMediumDevice
                                                                ? 'center'
                                                                : 'right',
                                                            paddingRight: Layout.isMediumDevice
                                                                ? null
                                                                : Layout.screenPaddingLarge,
                                                        },
                                                    ]}
                                                >
                                                    $2M seed
                                                </Text>
                                            </View> */}
                                                <View
                                                    style={{
                                                        flex: 1,
                                                        // minWidth: Layout.isMediumDevice
                                                        //     ? '100%'
                                                        //     : 360,
                                                        alignItems: Layout.isMediumDevice
                                                            ? 'center'
                                                            : 'center',
                                                    }}
                                                >
                                                    <Button
                                                        iconLeft={true}
                                                        iconName={'activity'}
                                                        iconColor={
                                                            Colors.accentDeepsea
                                                        }
                                                        onPress={() =>
                                                            this.props.navigation.navigate(
                                                                'Contact'
                                                            )
                                                        }
                                                        buttonStyle={{
                                                            borderWidth: 1,
                                                            backgroundColor:
                                                                Colors.transparentBackground,
                                                            borderColor:
                                                                Colors.tintColor,
                                                        }}
                                                        textStyle={[
                                                            {
                                                                color:
                                                                    Colors.tintColor,
                                                            },
                                                        ]}
                                                        title={
                                                            'Let’s discuss funding'
                                                        }
                                                    />
                                                    {/* 
                                                <Text
                                                    style={[
                                                        TextStyles.Caption,
                                                        {
                                                            paddingTop:
                                                                Layout.screenPaddingMedium,
                                                            textAlign: Layout.isMediumDevice
                                                                ? 'center'
                                                                : 'auto',
                                                        },
                                                    ]}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Caption,
                                                            {
                                                                color:
                                                                    Colors.accentDeepsea,
                                                            },
                                                        ]}
                                                    >
                                                        •
                                                    </Text>
                                                    {'  '}
                                                    We are actively taking
                                                    conversations
                                                </Text> */}
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <ImageBackground
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            backgroundColor: Colors.gray4,
                                            height:
                                                Layout.SCREEN_HEIGHT * 0.382,
                                        },
                                    ]}
                                    source={require('../../assets/images/jorgen-haland-8UE83jPlNXg-unsplash.jpg')}
                                    resizeMode={'cover'}
                                />

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            backgroundColor: Colors.gray2,
                                        },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                flex: 1,
                                                alignSelf: 'center',

                                                // alignSelf: Layout.isMediumDevice
                                                //     ? 'center'
                                                //     : 'flex-start',
                                                // alignItems: Layout.isMediumDevice
                                                //     ? 'center'
                                                //     : 'flex-start',
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={'FAQs'}
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                ]}
                                            />

                                            {/* {this.renderSpacer()} */}
                                        </View>

                                        <View
                                            style={{
                                                alignItems: 'flex-start',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,
                                                }}
                                            >
                                                <Text
                                                    style={TextStyles.Heading3}
                                                >
                                                    Why is Indvstry an online
                                                    destination?
                                                </Text>
                                                {this.renderSpacer()}
                                                {this.renderItem({
                                                    text:
                                                        'For the discerning Millenial consumers, full-price, multi-brand online stores drives 1/3 of purchases.',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                                {this.renderItem({
                                                    text:
                                                        'What matters the most is a “wide range of brands” and an “opportunity to find niche brands and products.”',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                }}
                                            >
                                                <Text
                                                    style={TextStyles.Heading3}
                                                >
                                                    Why is Indvstry focused on
                                                    mobile content?
                                                </Text>
                                                {this.renderSpacer()}
                                                {this.renderItem({
                                                    text:
                                                        'By 2024, discerning consumers will make up to 90% of purchases on a mobile device vs desktop.',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                                {this.renderItem({
                                                    text:
                                                        'Social media and influencers curently rank as the 1st influence lever at 39%, for discerning consumers. This is followed by Magazines (both traditional and digital) at 37% and Brand websites or app at 31%.',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                            </View>
                                        </View>

                                        <View
                                            style={{
                                                alignItems: 'flex-start',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,
                                                }}
                                            >
                                                <Text
                                                    style={TextStyles.Heading3}
                                                >
                                                    What are some ideas for
                                                    monetization?
                                                </Text>
                                                {this.renderSpacer()}
                                                {this.renderItem({
                                                    text:
                                                        'Payment processing for events and pre-paid dining inventory, through the platform',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                                {this.renderItem({
                                                    text:
                                                        'Flexible pricing mechanisms based on the restaurant’s demand volume',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                                {this.renderItem({
                                                    text:
                                                        'ROI-based pricing that reflects impact of content posted in-app, as well as through integrated channels',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                }}
                                            >
                                                <Text
                                                    style={TextStyles.Heading3}
                                                >
                                                    How are you different from
                                                    the big companies?
                                                </Text>
                                                {this.renderSpacer()}
                                                {this.renderItem({
                                                    text:
                                                        'Not a B2C information aggregator',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                                {this.renderItem({
                                                    text:
                                                        'Not a fixed-fee SaaS model',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                                {this.renderItem({
                                                    text:
                                                        'Not dependent on ads',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                            </View>
                                        </View>

                                        {this.renderSeparator(Colors.gray5)}

                                        <View
                                            style={{
                                                alignItems: 'flex-start',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.CaptionBold,
                                                        {
                                                            lineHeight: 24,
                                                            wordBreak:
                                                                'break-all',
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                        },
                                                    ]}
                                                >
                                                    Data
                                                </Text>

                                                <Text
                                                    style={[
                                                        TextStyles.Caption,
                                                        {
                                                            lineHeight: 24,
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                        },
                                                    ]}
                                                >
                                                    *$1.4T is an approximation
                                                    of the global Full-service
                                                    restaurant market. This is
                                                    based on the 22% U.S. share
                                                    of total global spending,
                                                    where 40% of U.S. spending
                                                    was in Full-service
                                                    restaurants.
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.CaptionBold,
                                                        {
                                                            lineHeight: 24,
                                                            wordBreak:
                                                                'break-all',
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                        },
                                                    ]}
                                                >
                                                    Resources
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.Caption,
                                                        {
                                                            lineHeight: 24,
                                                            wordBreak:
                                                                'break-all',
                                                        },
                                                    ]}
                                                >
                                                    https://www.restaurantbusinessonline.com/top-100-independents
                                                    {`\n`}
                                                    https://www.exploretock.com/join/events
                                                    {`\n`}
                                                    https://versionone.vc/marketplaces-guide-ed2/
                                                    {'\n'}
                                                    https://www.luxurydaily.com/wp-content/uploads/2018/11/01-BCG-Luxury-FirstLook-2019.pptx
                                                    {`\n`}
                                                    http://media-publications.bcg.com/france/True-Luxury%20Global%20Consumer%20Insight%202019%20-%20Plenary%20-%20vMedia.pdf
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingXL,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Caption,
                                                        {
                                                            lineHeight: 24,
                                                            wordBreak:
                                                                'break-all',
                                                        },
                                                    ]}
                                                >
                                                    {`\n`}
                                                    https://www.statista.com/topics/4476/restaurant-and-foodservice-industry-in-the-us/
                                                    {`\n`}
                                                    https://www.bls.gov/oes/current/naics5_722511.htm
                                                    {'\n'}
                                                    https://www.mckinsey.com/industries/private-equity-and-principal-investors/our-insights/cashing-in-on-the-us-experience-economy
                                                    {'\n'}
                                                    https://www.afr.com/news/world/asia/why-millennials-are-creating-an-experience-economy-20180717-h12ruy
                                                    {'\n'}
                                                    https://www.npd.com/wps/portal/npd/us/news/videos/digital-is-more-than-delivery/
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                {/* 
                            <ImageBackground
                                style={[
                                    styles.sectionContainer,
                                    {
                                        //backgroundColor: Colors.gray5,                                        height: Layout.SCREEN_HEIGHT * 0.382,
                                    },
                                ]}
                                source={require('../../assets/images/louis-hansel-qbC9hh0aRiY-unsplash.jpg')}
                                resizeMode={'cover'}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        //paddingVertical: 88,
                                        alignSelf: 'center',
                                        // height:
                                        //     Layout.SCREEN_HEIGHT * 0.382,
                                        backgroundColor:
                                            Colors.mediumBackground,
                                    }}
                                />
                            </ImageBackground> */}

                                <GetInTouch
                                    navigation={this.props.navigation}
                                />

                                <Footer navigation={this.props.navigation} />
                            </ScrollView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    mainView: {
        flex: 1,
        borderTopWidth: 6,
        paddingTop: 88,
        borderTopColor: Colors.tintColor,
        backgroundColor: Colors.tintColor,
    },

    sectionContainer: {
        alignItems: 'center',
        backgroundColor: Colors.gray0,
        paddingVertical: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
    },

    cardContent: {
        flex: 1,
        maxWidth: 720,
        marginVertical: Layout.screenPaddingMedium,
        backgroundColor: Colors.white,
        padding: Layout.screenPaddingLarge,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        paddingVertical: Layout.screenPaddingLarge,
        paddingHorizontal: Layout.screenPaddingLarge,
    },

    imageContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        padding: Layout.screenPaddingLarge,
    },
    stickyButton: {
        flex: 1,
        position: 'absolute',
        bottom: 0,
        right: 0,
        padding: Layout.isMediumDevice
            ? Layout.screenPaddingMedium
            : Layout.screenPaddingLarge,
    },
})
