const tintColor = '#20201E' // previously black #1A1A1A
const black = '#000000'
const white = '#ffffff'

// Original grays
// const gray0 = '#f9f9f9'
// const gray1 = '#ebebeb'
// const gray2 = '#d5d5d5'
// const gray3 = '#bcbcbc'
// const gray4 = '#9d9d9d'
// const gray5 = '#747474'

// Aesop warm 'grays'
// https://palx.jxnblk.com/fffef2
// https://htmlcolorcodes.com/color-picker/

const gray0 = '#FFFFFA'
const gray1 = '#FFFEF2'
const gray2 = '#EFEEE4'
const gray3 = '#DFDED5'
const gray4 = '#AFAFAA'
const gray5 = '#8F8F8E'

const shadowColor = '#000000'
const shadowOffset = { width: 0, height: 1 }
const shadowOpacity = 0.2
const shadowRadius = 2

const shadowOffsetDepth = { width: 0, height: 4 }
const shadowOpacityDepth = 0.1
const shadowRadiusDepth = 6

export default {
    // Base Colors
    tintColor,
    black,
    white,
    gray0,
    gray1,
    gray2,
    gray3,
    gray4,
    gray5,

    // BGs
    primaryBackground: white,
    secondaryBackground: gray0,
    tertiaryBackground: gray1,
    darkBackground: 'rgba(0,0,0,0.72)',
    mediumBackground: 'rgba(0,0,0,0.32)',
    transparentBackground: 'rgba(0,0,0,0)',
    tabBarLightBackground: 'rgba(255,254,240, .5)', // <-- Gray 0 with transparency

    // Color palette
    aesop: '#f6f5e8',
    sunshine: '#fbe099',
    gold: '#f1ae00',
    coral: '#fa7268',
    lightsea: '#79deb9',
    marine: '#26477c',
    deepsea: '#004150' /* currently selected */,

    // Accent colors
    accentRed: '#ff1744',
    accentYellow: '#ffea00',
    accentBlue: '#2962ff',
    accentMarine: '#12098f',
    accentGreen: '#00e676',
    accentSea: '#1de9b6',
    accentDeepsea: '#00bfa5' /* currently selected */,

    // States and Elements
    tabIconSelected: tintColor,
    tabIconSelectedInverse: gray0,
    tabBar: gray0,
    tabBarBlack: black,
    border: gray2, // was gray1
    disabled: gray3,
    disabledAlt: 'rgba(231,230,219, 0.32)',

    tabIconDefault: gray2,
    highlightColor: gray5,

    // Text colors
    primaryText: tintColor,
    secondaryText: gray5,
    inverseText: gray0, // was "aesop" '#f6f5e8', was white,
    inverseTextDisabled: 'rgba(255,255,255,0.48)',

    // Icon colors
    primaryIcon: tintColor,
    secondaryIcon: gray4,
    inverseIcon: {
        shadowColor: shadowColor,
        shadowOffset: shadowOffset,
        shadowOpacity: shadowOpacity,
        shadowRadius: shadowRadius,
    },
    inverseIconDisabled: 'rgba(255,255,255,0.24)',

    // Shadows
    shadowColor,
    shadowOffset,
    shadowOpacity,
    shadowRadius,

    shadowOffsetDepth,
    shadowOpacityDepth,
    shadowRadiusDepth,

    // Extra
    errorBackground: 'red',
    errorText: '#fff',
    greyBackground: '#fafafa',
    warningBackground: '#EAEB5E',
    warningText: '#666804',
    noticeBackground: 'black',
    noticeText: '#fff',
    listItemTouchableHighlight: '#eee',
}
