import React from 'react'
import {
    KeyboardAvoidingView,
    LayoutAnimation,
    Platform,
    ScrollView,
    StyleSheet,
    StatusBar,
    Text,
    TextInput,
    View,
} from 'react-native'
import { SafeAreaView } from 'react-navigation'
import { Feather } from '@expo/vector-icons'
import { Colors, Icons, TextStyles, Layout } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import FormHeaderTitle from '../../components/FormHeaderTitle'
import FormFooterOptions from '../../components/FormFooterOptions'
import Button from '../../components/Button'
import ButtonIcon from '../../components/ButtonIcon'
import Footer from '../../components/Footer'

export default class RequestConfirmation extends React.Component {
    componentDidMount = async () => {
        await this.context.resetUserForms()
        this.props.navigation.setParams({ active: true })
    }

    renderSeparator() {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                    paddingTop: 16,
                }}
            />
        )
    }

    render() {
        // LayoutAnimation.easeInEaseOut()

        return (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View
                            style={{
                                flex: 1,
                            }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <KeyboardAvoidingView
                                behavior={
                                    Platform.OS == 'ios' ? 'padding' : null
                                }
                                keyboardVerticalOffset={
                                    Layout.TAB_DEFAULT_HEIGHT + 8
                                }
                                style={{
                                    flex: 1,
                                }}
                            >
                                <ScrollView
                                    style={{
                                        flex: 1,
                                        backgroundColor: Colors.gray0,
                                        paddingTop: Layout.isMediumDevice
                                            ? Layout.topNavHeightMobile
                                            : Layout.topNavHeightDesktop,
                                    }}
                                >
                                    <View style={styles.sectionContainer}>
                                        <View style={styles.bodyContent}>
                                            <View
                                                style={{
                                                    height: Icons.XL * 2,
                                                    width: Icons.XL * 2,
                                                    borderRadius: Icons.XL,
                                                    borderWidth: 3,
                                                    borderColor:
                                                        Colors.accentDeepsea,
                                                    // backgroundColor:
                                                    //     Colors.accentDeepsea,
                                                    justifyContent: 'center',
                                                    alignItems: 'center',
                                                    alignSelf: 'center',
                                                    marginBottom:
                                                        Layout.screenPaddingLarge,
                                                }}
                                            >
                                                <Feather
                                                    style={{
                                                        alignSelf: 'center',
                                                    }}
                                                    color={Colors.accentDeepsea}
                                                    size={Icons.XL}
                                                    name={'check'}
                                                />
                                            </View>
                                            <FormHeaderTitle
                                                headerTitle={
                                                    'Thank’s for reaching out'
                                                }
                                                // headerTitleStyle={[
                                                //     TextStyles.Heading2,
                                                //     { textAlign: 'center' },
                                                // ]}
                                                headerTitleStyle={[
                                                    Layout.isMediumDevice
                                                        ? TextStyles.Heading2
                                                        : TextStyles.Heading1,
                                                    { textAlign: 'center' },
                                                ]}
                                            />
                                            <Text
                                                style={[
                                                    TextStyles.Paragraph,
                                                    {
                                                        paddingBottom: 36,
                                                        lineHeight: 26,
                                                        textAlign: 'center',
                                                    },
                                                ]}
                                            >
                                                We successfully received your
                                                request and will be in touch
                                                soon.
                                            </Text>
                                        </View>
                                    </View>
                                </ScrollView>
                                <Footer navigation={this.props.navigation} />
                            </KeyboardAvoidingView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        alignItems: 'center',
        paddingVertical: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        paddingVertical: Layout.screenPaddingLarge,
        paddingHorizontal: Layout.screenPaddingLarge,
    },

    stickyButton: {
        zIndex: 100,
        position: 'absolute',
        top: 0,
        right: 0,
        paddingVertical: Layout.screenPaddingMedium,
        paddingHorizontal: Layout.isMediumDevice
            ? Layout.screenPaddingMedium
            : Layout.screenPaddingLarge,
    },
})
