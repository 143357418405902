import React from 'react'
import {
    ImageBackground,
    Image,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import { Feather, Entypo } from '@expo/vector-icons'
import { Colors, TextStyles, Layout, Icons } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import FormHeaderTitle from '../../components/FormHeaderTitle'
import Button from '../../components/Button'
import moment from 'moment'
import Footer from '../../components/Footer'
import GetInTouch from '../../components/GetInTouch'
import ProductFeatures from '../../components/ProductFeaturesCarousel'
import ProductExperienceRules from '../../components/ProductExperienceRules'
import ProductAttributes from '../../components/ProductAttributes'
import BadgeBeta from '../../components/BadgeBeta'

export default class Product extends React.Component {
    onScroll = async event => {
        const pageYOffset = await event.nativeEvent.contentOffset.y

        if (pageYOffset >= 88) {
            this.props.navigation.setParams({ active: true })
        } else {
            this.props.navigation.setParams({ active: false })
        }
    }

    renderMilestone = ({ text, textColor, containerColor }) => {
        return (
            <View
                style={{
                    paddingRight: Layout.isMediumDevice
                        ? 0
                        : Layout.screenPaddingMedium,
                    paddingVertical: Layout.screenPaddingSmall,
                    width: Layout.isMediumDevice ? '100%' : null,
                }}
            >
                <View
                    style={{
                        padding: Layout.screenPaddingMedium,
                        borderRadius: Layout.borderRadiusMedium,
                        backgroundColor: containerColor,
                    }}
                >
                    <Text
                        style={[
                            TextStyles.Paragraph,
                            {
                                color: textColor,
                            },
                        ]}
                    >
                        {text}
                    </Text>
                </View>
            </View>
        )
    }

    renderFeatureImage = ({ image }) => {
        return (
            <View
                style={{
                    flex: 1,
                    alignSelf: 'center',
                    alignItems: 'center',
                    minWidth: Layout.isMediumDevice
                        ? '100%'
                        : Layout.SCREEN_WIDTH * 0.3333,
                    paddingVertical: Layout.screenPaddingMedium,
                }}
            >
                <View
                    style={{
                        backgroundColor: Colors.white,
                        paddingVertical: 8,
                        paddingHorizontal: 8,
                        borderRadius: 40,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <View
                        style={{
                            position: 'absolute',
                            top: 0,
                            zIndex: 10,
                            backgroundColor: Colors.white,
                            width: 84,
                            height: 32,
                            borderBottomStartRadius: 12,
                            borderBottomEndRadius: 12,
                        }}
                    />

                    <Image
                        source={image}
                        style={{
                            backgroundColor: Colors.white,
                            borderRadius: 32,
                            width: 270,
                            height: 585,
                        }}
                        resizeMode={'cover'}
                    />
                </View>
            </View>
        )
    }

    renderRule = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 2,
                    borderBottomColor: Colors.tintColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSeparator = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSpacer() {
        return (
            <View
                style={{
                    //flex: 1,
                    width: '100%',
                    height: Layout.screenPaddingLarge,
                }}
            />
        )
    }

    render() {
        const timestampLabel = moment().format('dddd, MMMM D, YYYY')

        return (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View
                            style={{
                                flex: 1,
                            }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <ScrollView
                                onScroll={event => this.onScroll(event)}
                                scrollEventThrottle={16}
                                style={{
                                    flex: 1,
                                }}
                            >
                                <ImageBackground
                                    source={require('../../assets/images/jonathan-borba-uB7q7aipU2o-unsplash.jpg')}
                                    style={{
                                        borderTopWidth: 6,
                                        borderTopColor: Colors.tintColor,
                                        backgroundColor: Colors.tintColor,
                                    }}
                                    resizeMode={'cover'}
                                >
                                    <View
                                        style={{
                                            flex: 1,
                                            paddingVertical: 88,
                                            alignItems: 'center',
                                            backgroundColor:
                                                Colors.mediumBackground,
                                        }}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                maxWidth: 960,
                                                paddingHorizontal: Layout.isMediumDevice
                                                    ? Layout.screenPaddingLarge
                                                    : Layout.screenPaddingXL,
                                                paddingVertical: Layout.isMediumDevice
                                                    ? Layout.screenPaddingXL
                                                    : Layout.screenPaddingXL *
                                                      2,
                                            }}
                                        >
                                            <Text
                                                style={[
                                                    Layout.isMediumDevice
                                                        ? TextStyles.Heading1Inverse
                                                        : TextStyles.Heading0Inverse,
                                                    {
                                                        paddingBottom:
                                                            Layout.screenPaddingMedium,
                                                    },
                                                ]}
                                            >
                                                Culinary events and inspired
                                                stories, all in one place
                                            </Text>

                                            <Button
                                                iconRight={false}
                                                iconName={'arrow-right'}
                                                iconColor={Colors.tintColor}
                                                onPress={() => {
                                                    this.props.navigation.navigate(
                                                        'Contact'
                                                    )
                                                }}
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'flex-start',
                                                    paddingTop:
                                                        Layout.screenPaddingMedium,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    borderColor:
                                                        Colors.inverseText,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                }}
                                                textStyle={[
                                                    TextStyles.Button,
                                                    {
                                                        color:
                                                            Colors.inverseText,
                                                    },
                                                ]}
                                                title={'Request a demo'}
                                            />
                                        </View>
                                    </View>
                                </ImageBackground>

                                <View style={styles.sectionContainer}>
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                paddingVertical: Layout.isMediumDevice
                                                    ? Layout.screenPaddingLarge
                                                    : Layout.screenPaddingXL,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                // alignItems: 'center',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    maxWidth: 720,
                                                    alignSelf: 'center',
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading1,
                                                        {
                                                            // paddingRight:
                                                            //     Layout.screenPaddingMedium,
                                                            textAlign: Layout.isMediumDevice
                                                                ? 'left'
                                                                : 'center',
                                                        },
                                                    ]}
                                                >
                                                    Indvstry is a dedicated
                                                    space for restaurants to
                                                    craft events, create
                                                    collaborative stories, and
                                                    invite guests to culinary
                                                    experiences.
                                                </Text>
                                            </View>
                                            {/* 
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isSmallDevice
                                                        ? 260
                                                        : 360,
                                                    //alignSelf: 'center',
                                                    paddingRight: Layout.isSmallDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingBottom: Layout.isSmallDevice
                                                        ? Layout.screenPaddingLarge
                                                        : 0,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading1,
                                                        {
                                                            paddingRight:
                                                                Layout.screenPaddingMedium,
                                                            //width: '50%',
                                                        },
                                                    ]}
                                                >
                                                    Indvstry is a digital space
                                                    for restaurants to craft
                                                    events, tell their stories,
                                                    and connect with guests.
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isSmallDevice
                                                        ? 260
                                                        : 360,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 36,
                                                        },
                                                    ]}
                                                >
                                                    Nine of 10 restaurant
                                                    occassions are dining
                                                    experiences at
                                                    brick-and-mortar locations
                                                    vs. food delivery. It's time
                                                    for an ecosystem dedicated
                                                    to connecting food lovers to
                                                    events of all stripes.
                                                </Text>
                                            </View>
                                        */}
                                        </View>
                                    </View>
                                </View>

                                <ProductFeatures />

                                <ProductExperienceRules />

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        { backgroundColor: Colors.gray0 },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    maxWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    paddingVertical:
                                                        Layout.screenPaddingMedium,
                                                    marginRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingXL,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading2,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                        },
                                                    ]}
                                                >
                                                    How restaurants use Indvstry
                                                </Text>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Recurring dining
                                                        experiences
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Special events, pop-ups
                                                        and holidays
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Chef's table, group and
                                                        communal dining
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Prepaid service with
                                                        beverage pairings
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'plus'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Wine and beer tastings
                                                    </Text>
                                                </View>

                                                <Button
                                                    iconRight={false}
                                                    iconName={'arrow-right'}
                                                    iconColor={Colors.tintColor}
                                                    onPress={() => {
                                                        this.props.navigation.navigate(
                                                            'Roadmap'
                                                        )
                                                    }}
                                                    style={{
                                                        flex: 1,
                                                        alignSelf: 'flex-start',
                                                        paddingVertical:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                    buttonStyle={{
                                                        borderWidth: 1,
                                                        backgroundColor:
                                                            Colors.transparentBackground,
                                                        borderColor:
                                                            Colors.tintColor,
                                                    }}
                                                    textStyle={[
                                                        TextStyles.Button,
                                                        {
                                                            color:
                                                                Colors.tintColor,
                                                        },
                                                    ]}
                                                    title={'Our Roadmap'}
                                                />
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    paddingVertical:
                                                        Layout.screenPaddingMedium,
                                                }}
                                            >
                                                <Image
                                                    source={require('../../assets/images/kayleigh-harrington-yhn4okt6ci0-unsplash.jpg')}
                                                    style={{
                                                        width: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_HEIGHT *
                                                              0.618
                                                            : 480,
                                                    }}
                                                    resizeMode={'cover'}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <ProductAttributes />

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        { backgroundColor: Colors.gray0 },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    maxWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    paddingVertical:
                                                        Layout.screenPaddingMedium,
                                                    marginRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingXL,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading2,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                        },
                                                    ]}
                                                >
                                                    Why restaurants love
                                                    Indvstry
                                                </Text>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Streamlines Marketing
                                                        output and effectiveness
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Enhances direct
                                                        relationship with guests
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Delivers measurable ROI,
                                                        performance and
                                                        predictive insights on
                                                        content
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Amplifies the personal
                                                        brand and status of
                                                        employees
                                                    </Text>
                                                </View>

                                                <Button
                                                    iconRight={false}
                                                    iconName={'arrow-right'}
                                                    iconColor={Colors.tintColor}
                                                    onPress={() => {
                                                        this.props.navigation.navigate(
                                                            'Roadmap'
                                                        )
                                                    }}
                                                    style={{
                                                        flex: 1,
                                                        alignSelf: 'flex-start',
                                                        paddingVertical:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                    buttonStyle={{
                                                        borderWidth: 1,
                                                        backgroundColor:
                                                            Colors.transparentBackground,
                                                        borderColor:
                                                            Colors.tintColor,
                                                    }}
                                                    textStyle={[
                                                        TextStyles.Button,
                                                        {
                                                            color:
                                                                Colors.tintColor,
                                                        },
                                                    ]}
                                                    title={'Our Vision'}
                                                />
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    paddingVertical:
                                                        Layout.screenPaddingMedium,
                                                }}
                                            >
                                                <Image
                                                    source={require('../../assets/images/chris-fuller--FVz4FcYNiI-unsplash.jpg')}
                                                    style={{
                                                        width: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_HEIGHT *
                                                              0.618
                                                            : 480,
                                                    }}
                                                    resizeMode={'cover'}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        { backgroundColor: Colors.gray2 },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <FormHeaderTitle
                                            headerTitle={'Product milestones'}
                                            headerTitleStyle={[
                                                TextStyles.Heading1,
                                            ]}
                                        />
                                        <Text
                                            style={[
                                                TextStyles.Caption,
                                                {
                                                    paddingBottom:
                                                        Layout.screenPaddingLarge,
                                                },
                                            ]}
                                        >
                                            <Text
                                                style={[
                                                    TextStyles.Caption,
                                                    {
                                                        color:
                                                            Colors.accentDeepsea,
                                                    },
                                                ]}
                                            >
                                                •
                                            </Text>
                                            {'  '}
                                            Last updated: {timestampLabel}
                                        </Text>

                                        {this.renderRule()}

                                        <View
                                            style={{
                                                //flex: 1,
                                                alignItems: 'flex-start',
                                                // paddingVertical:
                                                //     Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingBottom:
                                                        Layout.screenPaddingLarge,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                <Text
                                                    style={TextStyles.Heading3}
                                                >
                                                    In progress
                                                </Text>
                                                {this.renderSpacer()}

                                                {this.renderMilestone({
                                                    text:
                                                        'Explorer mobile app for Food enthusiasts and dining out',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingBottom:
                                                        Layout.screenPaddingLarge,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                <Text
                                                    style={TextStyles.Heading3}
                                                >
                                                    Up next
                                                </Text>
                                                {this.renderSpacer()}

                                                {this.renderMilestone({
                                                    text:
                                                        'Privacy and security advanced permissions',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                                {this.renderMilestone({
                                                    text:
                                                        'Multiple-venue membership',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                                {this.renderMilestone({
                                                    text: 'Activity updates',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                                {this.renderMilestone({
                                                    text: 'Email subscriptions',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                                {this.renderMilestone({
                                                    text:
                                                        'Peer verification of employment',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}

                                                {this.renderMilestone({
                                                    text: 'Offline support',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                                {this.renderMilestone({
                                                    text: 'Inbox messages',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingBottom:
                                                        Layout.screenPaddingLarge,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                <Text
                                                    style={TextStyles.Heading3}
                                                >
                                                    On the horizon
                                                </Text>
                                                {this.renderSpacer()}

                                                {this.renderMilestone({
                                                    text:
                                                        'Indvstry Studio original content',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}

                                                {this.renderMilestone({
                                                    text:
                                                        'Payments and Booking',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                                {this.renderMilestone({
                                                    text:
                                                        'Advanced verification of employment',
                                                    textColor: Colors.tintColor,
                                                    containerColor:
                                                        Colors.gray0,
                                                })}
                                            </View>
                                        </View>
                                        {this.renderRule()}
                                        <View
                                            style={{
                                                flex: 1,
                                            }}
                                        >
                                            <Text style={[TextStyles.Heading3]}>
                                                Completed
                                            </Text>
                                            <View
                                                style={{
                                                    alignItems: 'flex-start',
                                                    paddingTop:
                                                        Layout.screenPaddingLarge,
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                {this.renderMilestone({
                                                    text:
                                                        'Launched iOS app on Apple TestFlight',
                                                    textColor: Colors.gray0,
                                                    containerColor:
                                                        Colors.gray4,
                                                })}
                                                {this.renderMilestone({
                                                    text:
                                                        'Launched Android app on Google Play Console',
                                                    textColor: Colors.gray0,
                                                    containerColor:
                                                        Colors.gray4,
                                                })}

                                                {this.renderMilestone({
                                                    text:
                                                        'Creator mobile app for Food & Hospitality professionals',
                                                    textColor: Colors.gray0,
                                                    containerColor:
                                                        Colors.gray4,
                                                })}
                                                {this.renderMilestone({
                                                    text:
                                                        'Video-story themes and prompts',
                                                    textColor: Colors.gray0,
                                                    containerColor:
                                                        Colors.gray4,
                                                })}
                                                {this.renderMilestone({
                                                    text:
                                                        'Verified business venue finder',
                                                    textColor: Colors.gray0,
                                                    containerColor:
                                                        Colors.gray4,
                                                })}
                                                {this.renderMilestone({
                                                    text:
                                                        'Collaborative video-stories archive of past events',
                                                    textColor: Colors.gray0,
                                                    containerColor:
                                                        Colors.gray4,
                                                })}
                                                {this.renderMilestone({
                                                    text:
                                                        'Venue Events calendar with collaborative editing',
                                                    textColor: Colors.gray0,
                                                    containerColor:
                                                        Colors.gray4,
                                                })}
                                                {this.renderMilestone({
                                                    text: 'Venue Event creator',
                                                    textColor: Colors.gray0,
                                                    containerColor:
                                                        Colors.gray4,
                                                })}
                                                {this.renderMilestone({
                                                    text: 'Video-story creator',
                                                    textColor: Colors.gray0,
                                                    containerColor:
                                                        Colors.gray4,
                                                })}
                                                {this.renderMilestone({
                                                    text:
                                                        'Collaborative video-stories feed of upcoming events',
                                                    textColor: Colors.gray0,
                                                    containerColor:
                                                        Colors.gray4,
                                                })}
                                                {this.renderMilestone({
                                                    text:
                                                        'Team Profiles with group members',
                                                    textColor: Colors.gray0,
                                                    containerColor:
                                                        Colors.gray4,
                                                })}
                                                {this.renderMilestone({
                                                    text:
                                                        'Team video-story playlist viewer',
                                                    textColor: Colors.gray0,
                                                    containerColor:
                                                        Colors.gray4,
                                                })}
                                                {this.renderMilestone({
                                                    text: 'Individual profiles',
                                                    textColor: Colors.gray0,
                                                    containerColor:
                                                        Colors.gray4,
                                                })}
                                                {this.renderMilestone({
                                                    text:
                                                        'Individual video-stories feed of upcoming events',
                                                    textColor: Colors.gray0,
                                                    containerColor:
                                                        Colors.gray4,
                                                })}
                                                {this.renderMilestone({
                                                    text:
                                                        'Individual video-stories archive of past events',
                                                    textColor: Colors.gray0,
                                                    containerColor:
                                                        Colors.gray4,
                                                })}
                                                {this.renderMilestone({
                                                    text:
                                                        'Sharing to Messages, Instagram, Twitter and more',
                                                    textColor: Colors.gray0,
                                                    containerColor:
                                                        Colors.gray4,
                                                })}
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <ImageBackground
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            backgroundColor: Colors.gray5,
                                        },
                                    ]}
                                    source={require('../../assets/images/mathias-adam-JKHUw0Xujf8-unsplash.jpg')}
                                    resizeMode={'cover'}
                                >
                                    <View
                                        style={[
                                            styles.bodyContent,
                                            {
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            },
                                        ]}
                                    >
                                        <View
                                            style={[
                                                styles.cardContent,
                                                {
                                                    //flex: 1,
                                                    alignSelf: 'center',
                                                    // alignItems: Layout.isMediumDevice
                                                    //     ? 'center'
                                                    //     : 'flex-start',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                },
                                            ]}
                                        >
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    paddingBottom:
                                                        Layout.screenPaddingMedium,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        overflow: 'hidden',
                                                        backgroundColor:
                                                            Colors.tintColor,
                                                        borderRadius: 20,
                                                        // alignSelf: Layout.isMediumDevice
                                                        //     ? 'center'
                                                        //     : 'flex-start',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        //marginBottom: Layout.screenPaddingMedium,
                                                        padding:
                                                            Layout.screenPaddingSmall,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/svgs/indvstry-icon-inverse.svg')}
                                                        style={{
                                                            width: Icons.XL,
                                                            height: Icons.XL,
                                                        }}
                                                        resizeMode={'cover'}
                                                    />
                                                </View>
                                                <BadgeBeta />
                                            </View>
                                            <View
                                                style={{
                                                    //flex: 1,
                                                    paddingBottom:
                                                        Layout.screenPaddingLarge,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading2,
                                                        // {
                                                        //     textAlign: Layout.isMediumDevice
                                                        //         ? 'center'
                                                        //         : 'left',
                                                        // },
                                                    ]}
                                                >
                                                    Demo the Indvstry App
                                                </Text>
                                                {this.renderRule()}
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        // {
                                                        //     textAlign: Layout.isMediumDevice
                                                        //         ? 'center'
                                                        //         : 'left',
                                                        // },
                                                    ]}
                                                >
                                                    Learn how restaurants are
                                                    streamlining their Events
                                                    and Marketing output.
                                                </Text>
                                            </View>

                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Entypo
                                                    style={{
                                                        alignSelf: 'center',
                                                        paddingRight: 12,
                                                    }}
                                                    color={Colors.tintColor}
                                                    size={Icons.small}
                                                    name={'app-store'}
                                                />
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    App Store
                                                </Text>
                                            </View>

                                            {this.renderSeparator()}

                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Entypo
                                                    style={{
                                                        alignSelf: 'center',
                                                        paddingRight: 12,
                                                    }}
                                                    color={Colors.tintColor}
                                                    size={Icons.small}
                                                    name={'google-play'}
                                                />
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    Google Play
                                                </Text>
                                            </View>
                                            {this.renderSeparator()}

                                            <Text
                                                style={[
                                                    TextStyles.Caption,
                                                    {
                                                        // textAlign: Layout.isMediumDevice
                                                        //     ? 'center'
                                                        //     : 'left',
                                                        paddingBottom:
                                                            Layout.screenPaddingMedium,
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Caption,
                                                        {
                                                            color:
                                                                Colors.accentDeepsea,
                                                        },
                                                    ]}
                                                >
                                                    •
                                                </Text>
                                                {'  '}
                                                App now available
                                            </Text>

                                            <Button
                                                iconLeft={false}
                                                iconName={'smartphone'}
                                                iconColor={Colors.accentDeepsea}
                                                onPress={() => {
                                                    this.props.navigation.navigate(
                                                        'Contact'
                                                    )
                                                }}
                                                style={{
                                                    width: Layout.isMediumDevice
                                                        ? '100%'
                                                        : null,
                                                    alignSelf: Layout.isMediumDevice
                                                        ? 'center'
                                                        : 'flex-start',
                                                    paddingTop:
                                                        Layout.screenPaddingLarge,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                    borderColor:
                                                        Colors.tintColor,
                                                }}
                                                textStyle={[
                                                    TextStyles.Button,
                                                    {
                                                        color:
                                                            Colors.primaryText,
                                                    },
                                                ]}
                                                title={'Request a demo'}
                                            />
                                        </View>

                                        {this.renderFeatureImage({
                                            image: require('../../assets/images/IMG_4345.jpg'),
                                        })}
                                    </View>
                                </ImageBackground>

                                <GetInTouch
                                    navigation={this.props.navigation}
                                />

                                <Footer navigation={this.props.navigation} />
                            </ScrollView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    mainView: {
        flex: 1,
        borderTopWidth: 6,
        paddingTop: 88,
        borderTopColor: Colors.tintColor,
        backgroundColor: Colors.tintColor,
    },

    sectionContainer: {
        alignItems: 'center',
        backgroundColor: Colors.gray0,
        paddingVertical: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
    },

    cardContent: {
        flex: 1,
        //maxWidth: 720,  // Normally 720
        marginVertical: Layout.screenPaddingMedium,
        backgroundColor: Colors.white,
        padding: Layout.screenPaddingLarge,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        paddingVertical: Layout.screenPaddingLarge,
        paddingHorizontal: Layout.screenPaddingLarge,
    },

    imageContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        padding: Layout.screenPaddingLarge,
    },
})
