import React from 'react'
import {
    ActivityIndicator,
    StatusBar,
    StyleSheet,
    View,
    Image,
    Text,
} from 'react-native'
import { TextStyles, Colors, Icons, Layout } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'

import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import '../../firebase.config'

/**
 *
 *  REFERENCES:
 *  https://reactnavigation.org/docs/en/auth-flow.html#docsNav
 *  https://firebase.google.com/docs/auth/web/start
 *
 **/

const AUTH = firebase.auth()
const DB = firebase.firestore()
const COLLECTION = 'users'

class AuthLoadingScreen extends React.Component {
    componentDidMount = async () => {
        /* DEVELOPMENT MODE */
        //this.props.navigation.navigate('Public')

        /* PRODUCTION MODE */
        await this.context.initializeUserAccount()
        this.userAuthenticationStatus()
    }

    componentDidUpdate = async prevProps => {
        if (prevProps.isFocused !== this.props.isFocused) {
            // Use the `this.props.isFocused` boolean
            // Call any action
            await this.context.initializeUserAccount()

            this.userAuthenticationStatus()
        }
    }

    userAuthenticationStatus = async () => {
        AUTH.onAuthStateChanged(async user => {
            if (!user) {
                /* PRODUCTION MODE original */
                //this.props.navigation.navigate('Public')

                const checkout = await this.context.checkout
                const studioClient = await this.context.studioClient

                if (checkout) {
                    this.props.navigation.navigate('Explorer')
                } else if (studioClient) {
                    this.props.navigation.navigate('StudioPublic')
                } else {
                    this.props.navigation.navigate('Public')
                }

                /* DEVELOPMENT MODE */
                // this.props.navigation.navigate('Creator')
            } else if (user) {
                // console.log('Investor', user)
                // this.props.navigation.navigate('Investor')

                const userProfileRef = await DB.collection(COLLECTION).doc(
                    user.uid
                )

                await userProfileRef.get().then(async doc => {
                    if (doc.exists) {
                        //console.log('AUTH LOADING', 'USER EXISTS', user)
                        const isCreator = doc.data().roles.creator
                        const isInvestor = doc.data().roles.investor
                        const isStudio = doc.data().roles.studio

                        await this.context.setRoles({
                            isCreator: isCreator,
                            isInvestor: isInvestor,
                            isStudio: isStudio,
                        })

                        if (isCreator) {
                            this.props.navigation.navigate('Creator')
                        } else if (isInvestor) {
                            this.props.navigation.navigate('Investor')
                        } else if (isStudio) {
                            this.props.navigation.navigate('Studio')
                        } else {
                            this.props.navigation.navigate('Public')
                        }
                    }
                })
            }
        })
    }

    /* This can be an animation, welcome screen, etc. */
    render() {
        return (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View
                            style={styles.container}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <ActivityIndicator
                                size="small"
                                color={Colors.tintColor}
                            />

                            <View
                                style={{
                                    overflow: 'hidden',
                                    //backgroundColor: Colors.tintColor,
                                    borderRadius: 60,
                                    // alignSelf: Layout.isMediumDevice
                                    //     ? 'center'
                                    //     : 'flex-start',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    margin: Layout.screenPaddingSmall,
                                    padding: Layout.screenPaddingSmall,
                                }}
                            >
                                <Image
                                    source={require('../../assets/svgs/indvstry-icon.svg')}
                                    style={{
                                        width: Icons.XXL,
                                        height: Icons.XXL,
                                    }}
                                    resizeMode={'cover'}
                                />
                            </View>
                            {/* <Text
                                style={[TextStyles.Overline1, { padding: 16 }]}
                            >
                                INDVSTRY
                            </Text> */}
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

export default AuthLoadingScreen

const styles = StyleSheet.create({
    container: {
        flex: 1,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.gray0,
    },
})
