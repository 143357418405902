import React from 'react'
import { Alert } from 'react-native'
import User from '../api/User'
import Place from '../api/Place'
import getUrlParam from '../utils/getUrlParam'

import { ActionSheetIOS } from 'react-native'
import { ImagePicker, Permissions } from 'expo'
//import getPermission from '../utilities/getPermission'

export const UserProfileContext = React.createContext()
export const UserProfileConsumer = UserProfileContext.Consumer

export class UserProfileProvider extends React.Component {
    state = {
        /* ACCESS TOKENS */
        checkout: false,
        studioClient: false,

        tokenExists: false,
        /* USER ACCOUNT EXISTS */
        userExists: '',
        isCreator: false,
        isInvestor: false,
        isStudio: false,

        /* REQUEST INFO FORM */
        requestCreator: false,
        requestExplorer: false,
        requestInvestor: false,
        requestFormValid: false,
        requestDemo: true,
        contactMessage: '',

        /* DISABLE ACCOUNT */
        accountDisabled: false, // TODO

        /* PRIVACY SETTINGS */
        visibility: {},

        /* COMPONENT STATE MANAGEMENT */
        loading: false,
        searchModalVisible: false,
        noticeModalVisible: false,
        showItemPicker: false,
        textInputFocused: false,

        /* ONBOARD STATUS */
        onboardValid: false,
        //inProgress: false,
        peerVerified: false,

        /* OPT-IN/OUT OPTIONS  */
        receiveMarketingEmail: true,
        agreeToTermsCommunity: false,
        agreeToTermsWork: false,

        /* USER PROFILE */
        fullName: '',
        fullNameValid: false,
        fullNameCurrent: '',

        email: '',
        emailVerified: false,
        emailNew: '',
        emailNewValid: false,
        emailValid: false,

        password: '',
        passwordNew: '',
        passwordConfirm: '',
        passwordValid: false,
        passwordConfirmValid: false,
        passwordFormValid: false,
        secureEntry: true,

        jobTitle: {}, // <-- problematic on avatarselected page
        jobTitleNew: '',

        avatarImage: 'https://',
        avatarImageNew: {},

        username: '',
        usernameFormValid: false,
        //usernameNew: '',
        usernameTaken: '',
        //usernameNewValid: false,
        usernameCurrent: '',

        placeId: '',
        venueCurrent: {},
        venueDetails: {},
        venueActive: {},
    }

    /**
     *
     *  SPECIAL BATCH FUNCTION:
     *  Updates all documents in collection
     *
     **/

    // componentDidMount() {
    //     User._batchUserUpdate()
    // }

    checkAuthStateChanged = async () => {
        const getOnboardValid = await User._checkAuthStateChanged()
        this.setState({ onboardValid: getOnboardValid })
        //console.log('UPC', getOnboardValid, this.state.onboardValid)
        return getOnboardValid
    }

    setRoles = async ({ isCreator, isInvestor, isStudio }) => {
        this.setState({
            isCreator: isCreator,
            isInvestor: isInvestor,
            isStudio: isStudio,
        })
    }

    setAuthInitialState = async () => {
        await this.setState({
            inProgress: false,
            userExists: false,
            onboardValid: false,
            loading: false,
            searchModalVisible: false,
            noticeModalVisible: false,
            secureEntry: true,

            /* OPT-IN/OUT OPTIONS  */
            receiveMarketingEmail: true,
            agreeToTermsCommunity: false,
            agreeToTermsWork: false,

            /* FORM */
            fullName: '',
            fullNameValid: false,
            fullNameCurrent: '',

            email: '',
            emailValid: false,

            password: '',
            passwordValid: false,
            passwordFormValid: false,

            username: '',
            usernameTaken: false,
            usernameFormValid: false,

            placeId: '',
            venueActive: {},
            venueCurrent: {},
            venueDetails: {},
        })
    }

    resetUserForms = async () => {
        await this.setState({
            agreeToTermsWork: false,
            searchModalVisible: false,
            noticeModalVisible: false,
            showItemPicker: false,
            textInputFocused: false,

            requestCreator: false,
            requestExplorer: false,
            requestInvestor: false,
            requestDemo: true,
            contactMessage: '',

            /* FORM */
            avatarImageNew: {},

            //fullName: '',
            fullNameValid: false,

            emailNew: '',
            emailNewValid: false,

            password: '',
            passwordValid: false,
            passwordNew: '',
            passwordConfirm: '',
            passwordConfirmValid: false,
            passwordFormValid: false,
            secureEntry: true,

            usernameTaken: false,
            usernameFormValid: false,

            showItemPicker: false,
        })
    }

    initializeUrlParams = async () => {
        const checkout = await getUrlParam('checkout')
        if (checkout) {
            this.setState({ checkout: true })
        }

        const studioClient = await getUrlParam('studioClient')
        if (studioClient) {
            this.setState({ studioClient: true })
        }

        const tokenExists = await getUrlParam('token')
        if (tokenExists) {
            this.setState({ tokenExists: true })
        }

        const fullName = await getUrlParam('fullName')
        if (fullName) {
            this.setState({ fullName, fullNameValid: true })
        }

        const email = await getUrlParam('email')
        if (email) {
            this.setState({ email, emailValid: true })
        }

        //const role = await getUrlParam('role')

        // console.log(
        //     'USER-PROFILE-CONTEXT',
        //     'INITIALIZE USER',
        //     'THIS-STATE',
        //     //tokenExists,
        //     this.state.tokenExists,
        //     //fullName,
        //     this.state.fullName,
        //     //email,
        //     this.state.email
        // )
    }

    initializeUserAccount = async () => {
        const {
            uid,
            email,
            emailVerified,
            fullName,
            fullNameCurrent,
            photoUrl,
        } = await User._getUserAccount()

        this.setState({
            userExists: uid,
            email,
            emailVerified,
            fullName,
            fullNameCurrent,
            avatarImage: photoUrl,
        })

        const {
            //inProgress,
            //roles,
            onboardValid,
            username,
            venueActive,
            jobTitle,
            placeId,
            peerVerified,
            visibility,
        } = await User._getUserProfile()

        this.setState({
            //inProgress,
            //isInvestor: roles.investor,
            onboardValid,
            username,
            usernameCurrent: username,
            venueActive,
            venueCurrent: venueActive,
            jobTitle: jobTitle || {},
            jobTitleNew: jobTitle,
            placeId,
            peerVerified,
            visibility,
        })

        await this.initializeUrlParams()

        // console.log(
        //     'USER-PROFILE-CONTEXT',
        //     'INITIALIZE USER',
        //     tokenExists,
        //     this.state.tokenExists,
        //     this.state.email,
        //     this.state.fullName
        // )
    }

    /* AUTHLOADING PATH */

    // userAuthenticationStatus = async () => {
    //     const navigation = this.props.navigation

    //     await firebase.auth().onAuthStateChanged(async user => {
    //         if (!user) {
    //             navigation.navigate('Auth')
    //         } else if (user) {
    //             await this.context.initializeUserAccount()
    //             let getOnboardValid = await this.context.onboardValid

    //             getOnboardValid === false
    //                 ? // User is signed in BUT onboard incomplete.

    //                   navigation.navigate('AuthHome')
    //                 : // User is signed in and onboard is valid.
    //                   navigation.navigate('App')
    //             // console.log(onboardValid)
    //             // if (onboardValid === false) {
    //             //     navigation.navigate('AuthHome')
    //             // } else {
    //             //     navigation.navigate('App')
    //             // }

    //             // try {
    //             //     console.log(onboardValid)

    //             //     onboardValid
    //             //         ? // User is signed in BUT onboard incomplete.
    //             //           navigation.navigate('AuthHome')
    //             //         : // User is signed in and onboard is valid.
    //             //           navigation.navigate('App')
    //             // } catch ({ message }) {
    //             //     console.log(message)
    //             // }
    //         }
    //     })
    // }

    /* LOG-IN & FORGOT PASSWORD */
    onClearUrlToken = async () => {
        await User._clearUrlToken()
    }

    onSubmitRequestForm = async () => {
        this.setState({ loading: true })

        await User._createRequestEntry({
            fullName: this.state.fullName,
            email: this.state.emailNew,
            requestCreator: this.state.requestCreator,
            requestExplorer: this.state.requestExplorer,
            requestInvestor: this.state.requestInvestor,
        })

        this.setState({ loading: false })
    }

    onSubmitContactForm = async () => {
        this.setState({ loading: true })

        await User._createContactEntry({
            fullName: this.state.fullName,
            email: this.state.email,
            contactMessage: this.state.contactMessage,
            requestDemo: this.state.requestDemo,
        })

        this.setState({ loading: false })
    }

    onSignUpEmailPassword = async () => {
        this.setState({ loading: true })

        await User._signInWithTokenEmailAndPassword(
            this.state.email,
            this.state.password,
            getUrlParam('token')
        )

        this.setState({ loading: false })
    }

    onLoginEmailPassword = async () => {
        await this.setState({ loading: true })

        await User._signInWithEmailAndPassword(
            this.state.email,
            this.state.password
        )

        await this.setState({ loading: false })
    }

    onLoginWithEmailLink = async () => {
        this.setState({ loading: true })

        await User._sendSignInLinkToEmail(
            this.state.email
            //this.state.password,
            //getUrlParam('token')
        )

        this.setState({ loading: false })
    }

    onPasswordResetPress = async () => {
        this.setState({ loading: true })

        await User._passwordReset(this.state.email)
        await this.setState({ email: '', emailValid: false })
        this.setState({ loading: false })
    }

    setInProgress = inProgress => {
        this.setState({
            inProgress: inProgress,
        })
    }

    /**
     *
     * SIGN-UP FUNCTION
     *
     **/

    createUserAccount = async () => {
        this.setState({ loading: true })

        await User._createUserWithEmailAndPassword({
            email: this.state.email,
            password: this.state.password,
            fullName: this.state.fullName,
            receiveMarketingEmail: this.state.receiveMarketingEmail,
        })
        this.setState({ loading: false })
    }

    /* COMPLETE ONBOARDING */
    saveAgreeToTermsWork = () => {
        User._agreeToTermsWork({
            onboardValid: true,
            agreeToTermsWork: this.state.agreeToTermsWork,
        })
    }

    saveAgreeToTermsCommunity = async () => {
        this.setState({ loading: true })

        await User._agreeToTermsCommunity({
            agreeToTermsCommunity: this.state.agreeToTermsCommunity,
        })

        this.setState({ onboardValid: true })

        this.setState({ loading: false })
    }

    /* SAVE PROFILE */
    saveUserProfileUpdates = async () => {
        this.setState({ loading: true })

        User._displayNameUpdate(this.state.fullName)

        User._userJobTitle({ jobTitle: this.state.jobTitleNew })

        if ((this.state.avatarImageNew !== {}) === true) {
            await User._photoURLUpdate(this.state.avatarImageNew)
        }

        this.setState({ loading: false })
    }

    saveUsernameUpdate = async () => {
        this.setState({ loading: true })

        await User._usernameUpdate({
            username: this.state.username,
        })

        this.setState({ loading: false })
    }

    /* SET COMPONENT STATE */
    setLoading = loading => {
        this.setState({ loading: loading })
    }

    /* SET OPT-IN/OUT OPTIONS  */
    setTokenExists = () => {
        {
            this.state.tokenExists
                ? this.setState({
                      tokenExists: false,
                  })
                : this.setState({
                      tokenExists: true,
                  })
        }
    }

    setRequestCreator = async () => {
        {
            this.state.requestCreator
                ? await this.setState({
                      requestCreator: false,
                  })
                : await this.setState({
                      requestCreator: true,
                  })
        }
        this.requestFormValid()
    }

    setRequestExplorer = async () => {
        {
            this.state.requestExplorer
                ? await this.setState({
                      requestExplorer: false,
                  })
                : await this.setState({
                      requestExplorer: true,
                  })
        }
        this.requestFormValid()
    }

    setRequestInvestor = async () => {
        {
            this.state.requestInvestor
                ? await this.setState({
                      requestInvestor: false,
                  })
                : await this.setState({
                      requestInvestor: true,
                  })
        }
        this.requestFormValid()
    }

    requestFormValid = () => {
        const requestCreator = this.state.requestCreator
        const requestExplorer = this.state.requestExplorer
        const requestInvestor = this.state.requestInvestor

        if (
            requestCreator === true ||
            requestExplorer === true ||
            requestInvestor === true
        ) {
            this.setState({ requestFormValid: true })
        } else {
            this.setState({ requestFormValid: false })
        }
    }

    setRequestDemo = async () => {
        {
            this.state.requestDemo
                ? await this.setState({
                      requestDemo: false,
                  })
                : await this.setState({
                      requestDemo: true,
                  })
        }
    }

    setContactMessage = contactMessage => {
        this.setState({ contactMessage: contactMessage })
    }

    /**
     *
     * Footer switch WIP
     *
     */
    setFooterSwitchFocused = () => {
        {
            this.state.textInputFocused
                ? this.setState({
                      textInputFocused: false,
                  })
                : this.setState({
                      textInputFocused: true,
                  })
        }
    }

    setTextInputFocused = () => {
        {
            this.state.textInputFocused
                ? this.setState({
                      textInputFocused: false,
                  })
                : this.setState({
                      textInputFocused: true,
                  })
        }
    }

    setSecureEntry = () => {
        {
            this.state.secureEntry
                ? this.setState({
                      secureEntry: false,
                  })
                : this.setState({
                      secureEntry: true,
                  })
        }
    }

    setShowItemPicker = () => {
        {
            this.state.showItemPicker
                ? this.setState({
                      showItemPicker: false,
                  })
                : this.setState({
                      showItemPicker: true,
                  })
        }
    }

    setSearchModalVisible = () => {
        {
            this.state.searchModalVisible
                ? this.setState({
                      searchModalVisible: false,
                  })
                : this.setState({
                      searchModalVisible: true,
                  })
        }
    }

    setNoticeModalVisible = () => {
        {
            this.state.noticeModalVisible
                ? this.setState({
                      noticeModalVisible: false,
                  })
                : this.setState({
                      noticeModalVisible: true,
                  })
        }
    }

    setReceiveMarketingEmail = () => {
        {
            this.state.receiveMarketingEmail
                ? this.setState({
                      receiveMarketingEmail: false,
                  })
                : this.setState({
                      receiveMarketingEmail: true,
                  })
        }
    }

    setAgreeToTermsWork = () => {
        {
            this.state.agreeToTermsWork
                ? this.setState({
                      agreeToTermsWork: false,
                  })
                : this.setState({
                      agreeToTermsWork: true,
                  })
        }
    }

    setAgreeToTermsCommunity = () => {
        {
            this.state.agreeToTermsCommunity
                ? this.setState({
                      agreeToTermsCommunity: false,
                  })
                : this.setState({
                      agreeToTermsCommunity: true,
                  })
        }
    }

    setPeerVerified = peerVerified => {
        this.setState({ peerVerified: peerVerified })
    }

    setOnboardValid = onboardValid => {
        this.setState({ onboardValid: onboardValid })
    }

    getOnboardValid = () => {
        const onboardValid = this.state.onboardValid
        return onboardValid
    }

    /* EMAIL */
    setEmail = email => {
        this.setState({ email: email })
    }

    setEmailNew = emailNew => {
        this.setState({ emailNew: emailNew })
    }

    setEmailVerified = () => {
        this.setState({ emailVerified: true })
    }

    /* PASSWORD */
    setPassword = password => {
        this.setState({ password: password })
    }

    setPasswordNew = passwordNew => {
        this.setState({ passwordNew: passwordNew })
    }

    setPasswordConfirm = passwordConfirm => {
        this.setState({ passwordConfirm: passwordConfirm })
    }

    /* FULL NAME */
    setFullName = fullName => {
        this.setState({ fullName: fullName })
    }

    setFullNameNew = fullNameNew => {
        this.setState({ fullNameNew: fullNameNew })
    }

    /* AVATAR IMAGE */
    setAvatarImageNew = avatarImageNew => {
        this.setState({ avatarImageNew: avatarImageNew })
    }

    setAvatarImage = avatarImage => {
        this.setState({ avatarImage: avatarImage })
    }

    // choosePhoto = async () => {
    //     const status = await getPermission(Permissions.CAMERA_ROLL)
    //     if (status) {
    //         const result = await ImagePicker.launchImageLibraryAsync({
    //             allowsEditing: false,
    //         })
    //         if (!result.cancelled) {
    //             this.setState({ avatarImageNew: result })
    //         }
    //     }
    // }

    takePhoto = async () => {
        const status = await getPermission(Permissions.CAMERA)
        if (status) {
            const result = await ImagePicker.launchCameraAsync({
                allowsEditing: false,
            })
            if (!result.cancelled) {
                this.setState({ avatarImageNew: result })
            }
        }
    }

    changeProfileImage = () => {
        ActionSheetIOS.showActionSheetWithOptions(
            {
                options: [
                    'Cancel',
                    'Choose from library',
                    'Camera',
                    //'Remove'
                ],
                //destructiveButtonIndex: 3,
                cancelButtonIndex: 0,
                //tintColor: Colors.deepsea,
            },
            buttonIndex => {
                if (buttonIndex === 1) {
                    this.choosePhoto()
                }
                if (buttonIndex === 2) {
                    this.takePhoto()
                }
                if (buttonIndex === 3) {
                    /* destructive action */
                }
            }
        )
    }

    setAccountDisabled = () => {
        this.setState({ accountDisabled: true })
    }

    /* USERNAME */
    setUsername = username => {
        this.setState({ username: username })
    }

    setUsernameNew = async usernameNew => {
        this.setState({ usernameNew: usernameNew })
    }

    setUsernameTaken = usernameTaken => {
        this.setState({ usernameTaken: usernameTaken })
    }

    setUsernameFormValid = usernameFormValid => {
        this.setState({ usernameFormValid: usernameFormValid })
    }

    usernameValidate = async () => {
        const usernameNew = this.state.usernameNew
        const { usernameFlag } = await User._checkUsername({
            usernameNew,
        })
        this.setState({ usernameTaken: usernameFlag })
    }
    // usernameValidate = async usernameNew => {
    //     const { usernameFlag } = await User._checkUsername({ usernameNew })
    //     this.setState({ usernameTaken: usernameFlag })
    // }

    /* JOB ROLE */
    setJobTitle = jobTitle => {
        this.setState({ jobTitle: jobTitle })
    }

    setJobTitleNew = jobTitleNew => {
        this.setState({ jobTitleNew: jobTitleNew })
    }

    /* PRIMARY VENUE */

    setVenueActive = (data, details) => {
        this.setState({
            placeId: data.place_id,

            venueActive: {
                placeId: data.place_id,
                venueName: data.structured_formatting.main_text,
                venueAddress: data.structured_formatting.secondary_text,
            },
            venueDetails: {
                plusCode: details.plus_code || {},
                types: details.types || {},
                mapsUrl: details.url || {},
                phoneIntl: details.international_phone_number || {},
                website: details.website || {},
                priceLevel: details.price_level || {},
            },
            searchModalVisible: false,
        })
    }

    saveUserProfileVenueActive = async () => {
        this.setState({ loading: true })

        await this.saveVenueActiveToPlaces()

        await User._userVenueActive({
            agreeToTermsWork: true,

            placeId: this.state.venueActive.placeId,

            venueActive: {
                placeId: this.state.venueActive.placeId,
                venueName: this.state.venueActive.venueName,
                venueAddress: this.state.venueActive.venueAddress,
            },

            venueCurrent: this.state.venueActive,
        })
        this.setState({ loading: false })
    }

    saveVenueActiveToPlaces = async () => {
        this.setState({ loading: true })

        await Place._createVenue({
            placeId: this.state.venueActive.placeId,
            name: this.state.venueActive.venueName,
            formattedAddress: this.state.venueActive.venueAddress,
            plusCode: this.state.venueDetails.plusCode,
            types: this.state.venueDetails.types,
            mapsUrl: this.state.venueDetails.mapsUrl,
            phoneIntl: this.state.venueDetails.phoneIntl,
            website: this.state.venueDetails.website,
            priceLevel: this.state.venueDetails.priceLevel,
        })

        this.setState({ loading: false })
    }

    /* SIGN-UP FLOW */
    validatePassword = () => {
        const reg = /^(?=.*\d)(?=.*[a-z])(?!.* )(?=.*[^a-zA-Z0-9]).{8,24}$/
        if (
            this.state.password.length < 8 ||
            reg.test(this.state.password) === false
        ) {
            this.setState({ passwordValid: false })
            console.log('Password format is wrong')
        } else {
            this.setState({ passwordValid: true })
            console.log('Password format is correct')
        }
    }

    /* USER AUTHENTICATION ACCOUNT */

    validatePasswordNew = () => {
        const reg = /^(?=.*\d)(?=.*[a-z])(?!.* )(?=.*[^a-zA-Z0-9]).{8,24}$/
        if (
            this.state.passwordNew.length < 8 ||
            reg.test(this.state.passwordNew) === false
        ) {
            this.setState({ passwordValid: false })
            console.log('Password format is wrong')
        } else if (this.state.passwordNew !== this.state.password) {
            this.setState({ passwordValid: true })
            console.log('Password format is correct')
        } else {
            Alert.alert('You can not use a previous password.')
            window.alert('You can not use a previous password.')
            console.log('Password confirm SHOULD NOT MATCH password new.')
        }
    }

    validatePasswordConfirm = () => {
        const reg = /^(?=.*\d)(?=.*[a-z])(?!.* )(?=.*[^a-zA-Z0-9]).{8,24}$/
        if (
            this.state.passwordConfirm.length < 8 ||
            reg.test(this.state.passwordConfirm) === false
        ) {
            console.log('Password confirm format is wrong')
            this.setState({ passwordConfirmValid: false })
        } else if (this.state.passwordConfirm === this.state.passwordNew) {
            this.setState({ passwordConfirmValid: true })
            console.log('Password confirm format is correct')
        } else {
            Alert.alert('You did not retype the new password correctly.')
            console.log('Password confirm does NOT match password new.')
        }
    }

    validatePasswordForm = () => {
        const passwordNew = this.state.passwordNew
        const passwordConfirm = this.state.passwordConfirm
        const passwordValid = this.state.passwordValid
        const passwordConfirmValid = this.state.passwordConfirmValid

        if (passwordValid === true && passwordConfirmValid === true) {
            this.setState({ passwordFormValid: true })
        } else {
            this.setState({ passwordFormValid: false })
        }
    }

    // Changes user's password
    changePassword = () => {
        User._reauthenticate(this.state.password)
            .then(() => {
                //const user = firebase.auth().currentUser
                User._passwordUpdate(this.state.passwordNew)
                    .then(() => {
                        Alert.alert('Your password was changed')
                    })
                    .catch(error => {
                        Alert.alert(error.message)
                        console.log(error.message)
                    })
            })
            .catch(error => {
                console.log(error.message)
            })
    }

    onPasswordResetPress = async () => {
        this.setState({ loading: true })

        await User._passwordReset(this.state.email)

        await this.setState({ email: '', emailValid: false })
        this.setState({ loading: false })
    }

    /* EMAIL */
    validateEmail = text => {
        const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,24})+$/
        if (
            this.state.email.length <= 5 ||
            (reg.test(this.state.email) === false && reg.test(text) === false)
        ) {
            this.setState({
                emailValid: false,
            })
            console.log('Email format is wrong')
            //return false
        } else {
            this.setState({
                emailValid: true,
            })
            console.log('Email format is correct')
            //return true
        }
    }

    validateEmailNew = () => {
        const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,24})+$/
        if (
            this.state.emailNew.length <= 5 ||
            reg.test(this.state.emailNew) === false
        ) {
            this.setState({
                emailNewValid: false,
            })
            console.log('Email format is wrong')
            //return false
        } else {
            this.setState({
                emailNewValid: true,
            })
            console.log('Email format is correct')
            //return true
        }
    }

    // Changes user's email
    changeEmail = () => {
        User._reauthenticate(this.state.password)
            .then(() => {
                User._emailUpdate(this.state.emailNew)
                    .then(() => {
                        Alert.alert('Your email was changed.')
                    })
                    .catch(error => {
                        Alert.alert(error.message)
                        console.log(error.message)
                    })
            })
            .catch(error => {
                //Alert.alert(error.message)
                console.log(error.message)
            })
    }

    resendEmailVerification = () => {
        User._sendEmailVerification()
    }

    validateFullName = text => {
        const reg = /^([a-z\u00C0-\u02AB'´`]{1,}\.?\s?)([a-z\u00C0-\u02AB'´`]?\.?\s?)+$/i

        if (
            !this.state.fullName.length ||
            (reg.test(this.state.fullName) === false &&
                reg.test(text) === false)
        ) {
            this.setState({
                fullNameValid: false,
            })
            console.log('Please input your name')
            //return false
        } else {
            this.setState({
                fullNameValid: true,
            })
            console.log('Name format is correct')
            //return true
        }
    }

    /* USERNAME */
    validateUsername = () => {
        const reg = /^(?=.{6,24}$)[a-zA-Z0-9]+(?:[._][a-zA-Z0-9]+)*$/
        if (reg.test(this.state.username) === false) {
            console.log('Username format is wrong')
            //this.isUsernameAvailable({ usernameNew: text })
            this.setState({ usernameValid: false })
            //return false
        } else {
            //this.isUsernameAvailable({ username: text })
            this.setState({ usernameValid: true })
            console.log('Username format is correct')
            //return true
        }
    }

    validateUsernameForm = async () => {
        const usernameValid = this.state.usernameValid
        const username = this.state.username
        const { usernameTaken } = await User._checkUsername({
            username,
        })

        this.setState({ usernameTaken })

        if (usernameValid === true && !usernameTaken) {
            this.setState({
                usernameFormValid: true,
            })
        } else {
            this.setState({
                usernameFormValid: false,
            })
            //Alert.alert('This username is taken.')
        }
    }

    userSignOut = async () => {
        await User._signOut()
        this.setAuthInitialState()
    }

    /**
     *
     *  BLACK LIST FUNCTION, BLOCK USER, NEEDS WORK
     * https://firebase.googleblog.com/2019/03/firebase-security-rules-admin-sdk-tips.html
     *
     **/
    revokeWriteAccess = async userId => {
        await revokeWriteAccess('bAdAgEnT')

        const user = admin
            .firestore()
            .collection('blacklist')
            .document(userId)
        await user.set({
            reason: 'possible bad agent',
            blacklisted_at: admin.firestore.FieldValue.serverTimestamp(),
        })
    }

    render() {
        return (
            <UserProfileContext.Provider
                value={{
                    /**
                     *
                     *  PRIMARY ACTIONS
                     *
                     **/
                    setRoles: this.setRoles,
                    isCreator: this.state.isCreator,
                    isInvestor: this.state.isInvestor,
                    isStudio: this.state.isStudio,
                    setTextInputFocused: this.setTextInputFocused,
                    textInputFocused: this.state.textInputFocused,
                    setTokenExists: this.setTokenExists,
                    onClearUrlToken: this.onClearUrlToken,
                    initializeUrlParams: this.initializeUrlParams,
                    onLoginWithEmailLink: this.onLoginWithEmailLink,

                    onSignUpEmailPassword: this.onSignUpEmailPassword,

                    /* REQUEST FORMS */
                    setRequestCreator: this.setRequestCreator,
                    requestCreator: this.state.requestCreator,
                    setRequestExplorer: this.setRequestExplorer,
                    requestExplorer: this.state.requestExplorer,
                    setRequestInvestor: this.setRequestInvestor,
                    requestInvestor: this.state.requestInvestor,
                    requestFormValid: this.state.requestFormValid,
                    onSubmitRequestForm: this.onSubmitRequestForm,
                    setRequestDemo: this.setRequestDemo,
                    requestDemo: this.state.requestDemo,
                    setContactMessage: this.setContactMessage,
                    contactMessage: this.state.contactMessage,
                    onSubmitContactForm: this.onSubmitContactForm,

                    /* AUTH */
                    checkout: this.state.checkout,
                    studioClient: this.state.studioClient,

                    tokenExists: this.state.tokenExists,
                    inProgress: this.state.inProgress,
                    setInProgress: this.setInProgress,
                    checkAuthStateChanged: this.checkAuthStateChanged,
                    userExists: this.state.userExists,
                    userSignOut: this.userSignOut,
                    setAuthInitialState: this.setAuthInitialState,
                    onLoginEmailPassword: this.onLoginEmailPassword,
                    onPasswordResetPress: this.onPasswordResetPress,
                    createUserAccount: this.createUserAccount,
                    createUserProfile: this.createUserProfile,

                    /* USER ACCOUNT & PROFILE */
                    initializeUserAccount: this.initializeUserAccount,
                    setInitialState: this.setInitialState,
                    setCurrentUser: this.setCurrentUser,
                    saveUserProfileUpdates: this.saveUserProfileUpdates,
                    saveUserProfileVenueActive: this.saveUserProfileVenueActive,
                    saveUserProfileVenueActive: this.saveUserProfileVenueActive,
                    changeEmail: this.changeEmail,
                    resendEmailVerification: this.resendEmailVerification,
                    changePassword: this.changePassword,
                    saveVenueActiveToPlaces: this.saveVenueActiveToPlaces,
                    saveUsernameUpdate: this.saveUsernameUpdate,

                    /**
                     *
                     *  FORM STATE MANAGEMENT
                     *
                     **/

                    /* MODALS AND LOADING */

                    resetUserForms: this.resetUserForms,

                    onboardValid: this.state.onboardValid,
                    setOnboardValid: this.setOnboardValid,
                    getOnboardValid: this.getOnboardValid,

                    loading: this.state.loading,
                    setLoading: this.setLoading,

                    showItemPicker: this.state.showItemPicker,
                    setShowItemPicker: this.setShowItemPicker,

                    searchModalVisible: this.state.searchModalVisible,
                    setSearchModalVisible: this.setSearchModalVisible,

                    noticeModalVisible: this.state.noticeModalVisible,
                    setNoticeModalVisible: this.setNoticeModalVisible,

                    receiveMarketingEmail: this.state.receiveMarketingEmail,
                    setReceiveMarketingEmail: this.setReceiveMarketingEmail,

                    agreeToTermsWork: this.state.agreeToTermsWork,
                    setAgreeToTermsWork: this.setAgreeToTermsWork,
                    saveAgreeToTermsWork: this.saveAgreeToTermsWork,

                    agreeToTermsCommunity: this.state.agreeToTermsCommunity,
                    setAgreeToTermsCommunity: this.setAgreeToTermsCommunity,
                    saveAgreeToTermsCommunity: this.saveAgreeToTermsCommunity,
                    /* PEER VERIFIED */
                    peerVerified: this.state.peerVerified,
                    setPeerVerified: this.setPeerVerified,

                    /* EMAIL */
                    email: this.state.email,
                    setEmail: this.setEmail,
                    emailValid: this.state.emailValid,

                    emailVerified: this.state.emailVerified,
                    setEmailVerified: this.setEmailVerified,

                    emailNew: this.state.emailNew,
                    setEmailNew: this.setEmailNew,

                    emailNewValid: this.state.emailNewValid,
                    validateEmail: this.validateEmail,
                    validateEmailNew: this.validateEmailNew,

                    /* PASSWORD */
                    password: this.state.password,
                    setPassword: this.setPassword,

                    passwordNew: this.state.passwordNew,
                    setPasswordNew: this.setPasswordNew,

                    passwordConfirm: this.state.passwordConfirm,
                    setPasswordConfirm: this.setPasswordConfirm,

                    passwordValid: this.state.passwordValid,
                    //setPasswordValid: this.setPasswordValid,
                    secureEntry: this.state.secureEntry,
                    setSecureEntry: this.setSecureEntry,

                    passwordConfirmValid: this.state.passwordConfirmValid,
                    //setPasswordConfirmValid: this.setPasswordConfirmValid,

                    passwordFormValid: this.state.passwordFormValid,
                    //setpasswordFormValid: this.setPasswordFormValid,

                    validatePassword: this.validatePassword,
                    validatePasswordNew: this.validatePasswordNew,
                    validatePasswordConfirm: this.validatePasswordConfirm,
                    validatePasswordForm: this.validatePasswordForm,

                    /* FULL NAME */
                    fullName: this.state.fullName,
                    setFullName: this.setFullName,
                    fullNameValid: this.state.fullNameValid,
                    validateFullName: this.validateFullName,
                    fullNameCurrent: this.state.fullNameCurrent,

                    fullNameNew: this.state.fullNameNew,
                    setFullNameNew: this.setFullNameNew,

                    /* AVATAR IMAGE */
                    avatarImage: this.state.avatarImage,
                    setAvatarImage: this.setAvatarImage,

                    avatarImageNew: this.state.avatarImageNew,
                    setAvatarImageNew: this.setAvatarImageNew,
                    changeProfileImage: this.changeProfileImage,

                    accountDisabled: this.state.accountDisabled,
                    setAccountDisabled: this.setAccountDisabled,

                    /* USERNAME */
                    username: this.state.username,
                    setUsername: this.setUsername,
                    usernameCurrent: this.state.usernameCurrent,

                    usernameNew: this.state.usernameNew,
                    setUsernameNew: this.setUsernameNew,

                    validateUsername: this.validateUsername,
                    validateUsernameForm: this.validateUsernameForm,

                    usernameTaken: this.state.usernameTaken,
                    setUsernameTaken: this.setUsernameTaken,
                    usernameValidate: this.usernameValidate,
                    usernameFormValid: this.state.usernameFormValid,
                    setUsernameFormValid: this.setUsernameFormValid,

                    /* JOB ROLE */
                    jobTitle: this.state.jobTitle,
                    setJobTitle: this.setJobTitle,

                    jobTitleNew: this.state.jobTitleNew,
                    setJobTitleNew: this.setJobTitleNew,

                    /* VENUE */
                    venueActive: this.state.venueActive,
                    setVenueActive: this.setVenueActive,
                    placeId: this.state.placeId,

                    venueCurrent: this.state.venueCurrent,
                    //setVenueActive: this.setVenueActive,

                    visibility: this.state.visibility,
                }}
            >
                {this.props.children}
            </UserProfileContext.Provider>
        )
    }
}
