import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { createStackNavigator } from 'react-navigation-stack'
import { DrawerActions, createDrawerNavigator } from 'react-navigation-drawer'
import { Colors, TextStyles, Layout } from '../constants'
import CheckoutScreen from '../screens/global/CheckoutScreen'

// import AuthHomeScreen from '../screens/auth/AuthHomeScreen'
// import RestaurantsScreen from '../screens/creator/RestaurantsScreen'
// import GetExplorerAppScreen from '../screens/creator/GetExplorerAppScreen'
// import TeamScreen from '../screens/global/TeamScreen'
// import ContactScreen from '../screens/global/ContactScreen'
// import RequestInfoScreen from '../screens/global/RequestInfoScreen'
import RequestConfirmationScreen from '../screens/global/RequestConfirmationScreen'
// import ButtonIcon from '../components/ButtonIcon'
import DrawerNavigation from '../components/DrawerNavigation'

const DrawerConfig = {
    /**
     *
     *  https://reactnavigation.org/docs/en/drawer-navigator.html
     *
     **/
    drawerWidth: Layout.isMediumDevice
        ? Layout.SCREEN_WIDTH
        : Layout.SCREEN_WIDTH * 0.5,
    drawerPosition: 'right',
    drawerBackgroundColor: Colors.gray0,

    contentComponent: props => <DrawerNavigation {...props} />,

    contentOptions: {
        activeBackgroundColor: 'transparent',
        itemsContainerStyle: {
            marginVertical: 24,
        },
        iconContainerStyle: {
            opacity: 1,
        },
        labelStyle: TextStyles.Heading3,
    },

    defaultNavigationOptions: ({ navigation }) => {
        return {
            // drawerIcon: (
            //     <ButtonIcon
            //         activityColor={Colors.white}
            //         iconName={'menu'}
            //         iconColor={Colors.inverseText}
            //         buttonStyle={{
            //             //alignSelf: 'center',
            //             backgroundColor: 'transparent',
            //         }}
            //         loading={false}
            //         disabled={false}
            //         onPress={() =>
            //             navigation.dispatch(DrawerActions.toggleDrawer())
            //         }
            //     />
            // ),
            // drawerIcon: (
            //     <HeaderButtons HeaderButtonComponent={FeatherHeaderButton}>
            //         <Item
            //             title="close"
            //             iconName="x"
            //             iconSize={Icons.large}
            //             color={Colors.tintColor}
            //         />
            //     </HeaderButtons>
            // ),
        }
    },
}

//const active = this.navigation.getParam('active', false)

const StackConfig = {
    /**
     *
     *  https://reactnavigation.org/docs/en/stack-navigator.html#stacknavigatorconfig
     *
     **/
    initialRouteName: 'Checkout',

    cardShadowEnabled: false,

    headerMode: 'screen', // 'float' | 'screen' | 'none'
    // headerTransitionPreset: 'fade-in-place', // float is enabled; 'uikit' | 'fade-in-place'

    defaultNavigationOptions: ({ navigation }) => ({
        cardShadowEnabled: false,

        headerBackTitle: null,
        headerPressColorAndroid: Colors.tintColor,
        headerTransparent: true,
        //headerTitleStyle: { justifyContent: 'center', alignItems: 'center' },

        //headerStyle: styles.header,

        headerStyle: {
            boxShadow: 'none', // for web
            elevation: 0, //for android
            shadowOpacity: 0, //for ios
            borderBottomWidth: 0, //for ios
            paddingVertical: 6,
            height: Layout.isMediumDevice
                ? Layout.topNavHeightMobile
                : Layout.topNavHeightDesktop,
            backgroundColor: navigation.getParam('active', false)
                ? Colors.gray0
                : 'transparent',
        },

        // headerLeft: (
        //     <View
        //         style={{
        //             flex: 1,
        //             paddingVertical: Layout.screenPaddingMedium,
        //             paddingHorizontal: Layout.isMediumDevice
        //                 ? Layout.screenPaddingMedium
        //                 : Layout.screenPaddingLarge,
        //         }}
        //     >
        //         <TouchableOpacity
        //             style={{ flex: 1 }}
        //             onPress={() => navigation.navigate('ExplorerHome')}
        //         >
        //             <Text
        //                 style={[
        //                     Layout.isMediumDevice
        //                         ? TextStyles.LogotypeInverse
        //                         : TextStyles.LogotypeLargeInverse,
        //                     {
        //                         color: navigation.getParam('active', false)
        //                             ? Colors.tintColor
        //                             : Colors.inverseText,
        //                     },
        //                 ]}
        //             >
        //                 Indvstry
        //             </Text>
        //         </TouchableOpacity>
        //     </View>
        // ),

        // headerTitle: Layout.isMediumDevice ? (
        //     <View />
        // ) : (
        //     <View style={{ flex: 1 }}>
        //         <View
        //             style={{
        //                 alignSelf: 'center',
        //                 flexDirection: 'row',
        //             }}
        //         >
        //             <Text
        //                 numberOfLines={1}
        //                 style={[
        //                     TextStyles.Overline1,
        //                     {
        //                         paddingVertical: Layout.screenPaddingMedium,
        //                         paddingHorizontal: Layout.screenPaddingLarge,
        //                         color: navigation.getParam('active', false)
        //                             ? Colors.tintColor
        //                             : Colors.inverseText,
        //                         textTransform: 'uppercase',
        //                     },
        //                 ]}
        //                 onPress={() => navigation.navigate('Restaurants')}
        //             >
        //                 For Restaurants
        //             </Text>
        //             {/* <Text
        //                 numberOfLines={1}
        //                 style={[
        //                     TextStyles.Overline1,
        //                     {
        //                         paddingVertical: Layout.screenPaddingMedium,
        //                         paddingHorizontal: Layout.screenPaddingLarge,
        //                         color: navigation.getParam('active', false)
        //                             ? Colors.tintColor
        //                             : Colors.inverseText,
        //                         textTransform: 'uppercase',
        //                     },
        //                 ]}
        //                 onPress={() => navigation.navigate('GetExplorerApp')}
        //             >
        //                 Get the App
        //             </Text>
        //             <Text
        //                 style={[
        //                     TextStyles.Overline1,
        //                     {
        //                         paddingVertical: Layout.screenPaddingMedium,
        //                         paddingHorizontal: Layout.screenPaddingLarge,
        //                         color: navigation.getParam('active', false)
        //                             ? Colors.tintColor
        //                             : Colors.inverseText,
        //                         textTransform: 'uppercase',
        //                     },
        //                 ]}
        //                 onPress={() => navigation.navigate('About')}
        //             >
        //                 About
        //             </Text>
        //             <Text
        //                 style={[
        //                     TextStyles.Overline1,
        //                     {
        //                         paddingVertical: Layout.screenPaddingMedium,
        //                         paddingHorizontal: Layout.screenPaddingLarge,
        //                         color: navigation.getParam('active', false)
        //                             ? Colors.tintColor
        //                             : Colors.inverseText,
        //                         textTransform: 'uppercase',
        //                     },
        //                 ]}
        //                 onPress={() => navigation.navigate('Contact')}
        //             >
        //                 Contact
        //             </Text> */}
        //         </View>
        //     </View>
        // ),

        // headerRight: (
        //     // <HeaderButtons HeaderButtonComponent={FeatherHeaderButton}>
        //     //     <Item
        //     //         title="chevron-left"
        //     //         iconName="chevron-left"
        //     //         iconSize={Icons.large}
        //     //         color={Colors.tintColor}
        //     //     />
        //     // </HeaderButtons>
        //     <View
        //         style={{
        //             flex: 1,
        //             flexDirection: 'row',
        //             paddingVertical: Layout.screenPaddingMedium,
        //             paddingHorizontal: Layout.isMediumDevice
        //                 ? Layout.screenPaddingSmall
        //                 : Layout.screenPaddingLarge,
        //         }}
        //     >
        //         {/* <ButtonMini
        //             //onPress={() => navigation.navigate('AuthLogin')}

        //             buttonStyle={{
        //                 borderWidth: 1,
        //                 backgroundColor: Colors.transparentBackground,
        //                 borderColor: Layout.isMediumDevice
        //                     ? Colors.inverseText
        //                     : Colors.inverseText,
        //             }}
        //             textStyle={[
        //                 TextStyles.Button,
        //                 {
        //                     color: Layout.isMediumDevice
        //                         ? Colors.inverseText
        //                         : Colors.inverseText,
        //                 },
        //             ]}
        //             title={'Log in'}
        //             disabled={false}
        //             onPress={() =>
        //                 navigation.dispatch(DrawerActions.toggleDrawer())
        //             }
        //         /> */}
        //         <ButtonIcon
        //             activityColor={Colors.white}
        //             iconName={'menu'}
        //             iconColor={
        //                 navigation.getParam('active', false)
        //                     ? Colors.tintColor
        //                     : Colors.inverseText
        //             }
        //             buttonStyle={{
        //                 //alignSelf: 'center',
        //                 backgroundColor: 'transparent',
        //             }}
        //             loading={false}
        //             disabled={false}
        //             onPress={() =>
        //                 navigation.dispatch(DrawerActions.toggleDrawer())
        //             }
        //         />
        //     </View>
        // ),
    }),
}

class DrawerComponent extends React.Component {
    // state = {
    //     active: 0,
    // }
    // componentDidMount() {
    //
    //     console.log(this.state)
    // }
    // componentWillUnmount() {
    //     window.removeEventListener('scroll', this.onScroll)
    // }
    // onScroll = event => {
    //     const { pageYOffset } = window
    //     const { active } = this.state
    //     if (pageYOffset >= 100 && active === 0) {
    //         this.setState({ active: 1 })
    //     } else if (pageYOffset < 100 && active === 1) {
    //         this.setState({ active: 0 })
    //     }
    // }
}

const ExplorerStack = createStackNavigator(
    {
        Checkout: {
            screen: CheckoutScreen,
        },
        // GetExplorerApp: {
        //     screen: GetExplorerAppScreen,
        // },
        // About: {
        //     screen: TeamScreen,
        // },
        // Contact: {
        //     screen: ContactScreen,
        // },
        RequestConfirmation: {
            screen: RequestConfirmationScreen,
        },

        // RequestInfo: {
        //     screen: RequestInfoScreen,
        // },

        // ExplorerHome: {
        //     screen: AuthHomeScreen,
        // },
    },
    StackConfig
)

export default createDrawerNavigator(
    {
        ExplorerStack,
    },

    DrawerConfig,
    DrawerComponent
)
