import React from 'react'
import {
    Image,
    ImageBackground,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import { Feather } from '@expo/vector-icons'
import { Colors, TextStyles, Layout, Icons } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import FormHeaderTitle from '../../components/FormHeaderTitle'
import StudioFooter from '../../components/StudioFooter'
import GetInTouch from '../../components/GetInTouch'
import Button from '../../components/Button'

export default class StudioTeam extends React.Component {
    // state = {
    //     windowWidth: '',
    //     windowHeight: '',
    // }
    componentDidMount() {
        this.props.navigation.setParams({ active: true })
        //window.addEventListener('onLayout', this.handleResize)
    }

    componentDidUpdate = prevProps => {
        if (prevProps.isFocused !== this.props.isFocused) {
            this.props.navigation.setParams({ active: true })
            //window.addEventListener('onLayout', this.handleResize)

            //window.removeEventListener('scroll', this.onScroll)
        }
    }

    // handleResize = async event => {
    //     const windowWidth = await event.nativeEvent.layout.width
    //     const windowHeight = await event.nativeEvent.layout.height

    //     // const {
    //     //     window: { width, height, fontScale, scale },
    //     //     screen,
    //     // } = useDimensions()

    //     console.log(windowWidth, windowHeight)
    //     //console.log(event, width, height)

    //     this.setState(prevState => {
    //         return {
    //             windowWidth,
    //             windowHeight,
    //         }
    //     })
    // }

    // onScroll = async event => {
    //     const pageYOffset = await event.nativeEvent.contentOffset.y

    //     console.log(event.nativeEvent.contentOffset.y)

    //     if (pageYOffset >= 88) {
    //         this.props.navigation.setParams({ active: true })
    //     } else {
    //         this.props.navigation.setParams({ active: false })
    //     }
    // }

    renderRule = lineColor => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 2,
                    borderBottomColor: lineColor || Colors.tintColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSeparator = lineColor => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border, //lineColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSpacer() {
        return (
            <View
                style={{
                    width: '100%',
                    height: Layout.screenPaddingLarge,
                }}
            />
        )
    }

    render() {
        return (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View
                            //onLayout={event => this.handleResize(event)}
                            style={{
                                flex: 1,
                            }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <ScrollView
                                // onScroll={event =>
                                //     this.onScroll(event)
                                // }
                                // scrollEventThrottle={16}
                                style={{
                                    flex: 1,
                                    // height: this.state.windowHeight,
                                    // width: this.state.windowWidth,
                                    backgroundColor: Colors.gray0,
                                    paddingTop: Layout.isMediumDevice
                                        ? Layout.topNavHeightMobile
                                        : Layout.topNavHeightDesktop,
                                }}
                                pagingEnabled={
                                    Layout.isMediumDevice ? false : true
                                }
                            >
                                <View
                                    style={[
                                        styles.sectionContainer,
                                        { paddingBottom: 0 },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                //flex: 1,
                                                paddingVertical: Layout.isMediumDevice
                                                    ? Layout.screenPaddingLarge
                                                    : Layout.screenPaddingXL,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingBottom: Layout.isMediumDevice
                                                        ? Layout.screenPaddingLarge
                                                        : 0,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading1,
                                                        {
                                                            paddingRight:
                                                                Layout.screenPaddingMedium,
                                                            //width: '50%',
                                                        },
                                                    ]}
                                                >
                                                    At Studio, we lead by
                                                    example
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading2,
                                                        {
                                                            lineHeight: 36,
                                                        },
                                                    ]}
                                                >
                                                    We are a team of creatives
                                                    and technologists with
                                                    experience launching top
                                                    Food and Hospitality, Tech,
                                                    and Fashion brands.
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                    <ImageBackground
                                        style={[
                                            {
                                                width: '100%',
                                                height:
                                                    Layout.SCREEN_HEIGHT *
                                                    0.382,
                                            },
                                        ]}
                                        source={require('../../assets/images/daniel-pascoa-pCvCDub8ieI-unsplash.jpg')}
                                        resizeMode={'cover'}
                                    />
                                </View>

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            backgroundColor: Colors.gray0,
                                        },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        {/* <View
                                            style={{
                                                flex: 1,
                                                maxWidth: 720,
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={
                                                    'Statement demonstrating alignment of Studio and noname'
                                                }
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                    {
                                                        textAlign: 'center',
                                                        color: 'red',
                                                    },
                                                ]}
                                            />
                                            <Text
                                                style={[
                                                    TextStyles.Paragraph,
                                                    {
                                                        lineHeight: 26,
                                                        textAlign: 'center',
                                                        color: 'red',
                                                    },
                                                ]}
                                            >
                                                Detail supporting point or idea.
                                            </Text>
                                        </View> */}
                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                alignItems: 'stretch',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={[
                                                    styles.cardContent,
                                                    {
                                                        padding: 0,
                                                        marginVertical: 0,
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                    },
                                                ]}
                                            >
                                                <Image
                                                    source={require('../../assets/images/eric-ward-LsPw-DE_GAs-unsplash.jpg')}
                                                    style={{
                                                        width: '100%',
                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_HEIGHT *
                                                              0.5
                                                            : '100%',
                                                    }}
                                                    resizeMode={'cover'}
                                                />
                                            </View>

                                            <View
                                                style={[
                                                    styles.cardContent,
                                                    {
                                                        marginVertical: 0,
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                    },
                                                ]}
                                            >
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingSmall,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Overline2,
                                                            {
                                                                color:
                                                                    Colors.primaryText,
                                                                paddingVertical:
                                                                    Layout.screenPaddingSmall,
                                                                textAlign:
                                                                    'center',
                                                                textTransform:
                                                                    'uppercase',
                                                            },
                                                        ]}
                                                    >
                                                        Wake up movement
                                                    </Text>
                                                    {this.renderRule()}
                                                </View>
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading4,
                                                            {
                                                                paddingBottom:
                                                                    Layout.screenPaddingSmall,
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        “Dear Food Industry:
                                                        This is your WAKE-UP
                                                        CALL. I’m calling on
                                                        everyone in this
                                                        industry that we love so
                                                        much—chefs, farmers,
                                                        winemakers, journalists,
                                                        restaurant owners,
                                                        influencers, food
                                                        lovers, servers,
                                                        dishwashers, sommeliers,
                                                        bakers, bloggers,
                                                        EVERYONE—to share this
                                                        post and write ONE THING
                                                        in the caption that they
                                                        are going to start doing
                                                        today to fight the
                                                        climate emergency. My
                                                        promise is this: I am
                                                        going to strive to make
                                                        all my restaurants
                                                        waste-free—I've already
                                                        started. Join me in
                                                        sounding the alarm.
                                                        WAKEUP!”
                                                    </Text>
                                                </View>
                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        paddingVertical:
                                                            Layout.screenPaddingMedium,
                                                    }}
                                                >
                                                    <Text
                                                        style={
                                                            TextStyles.Paragraph
                                                        }
                                                    >
                                                        #incrennible #planet
                                                        #humanity
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            //backgroundColor: Colors.aesop,
                                            paddingVertical: Layout.isMediumDevice
                                                ? Layout.screenPaddingLarge
                                                : Layout.screenPaddingXL,
                                        },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <FormHeaderTitle
                                            headerTitle={'Leadership'}
                                            headerTitleStyle={[
                                                TextStyles.Heading1,
                                                {
                                                    alignSelf: 'center',
                                                },
                                            ]}
                                        />

                                        <View
                                            style={{
                                                paddingVertical: Layout.isMediumDevice
                                                    ? Layout.screenPaddingLarge
                                                    : Layout.screenPaddingXL,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingBottom: Layout.isMediumDevice
                                                        ? Layout.screenPaddingLarge
                                                        : 0,
                                                }}
                                            >
                                                <Image
                                                    source={require('../../assets/images/eric-ward-LsPw-DE_GAs-unsplash.jpg')}
                                                    style={{
                                                        width: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 360,
                                                        height: 480,
                                                        marginBottom:
                                                            Layout.screenPaddingMedium,
                                                        // backgroundColor:
                                                        //     Colors.disabled,
                                                        alignItems: 'center',
                                                        alignSelf: 'center',
                                                    }}
                                                    resizeMode={
                                                        Layout.isMediumDevice
                                                            ? 'contain'
                                                            : 'cover'
                                                    }
                                                />
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                            alignSelf: 'center',
                                                        },
                                                    ]}
                                                    maxLength={64}
                                                >
                                                    Master Chef
                                                </Text>
                                                <Text
                                                    numberOfLines={1}
                                                    style={[
                                                        TextStyles.Overline2,
                                                        {
                                                            flex: 1,
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                            alignSelf: 'center',
                                                        },
                                                    ]}
                                                >
                                                    VISIONARY
                                                </Text>
                                            </View>
                                            {/* <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            lineHeight: 36,
                                                            maxWidth: Layout.isMediumDevice
                                                                ? '100%'
                                                                : 480,
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                            color: 'red',
                                                        },
                                                    ]}
                                                >
                                                    At vero eos et accusamus et
                                                    iusto odio dignissimos
                                                    ducimus qui blanditiis
                                                    praesentium voluptatum
                                                    deleniti.
                                                </Text>

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 36,
                                                            maxWidth: Layout.isMediumDevice
                                                                ? '100%'
                                                                : 480,
                                                            color: 'red',
                                                        },
                                                    ]}
                                                >
                                                    Lorem ipsum dolor sit amet,
                                                    consectetur adipiscing elit,
                                                    sed do eiusmod tempor
                                                    incididunt ut labore et
                                                    dolore magna aliqua. Ut enim
                                                    ad minim veniam, quis
                                                    nostrud exercitation ullamco
                                                    laboris nisi ut aliquip ex
                                                    ea commodo consequat.
                                                </Text>
                                            </View> */}
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.sectionContainer}>
                                    <View style={styles.bodyContent}>
                                        {/* <View
                                            style={{
                                                maxWidth: 720,
                                                alignSelf: 'center',
                                                paddingVertical: Layout.isMediumDevice
                                                    ? Layout.screenPaddingLarge
                                                    : Layout.screenPaddingXL,
                                            }}
                                        >
                                            {/* <FormHeaderTitle
                                            headerTitle={'Company Vision'}
                                            headerTitleStyle={[
                                                TextStyles.Heading1,
                                                {
                                                    paddingBottom:
                                                        Layout.screenPaddingMedium,
                                                },
                                            ]}
                                        /> 

                                            <Text
                                                style={[
                                                    TextStyles.Heading1,
                                                    {
                                                        textAlign: Layout.isMediumDevice
                                                            ? 'left'
                                                            : 'center',
                                                    },
                                                ]}
                                            >
                                                Our brand values
                                            </Text>
                                            </View> */}

                                        <View
                                            style={{
                                                alignItems: 'flex-start',
                                                paddingVertical: Layout.isMediumDevice
                                                    ? 0
                                                    : Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,

                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                {this.renderRule()}
                                                <View
                                                    style={{
                                                        overflow: 'hidden',
                                                        backgroundColor:
                                                            Colors.gray2,
                                                        borderRadius: 96,
                                                        alignSelf: 'flex-start',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        padding:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/svgs/cloud-check.svg')}
                                                        style={{
                                                            width: Icons.XL,
                                                            height: Icons.XL,
                                                        }}
                                                        resizeMode={'cover'}
                                                    />
                                                </View>

                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                            paddingRight: Layout.isMediumDevice
                                                                ? 0
                                                                : Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    Robens Elsume
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.Overline2,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    ]}
                                                >
                                                    Business Strategist
                                                </Text>
                                                {/* 
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    “I was working in a
                                                    restaurant in Tasmania in
                                                    2013 and we did 100 covers a
                                                    night with the choice of an
                                                    eight- or 10-course tasting
                                                    menu that changed every
                                                    day,” she explains. “With
                                                    only three of us in the
                                                    kitchen, the days were long,
                                                    hard, and fast, and I could
                                                    not, for the life of me,
                                                    find a pair of trousers that
                                                    were cool, comfortable, and
                                                    durable enough to work in
                                                    for 14 hours a day.”
                                                </Text> */}
                                            </View>

                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,

                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                {this.renderRule()}
                                                <View
                                                    style={{
                                                        overflow: 'hidden',
                                                        backgroundColor:
                                                            Colors.gray2,
                                                        borderRadius: 96,
                                                        alignSelf: 'flex-start',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        padding:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/svgs/team-idea.svg')}
                                                        style={{
                                                            width: Icons.XL,
                                                            height: Icons.XL,
                                                        }}
                                                        resizeMode={'cover'}
                                                    />
                                                </View>

                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                            paddingRight: Layout.isMediumDevice
                                                                ? 0
                                                                : Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    Maurice Kenji Clarke
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.Overline2,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    ]}
                                                >
                                                    Creative Director
                                                </Text>
                                                {/* 
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                            color: 'red',
                                                        },
                                                    ]}
                                                >
                                                    “I was working in a
                                                    restaurant in Tasmania in
                                                    2013 and we did 100 covers a
                                                    night with the choice of an
                                                    eight- or 10-course tasting
                                                    menu that changed every
                                                    day,” she explains. “With
                                                    only three of us in the
                                                    kitchen, the days were long,
                                                    hard, and fast, and I could
                                                    not, for the life of me,
                                                    find a pair of trousers that
                                                    were cool, comfortable, and
                                                    durable enough to work in
                                                    for 14 hours a day.”
                                                </Text> */}
                                            </View>

                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,

                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                {this.renderRule()}
                                                <View
                                                    style={{
                                                        overflow: 'hidden',
                                                        backgroundColor:
                                                            Colors.gray2,
                                                        borderRadius: 96,
                                                        alignSelf: 'flex-start',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        padding:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/svgs/renewable-energy-earth.svg')}
                                                        style={{
                                                            width: Icons.XL,
                                                            height: Icons.XL,
                                                        }}
                                                        resizeMode={'cover'}
                                                    />
                                                </View>

                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                            paddingRight: Layout.isMediumDevice
                                                                ? 0
                                                                : Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    Vick Weerasak
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.Overline2,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    ]}
                                                >
                                                    Fashion Designer
                                                </Text>

                                                {/* <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    “Here in the UK the industry
                                                    seems to ignore chefs
                                                    problems with there feet. I
                                                    recently had a sit down with
                                                    my owner and he was
                                                    concerned that me limping
                                                    around (from plantar
                                                    fasciitis) was making him
                                                    look bad. ”
                                                </Text> */}
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                {/* 
                                <View style={styles.sectionContainer}>
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                alignItems: 'flex-start',
                                                paddingVertical: Layout.isMediumDevice
                                                    ? 0
                                                    : Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingHorizontal: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingMedium,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,

                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        alignSelf: 'flex-start',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        // padding:
                                                        //     Layout.screenPaddingLarge,
                                                    }}
                                                >
                                                    <Image
                                                        //source={require('../../assets/images/12mauricekenjiclarke.jpg')}
                                                        source={{}}
                                                        style={{
                                                            width: Layout.isMediumDevice
                                                                ? '100%'
                                                                : 360,
                                                            height: 480,
                                                            backgroundColor:
                                                                'red',
                                                        }}
                                                        resizeMode={'cover'}
                                                    />
                                                </View>
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                            alignSelf: 'center',
                                                        },
                                                    ]}
                                                    maxLength={64}
                                                >
                                                    Robens Elsume
                                                </Text>
                                                <Text
                                                    numberOfLines={1}
                                                    style={[
                                                        TextStyles.Overline2,
                                                        {
                                                            flex: 1,
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                            alignSelf: 'center',
                                                            color: 'red',
                                                        },
                                                    ]}
                                                >
                                                    ROLE
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingHorizontal: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingMedium,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        alignSelf: 'flex-start',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        // padding:
                                                        //     Layout.screenPaddingLarge,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/images/12mauricekenjiclarke.jpg')}
                                                        style={{
                                                            width: Layout.isMediumDevice
                                                                ? '100%'
                                                                : 360,
                                                            height: 480,
                                                        }}
                                                        resizeMode={'cover'}
                                                    />
                                                </View>

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                            alignSelf: 'center',
                                                        },
                                                    ]}
                                                    maxLength={64}
                                                >
                                                    Maurice Kenji Clarke
                                                </Text>
                                                <Text
                                                    numberOfLines={1}
                                                    style={[
                                                        TextStyles.Overline2,
                                                        {
                                                            flex: 1,
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                            alignSelf: 'center',
                                                        },
                                                    ]}
                                                >
                                                    ROLE
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    paddingHorizontal: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingMedium,
                                                    paddingVertical:
                                                        Layout.screenPaddingLarge,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        alignSelf: 'flex-start',
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        // padding:
                                                        //     Layout.screenPaddingLarge,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/images/12mauricekenjiclarke.jpg')}
                                                        style={{
                                                            width: Layout.isMediumDevice
                                                                ? '100%'
                                                                : 360,
                                                            height: 480,
                                                        }}
                                                        resizeMode={'cover'}
                                                    />
                                                </View>

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                            alignSelf: 'center',
                                                        },
                                                    ]}
                                                    maxLength={64}
                                                >
                                                    Vick Weerasak
                                                </Text>
                                                <Text
                                                    numberOfLines={1}
                                                    style={[
                                                        TextStyles.Overline2,
                                                        {
                                                            flex: 1,
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                            alignSelf: 'center',
                                                        },
                                                    ]}
                                                >
                                                    ROLE
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View> */}

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        { justifyContent: 'space-between' },
                                    ]}
                                >
                                    <ImageBackground
                                        style={{
                                            width: '100%',
                                            height: Layout.SCREEN_HEIGHT * 0.33,
                                        }}
                                        source={require('../../assets/images/jc-gellidon-c61FgpW3cXM-unsplash.jpg')}
                                        resizeMode={'cover'}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                alignItems: 'flex-start',
                                                justifyContent: 'center',
                                                backgroundColor:
                                                    Colors.mediumBackground,
                                                paddingHorizontal:
                                                    Layout.screenPaddingLarge,
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={'Transform'}
                                                headerTitleStyle={[
                                                    Layout.isMediumDevice
                                                        ? Textstyle.Heading1Inverse
                                                        : TextStyles.Heading0Inverse,
                                                ]}
                                            />
                                        </View>
                                    </ImageBackground>

                                    <ImageBackground
                                        style={{
                                            width: '100%',
                                            height: Layout.SCREEN_HEIGHT * 0.33,
                                        }}
                                        source={require('../../assets/images/jc-gellidon-c61FgpW3cXM-unsplash.jpg')}
                                        resizeMode={'cover'}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                alignItems: 'flex-start',
                                                justifyContent: 'center',
                                                backgroundColor:
                                                    Colors.mediumBackground,
                                                paddingHorizontal:
                                                    Layout.screenPaddingLarge,
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={'Empower'}
                                                headerTitleStyle={[
                                                    Layout.isMediumDevice
                                                        ? Textstyle.Heading1Inverse
                                                        : TextStyles.Heading0Inverse,
                                                ]}
                                            />
                                        </View>
                                    </ImageBackground>

                                    <ImageBackground
                                        style={{
                                            width: '100%',
                                            height: Layout.SCREEN_HEIGHT * 0.33,
                                        }}
                                        source={require('../../assets/images/jc-gellidon-c61FgpW3cXM-unsplash.jpg')}
                                        resizeMode={'cover'}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                alignItems: 'flex-start',
                                                justifyContent: 'center',
                                                backgroundColor:
                                                    Colors.mediumBackground,
                                                paddingHorizontal:
                                                    Layout.screenPaddingLarge,
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={'Together'}
                                                headerTitleStyle={[
                                                    Layout.isMediumDevice
                                                        ? Textstyle.Heading1Inverse
                                                        : TextStyles.Heading0Inverse,
                                                ]}
                                            />
                                        </View>
                                    </ImageBackground>
                                </View>

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        { backgroundColor: Colors.gray0 },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        {/* <View
                                            style={{
                                                flex: 1,
                                                maxWidth: 720,
                                                alignSelf: 'center',
                                                alignItems: 'center',
                                            }}
                                        >
                                            <FormHeaderTitle
                                                headerTitle={'The perfect pair'}
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                    { textAlign: 'center' },
                                                ]}
                                            />
                                            <Text
                                                style={[
                                                    TextStyles.Paragraph,
                                                    {
                                                        lineHeight: 26,
                                                        textAlign: 'center',
                                                        color: 'red',
                                                    },
                                                ]}
                                            >
                                                Cosmic's and Master Chef are a
                                                great partnership match based on
                                                this main idea.
                                            </Text>
                                        </View> */}

                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : Layout.SCREEN_HEIGHT *
                                                          0.382,
                                                    padding:
                                                        Layout.screenPaddingMedium,
                                                }}
                                            >
                                                <Image
                                                    source={require('../../assets/images/eric-ward-LsPw-DE_GAs-unsplash.jpg')}
                                                    style={{
                                                        borderRadius: 1000,
                                                        overflow: 'hidden',
                                                        width: Layout.isMediumDevice
                                                            ? Layout.SCREEN_WIDTH *
                                                              0.75
                                                            : Layout.SCREEN_HEIGHT *
                                                              0.382,
                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_WIDTH *
                                                              0.75
                                                            : Layout.SCREEN_HEIGHT *
                                                              0.382,
                                                    }}
                                                    resizeMode={'cover'}
                                                />
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : Layout.SCREEN_HEIGHT *
                                                          0.382,
                                                    padding:
                                                        Layout.screenPaddingMedium,
                                                }}
                                            >
                                                <Image
                                                    source={require('../../assets/images/manuel-vincent-KRQYcW1sXlc-unsplash.jpg')}
                                                    style={{
                                                        borderRadius: 1000,
                                                        overflow: 'hidden',
                                                        width: Layout.isMediumDevice
                                                            ? Layout.SCREEN_WIDTH *
                                                              0.75
                                                            : Layout.SCREEN_HEIGHT *
                                                              0.382,
                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_WIDTH *
                                                              0.75
                                                            : Layout.SCREEN_HEIGHT *
                                                              0.382,
                                                    }}
                                                    resizeMode={'cover'}
                                                />
                                            </View>
                                        </View>

                                        <View
                                            style={{
                                                alignSelf: 'center',
                                                // minWidth: Layout.isMediumDevice
                                                //     ? '100%'
                                                //     : 720,
                                                // maxWidth: Layout.isMediumDevice
                                                //     ? '100%'
                                                //     : 720,
                                                paddingVertical:
                                                    Layout.screenPaddingMedium,
                                            }}
                                        >
                                            <Text
                                                style={[
                                                    TextStyles.Heading2,
                                                    {
                                                        textAlign: 'center',
                                                        paddingBottom:
                                                            Layout.screenPaddingLarge,
                                                    },
                                                ]}
                                            >
                                                The perfect pair
                                            </Text>
                                            {/* 
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Feather
                                                    style={{
                                                        alignSelf: 'center',
                                                        paddingRight: 12,
                                                    }}
                                                    color={Colors.tintColor}
                                                    size={Icons.small}
                                                    name={'check'}
                                                />
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                            color: 'red',
                                                        },
                                                    ]}
                                                >
                                                    Partnership alignment or
                                                    opportunity reason 1
                                                </Text>
                                            </View>

                                            {this.renderSeparator(Colors.gray3)}
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Feather
                                                    style={{
                                                        alignSelf: 'center',
                                                        paddingRight: 12,
                                                    }}
                                                    color={Colors.tintColor}
                                                    size={Icons.small}
                                                    name={'check'}
                                                />
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                            color: 'red',
                                                        },
                                                    ]}
                                                >
                                                    Partnership alignment or
                                                    opportunity reason 2
                                                </Text>
                                            </View>

                                            {this.renderSeparator(Colors.gray3)}
                                            <View
                                                style={{
                                                    flexDirection: 'row',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <Feather
                                                    style={{
                                                        alignSelf: 'center',
                                                        paddingRight: 12,
                                                    }}
                                                    color={Colors.tintColor}
                                                    size={Icons.small}
                                                    name={'check'}
                                                />
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                            color: 'red',
                                                        },
                                                    ]}
                                                >
                                                    Partnership alignment or
                                                    opportunity reason 3
                                                </Text>
                                            </View> */}
                                        </View>
                                    </View>
                                </View>

                                <ImageBackground
                                    style={styles.sectionContainer}
                                    source={require('../../assets/images/axel-antas-bergkvist-_xEUSpqL-_U-unsplash.jpg')}
                                    resizeMode={'cover'}
                                >
                                    <View
                                        style={[
                                            styles.bodyContent,
                                            {
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                paddingVertical:
                                                    Layout.screenPaddingXL * 2,
                                            },
                                        ]}
                                    >
                                        <View
                                            style={[
                                                styles.cardContent,
                                                {
                                                    alignSelf: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                },
                                            ]}
                                        >
                                            {/* <View
                                                style={{
                                                    //flex: 1,
                                                    paddingBottom:
                                                        Layout.screenPaddingLarge,
                                                }}
                                            > */}
                                            {/* {this.renderRule()} */}

                                            <Text
                                                style={[
                                                    TextStyles.Heading2,
                                                    {
                                                        textAlign: 'center',

                                                        paddingBottom:
                                                            Layout.screenPaddingSmall,
                                                    },
                                                ]}
                                            >
                                                noname × Studio
                                            </Text>
                                            <Text
                                                style={[
                                                    TextStyles.Paragraph,
                                                    {
                                                        textAlign: 'center',
                                                    },
                                                ]}
                                            >
                                                Thank you
                                            </Text>
                                            {/* </View> */}

                                            {/* <Button
                                                iconRight={true}
                                                iconName={'arrow-right'}
                                                iconColor={Colors.tintColor}
                                                onPress={() => {
                                                    this.props.navigation.navigate(
                                                        'StudioBrand'
                                                    )
                                                }}
                                                style={{
                                                    //flex: 1,
                                                    width: Layout.isMediumDevice
                                                        ? '100%'
                                                        : null,
                                                    alignSelf: Layout.isMediumDevice
                                                        ? 'center'
                                                        : 'flex-start',
                                                    // paddingTop:
                                                    //     Layout.screenPaddingSmall,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                    borderColor:
                                                        Colors.tintColor,
                                                }}
                                                textStyle={[
                                                    TextStyles.Button,
                                                    {
                                                        color:
                                                            Colors.primaryText,
                                                    },
                                                ]}
                                                title={'See the opportunity'}
                                            /> */}
                                        </View>
                                    </View>
                                </ImageBackground>

                                {/* <GetInTouch
                                    navigation={this.props.navigation}
                                    message={
                                        'Talk to a Studio team member who can take you through a demo of everything Master Chef has envisioned.'
                                    }
                                />
                                <StudioFooter
                                    navigation={this.props.navigation}
                                /> */}
                            </ScrollView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        height: Layout.isMediumDevice ? null : Layout.SCREEN_HEIGHT,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.gray0,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: Layout.screenPaddingLarge,
    },

    cardContent: {
        flex: 1,
        maxWidth: 720,
        marginVertical: Layout.screenPaddingMedium,
        backgroundColor: Colors.white,
        padding: Layout.screenPaddingLarge,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
    },
})
