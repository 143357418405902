import React from 'react'
import { TouchableOpacity, StyleSheet, Image, Text, View } from 'react-native'
import { Colors, Icons, Layout, TextStyles } from '../constants'

export default class Footer extends React.Component {
    render() {
        return (
            <View
                style={{
                    //flex: 1,
                    width: '100%',
                    alignSelf: 'center',
                    backgroundColor: Colors.gray0,
                    paddingTop: Layout.isMediumDevice
                        ? Layout.screenPaddingLarge
                        : Layout.screenPaddingXL,
                }}
            >
                <View
                    style={{
                        overflow: 'hidden',
                        //backgroundColor: Colors.tintColor,
                        borderRadius: 96,
                        alignSelf: 'center',
                        alignItems: 'center',
                        justifyContent: 'center',
                        padding: Layout.screenPaddingSmall,
                    }}
                >
                    <Image
                        source={require('../assets/svgs/indvstry-icon.svg')}
                        style={{
                            width: Icons.XL,
                            height: Icons.XL,
                        }}
                        resizeMode={'cover'}
                    />
                </View>
                <View style={styles.footer}>
                    {/* <View style={{ flex: 1 }}>
                    <Text
                        style={[
                            TextStyles.Heading4,
                            {
                                alignSelf: 'center',
                            },
                        ]}
                    >
                        Indvstry
                    </Text>
                </View> */}
                    <View
                        style={{
                            flex: 1,
                            minWidth: 240,
                            paddingVertical: Layout.screenPaddingSmall,
                        }}
                    >
                        <Text
                            style={[
                                TextStyles.Heading4,
                                {
                                    alignSelf: Layout.isMediumDevice
                                        ? 'center'
                                        : 'flex-start',
                                },
                            ]}
                        >
                            info@indvstry.io
                        </Text>
                    </View>
                    <View
                        style={{
                            flex: 1,
                            minWidth: 240,
                            paddingVertical: Layout.screenPaddingSmall,
                        }}
                    >
                        <Text
                            style={[
                                TextStyles.Paragraph,
                                {
                                    alignSelf: 'center',
                                },
                            ]}
                        >
                            Crafted in California
                        </Text>
                    </View>
                    <View
                        style={{
                            flex: 1,
                            minWidth: 240,
                            paddingVertical: Layout.screenPaddingSmall,
                        }}
                    >
                        <Text
                            style={[
                                TextStyles.Paragraph,
                                {
                                    alignSelf: Layout.isMediumDevice
                                        ? 'center'
                                        : 'flex-end',
                                },
                            ]}
                        >
                            © 2020 Indvstry Inc.
                        </Text>
                    </View>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    footer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        padding: Layout.screenPaddingLarge,
        // backgroundColor: Colors.gray0,
    },
})
