import React from 'react'
import { View, Text } from 'react-native'
import { Elements, StripeProvider } from 'react-stripe-elements'
import CheckoutForm from '../../components/CheckoutForm'
import { StripeCheckout } from 'expo-stripe-checkout'

class CheckoutScreen extends React.Component {
    render() {
        return (
            <StripeProvider apiKey="pk_test_TYooMQauvdEDq54NiTphI7jx">
                <View style={{ flex: 1, padding: 24 }}>
                    <Elements>
                        <CheckoutForm />
                    </Elements>
                </View>
            </StripeProvider>

            // <StripeCheckout
            //     publicKey="sk_test_4eC39HqLyjWDarjtT1zdp7dc"
            //     amount={100000}
            //     imageUrl="https://pbs.twimg.com/profile_images/778378996580888577/MFKh-pNn_400x400.jpg"
            //     storeName="Stripe Checkout"
            //     description="Test"
            //     currency="USD"
            //     allowRememberMe={false}
            //     prepopulatedEmail="test@test.com"
            //     onClose={this.onClose}
            //     onPaymentSuccess={this.onPaymentSuccess}
            // />
        )
    }
}

export default CheckoutScreen

// import React from 'react'
// import StripeCheckout from 'react-stripe-checkout'
// import Button from '../../components/Button'

// export default class TakeMoney extends React.Component {
//     onToken = token => {
//         fetch('/save-stripe-token', {
//             method: 'POST',
//             body: JSON.stringify(token),
//         }).then(response => {
//             response.json().then(data => {
//                 alert(`We are in business, ${data.email}`)
//             })
//         })
//     }

//     render() {
//         return (
//             <StripeCheckout
//                 name="Three Comma Co." // the pop-in header title
//                 description="Big Data Stuff" // the pop-in header subtitle
//                 image="https://www.vidhub.co/assets/logos/vidhub-icon-2e5c629f64ced5598a56387d4e3d0c7c.png" // the pop-in header image (default none)
//                 ComponentClass="View"
//                 label="Buy the Thing" // text inside the Stripe button
//                 panelLabel="Give Money" // prepended to the amount in the bottom pay button
//                 amount={1000000} // cents
//                 currency="USD"
//                 stripeKey="..."
//                 locale="zh"
//                 email="info@vidhub.co"
//                 // Note: Enabling either address option will give the user the ability to
//                 // fill out both. Addresses are sent as a second parameter in the token callback.
//                 shippingAddress
//                 billingAddress={false}
//                 // Note: enabling both zipCode checks and billing or shipping address will
//                 // cause zipCheck to be pulled from billing address (set to shipping if none provided).
//                 zipCode={false}
//                 alipay // accept Alipay (default false)
//                 bitcoin // accept Bitcoins (default false)
//                 allowRememberMe // "Remember Me" option (default true)
//                 token={this.onToken} // submit callback
//                 opened={this.onOpened} // called when the checkout popin is opened (no IE6/7)
//                 closed={this.onClosed} // called when the checkout popin is closed (no IE6/7)
//                 // Note: `reconfigureOnUpdate` should be set to true IFF, for some reason
//                 // you are using multiple stripe keys
//                 reconfigureOnUpdate={false}
//                 // Note: you can change the event to `onTouchTap`, `onClick`, `onTouchStart`
//                 // useful if you're using React-Tap-Event-Plugin
//                 triggerEvent="onTouchTap"
//             >
//                 <Button title={'Yolo'} />
//             </StripeCheckout>
//         )
//     }
// }
