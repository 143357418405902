import React from 'react'
import {
    KeyboardAvoidingView,
    LayoutAnimation,
    Platform,
    ScrollView,
    StyleSheet,
    Text,
    TextInput,
    View,
} from 'react-native'
import { DrawerActions } from 'react-navigation-drawer'
import { Feather } from '@expo/vector-icons'
import { Colors, Icons, TextStyles, Layout } from '../constants'
import { UserProfileConsumer } from '../context/UserProfileContext'
import FormHeaderTitle from './FormHeaderTitle'
import FormFooterOptions from './FormFooterOptions'
import Button from './Button'
import ButtonIcon from './ButtonIcon'
import UserProfile from './UserProfile'
import UserSignUp from './UserSignUp'
import PublicNavigationTabs from './PublicNavigationTabs'
import CreatorNavigationTabs from './CreatorNavigationTabs'
import InvestorNavigationTabs from './InvestorNavigationTabs'
import StudioNavigationTabs from './StudioNavigationTabs'

export default class DrawerNavigation extends React.Component {
    componentDidMount = async () => {
        //await this.context.setAuthInitialState()
        await this.context.initializeUserAccount()
        await this.context.initializeUrlParams()
    }

    componentDidUpdate = async prevProps => {
        if (prevProps.isFocused !== this.props.isFocused) {
            //await this.context.setAuthInitialState()
            this.context.initializeUserAccount()
            await this.context.initializeUrlParams()

            //this.props.navigation.goBack()
        }
    }

    renderRule = lineColor => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 2,
                    borderBottomColor: lineColor || Colors.tintColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSpacer() {
        return (
            <View
                style={{
                    width: '100%',
                    height: Layout.screenPaddingLarge,
                }}
            />
        )
    }

    renderSeparator = lineColor => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border, //lineColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    render() {
        //LayoutAnimation.easeInEaseOut()

        return (
            <UserProfileConsumer>
                {context => {
                    const isCreator = context.isCreator
                    const isInvestor = context.isInvestor
                    const isStudio = context.isStudio
                    const studioClient = context.studioClient

                    return (
                        <View
                            style={{
                                flex: 1,
                            }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <KeyboardAvoidingView
                                behavior={
                                    Platform.OS == 'ios' ? 'padding' : null
                                }
                                keyboardVerticalOffset={
                                    Layout.TAB_DEFAULT_HEIGHT + 8
                                }
                                style={{
                                    flex: 1,
                                }}
                            >
                                <View style={styles.stickyButton}>
                                    <ButtonIcon
                                        activityColor={Colors.white}
                                        iconName={'x'}
                                        iconColor={Colors.tintColor}
                                        buttonStyle={{
                                            //alignSelf: 'center',
                                            backgroundColor: 'transparent',
                                        }}
                                        loading={false}
                                        disabled={false}
                                        onPress={() =>
                                            this.props.navigation.dispatch(
                                                DrawerActions.toggleDrawer()
                                            ) && this.context.resetUserForms()
                                        }
                                    />
                                </View>
                                <ScrollView
                                    style={{ flex: 1 }}
                                    pagingEnabled={true}
                                    decelerationRate="fast"
                                    // snapToOffsets
                                    // snapToEnd
                                >
                                    {Layout.isMediumDevice ? (
                                        context.userExists ? (
                                            isCreator ? (
                                                <CreatorNavigationTabs
                                                    navigation={
                                                        this.props.navigation
                                                    }
                                                />
                                            ) : isInvestor ? (
                                                <InvestorNavigationTabs
                                                    navigation={
                                                        this.props.navigation
                                                    }
                                                />
                                            ) : isStudio ? (
                                                <StudioNavigationTabs
                                                    navigation={
                                                        this.props.navigation
                                                    }
                                                />
                                            ) : (
                                                <PublicNavigationTabs
                                                    navigation={
                                                        this.props.navigation
                                                    }
                                                />
                                            )
                                        ) : studioClient ? (
                                            <View />
                                        ) : (
                                            <PublicNavigationTabs
                                                navigation={
                                                    this.props.navigation
                                                }
                                            />
                                        )
                                    ) : (
                                        <View />
                                    )}

                                    {context.userExists &&
                                    isStudio &&
                                    !Layout.isMediumDevice ? (
                                        <StudioNavigationTabs
                                            navigation={this.props.navigation}
                                        />
                                    ) : (
                                        <View />
                                    )}

                                    {context.userExists ? (
                                        <UserProfile />
                                    ) : (
                                        <UserSignUp />
                                    )}
                                </ScrollView>
                                {context.userExists ? (
                                    <FormFooterOptions
                                        navigation={this.props.navigation}
                                        loading={context.loading}
                                        displayLeftButton={true}
                                        leftButtonTitle={'Log out'}
                                        onLeftButtonPress={context.userSignOut}
                                        displayRightButton={false}
                                    />
                                ) : (
                                    <FormFooterOptions
                                        navigation={this.props.navigation}
                                        loading={context.loading}
                                        displayLeftButton={true}
                                        leftButtonTitle={
                                            context.tokenExists
                                                ? 'Have an account?'
                                                : 'Forgot password'
                                        }
                                        onLeftButtonPress={() => {
                                            context.tokenExists
                                                ? context.resetUserForms() &&
                                                  context.setTokenExists()
                                                : this.props.navigation.navigate(
                                                      'AuthForgotPassword'
                                                  )
                                        }}
                                        displayRightButton={true}
                                        rightButtonTitle={
                                            context.tokenExists
                                                ? 'Sign up'
                                                : 'Log in'
                                        }
                                        onRightButtonPress={() => {
                                            context.tokenExists
                                                ? context.onSignUpEmailPassword()
                                                : context.onLoginEmailPassword()
                                        }}
                                        rightButtonDisabled={
                                            context.tokenExists
                                                ? !context.passwordValid ||
                                                  !context.emailValid
                                                : !context.password.length ||
                                                  !context.emailValid
                                        }
                                    />
                                )}
                            </KeyboardAvoidingView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    stickyButton: {
        zIndex: 100,
        position: 'absolute',
        top: 0,
        right: 0,
        paddingVertical: Layout.screenPaddingMedium,
        paddingHorizontal: Layout.isMediumDevice
            ? Layout.screenPaddingSmall
            : Layout.screenPaddingLarge,
    },
})
