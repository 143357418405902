import React from 'react'
import { ScrollView, StyleSheet, Image, Text, View } from 'react-native'
import { Video } from 'expo-av'
import { Colors, Icons, TextStyles, Layout } from '../constants'
import ButtonIcon from './ButtonIcon'

export default class StudioApparelOuter extends React.Component {
    renderStudioImage = ({ theme, description, image }) => {
        return (
            <View
                style={{
                    flex: 1,
                    alignSelf: 'center',
                    alignItems: 'center',
                    minWidth: Layout.isMediumDevice
                        ? '100%'
                        : Layout.SCREEN_WIDTH * 0.3333,
                    //paddingBottom: Layout.screenPaddingXL,
                }}
            >
                <View
                    style={{
                        //backgroundColor: Colors.gray3,
                        // paddingVertical: 8,
                        // paddingHorizontal: 8,
                        // borderRadius: 40,
                        borderWidth: Layout.isMediumDevice ? 0 : 1,
                        borderColor: Colors.gray0,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Image
                        source={image}
                        style={{
                            backgroundColor: Colors.gray3,
                            width: Layout.isMediumDevice
                                ? Layout.SCREEN_WIDTH
                                : Layout.SCREEN_WIDTH * 0.3333,
                            height: Layout.isMediumDevice
                                ? Layout.SCREEN_WIDTH
                                : Layout.SCREEN_WIDTH * 0.3333,
                        }}
                        resizeMode={'cover'}
                    />
                </View>
                {/* <View
                    style={{
                        flex: 1,
                        alignSelf: 'center',
                        alignItems: 'center',
                        maxWidth: Layout.isMediumDevice
                            ? Layout.SCREEN_WIDTH
                            : Layout.SCREEN_WIDTH * 0.3333,
                        paddingHorizontal: Layout.isMediumDevice
                            ? Layout.screenPaddingLarge
                            : Layout.screenPaddingXL,
                        paddingVertical: Layout.screenPaddingLarge,
                    }}
                >
                    <Text
                        style={[
                            TextStyles.Heading3,
                            {
                                paddingBottom: Layout.screenPaddingSmall,
                                textAlign: 'center',
                            },
                        ]}
                    >
                        {theme}
                    </Text>
                    <Text
                        style={[
                            TextStyles.Paragraph,
                            {
                                lineHeight: 26,
                                textAlign: 'center',
                            },
                        ]}
                    >
                        {description}
                    </Text>
                </View> */}
            </View>
        )
    }

    renderRule = lineColor => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 2,
                    borderBottomColor: lineColor || Colors.tintColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSeparator = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border, //lineColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    render = () => {
        return (
            <View
                style={[styles.sectionContainer, this.props.sectionContainer]}
            >
                <View style={styles.container}>
                    <ButtonIcon
                        activityColor={Colors.white}
                        iconName={'chevron-left'}
                        iconColor={Colors.white}
                        style={{
                            display: Layout.isMediumDevice ? 'none' : 'flex',
                            position: 'absolute',
                            left: 0,
                            zIndex: 100,
                            //bottom: Layout.isMediumDevice ? 0 : null,
                        }}
                        buttonStyle={{
                            width: Layout.isMediumDevice
                                ? Layout.SCREEN_WIDTH * 0.5
                                : 72,
                            height: Layout.isMediumDevice ? 48 : 72,
                            paddingVertical: Layout.isMediumDevice
                                ? Layout.screenPaddingMedium
                                : Layout.screenPaddingLarge,
                            paddingVertical: Layout.isMediumDevice
                                ? Layout.screenPaddingMedium
                                : Layout.screenPaddingLarge,
                            borderRadius: 0,
                            backgroundColor: Colors.tintColor,
                        }}
                        loading={false}
                        disabled={false}
                        onPress={() => {
                            this.scroll.scrollTo({
                                x: -Layout.SCREEN_WIDTH,
                            })
                        }}
                    />
                    <ScrollView
                        ref={node => (this.scroll = node)}
                        //onScroll={event => this.onScroll(event)}
                        decelerationRate="fast"
                        style={{ flex: 1 }}
                        horizontal
                        //persistentScrollbar={true}
                        showsHorizontalScrollIndicator={true}
                        scrollIndicatorInsets={{
                            left: Layout.screenPaddingLarge,
                            right: Layout.screenPaddingLarge,
                        }}
                        pagingEnabled
                    >
                        {this.renderStudioImage({
                            image: require('../assets/images/IMG_4401.jpeg'),
                            theme: 'Title',
                            description: 'Description',
                        })}
                        {this.renderStudioImage({
                            image: require('../assets/images/IMG_4400.jpeg'),
                            theme: 'Title',
                            description: 'Description',
                        })}

                        {this.renderStudioImage({
                            image: require('../assets/images/IMG_4399.jpeg'),
                            theme: 'Title',
                            description: 'Description',
                        })}
                        {this.renderStudioImage({
                            image: require('../assets/images/IMG_4402.jpeg'),
                            theme: 'Title',
                            description: 'Description',
                        })}
                        {this.renderStudioImage({
                            image: require('../assets/images/IMG_4403.jpeg'),
                            theme: 'Title',
                            description: 'Description',
                        })}
                        {this.renderStudioImage({
                            image: require('../assets/images/IMG_4404.jpeg'),
                            theme: 'Title',
                            description: 'Description',
                        })}
                        {this.renderStudioImage({
                            image: require('../assets/images/IMG_4405.jpeg'),
                            theme: 'Title',
                            description: 'Description',
                        })}
                        {this.renderStudioImage({
                            image: require('../assets/images/IMG_4406.jpeg'),
                            theme: 'Title',
                            description: 'Description',
                        })}
                    </ScrollView>
                    <View
                        style={{
                            display: Layout.isMediumDevice ? 'flex' : 'none',
                            overflow: 'hidden',
                            position: 'absolute',
                            bottom: Layout.screenPaddingLarge,
                            alignItems: 'center',
                            justifyContent: 'center',
                            padding: Layout.screenPaddingSmall,
                        }}
                    >
                        <Image
                            //source={require('../assets/svgs/expand-horizontal.svg')}
                            source={require('../assets/svgs/expand-horizontal.svg')}
                            style={{
                                width: Icons.XL,
                                height: Icons.XL,
                            }}
                            resizeMode={'cover'}
                        />
                    </View>

                    <ButtonIcon
                        activityColor={Colors.white}
                        iconName={'chevron-right'}
                        iconColor={Colors.white}
                        style={{
                            display: Layout.isMediumDevice ? 'none' : 'flex',
                            position: 'absolute',
                            right: 0,
                            zIndex: 100,
                            //bottom: Layout.isMediumDevice ? 0 : null,
                        }}
                        buttonStyle={{
                            width: Layout.isMediumDevice
                                ? Layout.SCREEN_WIDTH * 0.5
                                : 72,
                            height: Layout.isMediumDevice ? 48 : 72,
                            paddingVertical: Layout.isMediumDevice
                                ? Layout.screenPaddingMedium
                                : Layout.screenPaddingLarge,
                            paddingVertical: Layout.isMediumDevice
                                ? Layout.screenPaddingMedium
                                : Layout.screenPaddingLarge,
                            borderRadius: 0,
                            backgroundColor: Colors.tintColor,
                        }}
                        loading={false}
                        disabled={false}
                        onPress={() =>
                            this.scroll.scrollTo({
                                x: Layout.SCREEN_WIDTH * 4,
                            })
                        }
                    />
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        alignItems: 'center',
        backgroundColor: Colors.gray0,
        // paddingVertical: Layout.isMediumDevice
        //     ? Layout.screenPaddingLarge
        //     : Layout.screenPaddingXL,
        paddingTop: 1,
        paddingBottom: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
    },
    container: {
        flex: 1,
        width: Layout.SCREEN_WIDTH,
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
})
