import React from 'react'
import { Text, View } from 'react-native'
import { Colors, TextStyles, Layout } from '../constants'
import Button from './Button'
import FooterSwitch from './FooterSwitch'

export default class FormFooterOptions extends React.Component {
    onPress = () => {
        this.props.onPress && this.props.onPress()
    }

    render() {
        const onGoBackPress = () => this.props.navigation.goBack()

        return (
            <View
                style={{
                    flexShrink: 1,
                    //alignSelf: 'center',
                    //width: Layout.isMediumDevice ? '100%' : 768,
                    borderTopWidth: 1,
                    borderTopColor: Colors.border,
                    paddingTop: 12,
                    paddingBottom: Layout.screenPaddingMedium,
                    paddingHorizontal: Layout.screenPaddingMedium,
                    backgroundColor: Colors.gray0,
                }}
            >
                {this.props.extendedMessage ? (
                    <View style={{ flex: 1 }}>
                        <Text
                            style={[
                                TextStyles.Caption,
                                {
                                    lineHeight: 22,
                                    paddingTop: Layout.screenPaddingMedium,
                                    paddingHorizontal:
                                        Layout.screenPaddingSmall,
                                    paddingBottom: Layout.screenPaddingLarge,
                                },
                            ]}
                        >
                            {this.props.message}
                        </Text>
                    </View>
                ) : (
                    <View />
                )}
                {this.props.extendedSection ? (
                    <FooterSwitch
                        footerSwitchValue={this.props.footerSwitchValue}
                        footerSwitchTitle={this.props.footerSwitchTitle}
                        footerRouteTitle={this.props.footerRouteTitle}
                        footerSwitchOnValueChange={
                            this.props.footerSwitchOnValueChange
                        }
                    />
                ) : (
                    <View />
                )}
                <View
                    style={{
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                    }}
                >
                    {this.props.displayLeftButton ? (
                        <Button
                            title={this.props.leftButtonTitle}
                            textStyle={[
                                TextStyles.ButtonRegular,
                                // {
                                //     //fontFamily: TextStyles.regular,
                                //     color: Colors.deepsea,
                                // },
                            ]}
                            buttonStyle={{
                                alignSelf: 'flex-start',
                                paddingHorizontal: 0,
                                backgroundColor: Colors.transparentBackground,
                            }}
                            //onPress={onGoBackPress}
                            onPress={this.props.onLeftButtonPress}
                        />
                    ) : (
                        <View />
                    )}

                    {this.props.displayCenterButton ? (
                        <Button
                            iconRight={true}
                            iconName={this.props.centerButtonIconName}
                            iconColor={Colors.tintColor}
                            title={this.props.centerButtonTitle}
                            textStyle={[TextStyles.Button]}
                            buttonStyle={{
                                alignSelf: 'center',
                                width: '100%',
                                backgroundColor: Colors.transparentBackground,
                                borderWidth: 1,
                                borderColor: Colors.tintColor,
                                maxWidth: Layout.isMediumDevice ? '100%' : 414,
                            }}
                            onPress={this.props.onCenterButtonPress}
                        />
                    ) : (
                        <View />
                    )}

                    {this.props.displayRightButton ? (
                        <Button
                            title={this.props.rightButtonTitle}
                            textStyle={{ color: Colors.white }}
                            buttonStyle={{
                                alignSelf: 'flex-end',
                            }}
                            onPress={this.props.onRightButtonPress}
                            loading={this.props.loading}
                            disabled={this.props.rightButtonDisabled}
                        />
                    ) : (
                        <View />
                    )}
                </View>
            </View>
        )
    }
}
