import React from 'react'
import {
    ActivityIndicator,
    StyleSheet,
    TouchableOpacity,
    View,
} from 'react-native'
import { Colors, Icons } from '../constants'
import { Feather } from '@expo/vector-icons'

export default class ButtonIcon extends React.Component {
    render() {
        let style = [styles.button]
        if (this.props.disabled) {
            style.push(styles.disabledButton)
        }
        return (
            <View style={[styles.container, this.props.style]}>
                <TouchableOpacity
                    style={[style, this.props.buttonStyle]}
                    disabled={this.props.disabled}
                    //onPressIn={this.props.onPressIn}
                    onPress={this.props.onPress}
                    //underlayColor={Colors.highlightColor}
                    //underlayColor={Colors.transparentBackground}
                >
                    <View
                        style={{
                            flexDirection: 'row',
                            alignItems: 'center',
                        }}
                    >
                        {this.props.loading ? (
                            <ActivityIndicator
                                size="small"
                                color={this.props.activityColor}
                            />
                        ) : (
                            <Feather
                                name={this.props.iconName}
                                style={[
                                    this.props.iconStyle,
                                    { lineHeight: 22 },
                                ]}
                                size={Icons.medium}
                                color={this.props.iconColor}
                            />
                        )}
                    </View>
                </TouchableOpacity>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    button: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3, // 3 is like share, 12 makes it Like the main create tab icon
        paddingVertical: 11,
        paddingHorizontal: 12,
        backgroundColor: Colors.tintColor,
    },
    disabledButton: {
        backgroundColor: Colors.disabled,
    },
    label: {
        color: '#FFF',
        //fontWeight: '700',
        //fontFamily: 'neuzeit-s-bold',
    },
})
