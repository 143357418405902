import React from 'react'
import { Switch, Text, TouchableOpacity, View } from 'react-native'
import { Feather } from '@expo/vector-icons'
import { Colors, TextStyles } from '../constants'

export default class FormHeaderTitle extends React.Component {
    renderSeparator() {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                    paddingTop: 24,
                }}
            />
        )
    }

    render = () => {
        const navigateScreen = () => {
            this.props.navigation.navigate(footerNavigateScreenName)
        }

        return (
            <View style={{ flex: 1, padding: 0, paddingBottom: 0 }}>
                <View
                    style={{
                        alignItems: 'center',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        paddingBottom: 16,
                    }}
                >
                    <Text
                        style={[
                            TextStyles.Paragraph,
                            this.props.footerSwitchTitleStyle,
                            {
                                flex: 1,
                                paddingRight: 16,
                                alignSelf: 'center',
                                lineHeight: 26,
                            }, // Note: Flex: 1 must go specifically here for now, to render text visible.
                        ]}
                    >
                        {this.props.footerSwitchTitle}
                    </Text>
                    <Switch
                        //disabled={this.props.footerSwitchDisabled}
                        //switchValue={this.props.value}
                        value={this.props.footerSwitchValue}
                        /* Currently disabled track color so color states work on web */
                        // trackColor={{
                        //     false: Colors.disabled,
                        //     true: { color: 'red' },
                        // }}
                        //ios_backgroundColor={Colors.disabled}
                        onTintColor={Colors.accentDeepsea}
                        tintColor={Colors.disabled}
                        //thumbTintColor={Colors.white}
                        thumbColor={Colors.white}
                        onValueChange={() =>
                            this.props.footerSwitchOnValueChange()
                        }
                    />
                    {/* <Switch
                        //disabled={this.props.footerSwitchDisabled}
                        //switchValue={this.props.value}
                        value={this.state.switchOn}
                        trackColor={{
                            false: Colors.disabled,
                            true: Colors.accentDeepsea,
                        }}
                        ios_backgroundColor={Colors.disabled}
                        thumbColor={Colors.white}
                        onValueChange={() => {
                            this.setSwitchOn()
                        }}
                    /> */}
                </View>
                {this.props.footerRouteTitle ? (
                    <TouchableOpacity
                        style={{
                            flexDirection: 'row',
                        }}
                        onPress={navigateScreen}
                    >
                        <Text
                            numberOfLines={1}
                            style={[
                                TextStyles.CaptionBold,
                                {
                                    alignSelf: 'center',
                                    paddingRight: 8,
                                },
                            ]}
                        >
                            {this.props.footerRouteTitle}
                        </Text>
                        <Feather
                            style={{
                                alignSelf: 'center',
                            }}
                            color={Colors.secondaryIcon}
                            size={18}
                            name={'chevron-right'}
                        />
                    </TouchableOpacity>
                ) : (
                    <View />
                )}
                {/* {this.renderSeparator()} */}
            </View>
        )
    }
}
