import React from 'react'
import { StyleSheet, View } from 'react-native'
import { AppLoading } from 'expo'
import { Asset } from 'expo-asset'
import * as Font from 'expo-font'
import * as Icon from '@expo/vector-icons'
import AppNavigator from './navigation/AppNavigator'
import { UserProfileProvider } from './context/UserProfileContext'
import { Colors } from './constants'
import { StripeProvider } from 'react-stripe-elements'

export default class App extends React.Component {
    state = {
        isLoadingComplete: false,
        stripe: null,
    }

    // componentDidMount() {
    //     // Create Stripe instance in componentDidMount
    //     // (componentDidMount only fires in browser/DOM environment)
    //     this.setState({ stripe: Stripe('pk_test_12345') })
    // }

    componentDidMount() {
        // componentDidMount only runs in a browser environment.
        // In addition to loading asynchronously, this code is safe to server-side render.

        // You can inject a script tag manually like this,
        // or you can use the 'async' attribute on the Stripe.js v3 <script> tag.
        const stripeJs = document.createElement('script')
        stripeJs.src = 'https://js.stripe.com/v3/'
        stripeJs.async = true
        stripeJs.onload = () => {
            // The setTimeout lets us pretend that Stripe.js took a long time to load
            // Take it out of your production code!
            setTimeout(() => {
                this.setState({
                    stripe: window.Stripe('pk_test_6pRNASCoBOKtIshFeQd4XMUh'),
                })
            }, 500)
        }
        document.body && document.body.appendChild(stripeJs)
    }

    render() {
        if (!this.state.isLoadingComplete && !this.props.skipLoadingScreen) {
            return (
                <AppLoading
                    startAsync={this._loadResourcesAsync}
                    onError={this._handleLoadingError}
                    onFinish={this._handleFinishLoading}
                />
            )
        } else {
            return (
                <StripeProvider
                    stripe={this.state.stripe}
                    //ref={node => (this.window = node)}
                >
                    <UserProfileProvider>
                        <View style={styles.container}>
                            <AppNavigator />
                        </View>
                    </UserProfileProvider>
                </StripeProvider>
            )
        }
    }

    _loadResourcesAsync = async () => {
        return Promise.all([
            Asset.loadAsync([
                /* Try to run a glob in the future */
                //https://stackoverflow.com/questions/5364928/node-js-require-all-files-in-a-folder\

                // Home / About
                require('./assets/images/nik-shuliahin-o14RBhTPxnA-unsplash.jpg'),
                require('./assets/images/jared-erondu-6Fq2U47SKtE-unsplash.jpg'),
                require('./assets/images/12mauricekenjiclarke.jpg'),

                // Heros
                require('./assets/images/jonathan-borba-uB7q7aipU2o-unsplash.jpg'),
                require('./assets/images/lucas-gallone-Q4QjAPMpJRQ-unsplash.jpg'),
                require('./assets/images/mens-fashion-in-restaurant-booth.jpg'),

                // Product / Restaurants
                require('./assets/images/Frame-23-09-2019-11-52-21.jpg'),
                require('./assets/images/Frame-23-09-2019-11-54-38.jpg'),
                require('./assets/images/Frame-23-09-2019-11-55-24.jpg'),
                require('./assets/images/Frame-24-09-2019-12-12-36.jpg'),

                require('./assets/images/FoodExperienceVsDelivery.png'),
                require('./assets/images/IMG_4345.jpg'),

                require('./assets/images/chris-fuller--FVz4FcYNiI-unsplash.jpg'),
                require('./assets/images/eric-ward-LsPw-DE_GAs-unsplash.jpg'),
                require('./assets/images/kayleigh-harrington-yhn4okt6ci0-unsplash.jpg'),
                require('./assets/images/jeff-siepman-kyuPjZudBKs-unsplash.jpg'),
                require('./assets/images/shangyou-shi-VdRex9AiBOc-unsplash.jpg'),
                require('./assets/images/mathias-adam-JKHUw0Xujf8-unsplash.jpg'),
                require('./assets/images/jorgen-haland-8UE83jPlNXg-unsplash.jpg'),

                // Roadmap
                require('./assets/images/RoadmapIntersectDesktop.png'),
                require('./assets/images/RoadmapIntersectMobile.png'),
                require('./assets/images/RoadmapMarketSize.png'),

                // Videos
                require('./assets/videos/RPReplay_Final1568167446.mp4'),
                require('./assets/videos/RPReplay_Final1568168051.mp4'),
                require('./assets/videos/RPReplay_Final1568170295.mp4'),
                require('./assets/videos/RPReplay_Final1568171159.mp4'),
            ]),

            Font.loadAsync({
                ...Icon.Feather.font,
                'neuzeit-s': require('./assets/fonts/NeuzeitS-Book.ttf'),
                'neuzeit-s-bold': require('./assets/fonts/NeuzeitS-Heavy.ttf'),
                'mattone-regular': require('./assets/fonts/Mattone-Regular.otf'),
                'texgyre-regular': require('./assets/fonts/texgyreadventor-regular-webfont.ttf'),
                'texgyre-bold': require('./assets/fonts/texgyreadventor-bold-webfont.ttf'),
            }),
        ])
    }

    _handleLoadingError = error => {
        // In this case, you might want to report the error to your error
        // reporting service, for example Sentry
        console.warn(error)
    }

    _handleFinishLoading = () => {
        this.setState({ isLoadingComplete: true })
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        backgroundColor: Colors.secondaryBackground,
    },
})
