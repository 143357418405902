import * as React from 'react'
import { View, Text, TextInput } from 'react-native'
import {
    CardElement,
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement,
    PostalCodeElement,
    PaymentRequestButtonElement,
    IbanElement,
    IdealBankElement,
    StripeProvider,
    Elements,
    injectStripe,
} from 'react-stripe-elements'

import { Colors, TextStyles } from '../constants'
import Button from './Button'

const handleBlur = () => {
    console.log('[blur]')
}
const handleChange = change => {
    console.log('[change]', change)
}
const handleClick = () => {
    console.log('[click]')
}
const handleFocus = () => {
    console.log('[focus]')
}
const handleReady = () => {
    console.log('[ready]')
}

const createOptions = (fontSize, padding) => {
    return {
        style: {
            base: {
                fontSize,
                color: '#424770',
                letterSpacing: '0.025em',
                fontFamily: 'Source Code Pro, monospace',
                '::placeholder': {
                    color: '#aab7c4',
                },
                padding,
            },
            invalid: {
                color: '#9e2146',
            },
        },
    }
}

class CheckoutForm extends React.Component {
    state = {
        complete: false,
    }

    async submit(ev) {
        let { token } = await this.props.stripe.createToken({ name: 'Name' })
        let response = await fetch('/charge', {
            method: 'POST',
            headers: { 'Content-Type': 'text/plain' },
            body: token.id,
        })

        if (response.ok) console.log('Purchase Complete!')
    }

    // render() {
    //     if (this.state.complete)
    //         return (
    //             <View style={{ flex: 1 }}>
    //                 <Text style={TextStyles.Heading2}>Purchase Complete</Text>
    //             </View>
    //         )

    //     return (
    //         <View style={{ flex: 1 }} title="checkout">
    //             <TextInput onSubmit={() => {}}>
    //                 <CardElement />
    //                 <Button onClick={this.submit} title="Purchase" />
    //             </TextInput>
    //         </View>
    //     )
    // }

    handleSubmit = ev => {
        ev.preventDefault()
        if (this.props.stripe) {
            this.props.stripe
                .createToken()
                .then(payload => console.log('[token]', payload))
        } else {
            console.log("Stripe.js hasn't loaded yet.")
        }
    }
    render() {
        return (
            <View style={{ flex: 1 }}>
                {/* <CardElement value={{}} /> */}

                {/* <Button onClick={() => {}} title="Purchase" /> */}

                <Elements>
                    <CardNumberElement />
                </Elements>

                {/* <PostalCodeElement /> */}
                {/* <PaymentRequestButtonElement /> */}
            </View>
        )
    }
}

export default injectStripe(CheckoutForm)
