import React from 'react'
import {
    KeyboardAvoidingView,
    LayoutAnimation,
    Platform,
    Picker,
    ScrollView,
    StyleSheet,
    StatusBar,
    Text,
    TextInput,
    ImageBackground,
    View,
} from 'react-native'
import { SafeAreaView } from 'react-navigation'
import { Feather } from '@expo/vector-icons'
import { Colors, Icons, TextStyles, Layout } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import FormHeaderTitle from '../../components/FormHeaderTitle'
import FormFooterOptions from '../../components/FormFooterOptions'
import FooterSwitch from '../../components/FooterSwitch'
import Button from '../../components/Button'
import StudioFooter from '../../components/StudioFooter'

export default class StudioContact extends React.Component {
    componentDidMount() {
        this.props.navigation.setParams({ active: true })
    }

    onScroll = async event => {
        const pageYOffset = await event.nativeEvent.contentOffset.y

        if (pageYOffset >= 88) {
            this.props.navigation.setParams({ active: true })
        } else {
            this.props.navigation.setParams({ active: false })
        }
    }

    renderInputMessage = ({
        contactMessage,
        textColor,
        containerColor,
        sectionTitle,
    }) => {
        return (
            <View
                style={{
                    paddingVertical: Layout.screenPaddingSmall,
                    marginBottom: Layout.screenPaddingSmall,
                }}
            >
                <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Overline2,
                        {
                            flex: 1,
                            paddingBottom: 16,
                            color: Colors.tintColor,
                            alignSelf: 'flex-start',
                        },
                    ]}
                >
                    {sectionTitle.toUpperCase()}
                </Text>
                <View
                    style={{
                        //flex: 1,
                        padding: Layout.screenPaddingMedium,
                        borderWidth: 1,
                        borderColor: Colors.border,
                        //borderRadius: Layout.borderRadiusMedium,
                        backgroundColor: containerColor,
                        // shadowColor: Colors.shadowColor,
                        // shadowOffset: Colors.shadowOffsetDepth,
                        // shadowOpacity: Colors.shadowOpacityDepth,
                        // shadowRadius: Colors.shadowRadiusDepth,
                    }}
                >
                    <TextInput
                        multiline
                        numberOfLines={6}
                        keyboardAppearance={'dark'}
                        style={[
                            TextStyles.Paragraph,
                            {
                                flex: 1,
                                color: textColor,
                                lineHeight: 26,
                                outlineWidth: 0,
                            },
                        ]}
                        value={contactMessage}
                        placeholder="Coffee, lunch or drinks..."
                        placeholderTextColor={Colors.disabled}
                        selectionColor={Colors.accentDeepsea}
                        onChangeText={text => {
                            this.context.setContactMessage(text)
                        }}
                        // onFocus={() => {}}
                        // onEndEditing={text => {
                        //     this.context.validateFullName(text)
                        // }}
                        maxLength={1000}
                        scrollEnabled={false}
                        keyboardType="default"
                        //autoCapitalize="words"
                        returnKeyType="return"
                        blurOnSubmit={true}
                        autoCorrect={false}
                        autoFocus={false}
                    />
                    <Text
                        style={[
                            TextStyles.Caption,
                            {
                                paddingTop: Layout.screenPaddingMedium,
                                alignSelf: 'flex-end',
                                color:
                                    contactMessage.length <= 0
                                        ? Colors.disabled
                                        : Colors.secondaryText,
                            },
                        ]}
                    >
                        {contactMessage.length}/1000
                    </Text>

                    {/* <Feather
                        style={{
                            alignSelf: 'center',
                        }}
                        color={
                            contactMessage.length
                                ? Colors.accentDeepsea
                                : Colors.transparentBackground
                        }
                        size={Icons.small}
                        name={'check'}
                    /> */}
                </View>
                {/* <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Overline2,
                        {
                            flex: 1,
                            paddingVertical: 16,
                            color: Colors.aesop,
                            alignSelf: 'flex-end',
                        },
                    ]}
                >
                    {sectionTitle.toUpperCase()}
                </Text> */}
            </View>
        )
    }

    renderMessage = ({ message, textColor, containerColor, sectionTitle }) => {
        return (
            <View
                style={{
                    paddingVertical: Layout.screenPaddingSmall,
                    marginBottom: Layout.screenPaddingLarge,
                }}
            >
                <View
                    style={{
                        padding: Layout.screenPaddingMedium,
                        borderRadius: Layout.borderRadiusMedium,
                        backgroundColor: containerColor,
                    }}
                >
                    <Text
                        style={[
                            TextStyles.Paragraph,
                            {
                                color: textColor,
                                lineHeight: 26,
                            },
                        ]}
                    >
                        {message}
                    </Text>
                </View>
                <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Overline2,
                        {
                            flex: 1,
                            paddingTop: 16,
                            color: Colors.disabled,
                        },
                    ]}
                >
                    {sectionTitle.toUpperCase()}
                </Text>
            </View>
        )
    }

    renderFooterSwitch = ({
        footerSwitchValue,
        footerSwitchTitle,
        footerRouteTitle,
    }) => {
        return (
            <View
                style={{
                    paddingVertical: Layout.screenPaddingLarge,
                }}
            >
                <FooterSwitch
                    //footerSwitchTitleStyle={{ color: Colors.aesop }}
                    footerSwitchValue={footerSwitchValue}
                    footerSwitchTitle={footerSwitchTitle}
                    footerRouteTitle={footerRouteTitle}
                    footerSwitchOnValueChange={() => {
                        this.context.setRequestDemo()
                    }}
                />
                {this.renderSeparator()}
            </View>
        )
    }

    renderSeparator(isFocused) {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: !isFocused
                        ? Colors.border
                        : Colors.accentDeepsea,
                    paddingTop: 16,
                }}
            />
        )
    }

    render() {
        // LayoutAnimation.easeInEaseOut()

        return (
            <UserProfileConsumer>
                {context => {
                    const splitName = context.fullName.split(' ')
                    const firstName = splitName[0]

                    return (
                        <View
                            style={{
                                flex: 1,
                            }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <KeyboardAvoidingView
                                behavior={
                                    Platform.OS == 'ios' ? 'padding' : null
                                }
                                keyboardVerticalOffset={
                                    Layout.TAB_DEFAULT_HEIGHT + 8
                                }
                                style={{
                                    flex: 1,
                                }}
                            >
                                <ScrollView
                                    // onScroll={event => this.onScroll(event)}
                                    // scrollEventThrottle={16}
                                    style={{
                                        flex: 1,
                                        backgroundColor: Colors.gray0,
                                        paddingTop: Layout.isMediumDevice
                                            ? Layout.topNavHeightMobile
                                            : Layout.topNavHeightDesktop,
                                    }}
                                >
                                    <View style={[styles.sectionContainer]}>
                                        <View style={styles.bodyContent}>
                                            <FormHeaderTitle
                                                headerTitle={'Contact us'}
                                                headerTitleStyle={[
                                                    Layout.isMediumDevice
                                                        ? TextStyles.Heading2
                                                        : TextStyles.Heading1,
                                                ]}
                                            />
                                            <Text
                                                style={[
                                                    TextStyles.Paragraph,
                                                    {
                                                        paddingBottom: 36,
                                                        lineHeight: 26,
                                                    },
                                                ]}
                                            >
                                                Hello {firstName}, let us know
                                                how we can help you today.
                                            </Text>
                                            {/* {this.renderMessage({
                                                sectionTitle: 'Indvstry Team',
                                                message:
                                                    'Hello' +
                                                    firstName +
                                                    ', let us know how we can help you today.',
                                                textColor: Colors.tintColor,
                                                containerColor: Colors.aesop,
                                            })} */}
                                            {this.renderInputMessage({
                                                sectionTitle: 'Your message',
                                                contactMessage:
                                                    context.contactMessage,
                                                textColor: Colors.tintColor,
                                                containerColor: Colors.white,
                                            })}
                                            {/* {this.renderMessage({
                                                sectionTitle: 'Indvstry Team',
                                                message:
                                                    'Thanks for sending us a note. We’ll be in touch with you soon.',
                                                textColor: Colors.tintColor,
                                                containerColor: Colors.aesop,
                                            })} */}

                                            {/* {this.renderSeparator()} */}

                                            {/* {this.renderFooterSwitch({
                                                footerSwitchValue:
                                                    context.requestDemo,
                                                // footerSwitchOnValueChange:
                                                //     'requestExplorer',
                                                footerSwitchTitle:
                                                    'I am interested in Early Access or a product demo',
                                                footerRouteTitle: null,
                                            })} */}

                                            {Layout.isMediumDevice ? (
                                                <View />
                                            ) : (
                                                <Button
                                                    iconRight={true}
                                                    iconName="arrow-right"
                                                    iconColor={Colors.white}
                                                    style={{
                                                        alignSelf: 'center',
                                                        // paddingBottom:
                                                        //     Layout.screenPaddingSmall,
                                                    }}
                                                    textStyle={[
                                                        TextStyles.Button,
                                                        {
                                                            color: Colors.white,
                                                        },
                                                    ]}
                                                    buttonStyle={{
                                                        backgroundColor:
                                                            Colors.tintColor,
                                                        //borderWidth: 1,
                                                        //borderColor: Colors.tintColor,
                                                    }}
                                                    title={'Send message'}
                                                    disabled={
                                                        !context.contactMessage
                                                            .length
                                                    }
                                                    onPress={() => {
                                                        this.context.onSubmitContactForm()
                                                        this.props.navigation.navigate(
                                                            'RequestConfirmation'
                                                        )
                                                    }}
                                                />
                                            )}
                                        </View>
                                        {/* <Text
                                            style={[
                                                TextStyles.Heading4,
                                                {
                                                    paddingBottom: 36,
                                                    lineHeight: 26,
                                                },
                                            ]}
                                        >
                                            info@indvstry.io
                                        </Text> */}
                                    </View>
                                    <ImageBackground
                                        style={[
                                            styles.sectionContainer,
                                            {
                                                height:
                                                    Layout.SCREEN_HEIGHT * 0.5,
                                            },
                                        ]}
                                        source={require('../../assets/images/olivier-miche-iIg4F2IWbTM-unsplash.jpg')}
                                        resizeMode={'cover'}
                                    />
                                    <StudioFooter
                                        navigation={this.props.navigation}
                                    />
                                </ScrollView>

                                {/* <FormFooterOptions
                                    displayRightButton={true}
                                    navigation={this.props.navigation}
                                    loading={context.loading}
                                    displayLeftButton={true}
                                    leftButtonTitle={'Close'}
                                    onLeftButtonPress={() => {
                                        //this.context.resetUserForms()
                                        this.props.navigation.goBack()
                                    }}
                                    rightButtonTitle={'Send'}
                                    onRightButtonPress={() => {
                                        this.context.onSubmitContactForm()
                                        this.props.navigation.navigate(
                                            'RequestConfirmation'
                                        )
                                    }}
                                    rightButtonDisabled={
                                        !context.contactMessage.length
                                    }
                                /> */}

                                {Layout.isMediumDevice ? (
                                    <FormFooterOptions
                                        displayRightButton={true}
                                        navigation={this.props.navigation}
                                        loading={context.loading}
                                        displayLeftButton={true}
                                        leftButtonTitle={'Close'}
                                        onLeftButtonPress={() => {
                                            this.context.resetUserForms()
                                            this.props.navigation.goBack()
                                        }}
                                        rightButtonTitle={'Send'}
                                        onRightButtonPress={() => {
                                            this.context.onSubmitContactForm()
                                            this.props.navigation.navigate(
                                                'RequestConfirmation'
                                            )
                                        }}
                                        rightButtonDisabled={
                                            !context.contactMessage.length
                                        }
                                    />
                                ) : (
                                    <View />
                                )}
                            </KeyboardAvoidingView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        alignItems: 'center',
        paddingVertical: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        paddingVertical: Layout.screenPaddingLarge,
        paddingHorizontal: Layout.screenPaddingLarge,
    },
})
