import React from 'react'
import { ActivityIndicator, StyleSheet, View } from 'react-native'
import { Colors, Icons, TextStyles } from '../constants'
import { Feather } from '@expo/vector-icons'

export default class BadgeIcon extends React.Component {
    render() {
        let style = [styles.container]
        if (this.props.disabled) {
            style.push(styles.disabledIconContainer)
        }
        return (
            <View style={[styles.container, this.props.style]}>
                {this.props.loading ? (
                    <ActivityIndicator
                        size="small"
                        color={this.props.activityColor}
                    />
                ) : (
                    <View style={styles.eightPointBurst}>
                        <View style={styles.iconContainer}>
                            <Feather
                                name={this.props.iconName}
                                size={this.props.iconSize}
                                color={this.props.iconColor}
                                style={this.props.iconStyle}
                            />
                        </View>
                        <View
                            style={[
                                this.props.burstBackgroundColor,
                                styles.eightPointBurst20,
                            ]}
                        />
                        <View
                            style={[
                                this.props.burstBackgroundColor,
                                styles.eightPointBurst155,
                            ]}
                        />
                    </View>
                )}
            </View>
        )
    }
}

// const EightPointBurst = React.createClass({
//     render: function() {
//         return (
//             <View style={styles.eightPointBurst}>
//                 <View style={styles.eightPointBurst20} />
//                 <View style={styles.eightPointBurst155} />
//             </View>
//         )
//     },
// })

// eightPointBurst: {},
// eightPointBurst20: {
//   width: 80,
//   height: 80,
//   backgroundColor: 'red',
//   transform: [
//     {rotate: '20deg'}
//   ]
// },
// eightPointBurst155: {
//   width: 80,
//   height: 80,
//   position: 'absolute',
//   backgroundColor: 'red',
//   top: 0,
//   left: 0,
//   transform: [
//     {rotate: '155deg'}
//   ]
// },

const styles = StyleSheet.create({
    disabledIconContainer: {
        backgroundColor: Colors.disabled,
    },

    container: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 100,
        height: 24,
        width: 24,
        //paddingVertical: 4,
        //paddingHorizontal: 4,
        //backgroundColor: Colors.gray2,
    },

    iconContainer: {
        width: 16,
        height: 16,
        position: 'absolute',
        top: 0,
        left: 0,
        zIndex: 10,
    },

    eightPointBurst: {},
    eightPointBurst20: {
        borderRadius: 2,
        width: 16,
        height: 16,
        //backgroundColor: 'red',
        transform: [{ rotate: '20deg' }],
    },
    eightPointBurst155: {
        borderRadius: 2,
        width: 16,
        height: 16,
        position: 'absolute',
        //backgroundColor: 'red',
        top: 0,
        left: 0,
        transform: [{ rotate: '160deg' }],
    },
})
