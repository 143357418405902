import React from 'react'
import { StyleSheet, Text, TextInput, View } from 'react-native'
import { Feather } from '@expo/vector-icons'
import { Colors, Icons, TextStyles, Layout } from '../constants'
import { UserProfileConsumer } from '../context/UserProfileContext'
import FormHeaderTitle from './FormHeaderTitle'
import Button from './Button'

export default class UserSignUp extends React.Component {
    componentDidMount = async () => {
        await this.context.resetUserForms()
    }

    renderInputEmail = ({ sectionTitle, email, emailValid, editEmail }) => {
        return (
            <View
                style={{
                    //flex: 1,
                    paddingBottom: 48,
                }}
            >
                <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Overline2,
                        {
                            flex: 1,
                            paddingBottom: 16,
                        },
                    ]}
                >
                    {sectionTitle.toUpperCase()}
                </Text>

                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <TextInput
                        editable={editEmail}
                        keyboardAppearance={'dark'}
                        style={[
                            TextStyles.Paragraph,
                            {
                                flex: 1,
                                height: 48,
                                color: Colors.primaryText,
                                outlineWidth: 0,
                            },
                        ]}
                        value={email}
                        placeholder="email@example.com"
                        placeholderTextColor={Colors.disabled}
                        selectionColor={Colors.accentDeepsea}
                        onChangeText={async text => {
                            await this.context.setEmail(text)
                            this.context.validateEmail(text)
                        }}
                        onFocus={() => {}}
                        onEndEditing={text => {
                            this.context.validateEmail(text)
                        }}
                        maxLength={64}
                        keyboardType="email-address"
                        autoCapitalize="none"
                        returnKeyType="next"
                        blurOnSubmit={true}
                        autoCorrect={false}
                    />
                    <Feather
                        style={{
                            alignSelf: 'center',
                        }}
                        color={
                            email.length && emailValid
                                ? Colors.accentDeepsea
                                : Colors.transparentBackground
                        }
                        size={Icons.small}
                        name={'check'}
                    />
                </View>
                {this.renderSeparator()}
            </View>
        )
    }

    renderInputPassword = ({
        sectionTitle,
        password,
        passwordValid,
        secureEntry,
        tokenExists,
    }) => {
        return (
            <View
                style={{
                    //flex: 1,
                    paddingBottom: 48,
                }}
            >
                <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Overline2,
                        {
                            flex: 1,
                            paddingBottom: 16,
                        },
                    ]}
                >
                    {sectionTitle.toUpperCase()}
                </Text>

                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <TextInput
                        keyboardAppearance={'dark'}
                        style={[
                            TextStyles.Paragraph,
                            {
                                flex: 1,
                                height: 48,
                                color: Colors.primaryText,
                                outlineWidth: 0,
                            },
                        ]}
                        value={password}
                        placeholder="Password"
                        placeholderTextColor={Colors.disabled}
                        selectionColor={Colors.accentDeepsea}
                        onChangeText={async text => {
                            await this.context.setPassword(text)
                            this.context.validatePassword(text)
                        }}
                        //onFocus={() => this.context.setTextInputFocused()}
                        onEndEditing={text => {
                            this.context.validatePassword(text)
                            //this.context.validatePasswordForm()
                        }}
                        // onSubmitEditing={text => {
                        //     this.context.validatePassword(text)
                        // }}
                        maxLength={24}
                        textContentType="password"
                        secureTextEntry={secureEntry}
                        autoCapitalize="none"
                        autoCorrect={false}
                        returnKeyType="next"
                        blurOnSubmit={true}
                    />
                    {tokenExists ? (
                        <Feather
                            style={{
                                alignSelf: 'center',
                            }}
                            color={
                                passwordValid
                                    ? Colors.accentDeepsea
                                    : password.length
                                    ? Colors.accentRed
                                    : 'transparent'
                            }
                            size={Icons.small}
                            //name={'check'}
                            name={
                                passwordValid
                                    ? 'check'
                                    : password.length
                                    ? 'x'
                                    : 'check'
                            }
                        />
                    ) : (
                        <Feather
                            style={{
                                alignSelf: 'center',
                            }}
                            color={
                                passwordValid
                                    ? Colors.accentDeepsea
                                    : 'transparent'
                            }
                            size={Icons.small}
                            name={'check'}
                        />
                    )}
                </View>

                {this.renderSeparator()}
                {tokenExists ? (
                    <Text
                        style={[
                            TextStyles.Caption,
                            {
                                flex: 1,
                                paddingTop: 16,
                                lineHeight: 26,
                                color: passwordValid
                                    ? Colors.secondaryText
                                    : password.length
                                    ? Colors.accentRed
                                    : Colors.secondaryText,
                            },
                        ]}
                    >
                        Your password must include a symbol, a number and be 8
                        or more characters long.
                    </Text>
                ) : (
                    <View />
                )}

                <Button
                    iconLeft={true}
                    iconName={secureEntry ? 'eye' : 'eye-off'}
                    iconColor={Colors.tintColor}
                    style={{
                        paddingTop: 16,
                        alignItems: 'flex-start',
                    }}
                    textStyle={[
                        TextStyles.Button,
                        {
                            color: Colors.tintColor,
                        },
                    ]}
                    buttonStyle={{
                        backgroundColor: Colors.transparentBackground,
                        borderWidth: 1,
                        borderColor: Colors.tintColor,
                        paddingVertical: 8,
                        paddingHorizontal: 6,
                    }}
                    title={secureEntry ? 'Show' : 'Hide'}
                    onPress={() => this.context.setSecureEntry()}
                />
            </View>
        )
    }

    renderSeparator() {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                    paddingTop: 16,
                }}
            />
        )
    }

    render() {
        // LayoutAnimation.easeInEaseOut()

        return (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View
                            style={styles.container}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <FormHeaderTitle
                                headerTitle={
                                    context.tokenExists ? 'Sign up' : 'Log in'
                                }
                                headerTitleStyle={[TextStyles.Heading2]}
                            />
                            <View
                                style={{
                                    flex: 1,
                                }}
                            >
                                {context.tokenExists ? (
                                    context.studioClient ? (
                                        <Text
                                            style={[
                                                TextStyles.Paragraph,
                                                {
                                                    paddingBottom: 36,
                                                    lineHeight: 26,
                                                },
                                            ]}
                                        >
                                            {context.fullName
                                                ? 'Welcome ' +
                                                  context.fullName +
                                                  '! '
                                                : ''}
                                            You are invited to learn more about
                                            our brand.
                                        </Text>
                                    ) : (
                                        <Text
                                            style={[
                                                TextStyles.Paragraph,
                                                {
                                                    paddingBottom: 36,
                                                    lineHeight: 26,
                                                },
                                            ]}
                                        >
                                            {context.fullName
                                                ? 'Welcome ' +
                                                  context.fullName +
                                                  '! '
                                                : ''}
                                            You are invited to join early access
                                            to our product.
                                        </Text>
                                    )
                                ) : (
                                    <Text
                                        style={[
                                            TextStyles.Paragraph,
                                            {
                                                paddingBottom: 36,
                                                lineHeight: 26,
                                            },
                                        ]}
                                    >
                                        {/* {context.fullName
                                            ? 'Welcome back ' +
                                              context.fullName +
                                              '. '
                                            : ''} */}
                                        Log in with your email and password.
                                    </Text>
                                )}
                                {this.renderInputEmail({
                                    sectionTitle: 'Email',
                                    email: context.email,
                                    emailValid: context.emailValid,
                                    editEmail: true,
                                    // context.tokenExists,
                                    //editEmail: !context.tokenExists,
                                })}
                                {this.renderInputPassword({
                                    sectionTitle: context.tokenExists
                                        ? 'Create password'
                                        : 'Password',
                                    password: context.password,
                                    passwordValid: context.passwordValid,
                                    secureEntry: context.secureEntry,
                                    tokenExists: context.tokenExists,
                                })}
                            </View>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
        paddingVertical: Layout.screenPaddingXL,
        paddingHorizontal: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
    },
})
