import React from 'react'
import {
    KeyboardAvoidingView,
    LayoutAnimation,
    Platform,
    Picker,
    ScrollView,
    StyleSheet,
    StatusBar,
    Text,
    TextInput,
    TouchableOpacity,
    View,
} from 'react-native'
import { Feather } from '@expo/vector-icons'
import { Colors, Icons, TextStyles, Layout } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import FormHeaderTitle from '../../components/FormHeaderTitle'
import FormFooterOptions from '../../components/FormFooterOptions'
import FooterSwitch from '../../components/FooterSwitch'
import Button from '../../components/Button'
import Footer from '../../components/Footer'

export default class RequestInfo extends React.Component {
    componentDidMount() {
        this.props.navigation.setParams({ active: true })
    }

    // onScroll = event => {
    //     const pageYOffset = event.nativeEvent.contentOffset.y || null

    //     if (pageYOffset >= 88) {
    //         this.props.navigation.setParams({ active: true })
    //     } else {
    //         this.props.navigation.setParams({ active: true })
    //     }
    // }

    renderInputFullName = ({
        sectionTitle,
        fullName,
        fullNameValid,
        fullNameCurrent,
    }) => {
        return (
            <View
                style={{
                    //flex: 1,
                    paddingBottom: 48,
                }}
            >
                <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Overline2,
                        {
                            flex: 1,
                            paddingBottom: 16,
                        },
                    ]}
                >
                    {sectionTitle.toUpperCase()}
                </Text>

                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <TextInput
                        keyboardAppearance={'dark'}
                        style={[
                            TextStyles.Paragraph,
                            {
                                flex: 1,
                                height: 48,
                                color: Colors.primaryText,
                                outlineWidth: 0,
                            },
                        ]}
                        value={fullName}
                        placeholder="Full name"
                        placeholderTextColor={Colors.disabled}
                        selectionColor={Colors.accentDeepsea}
                        onChangeText={async text => {
                            await this.context.setFullName(text)
                            this.context.validateFullName(text)
                        }}
                        onFocus={() => {}}
                        onEndEditing={text => {
                            this.context.validateFullName(text)
                        }}
                        maxLength={56}
                        keyboardType="default"
                        autoCapitalize="words"
                        returnKeyType="next"
                        blurOnSubmit={true}
                        autoCorrect={false}
                        autoFocus={false}
                    />
                    <Feather
                        style={{
                            alignSelf: 'center',
                        }}
                        color={
                            fullName.length && fullNameValid
                                ? Colors.accentDeepsea
                                : Colors.transparentBackground
                        }
                        size={Icons.small}
                        name={'check'}
                    />
                </View>
                {this.renderSeparator()}
            </View>
        )
    }

    renderInputEmail = ({ sectionTitle, emailNew, emailNewValid }) => {
        return (
            <View
                style={{
                    //flex: 1,
                    paddingBottom: 48,
                }}
            >
                <Text
                    numberOfLines={1}
                    style={[
                        TextStyles.Overline2,
                        {
                            flex: 1,
                            paddingBottom: 16,
                        },
                    ]}
                >
                    {sectionTitle.toUpperCase()}
                </Text>

                <View
                    style={{
                        flex: 1,
                        flexDirection: 'row',
                        alignItems: 'center',
                    }}
                >
                    <TextInput
                        keyboardAppearance={'dark'}
                        style={[
                            TextStyles.Paragraph,
                            {
                                flex: 1,
                                height: 48,
                                color: Colors.primaryText,
                                outlineWidth: 0,
                            },
                        ]}
                        value={emailNew}
                        placeholder="email@example.com"
                        placeholderTextColor={Colors.disabled}
                        selectionColor={Colors.accentDeepsea}
                        onChangeText={async text => {
                            await this.context.setEmailNew(text)
                            this.context.validateEmailNew(text)
                        }}
                        onFocus={() => {}}
                        onEndEditing={text => {
                            this.context.validateEmailNew(text)
                        }}
                        maxLength={64}
                        keyboardType="email-address"
                        autoCapitalize="none"
                        returnKeyType="next"
                        blurOnSubmit={true}
                        autoCorrect={false}
                    />
                    <Feather
                        style={{
                            alignSelf: 'center',
                        }}
                        color={
                            emailNew.length && emailNewValid
                                ? Colors.accentDeepsea
                                : Colors.transparentBackground
                        }
                        size={Icons.small}
                        name={'check'}
                    />
                </View>
                {this.renderSeparator()}
            </View>
        )
    }

    renderFooterSwitch = ({
        sectionTitle,
        footerSwitchValue,
        footerSwitchTitle,
        footerRouteTitle,
        footerSwitchOnValueChange,
    }) => {
        //const onSwitch = this.context.setRequestCreator

        return (
            <View
                style={{
                    paddingBottom: 48,
                }}
            >
                {sectionTitle ? (
                    <Text
                        //numberOfLines={1}
                        style={[
                            TextStyles.Overline2,
                            {
                                flex: 1,
                                paddingBottom: 32,
                                lineHeight: 26,
                            },
                        ]}
                    >
                        {sectionTitle.toUpperCase()}
                    </Text>
                ) : (
                    <View />
                )}
                <FooterSwitch
                    footerSwitchValue={footerSwitchValue}
                    footerSwitchTitle={footerSwitchTitle}
                    footerRouteTitle={footerRouteTitle}
                    footerSwitchOnValueChange={() => {
                        if (footerSwitchOnValueChange === 'requestCreator') {
                            this.context.setRequestCreator()
                        } else if (
                            footerSwitchOnValueChange === 'requestExplorer'
                        ) {
                            this.context.setRequestExplorer()
                        } else if (
                            footerSwitchOnValueChange === 'requestInvestor'
                        ) {
                            this.context.setRequestInvestor()
                        }
                    }}
                />
                {this.renderSeparator()}
            </View>
        )
    }

    renderSeparator(isFocused) {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: !isFocused
                        ? Colors.border
                        : Colors.accentDeepsea,
                    paddingTop: 16,
                }}
            />
        )
    }

    render() {
        // LayoutAnimation.easeInEaseOut()

        return (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View
                            style={{
                                flex: 1,
                            }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <KeyboardAvoidingView
                                behavior={
                                    Platform.OS == 'ios' ? 'padding' : null
                                }
                                keyboardVerticalOffset={
                                    Layout.TAB_DEFAULT_HEIGHT + 8
                                }
                                style={{
                                    flex: 1,
                                }}
                            >
                                <ScrollView
                                    style={{
                                        flex: 1,
                                        paddingTop: Layout.isMediumDevice
                                            ? Layout.topNavHeightMobile
                                            : Layout.topNavHeightDesktop,
                                    }}
                                >
                                    <View style={styles.sectionContainer}>
                                        <View style={styles.bodyContent}>
                                            <FormHeaderTitle
                                                headerTitle={'Request a tour'}
                                                headerTitleStyle={[
                                                    Layout.isMediumDevice
                                                        ? TextStyles.Heading2
                                                        : TextStyles.Heading1,
                                                ]}
                                            />
                                            <Text
                                                style={[
                                                    TextStyles.Paragraph,
                                                    {
                                                        paddingBottom: 36,
                                                        lineHeight: 26,
                                                    },
                                                ]}
                                            >
                                                Craft events, tell your story
                                                and connect with food lovers of
                                                all stripes.
                                            </Text>
                                            {this.renderInputFullName({
                                                sectionTitle: 'Your name',
                                                fullName: context.fullName,
                                                fullNameValid:
                                                    context.fullNameValid,
                                            })}
                                            {this.renderInputEmail({
                                                sectionTitle: 'Email',
                                                emailNew: context.emailNew,
                                                emailNewValid:
                                                    context.emailNewValid,
                                            })}
                                            {this.renderFooterSwitch({
                                                sectionTitle:
                                                    'Which best describes your interest in Indvsty? Please select one.',
                                                footerSwitchOnValueChange:
                                                    'requestCreator',
                                                footerSwitchValue:
                                                    context.requestCreator,
                                                footerSwitchTitle:
                                                    'I’m a Food & Hospitality professional who wants to broaden my digital reach.',
                                                footerRouteTitle: null,
                                            })}

                                            {this.renderFooterSwitch({
                                                footerSwitchValue:
                                                    context.requestExplorer,
                                                footerSwitchOnValueChange:
                                                    'requestExplorer',
                                                footerSwitchTitle:
                                                    'I‘m a foodie interested in new culinary events and experiences.',
                                                footerRouteTitle: null,
                                            })}

                                            {this.renderFooterSwitch({
                                                footerSwitchValue:
                                                    context.requestInvestor,
                                                footerSwitchOnValueChange:
                                                    'requestInvestor',
                                                footerSwitchTitle:
                                                    'I‘d like to help Indvstry grow and scale the business.',
                                                footerRouteTitle: null,
                                            })}
                                            {Layout.isMediumDevice ? (
                                                <View />
                                            ) : (
                                                <Button
                                                    iconRight={true}
                                                    iconName="arrow-right"
                                                    iconColor={Colors.white}
                                                    style={{
                                                        alignSelf: 'center',

                                                        paddingBottom:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                    textStyle={[
                                                        TextStyles.Button,
                                                        {
                                                            color: Colors.white,
                                                        },
                                                    ]}
                                                    buttonStyle={{
                                                        backgroundColor:
                                                            Colors.tintColor,
                                                        //borderWidth: 1,
                                                        //borderColor: Colors.tintColor,
                                                    }}
                                                    title={'Submit request'}
                                                    disabled={
                                                        !context.emailNewValid ||
                                                        !context.fullNameValid ||
                                                        !context.requestFormValid
                                                    }
                                                    onPress={() => {
                                                        this.context.onSubmitRequestForm()
                                                        this.props.navigation.navigate(
                                                            'RequestConfirmation'
                                                        )
                                                    }}
                                                />
                                            )}
                                        </View>
                                    </View>
                                    <Footer
                                        navigation={this.props.navigation}
                                    />
                                </ScrollView>
                                {Layout.isMediumDevice ? (
                                    <FormFooterOptions
                                        displayRightButton={true}
                                        navigation={this.props.navigation}
                                        loading={context.loading}
                                        displayLeftButton={true}
                                        leftButtonTitle={'Close'}
                                        onLeftButtonPress={() => {
                                            this.context.resetUserForms()
                                            this.props.navigation.goBack()
                                        }}
                                        rightButtonTitle={'Submit'}
                                        onRightButtonPress={() => {
                                            this.context.onSubmitRequestForm()
                                            this.props.navigation.navigate(
                                                'RequestConfirmation'
                                            )
                                        }}
                                        rightButtonDisabled={
                                            !context.emailNewValid ||
                                            !context.fullNameValid ||
                                            !context.requestFormValid
                                        }
                                    />
                                ) : (
                                    <View />
                                )}
                            </KeyboardAvoidingView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        alignItems: 'center',
        paddingVertical: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
        backgroundColor: Colors.gray0,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        paddingVertical: Layout.screenPaddingLarge,
        paddingHorizontal: Layout.screenPaddingLarge,
    },
})
