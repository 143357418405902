import React from 'react'
import { Image, StyleSheet, Text, View } from 'react-native'
import { Colors, TextStyles, Layout, Icons } from '../constants'
import FormHeaderTitle from './FormHeaderTitle'

export default class ProductAttributes extends React.Component {
    renderRule = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 2,
                    borderBottomColor: Colors.tintColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSeparator = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    render() {
        return (
            <View
                style={[
                    styles.sectionContainer,
                    {
                        backgroundColor: Colors.gray2,
                        paddingVertical: Layout.isMediumDevice
                            ? Layout.screenPaddingLarge
                            : Layout.screenPaddingXL * 2,
                    },
                ]}
            >
                <View style={styles.bodyContent}>
                    <FormHeaderTitle
                        headerTitle={'Pioneering the future'}
                        headerTitleStyle={[TextStyles.Heading1]}
                    />

                    <View
                        style={{
                            flex: 1,
                            maxWidth: 720,
                            minWidth: Layout.isMediumDevice ? '100%' : 480,
                            paddingRight: Layout.isMediumDevice
                                ? 0
                                : Layout.screenPaddingLarge,
                            paddingTop: Layout.screenPaddingLarge,
                        }}
                    >
                        <Text style={TextStyles.Heading2}>
                            Modern tools that distinguish your establishment
                            online
                        </Text>
                    </View>

                    <View
                        style={{
                            alignItems: 'flex-start',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                        }}
                    >
                        <View
                            style={{
                                flex: 1,
                                paddingRight: Layout.isMediumDevice
                                    ? 0
                                    : Layout.screenPaddingLarge,
                                paddingVertical: Layout.screenPaddingLarge,

                                minWidth: Layout.isMediumDevice ? '100%' : 360,
                            }}
                        >
                            {this.renderRule()}
                            <View
                                style={{
                                    overflow: 'hidden',
                                    backgroundColor: Colors.gray0,
                                    borderRadius: 96,
                                    alignSelf: 'flex-start',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginVertical: Layout.screenPaddingMedium,
                                    padding: Layout.screenPaddingLarge,
                                }}
                            >
                                <Image
                                    source={require('../assets/svgs/chef-gear-apron.svg')}
                                    style={{
                                        width: Icons.XL,
                                        height: Icons.XL,
                                    }}
                                    resizeMode={'cover'}
                                />
                            </View>

                            <Text
                                style={[
                                    TextStyles.Heading3,
                                    {
                                        paddingBottom:
                                            Layout.screenPaddingMedium,
                                        paddingRight: Layout.isMediumDevice
                                            ? 0
                                            : Layout.screenPaddingMedium,
                                    },
                                ]}
                            >
                                Culture comes first
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        lineHeight: 26,
                                        paddingRight: Layout.isMediumDevice
                                            ? 0
                                            : Layout.screenPaddingMedium,
                                    },
                                ]}
                            >
                                The only platform where industry professionals
                                exclusively produce video content. This gives
                                Food lovers an authoritative, authentic source
                                for access to food experiences, while getting to
                                know the people behind the dishes.
                            </Text>
                        </View>
                        <View
                            style={{
                                flex: 1,
                                paddingRight: Layout.isMediumDevice
                                    ? 0
                                    : Layout.screenPaddingLarge,
                                paddingVertical: Layout.screenPaddingLarge,
                                minWidth: Layout.isMediumDevice ? '100%' : 360,
                            }}
                        >
                            {this.renderRule()}
                            <View
                                style={{
                                    overflow: 'hidden',
                                    backgroundColor: Colors.gray0,
                                    borderRadius: 96,
                                    alignSelf: 'flex-start',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginVertical: Layout.screenPaddingMedium,
                                    padding: Layout.screenPaddingLarge,
                                }}
                            >
                                <Image
                                    source={require('../assets/svgs/restaurant-dishes.svg')}
                                    style={{
                                        width: Icons.XL,
                                        height: Icons.XL,
                                    }}
                                    resizeMode={'cover'}
                                />
                            </View>

                            <Text
                                style={[
                                    TextStyles.Heading3,
                                    {
                                        paddingBottom:
                                            Layout.screenPaddingMedium,
                                        paddingRight: Layout.isMediumDevice
                                            ? 0
                                            : Layout.screenPaddingMedium,
                                    },
                                ]}
                            >
                                Focused on Food
                            </Text>

                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        lineHeight: 26,
                                        paddingRight: Layout.isMediumDevice
                                            ? 0
                                            : Layout.screenPaddingMedium,
                                    },
                                ]}
                            >
                                An ecosystem 100% dedicated to Food &
                                Hospitality, working with top independent
                                establishments. Indvstry supports for a wide
                                variety of culinary experiences like dining at
                                restaurants, special events, pop-ups, and more.
                            </Text>
                        </View>
                    </View>
                    <View
                        style={{
                            alignItems: 'flex-start',
                            flexDirection: 'row',
                            flexWrap: 'wrap',
                        }}
                    >
                        <View
                            style={{
                                flex: 1,
                                paddingRight: Layout.isMediumDevice
                                    ? 0
                                    : Layout.screenPaddingLarge,
                                paddingVertical: Layout.screenPaddingLarge,
                                minWidth: Layout.isMediumDevice ? '100%' : 360,
                            }}
                        >
                            {this.renderRule()}
                            <View
                                style={{
                                    overflow: 'hidden',
                                    backgroundColor: Colors.gray0,
                                    borderRadius: 96,
                                    alignSelf: 'flex-start',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginVertical: Layout.screenPaddingMedium,
                                    padding: Layout.screenPaddingLarge,
                                }}
                            >
                                <Image
                                    source={require('../assets/svgs/phone-selfie-person.svg')}
                                    style={{
                                        width: Icons.XL,
                                        height: Icons.XL,
                                    }}
                                    resizeMode={'cover'}
                                />
                            </View>
                            <Text
                                style={[
                                    TextStyles.Heading3,
                                    {
                                        paddingBottom:
                                            Layout.screenPaddingMedium,
                                        paddingRight: Layout.isMediumDevice
                                            ? 0
                                            : Layout.screenPaddingMedium,
                                    },
                                ]}
                            >
                                Streamline content creation
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        lineHeight: 26,
                                        paddingRight: Layout.isMediumDevice
                                            ? 0
                                            : Layout.screenPaddingMedium,
                                    },
                                ]}
                            >
                                The premiere tool for collaboration, where team
                                stories are automatically merged. Create once on
                                Indvstry, share across your favorite channels,
                                and get measurable ROI.
                            </Text>
                        </View>
                        <View
                            style={{
                                flex: 1,
                                paddingRight: Layout.isMediumDevice
                                    ? 0
                                    : Layout.screenPaddingLarge,
                                paddingVertical: Layout.screenPaddingLarge,

                                minWidth: Layout.isMediumDevice ? '100%' : 360,
                            }}
                        >
                            {this.renderRule()}
                            <View
                                style={{
                                    overflow: 'hidden',
                                    backgroundColor: Colors.gray0,
                                    borderRadius: 96,
                                    alignSelf: 'flex-start',
                                    alignItems: 'center',
                                    justifyContent: 'center',
                                    marginVertical: Layout.screenPaddingMedium,
                                    padding: Layout.screenPaddingLarge,
                                }}
                            >
                                <Image
                                    source={require('../assets/svgs/cloud-smartphone-download.svg')}
                                    style={{
                                        width: Icons.XL,
                                        height: Icons.XL,
                                    }}
                                    resizeMode={'cover'}
                                />
                            </View>
                            <Text
                                style={[
                                    TextStyles.Heading3,
                                    {
                                        paddingBottom:
                                            Layout.screenPaddingMedium,
                                        paddingRight: Layout.isMediumDevice
                                            ? 0
                                            : Layout.screenPaddingMedium,
                                    },
                                ]}
                            >
                                Leverage Cloud technologies
                            </Text>
                            <Text
                                style={[
                                    TextStyles.Paragraph,
                                    {
                                        lineHeight: 26,
                                        paddingRight: Layout.isMediumDevice
                                            ? 0
                                            : Layout.screenPaddingMedium,
                                    },
                                ]}
                            >
                                Our enhanced tech stack is built on Google Cloud
                                and is currently integrated with Search and
                                Maps, as well as Analytics and SEO. Primed for
                                integrations with leading restaurant
                                reservations software.
                            </Text>
                        </View>
                    </View>
                </View>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        alignItems: 'center',
        backgroundColor: Colors.gray0,
        paddingVertical: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        paddingVertical: Layout.screenPaddingLarge,
        paddingHorizontal: Layout.screenPaddingLarge,
    },
})
