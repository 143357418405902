import React from 'react'
import {
    ImageBackground,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import { Linking } from 'expo'
import { Colors, TextStyles, Layout } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import FormHeaderTitle from '../../components/FormHeaderTitle'
import Button from '../../components/Button'
import Footer from '../../components/Footer'
import { DrawerActions } from 'react-navigation-drawer'

export default class AuthHomeScreen extends React.Component {
    componentDidMount = async () => {
        //await this.context.setAuthInitialState()
        this.context.initializeUserAccount()
        //window.addEventListener('scroll', this.onScroll)
    }

    componentDidUpdate = async (prevProps) => {
        if (prevProps.isFocused !== this.props.isFocused) {
            //await this.context.setAuthInitialState()
            this.context.initializeUserAccount()
            //window.removeEventListener('scroll', this.onScroll)
            //this.props.navigation.goBack()
        }
    }

    onScroll = async (event) => {
        const pageYOffset = await event.nativeEvent.contentOffset.y

        if (pageYOffset >= 88) {
            this.props.navigation.setParams({ active: true })
        } else {
            this.props.navigation.setParams({ active: false })
        }
    }

    renderSeparator() {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                }}
            />
        )
    }

    render() {
        return (
            <UserProfileConsumer>
                {(context) => {
                    return (
                        <View
                            style={{
                                flex: 1,
                            }}
                            ref={(ref) => {
                                this.context = context
                            }}
                        >
                            <ScrollView
                                onScroll={(event) => this.onScroll(event)}
                                scrollEventThrottle={16}
                                style={{
                                    flex: 1,
                                }}
                            >
                                {Layout.isMediumDevice ? (
                                    <View
                                        style={{
                                            flex: 1,
                                        }}
                                    >
                                        <ImageBackground
                                            //source={{}}
                                            source={require('../../assets/images/nik-shuliahin-o14RBhTPxnA-unsplash.jpg')}
                                            style={[
                                                styles.imageContainer,
                                                styles.mainView,
                                            ]}
                                            resizeMode={'cover'}
                                        />

                                        <View
                                            style={[
                                                styles.headerContainer,
                                                {
                                                    padding: Layout.isSmallDevice
                                                        ? Layout.screenPaddingLarge
                                                        : Layout.screenPaddingLarge,
                                                    paddingBottom: Layout.isSmallDevice
                                                        ? Layout.screenPaddingXL
                                                        : Layout.screenPaddingXL,
                                                },
                                            ]}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    // marginTop:
                                                    //     -Layout.SCREEN_HEIGHT *
                                                    //     0.125,
                                                    alignSelf: 'center',
                                                }}
                                            >
                                                <FormHeaderTitle
                                                    headerTitle={
                                                        context.tokenExists
                                                            ? 'Welcome to Indvstry'
                                                            : 'Cultivated content'
                                                    }
                                                    headerTitleStyle={[
                                                        Layout.isSmallDevice
                                                            ? TextStyles.Heading1
                                                            : TextStyles.Heading1,
                                                        // {
                                                        //     paddingBottom:
                                                        //         Layout.screenPaddingMedium,
                                                        // },
                                                    ]}
                                                />
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    Creator platform for Food
                                                    and Hospitality
                                                    professionals
                                                </Text>
                                                <Button
                                                    iconRight={true}
                                                    iconName={'arrow-right'}
                                                    iconColor={Colors.tintColor}
                                                    onPress={() => {
                                                        context.tokenExists
                                                            ? this.props.navigation.dispatch(
                                                                  DrawerActions.toggleDrawer()
                                                              )
                                                            : this.props.navigation.navigate(
                                                                  'RequestInfo'
                                                              )
                                                    }}
                                                    style={{
                                                        flex: 1,
                                                        width: Layout.isMediumDevice
                                                            ? '100%'
                                                            : null,
                                                        paddingTop:
                                                            Layout.screenPaddingMedium,
                                                        alignSelf: 'flex-start',
                                                    }}
                                                    buttonStyle={{
                                                        borderWidth: 1,
                                                        borderColor:
                                                            Colors.tintColor,
                                                        backgroundColor:
                                                            'transparent',
                                                    }}
                                                    textStyle={[
                                                        TextStyles.Button,
                                                    ]}
                                                    title={
                                                        context.tokenExists
                                                            ? 'Sign up now'
                                                            : 'Request a tour'
                                                    }
                                                />
                                            </View>
                                        </View>
                                    </View>
                                ) : (
                                    <ImageBackground
                                        // source={
                                        //     context.tokenExists
                                        //         ? require('../../assets/images/larm-rmah--AI-n49WVwY-unsplash.jpg')
                                        //         : require('../../assets/images/larm-rmah--AI-n49WVwY-unsplash.jpg')
                                        // }
                                        source={require('../../assets/images/nik-shuliahin-o14RBhTPxnA-unsplash.jpg')}
                                        style={[
                                            styles.mainView,
                                            {
                                                height: Layout.SCREEN_HEIGHT,
                                                flexDirection: 'row',
                                            },
                                        ]}
                                        resizeMode={'cover'}
                                    >
                                        <View
                                            style={[
                                                styles.headerContainer,
                                                {
                                                    padding:
                                                        Layout.screenPaddingXL,
                                                },
                                            ]}
                                        >
                                            <View
                                                style={[
                                                    styles.bodyContent,
                                                    { flexDirection: 'row' },
                                                ]}
                                            >
                                                <View
                                                    style={{
                                                        flex: 1,
                                                        alignSelf: 'center',
                                                        paddingRight:
                                                            Layout.screenPaddingLarge,
                                                        maxWidth: 960,
                                                        // paddingBottom:
                                                        //     Layout.screenPaddingXL,
                                                    }}
                                                >
                                                    <FormHeaderTitle
                                                        headerTitle={
                                                            context.tokenExists
                                                                ? 'Welcome to Indvstry'
                                                                : 'Cultivated content'
                                                        }
                                                        headerTitleStyle={[
                                                            TextStyles.Heading0Inverse,
                                                            // {
                                                            //     paddingBottom:
                                                            //         Layout.screenPaddingMedium,
                                                            // },
                                                        ]}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.ParagraphInverse,
                                                            {
                                                                lineHeight: 26,
                                                                paddingBottom:
                                                                    Layout.screenPaddingMedium,
                                                            },
                                                        ]}
                                                    >
                                                        Creator platform for
                                                        Food and Hospitality
                                                        professionals
                                                    </Text>
                                                    <Button
                                                        iconRight={true}
                                                        iconName={'arrow-right'}
                                                        iconColor={Colors.gray0}
                                                        onPress={() => {
                                                            context.tokenExists
                                                                ? this.props.navigation.dispatch(
                                                                      DrawerActions.toggleDrawer()
                                                                  )
                                                                : this.props.navigation.navigate(
                                                                      'RequestInfo'
                                                                  )
                                                        }}
                                                        style={{
                                                            flex: 1,
                                                            paddingTop:
                                                                Layout.screenPaddingMedium,
                                                            alignSelf:
                                                                'flex-start',
                                                        }}
                                                        buttonStyle={{
                                                            // borderWidth: 1,
                                                            // borderColor:
                                                            //     Colors.gray0,
                                                            backgroundColor:
                                                                Colors.tintColor,
                                                        }}
                                                        textStyle={[
                                                            TextStyles.ButtonInverse,
                                                        ]}
                                                        title={
                                                            context.tokenExists
                                                                ? 'Sign up now'
                                                                : 'Request a tour'
                                                        }
                                                    />
                                                </View>
                                            </View>
                                        </View>
                                    </ImageBackground>
                                )}

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        { backgroundColor: Colors.tintColor },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                flex: 1,
                                                paddingTop: Layout.isMediumDevice
                                                    ? 0
                                                    : Layout.screenPaddingXL,
                                                maxWidth: Layout.isMediumDevice
                                                    ? '100%'
                                                    : 720,

                                                // paddingRight: Layout.isMediumDevice
                                                //     ? 0
                                                //     : Layout.screenPaddingLarge,
                                                // paddingBottom: Layout.isMediumDevice
                                                //     ? Layout.screenPaddingLarge
                                                //     : 0,
                                            }}
                                        >
                                            <Text
                                                style={[
                                                    TextStyles.Heading1Inverse,
                                                    {
                                                        //textAlign: 'center',
                                                        //lineHeight: 36,
                                                    },
                                                ]}
                                            >
                                                If you'd like to support
                                                America's restaurants in the
                                                current moment, here are some
                                                trusted organizations you can
                                                help out through funding or
                                                advocacy.
                                            </Text>
                                        </View>
                                    </View>
                                </View>
                                <View
                                    style={[
                                        styles.sectionContainer,
                                        { backgroundColor: Colors.tintColor },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                // paddingVertical:
                                                //     Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingXL,
                                                    paddingBottom: Layout.isMediumDevice
                                                        ? Layout.screenPaddingLarge
                                                        : 0,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading3Inverse,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    Restaurant Workers’
                                                    Community Foundation -
                                                    COVID-19 Crisis Relief Fund
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.ParagraphInverse,
                                                        {
                                                            lineHeight: 36,
                                                        },
                                                    ]}
                                                >
                                                    Restaurant Workers’
                                                    Community Foundation works
                                                    towards equity, fair wages,
                                                    and a healthy work
                                                    environment for the
                                                    restaurant industry. Its
                                                    COVID-19 Crisis Relief Fund
                                                    puts 100% of donations
                                                    towards direct relief for
                                                    individual restaurant
                                                    workers, zero interest loans
                                                    for restaurants to get back
                                                    up and running, and towards
                                                    supporting other nonprofits
                                                    serving restaurant workers
                                                    in crisis.
                                                </Text>
                                                <Button
                                                    iconRight={false}
                                                    iconName={'arrow-right'}
                                                    iconColor={Colors.tintColor}
                                                    onPress={() =>
                                                        Linking.openURL(
                                                            'https://www.restaurantworkerscf.org/news/2020/3/15/restaurant-workers-community-foundation-announces-the-formation-of-restaurant-workers-covid19-crisis-relief-fund'
                                                        )
                                                    }
                                                    style={{
                                                        flex: 1,
                                                        alignSelf: 'flex-start',
                                                        paddingVertical:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                    buttonStyle={{
                                                        borderWidth: 1,
                                                        backgroundColor:
                                                            Colors.transparentBackground,
                                                        borderColor:
                                                            Colors.inverseText,
                                                    }}
                                                    textStyle={[
                                                        TextStyles.Button,
                                                        {
                                                            color:
                                                                Colors.inverseText,
                                                        },
                                                    ]}
                                                    title={'Donate'}
                                                />
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingXL,
                                                    paddingBottom: Layout.isMediumDevice
                                                        ? Layout.screenPaddingLarge
                                                        : 0,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading3Inverse,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    The James Beard Foundation
                                                    Food & Beverage Relief Fund
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.ParagraphInverse,
                                                        {
                                                            lineHeight: 36,
                                                        },
                                                    ]}
                                                >
                                                    To help bring swift economic
                                                    relief to the restaurant
                                                    industry, the James Beard
                                                    Foundation launched a fund
                                                    that will be gathering
                                                    support from corporate,
                                                    foundation, and individual
                                                    donors to provide
                                                    micro-grants to independent
                                                    food and beverage businesses
                                                    in need.
                                                </Text>
                                                <Button
                                                    iconRight={false}
                                                    iconName={'arrow-right'}
                                                    iconColor={Colors.tintColor}
                                                    onPress={() =>
                                                        Linking.openURL(
                                                            'https://www.jamesbeard.org/relief'
                                                        )
                                                    }
                                                    style={{
                                                        flex: 1,
                                                        alignSelf: 'flex-start',
                                                        paddingVertical:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                    buttonStyle={{
                                                        borderWidth: 1,
                                                        backgroundColor:
                                                            Colors.transparentBackground,
                                                        borderColor:
                                                            Colors.inverseText,
                                                    }}
                                                    textStyle={[
                                                        TextStyles.Button,
                                                        {
                                                            color:
                                                                Colors.inverseText,
                                                        },
                                                    ]}
                                                    title={'Donate'}
                                                />
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingXL,
                                                    paddingBottom: Layout.isMediumDevice
                                                        ? Layout.screenPaddingLarge
                                                        : 0,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading3Inverse,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    The Independent Restaurants
                                                    Coalition
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.ParagraphInverse,
                                                        {
                                                            lineHeight: 36,
                                                        },
                                                    ]}
                                                >
                                                    The Independent Restaurants
                                                    Coalition (IRC) was formed
                                                    by a group of chefs to save
                                                    local restaurants impacted
                                                    by COVID-19. By supporting
                                                    the IRC, you’ll be a part of
                                                    advancing critical
                                                    legislation that can help
                                                    save 11 million restaurant
                                                    employees and the hundreds
                                                    of millions who support
                                                    restaurant delivery and
                                                    supply chains.
                                                </Text>
                                                <Button
                                                    iconRight={false}
                                                    iconName={'arrow-right'}
                                                    iconColor={Colors.tintColor}
                                                    onPress={() =>
                                                        Linking.openURL(
                                                            'https://www.saverestaurants.co/'
                                                        )
                                                    }
                                                    style={{
                                                        flex: 1,
                                                        alignSelf: 'flex-start',
                                                        paddingVertical:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                    buttonStyle={{
                                                        borderWidth: 1,
                                                        backgroundColor:
                                                            Colors.transparentBackground,
                                                        borderColor:
                                                            Colors.inverseText,
                                                    }}
                                                    textStyle={[
                                                        TextStyles.Button,
                                                        {
                                                            color:
                                                                Colors.inverseText,
                                                        },
                                                    ]}
                                                    title={'Get involved'}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        { backgroundColor: Colors.gray0 },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingBottom: Layout.isMediumDevice
                                                        ? Layout.screenPaddingLarge
                                                        : 0,
                                                }}
                                            >
                                                <Text
                                                    style={TextStyles.Heading2}
                                                >
                                                    Content creation for
                                                    culinary events and
                                                    experiences is our
                                                    specialty.
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 36,
                                                        },
                                                    ]}
                                                >
                                                    We are a team of creatives
                                                    and technologists with
                                                    experience launching top
                                                    Food and Hospitality brands.
                                                    Here to help you connect
                                                    with your valued guests.
                                                </Text>
                                                <Button
                                                    iconRight={false}
                                                    iconName={'arrow-right'}
                                                    iconColor={Colors.tintColor}
                                                    onPress={() => {
                                                        context.userExists
                                                            ? this.props.navigation.navigate(
                                                                  'Team'
                                                              )
                                                            : this.props.navigation.navigate(
                                                                  'About'
                                                              )
                                                    }}
                                                    style={{
                                                        flex: 1,
                                                        alignSelf: 'flex-start',
                                                        paddingVertical:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                    buttonStyle={{
                                                        borderWidth: 1,
                                                        backgroundColor:
                                                            Colors.transparentBackground,
                                                        borderColor:
                                                            Colors.tintColor,
                                                    }}
                                                    textStyle={[
                                                        TextStyles.Button,
                                                        {
                                                            color:
                                                                Colors.tintColor,
                                                        },
                                                    ]}
                                                    title={'Get to know us'}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <Footer navigation={this.props.navigation} />
                            </ScrollView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    mainView: {
        borderTopWidth: 6,
        borderTopColor: Colors.tintColor,
        backgroundColor: Colors.tintColor,
    },

    headerContainer: {
        flex: 1,
        flexDirection: 'column',
        backgroundColor: Layout.isMediumDevice ? Colors.aesop : 'transparent',
    },

    imageContainer: {
        //flex: 1,
        height: Layout.SCREEN_HEIGHT * 0.5,
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        padding: Layout.screenPaddingLarge,
    },

    sectionContainer: {
        alignItems: 'center',
        backgroundColor: Colors.gray0,
        paddingVertical: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        paddingVertical: Layout.screenPaddingLarge,
        paddingHorizontal: Layout.screenPaddingLarge,
    },

    stickyButton: {
        flex: 1,
        position: 'absolute',
        bottom: 0,
        right: 0,
        padding: Layout.isMediumDevice
            ? Layout.screenPaddingMedium
            : Layout.screenPaddingLarge,
    },
})
