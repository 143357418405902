import { Dimensions } from 'react-native'

const SCREEN_HEIGHT = Dimensions.get('window').height
const SCREEN_WIDTH = Dimensions.get('window').width

// Tab bar heights
// https://github.com/react-navigation/react-navigation-tabs/blob/d2aa789109ed0df61c0c0ac7b759ac386a720804/src/views/BottomTabBar.js#L239

const TAB_DEFAULT_HEIGHT = 49
const TAB_COMPACT_HEIGHT = 29

export default {
    screenPaddingSmall: 8,
    screenPaddingMedium: 16,
    screenPaddingLarge: 32,
    screenPaddingXL: 64,

    borderRadiusSmall: 3,
    borderRadiusMedium: 6,
    borderRadiusLarge: 9,
    borderRadiusXL: 12,
    borderRadiusMax: 100,

    SCREEN_HEIGHT,
    SCREEN_WIDTH,

    TAB_DEFAULT_HEIGHT,
    TAB_COMPACT_HEIGHT,

    window: {
        SCREEN_HEIGHT,
        SCREEN_WIDTH,
    },
    isSmallDevice: SCREEN_WIDTH <= 414,
    isMediumDevice: SCREEN_WIDTH < 768,

    topNavHeightMobile: 64,
    topNavHeightDesktop: 88,
}
