import React from 'react'
import * as firebase from 'firebase/app'
import 'firebase/auth'
import 'firebase/firestore'
import '../firebase.config'

import uuid from 'uuid'

//import getDeviceInfo from '../utilities/getDeviceInfo'

const DB = firebase.firestore()
const AUTH = firebase.auth()
const COLLECTION = 'venues'

class Place extends React.Component {
    // Helpers
    get collection() {
        return firebase.firestore().collection(COLLECTION)
    }

    get uid() {
        return firebase.auth().currentUser.uid
    }

    get user() {
        return firebase.auth().currentUser
    }

    get timestamp() {
        return Date.now()
    }

    /* CREATE */
    _newVenue = async ({
        placeId,
        name,
        formattedAddress,
        plusCode,
        types,
        mapsUrl,
        phoneIntl,
        website,
        priceLevel,
    }) => {
        await this.collection.doc(placeId).set({
            //placeId,
            name,
            formattedAddress,
            plusCode,
            types,
            mapsUrl,
            phoneIntl,
            website,
            priceLevel,

            // add user to list

            groupName: name + ' Group',

            visibility: {
                group: true,
                owner: true,
                public: true,
                team: true,
            },

            createdBy: {
                uid: this.uid,
                timestamp: this.timestamp,
            },
        })
    }

    _createVenue = async ({
        placeId,
        name,
        formattedAddress,
        plusCode,
        types,
        mapsUrl,
        phoneIntl,
        website,
        priceLevel,
    }) => {
        let placesRef = await this.collection.doc(placeId)

        try {
            //const placeExists = []

            if (!placesRef.exists) {
                this._newVenue({
                    placeId,
                    name,
                    formattedAddress,
                    plusCode,
                    types,
                    mapsUrl,
                    phoneIntl,
                    website,
                    priceLevel,
                })
            } else {
                console.log(
                    '›››››››››››››››››› This place exists ››››››››››››››››››'
                )
            }
        } catch ({ message }) {
            //console.log(message)
            alert(message)
        }
    }

    __________createVenue = async ({
        placeId,
        name,
        formattedAddress,
        plusCode,
        types,
        mapsUrl,
        phoneIntl,
        website,
        priceLevel,
    }) => {
        let placesRef = await this.collection.doc(placeId)

        try {
            const placeExists = []

            //const queryPlaces = await placesRef.get()

            await placesRef.get().then(doc => {
                if (!doc.exists) {
                    this._newVenue({
                        placeId,
                        name,
                        formattedAddress,
                        plusCode,
                        types,
                        mapsUrl,
                        phoneIntl,
                        website,
                        priceLevel,
                    })
                } else {
                    console.log(
                        '›››››››››››››››››› This place exists ››››››››››››››››››',
                        place.data(),
                        '››››››››››››››››››'
                    )

                    placeExists.push(place.data())
                }
            })
        } catch ({ message }) {
            //console.log(message)
            alert(message)
        }
    }

    ____createVenue = async ({
        placeId,
        name,
        formattedAddress,
        plusCode,
        types,
        mapsUrl,
        phoneIntl,
        website,
        priceLevel,
    }) => {
        let placesRef = await this.collection

        try {
            const placeExists = []
            const queryPlaces = await placesRef.get()

            queryPlaces.forEach(async place => {
                if (place.id.exists) {
                    console.log(
                        '›››››››››››››››››› This place exists ››››››››››››››››››',
                        place.data(),
                        '››››››››››››››››››'
                    )
                    placeExists.push(place.data())
                    return
                }
            })

            if (!placeExists.length) {
                console.log(
                    '‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹ This place does not exist. Creating a new one. ‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹‹'
                )
                await this._newVenue({
                    placeId,
                    name,
                    formattedAddress,
                    plusCode,
                    types,
                    mapsUrl,
                    phoneIntl,
                    website,
                    priceLevel,
                })
            }
        } catch ({ message }) {
            //console.log(message)
            alert(message)
        }
    }

    /* GET */

    // const createNew = this.collection.add({
    //     placeId,
    //     name,
    //     formattedAddress,
    //     plusCode,
    //     types,
    //     mapsUrl,
    //     phoneIntl,
    //     website,
    //     priceLevel,

    //     createdBy: {
    //         uid: this.uid,
    //         timestamp: this.timestamp,
    //         user: getDeviceInfo(),
    //     },
    // })

    /**
     *
     *    rating: data.rating,
     *    userRatingsTotal: data.user_ratings_total,
     */

    _getVenueProfile = async () => {
        //const venueProfileRef = await DB.collection('users').doc(this.uid)

        const venueProfileRef = await this.collection
        // .orderBy('members')
        // .where(`${this.uid}`, '==', true)
        try {
            const venueProfile = []
            await venueProfileRef.get().then(doc => {
                if (doc.exists) {
                    venueProfile.push({
                        venueName: doc.data().venueActive.venueName,
                        venueAddress: doc.data().venueActive.venueAddress,
                    })
                }
            })

            console.log('PLACE: GET-VENUE-PROFILE', '›››', venueProfile)

            return {
                venueName: venueProfile[0],
                venueAddress: venueProfile[1],
            }
        } catch ({ message }) {
            console.log(
                '***************** FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
        }
    }

    _getVenue = async () => {
        let venueRef = await this.collection
            //.where('members', '==', this.uid) // <-- Need to make a custom token here. INstead of placeId, I can use custom tokens with placeId as tokenId?
            //.where('placeId', '==', userProfile.placeId) // something like this
            .limit(1)

        try {
            const userProfile = []
            const queryUsers = await userProfileRef.get()

            queryUsers.forEach(async user => {
                if (user.exists) {
                    // console.log(
                    //     'USER',
                    //     'GET-USER-DB-PROFILE',
                    //     '›››',
                    //     user.id,
                    //     user.data()
                    // )
                    userProfile.push(user.id, user.data())
                }
            })
            //console.log('USER', 'GET-USER-DB-PROFILE', '›››', userProfile)

            return {
                userProfileId: userProfile[0],
                username: userProfile[1].username,
                jobTitle: userProfile[1].jobTitle,
                workPlace: userProfile[1].workPlace,
            }
        } catch ({ message }) {
            console.log(
                '***************** FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
        }
    }

    /* SET */

    /* UPDATE */

    _usernameUpdate = async ({ userProfileId, username }) => {
        try {
            // Updates username in 'users' collection
            this.collection.doc(userProfileId).update({ username: username })
        } catch ({ message }) {
            console.log(message)
        }
    }

    _checkUsername = async ({ usernameNew }) => {
        let usernameCheck = await this.collection.where(
            'username',
            '==',
            usernameNew
        )

        try {
            const usernameFlag = []
            const queryUsers = await usernameCheck.get()

            queryUsers.forEach(async user => {
                if (user.exists && user.data().uid !== this.uid) {
                    usernameFlag.push(user.data().username)
                    console.log(user.data())
                }
            })
            console.log(usernameFlag[0])
            return {
                usernameFlag: usernameFlag.length ? usernameFlag[0] : {},
            }
        } catch ({ message }) {
            console.log(
                '***************** FIREBASE MESSAGE *****************',
                message,
                '***************** FIREBASE END *****************'
            )
        }
    }

    // _____newVenue = async ({
    //     placeId,
    //     name,
    //     formattedAddress,
    //     plusCode,
    //     types,
    //     mapsUrl,
    //     phoneIntl,
    //     website,
    //     priceLevel,
    // }) => {
    //     await this.collection.add({
    //         placeId,
    //         name,
    //         formattedAddress,
    //         plusCode,
    //         types,
    //         mapsUrl,
    //         phoneIntl,
    //         website,
    //         priceLevel,

    //         createdBy: {
    //             //uid: this.uid,
    //             timestamp: this.timestamp,
    //             user: getDeviceInfo(),
    //         },
    //     })
    // }
}

export default new Place()
