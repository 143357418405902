import React from 'react'
import {
    ImageBackground,
    Image,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import { Feather } from '@expo/vector-icons'
import { Colors, TextStyles, Layout, Icons } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import FormHeaderTitle from '../../components/FormHeaderTitle'
import Button from '../../components/Button'
import moment from 'moment'
import StudioFooter from '../../components/StudioFooter'
import GetInTouch from '../../components/GetInTouch'
import ButtonIcon from '../../components/ButtonIcon'

export default class StudioVision extends React.Component {
    onScroll = async event => {
        const pageYOffset = await event.nativeEvent.contentOffset.y

        if (pageYOffset >= 88) {
            this.props.navigation.setParams({ active: true })
        } else {
            this.props.navigation.setParams({ active: false })
        }
    }

    renderItem = ({ text, textColor, containerColor }) => {
        return (
            <View
                style={{
                    paddingRight: Layout.isMediumDevice
                        ? 0
                        : Layout.screenPaddingMedium,
                    paddingVertical: Layout.screenPaddingSmall,
                    width: Layout.isMediumDevice ? '100%' : null,
                }}
            >
                <View
                    style={{
                        padding: Layout.screenPaddingMedium,
                        borderRadius: Layout.borderRadiusMedium,
                        backgroundColor: containerColor,
                    }}
                >
                    <Text
                        style={[
                            TextStyles.Paragraph,
                            {
                                color: textColor,
                                lineHeight: 26,
                            },
                        ]}
                    >
                        {text}
                    </Text>
                </View>
            </View>
        )
    }

    renderSlider = () => {
        return (
            <View style={{ height: 9 }}>
                <View
                    style={{
                        flex: 1,
                        borderRadius: 18,
                        backgroundColor: Colors.gray3,
                    }}
                >
                    <View
                        style={{
                            flex: 1,
                            width: '66%',
                            borderRadius: 18,
                            backgroundColor: Colors.tintColor,
                        }}
                    />
                </View>
            </View>
        )
    }

    renderSliderMobile = () => {
        return (
            <View
                style={{
                    width: 9,
                    marginRight: Layout.screenPaddingLarge,
                    paddingTop: Layout.screenPaddingSmall,
                    paddingBottom: Layout.screenPaddingLarge,
                }}
            >
                <View
                    style={{
                        flex: 1,
                        borderRadius: 18,
                        backgroundColor: Colors.gray3,
                    }}
                >
                    <View
                        style={{
                            height: '66%',
                            borderRadius: 18,
                            backgroundColor: Colors.tintColor,
                        }}
                    />
                </View>
            </View>
        )
    }

    renderRule = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 2,
                    borderBottomColor: Colors.tintColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSeparator = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSpacer() {
        return (
            <View
                style={{
                    //flex: 1,
                    width: '100%',
                    height: Layout.screenPaddingLarge,
                }}
            />
        )
    }

    render() {
        // LayoutAnimation.easeInEaseOut()

        const timestampLabel = moment().format('dddd, MMMM D, YYYY')

        return (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View
                            style={{
                                flex: 1,
                            }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <ScrollView
                                ref={node => (this.scroll = node)}
                                onScroll={event => this.onScroll(event)}
                                scrollEventThrottle={16}
                                style={{
                                    flex: 1,
                                }}
                                pagingEnabled={
                                    Layout.isMediumDevice ? false : true
                                }
                            >
                                <ImageBackground
                                    source={require('../../assets/images/jesse-leake-u1yt6xiNilU-unsplash.jpg')}
                                    style={{
                                        borderTopWidth: 6,
                                        borderTopColor: Colors.tintColor,
                                        backgroundColor: Colors.tintColor,
                                        minHeight: Layout.SCREEN_HEIGHT,
                                        //alignItems: 'center',
                                        //justifyContent: 'center',
                                    }}
                                    resizeMode={'cover'}
                                >
                                    <View
                                        style={{
                                            flex: 1,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor:
                                                Colors.mediumBackground,
                                        }}
                                    >
                                        <View
                                            style={{
                                                maxWidth: 960,
                                                paddingHorizontal: Layout.isMediumDevice
                                                    ? Layout.screenPaddingLarge
                                                    : Layout.screenPaddingXL,
                                            }}
                                        >
                                            <Text
                                                style={[
                                                    TextStyles.Overline2Inverse,
                                                    {
                                                        alignSelf: Layout.isMediumDevice
                                                            ? 'center'
                                                            : 'center',
                                                        paddingBottom:
                                                            Layout.screenPaddingMedium,
                                                        textAlign: Layout.isMediumDevice
                                                            ? 'auto'
                                                            : 'center',
                                                        textTransform:
                                                            'uppercase',
                                                    },
                                                ]}
                                            >
                                                Vision
                                            </Text>
                                            <Text
                                                style={[
                                                    Layout.isMediumDevice
                                                        ? TextStyles.Heading1Inverse
                                                        : TextStyles.Heading0Inverse,
                                                    {
                                                        paddingBottom:
                                                            Layout.screenPaddingMedium,
                                                        textAlign: Layout.isMediumDevice
                                                            ? 'auto'
                                                            : 'center',
                                                    },
                                                ]}
                                            >
                                                Studio: {'\n'} a vision of
                                                transformation
                                            </Text>

                                            <ButtonIcon
                                                iconName={'arrow-down'}
                                                iconColor={Colors.inverseText}
                                                onPress={() => {
                                                    this.scroll.scrollTo({
                                                        y: Layout.SCREEN_HEIGHT,
                                                    })
                                                }}
                                                style={{
                                                    alignSelf: Layout.isMediumDevice
                                                        ? 'center'
                                                        : 'center',
                                                    paddingTop:
                                                        Layout.screenPaddingMedium,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    borderColor:
                                                        Colors.inverseText,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                }}
                                            />
                                            {/* <Button
                                                iconRight={false}
                                                iconName={'arrow-right'}
                                                iconColor={Colors.tintColor}
                                                onPress={() => {
                                                    this.props.navigation.navigate(
                                                        'StudioContact'
                                                    )
                                                }}
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'flex-start',
                                                    paddingTop:
                                                        Layout.screenPaddingMedium,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                    borderColor:
                                                        Colors.inverseText,
                                                }}
                                                textStyle={[
                                                    TextStyles.Button,
                                                    {
                                                        color:
                                                            Colors.inverseText,
                                                    },
                                                ]}
                                                title={'Get involved'}
                                            /> */}
                                        </View>
                                    </View>
                                </ImageBackground>

                                <View style={styles.sectionContainer}>
                                    <View
                                        style={{
                                            maxWidth: 720,
                                            alignSelf: 'center',
                                            paddingVertical: Layout.isMediumDevice
                                                ? Layout.screenPaddingLarge
                                                : Layout.screenPaddingXL,
                                        }}
                                    >
                                        <Text
                                            style={[
                                                TextStyles.Heading1,
                                                {
                                                    textAlign: Layout.isMediumDevice
                                                        ? 'left'
                                                        : 'center',
                                                },
                                            ]}
                                        >
                                            Studio is a visionary fashion brand
                                            by noname and Master Chef, dedicated
                                            to the next generation of Creatives
                                        </Text>
                                    </View>

                                    <Image
                                        source={
                                            Layout.isMediumDevice
                                                ? require('../../assets/images/MetaVisionMobile.png')
                                                : require('../../assets/images/MetaVision.png')
                                        }
                                        style={{
                                            alignSelf: 'center',
                                            width: '100%',
                                            height: Layout.isMediumDevice
                                                ? 320
                                                : Layout.SCREEN_HEIGHT * 0.382,
                                        }}
                                        resizeMode={'contain'}
                                    />
                                </View>

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        { backgroundColor: Colors.black },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                justifyContent: 'space-between',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'center',
                                                    paddingHorizontal: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    width: Layout.isMediumDevice
                                                        ? '100%'
                                                        : Layout.SCREEN_WIDTH *
                                                          0.236,
                                                    height:
                                                        Layout.SCREEN_HEIGHT,
                                                    backgroundColor:
                                                        Colors.gray0,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        overflow: 'hidden',
                                                        backgroundColor:
                                                            Colors.tintColor,
                                                        borderRadius: 96,
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        padding:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/svgs/renewable-energy-paper-turbine.svg')}
                                                        style={{
                                                            width: Icons.XL,
                                                            height: Icons.XL,
                                                        }}
                                                        resizeMode={'cover'}
                                                    />
                                                </View>

                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                            paddingRight: Layout.isMediumDevice
                                                                ? 0
                                                                : Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    We value Sustainability
                                                </Text>

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    Our brand is environmentally
                                                    sustainable, producing zero
                                                    waste. We believe in
                                                    clothing the future
                                                    generations to come.
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'center',
                                                    borderLeftWidth: Layout.isMediumDevice
                                                        ? null
                                                        : 6,
                                                    borderRightWidth: Layout.isMediumDevice
                                                        ? null
                                                        : 6,
                                                    borderColor: Colors.black,
                                                    paddingHorizontal: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,

                                                    width: Layout.isMediumDevice
                                                        ? '100%'
                                                        : Layout.SCREEN_WIDTH *
                                                          0.236,
                                                    height:
                                                        Layout.SCREEN_HEIGHT,
                                                    backgroundColor:
                                                        Colors.gray0,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        overflow: 'hidden',
                                                        backgroundColor:
                                                            Colors.tintColor,
                                                        borderRadius: 96,
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        padding:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/svgs/touch-id.svg')}
                                                        style={{
                                                            width: Icons.XL,
                                                            height: Icons.XL,
                                                        }}
                                                        resizeMode={'cover'}
                                                    />
                                                </View>

                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                            paddingRight: Layout.isMediumDevice
                                                                ? 0
                                                                : Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    We value Integrity
                                                </Text>

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    We honor makers as well as
                                                    fashion enthusiasts. Founded
                                                    on an athletic tradition and
                                                    taking cues from the Food
                                                    space, Studio reimagines
                                                    what performance wear can
                                                    be.
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignItems: 'flex-start',
                                                    justifyContent: 'center',
                                                    paddingHorizontal: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    width: Layout.isMediumDevice
                                                        ? '100%'
                                                        : Layout.SCREEN_WIDTH *
                                                          0.236,
                                                    height:
                                                        Layout.SCREEN_HEIGHT,
                                                    backgroundColor:
                                                        Colors.gray0,
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        overflow: 'hidden',
                                                        backgroundColor:
                                                            Colors.tintColor,
                                                        borderRadius: 96,
                                                        alignItems: 'center',
                                                        justifyContent:
                                                            'center',
                                                        marginVertical:
                                                            Layout.screenPaddingMedium,
                                                        padding:
                                                            Layout.screenPaddingLarge,
                                                    }}
                                                >
                                                    <Image
                                                        source={require('../../assets/svgs/wifi-heart.svg')}
                                                        style={{
                                                            width: Icons.XL,
                                                            height: Icons.XL,
                                                        }}
                                                        resizeMode={'cover'}
                                                    />
                                                </View>

                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                            paddingRight: Layout.isMediumDevice
                                                                ? 0
                                                                : Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    We value Accessibility
                                                </Text>

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                        },
                                                    ]}
                                                >
                                                    With inclusion and
                                                    self-expression in mind,
                                                    Studio is designed for all
                                                    genders, all forms of
                                                    creativity, and made at
                                                    affordable price points.
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <View style={styles.sectionContainer}>
                                    <ImageBackground
                                        style={{
                                            width: Layout.SCREEN_WIDTH,
                                            height: Layout.SCREEN_HEIGHT,
                                        }}
                                        source={require('../../assets/images/nick-karvounis-72018-unsplash.jpg')}
                                        resizeMode={'cover'}
                                    >
                                        <View
                                            style={{
                                                flex: 1,
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                                backgroundColor:
                                                    Colors.mediumBackground,
                                                // paddingHorizontal:
                                                //     Layout.screenPaddingLarge,
                                            }}
                                        >
                                            {/* <FormHeaderTitle
                                                headerTitle={
                                                    'One in 10 working Americans will be employed by the retaurant industry in 2019'
                                                }
                                                headerTitleStyle={[
                                                    TextStyles.Heading1,
                                                    {
                                                        maxWidth: 960,
                                                        color: Colors.gray0,
                                                        alignSelf: 'center',
                                                        textAlign: 'center',
                                                    },
                                                ]}
                                            /> */}
                                            <View
                                                style={{
                                                    //flex: 1,
                                                    maxWidth: 960,
                                                    paddingHorizontal: Layout.isMediumDevice
                                                        ? Layout.screenPaddingLarge
                                                        : null,
                                                    // paddingVertical: Layout.isMediumDevice
                                                    //     ? Layout.screenPaddingXL * 2
                                                    //     : Layout.screenPaddingXL *
                                                    //       4,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        Layout.isMediumDevice
                                                            ? TextStyles.Heading1Inverse
                                                            : TextStyles.Heading0Inverse,
                                                        {
                                                            // alignSelf: Layout.isMediumDevice
                                                            //     ? 'center'
                                                            //     : 'center',
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                            //textAlign: 'left',
                                                        },
                                                    ]}
                                                >
                                                    By 2020, one in 10 working
                                                    Americans will be employed
                                                    by the Restaurant industry
                                                </Text>
                                            </View>
                                        </View>
                                    </ImageBackground>
                                </View>

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            backgroundColor: Colors.gray0,
                                        },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                alignItems: 'stretch',
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={[
                                                    styles.cardContent,
                                                    {
                                                        padding: 0,
                                                        marginVertical: 0,
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                    },
                                                ]}
                                            >
                                                <Image
                                                    source={require('../../assets/images/wesley-tingey-Q5ugrfN4oHk-unsplash.jpg')}
                                                    style={{
                                                        width: '100%',
                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_HEIGHT *
                                                              0.5
                                                            : '100%',
                                                    }}
                                                    resizeMode={'cover'}
                                                />
                                            </View>

                                            <View
                                                style={[
                                                    styles.cardContent,
                                                    {
                                                        marginVertical: 0,
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                    },
                                                ]}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading2,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                        },
                                                    ]}
                                                >
                                                    Inspired footwear, apparel
                                                    and accessories, made for
                                                    you
                                                </Text>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Informed by the real
                                                        world design challenges
                                                        of the people of Food
                                                        and Hospitality
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Inspired by the orbit of
                                                        Creative professionals
                                                        that overlap with the
                                                        world of Master Chef
                                                    </Text>
                                                </View>

                                                {this.renderSeparator()}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Designed to meet the
                                                        lifestyle needs of all
                                                        people who are honing
                                                        their craft
                                                    </Text>
                                                </View>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                {/* 
                                <View style={styles.sectionContainer}>
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                paddingVertical:
                                                    Layout.screenPaddingLarge,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    maxWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    paddingVertical:
                                                        Layout.screenPaddingMedium,
                                                    marginRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingXL,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading2,
                                                        {
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                        },
                                                    ]}
                                                >
                                                    We see fashion as a vehicle
                                                    of empowerment
                                                </Text>

                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Amplify the voices of
                                                        Food & Hospitality
                                                        professionals
                                                    </Text>
                                                </View>

                                                {this.renderSeparator(
                                                    Colors.gray3
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Outlet for Creatives to
                                                        express their narratives
                                                    </Text>
                                                </View>

                                                {this.renderSeparator(
                                                    Colors.gray3
                                                )}
                                                <View
                                                    style={{
                                                        flexDirection: 'row',
                                                        alignItems: 'center',
                                                    }}
                                                >
                                                    <Feather
                                                        style={{
                                                            alignSelf: 'center',
                                                            paddingRight: 12,
                                                        }}
                                                        color={Colors.tintColor}
                                                        size={Icons.small}
                                                        name={'check'}
                                                    />
                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                            },
                                                        ]}
                                                    >
                                                        Deliver messages of
                                                        empowerment for all
                                                        people
                                                    </Text>
                                                </View>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    alignSelf: 'center',
                                                    alignItems: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    paddingVertical:
                                                        Layout.screenPaddingMedium,
                                                }}
                                            >
                                                <Image
                                                    source={require('../../assets/images/tanja-heffner-584866-unsplash.jpg')}
                                                    style={{
                                                        //borderRadius: 1000,
                                                        //overflow: 'hidden',
                                                        width: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 480,
                                                        height: Layout.isMediumDevice
                                                            ? Layout.SCREEN_HEIGHT *
                                                              0.618
                                                            : 480,
                                                    }}
                                                    resizeMode={'cover'}
                                                />
                                            </View>
                                        </View>
                                    </View>
                                </View>
 */}

                                <ImageBackground
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                        },
                                    ]}
                                    source={require('../../assets/images/george-evans-BqpdGGfezFw-unsplash.jpg')}
                                    resizeMode={'cover'}
                                >
                                    <View
                                        style={[
                                            styles.bodyContent,
                                            {
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            },
                                        ]}
                                    >
                                        <View
                                            style={[
                                                styles.cardContent,
                                                {
                                                    alignSelf: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 720,
                                                },
                                            ]}
                                        >
                                            <Text
                                                style={[
                                                    TextStyles.Heading2,
                                                    {
                                                        paddingBottom:
                                                            Layout.screenPaddingLarge,
                                                    },
                                                ]}
                                            >
                                                Giving form to ideas
                                            </Text>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    // paddingBottom:
                                                    //     Layout.screenPaddingLarge,
                                                    flexDirection: Layout.isMediumDevice
                                                        ? 'row'
                                                        : 'column',
                                                }}
                                            >
                                                {Layout.isMediumDevice
                                                    ? this.renderSliderMobile()
                                                    : this.renderSlider()}
                                                <View
                                                    style={{
                                                        flex: 1,
                                                        paddingVertical: Layout.isMediumDevice
                                                            ? 0
                                                            : Layout.screenPaddingMedium,

                                                        flexDirection: Layout.isMediumDevice
                                                            ? 'column'
                                                            : 'row',
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            flex: 1,
                                                        }}
                                                    >
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 36,
                                                                },
                                                            ]}
                                                        >
                                                            Research
                                                        </Text>
                                                        <Text
                                                            style={[
                                                                TextStyles.Caption,
                                                                {
                                                                    paddingBottom:
                                                                        Layout.screenPaddingMedium,
                                                                },
                                                            ]}
                                                        >
                                                            Spring 2019
                                                        </Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            flex: 1,
                                                            alignItems: Layout.isMediumDevice
                                                                ? 'flex-start'
                                                                : 'center',
                                                        }}
                                                    >
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    textAlign:
                                                                        'center',
                                                                    lineHeight: 36,
                                                                },
                                                            ]}
                                                        >
                                                            Ideation
                                                        </Text>
                                                        <Text
                                                            style={[
                                                                TextStyles.Caption,
                                                                {
                                                                    textAlign:
                                                                        'center',
                                                                    paddingBottom:
                                                                        Layout.screenPaddingMedium,
                                                                },
                                                            ]}
                                                        >
                                                            Summer 2019
                                                        </Text>
                                                    </View>
                                                    <View
                                                        style={{
                                                            flex: 1,
                                                            alignItems: Layout.isMediumDevice
                                                                ? 'flex-start'
                                                                : 'flex-end',
                                                        }}
                                                    >
                                                        <Text
                                                            style={[
                                                                TextStyles.Paragraph,
                                                                {
                                                                    lineHeight: 36,
                                                                },
                                                            ]}
                                                        >
                                                            Concept Design
                                                        </Text>
                                                        <Text
                                                            style={[
                                                                TextStyles.Caption,
                                                                {
                                                                    paddingBottom:
                                                                        Layout.screenPaddingMedium,
                                                                },
                                                            ]}
                                                        >
                                                            Fall 2019
                                                        </Text>
                                                    </View>
                                                </View>
                                            </View>

                                            {/* <Button
                                                iconRight={false}
                                                iconName={'arrow-right'}
                                                iconColor={Colors.tintColor}
                                                onPress={() => {
                                                    this.props.navigation.navigate(
                                                        'StudioContact'
                                                    )
                                                }}
                                                style={{
                                                    //flex: 1,
                                                    width: Layout.isMediumDevice
                                                        ? '100%'
                                                        : null,
                                                    alignSelf: Layout.isMediumDevice
                                                        ? 'center'
                                                        : 'flex-start',
                                                    paddingTop:
                                                        Layout.screenPaddingMedium,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                    borderColor:
                                                        Colors.tintColor,
                                                }}
                                                textStyle={[
                                                    TextStyles.Button,
                                                    {
                                                        color:
                                                            Colors.primaryText,
                                                    },
                                                ]}
                                                title={'Let’s work together'}
                                            /> */}
                                        </View>
                                    </View>
                                </ImageBackground>

                                <ImageBackground
                                    style={styles.sectionContainer}
                                    source={require('../../assets/images/IMG_4395.jpeg')}
                                    resizeMode={'cover'}
                                >
                                    <View
                                        style={[
                                            styles.bodyContent,
                                            {
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                paddingVertical:
                                                    Layout.screenPaddingXL * 2,
                                            },
                                        ]}
                                    >
                                        <View
                                            style={[
                                                styles.cardContent,
                                                {
                                                    alignSelf: 'center',
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                },
                                            ]}
                                        >
                                            <View
                                                style={{
                                                    //flex: 1,
                                                    paddingBottom:
                                                        Layout.screenPaddingLarge,
                                                }}
                                            >
                                                {/* {this.renderRule()} */}

                                                <Text
                                                    style={[
                                                        TextStyles.Heading2,
                                                        {
                                                            // textAlign: Layout.isMediumDevice
                                                            //     ? 'center'
                                                            //     : 'left',
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                        },
                                                    ]}
                                                >
                                                    Studio Design
                                                </Text>
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        // {
                                                        //     textAlign: Layout.isMediumDevice
                                                        //         ? 'center'
                                                        //         : 'left',
                                                        // },
                                                    ]}
                                                >
                                                    See what Master Chef has
                                                    envisioned for fashion.
                                                </Text>
                                            </View>

                                            <Button
                                                iconRight={true}
                                                iconName={'arrow-right'}
                                                iconColor={Colors.tintColor}
                                                onPress={() => {
                                                    this.props.navigation.navigate(
                                                        'StudioDesign'
                                                    )
                                                }}
                                                style={{
                                                    //flex: 1,
                                                    width: Layout.isMediumDevice
                                                        ? '100%'
                                                        : null,
                                                    alignSelf: Layout.isMediumDevice
                                                        ? 'center'
                                                        : 'flex-start',
                                                    // paddingTop:
                                                    //     Layout.screenPaddingSmall,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                    borderColor:
                                                        Colors.tintColor,
                                                }}
                                                textStyle={[
                                                    TextStyles.Button,
                                                    {
                                                        color:
                                                            Colors.primaryText,
                                                    },
                                                ]}
                                                title={'See the Design'}
                                            />
                                        </View>
                                    </View>
                                </ImageBackground>

                                {/* <GetInTouch
                                    navigation={this.props.navigation}
                                /> */}

                                {/* <StudioFooter
                                    navigation={this.props.navigation}
                                /> */}
                            </ScrollView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    mainView: {
        flex: 1,
        borderTopWidth: 6,
        paddingTop: 88,
        borderTopColor: Colors.tintColor,
        backgroundColor: Colors.tintColor,
    },

    sectionContainer: {
        height: Layout.isMediumDevice ? null : Layout.SCREEN_HEIGHT,
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: Colors.gray0,
    },

    cardContent: {
        flex: 1,
        maxWidth: 720,
        marginVertical: Layout.screenPaddingMedium,
        backgroundColor: Colors.white,
        padding: Layout.screenPaddingLarge,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        alignItems: 'center',
        justifyContent: 'center',
        paddingHorizontal: Layout.screenPaddingLarge,
    },

    imageContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        padding: Layout.screenPaddingLarge,
    },
    stickyButton: {
        flex: 1,
        position: 'absolute',
        bottom: 0,
        right: 0,
        padding: Layout.isMediumDevice
            ? Layout.screenPaddingMedium
            : Layout.screenPaddingLarge,
    },
})
