import React from 'react'
import { Text, TouchableOpacity, View } from 'react-native'
import { createStackNavigator } from 'react-navigation-stack'
import { DrawerActions, createDrawerNavigator } from 'react-navigation-drawer'
import { Colors, Layout, TextStyles } from '../constants'
import { UserProfileConsumer } from '../context/UserProfileContext'
import AuthLoadingScreen from '../screens/auth/AuthLoadingScreen'
import AuthHomeScreen from '../screens/auth/AuthHomeScreen'
import TeamScreen from '../screens/global/TeamScreen'
import AuthForgotPasswordScreen from '../screens/auth/AuthForgotPasswordScreen'
import RequestInfoScreen from '../screens/global/RequestInfoScreen'
import RequestConfirmationScreen from '../screens/global/RequestConfirmationScreen'
import DrawerNavigation from '../components/DrawerNavigation'
import ButtonIcon from '../components/ButtonIcon'
import ButtonMini from '../components/ButtonMini'

const DrawerConfig = {
    /**
     *
     *  https://reactnavigation.org/docs/en/drawer-navigator.html
     *
     **/
    drawerWidth: Layout.isMediumDevice
        ? Layout.SCREEN_WIDTH
        : Layout.SCREEN_WIDTH * 0.5,
    drawerPosition: 'right',
    drawerBackgroundColor: Colors.gray0,

    contentComponent: props => <DrawerNavigation {...props} />,

    contentOptions: {
        activeBackgroundColor: 'transparent',
        itemsContainerStyle: {
            marginVertical: 24,
        },
        iconContainerStyle: {
            opacity: 1,
        },
        labelStyle: TextStyles.Heading3,
    },

    defaultNavigationOptions: ({ navigation }) => {
        return {
            // drawerIcon: (
            //     <ButtonIcon
            //         activityColor={Colors.white}
            //         iconName={'menu'}
            //         iconColor={Colors.inverseText}
            //         buttonStyle={{
            //             //alignSelf: 'center',
            //             backgroundColor: 'transparent',
            //         }}
            //         loading={false}
            //         disabled={false}
            //         onPress={() =>
            //             navigation.dispatch(DrawerActions.toggleDrawer())
            //         }
            //     />
            // ),
            // drawerIcon: (
            //     <HeaderButtons HeaderButtonComponent={FeatherHeaderButton}>
            //         <Item
            //             title="close"
            //             iconName="x"
            //             iconSize={Icons.large}
            //             color={Colors.tintColor}
            //         />
            //     </HeaderButtons>
            // ),
        }
    },
}

const StackConfig = {
    /**
     *
     *  https://reactnavigation.org/docs/en/stack-navigator.html#stacknavigatorconfig
     *
     **/
    initialRouteName: 'AuthHome',

    cardShadowEnabled: false,

    headerMode: 'screen', // 'float' | 'screen' | 'none'
    // headerTransitionPreset: 'fade-in-place', // float is enabled; 'uikit' | 'fade-in-place'

    defaultNavigationOptions: ({ navigation }) => ({
        cardShadowEnabled: false,

        headerBackTitle: null,
        headerPressColorAndroid: Colors.tintColor,
        headerTransparent: true,
        //headerTitleStyle: { justifyContent: 'center', alignItems: 'center' },

        //headerStyle: styles.header,

        headerStyle: {
            boxShadow: 'none', // for web
            elevation: 0, //for android
            shadowOpacity: 0, //for ios
            borderBottomWidth: 0, //for ios
            paddingVertical: 6,
            height: Layout.isMediumDevice
                ? Layout.topNavHeightMobile
                : Layout.topNavHeightDesktop,
            backgroundColor: navigation.getParam('active', false)
                ? Colors.gray0
                : 'transparent',
        },

        headerLeft: (
            <View
                style={{
                    flex: 1,
                    paddingVertical: Layout.screenPaddingMedium,
                    paddingHorizontal: Layout.isMediumDevice
                        ? Layout.screenPaddingMedium
                        : Layout.screenPaddingLarge,
                }}
            >
                <TouchableOpacity
                    style={{ flex: 1 }}
                    onPress={() => navigation.navigate('AuthHome')}
                >
                    <Text
                        style={[
                            Layout.isMediumDevice
                                ? TextStyles.LogotypeInverse
                                : TextStyles.LogotypeLargeInverse,
                            {
                                color: navigation.getParam('active', false)
                                    ? Colors.tintColor
                                    : Colors.inverseText,
                            },
                        ]}
                    >
                        Indvstry
                    </Text>
                </TouchableOpacity>
            </View>
        ),

        /* TEMPORARILY HIDE */
        headerTitle: (
            <View style={{ flex: 1 }}>
                {Layout.isMediumDevice ? (
                    <View />
                ) : (
                    <View
                        style={{
                            alignSelf: 'center',
                            flexDirection: 'row',
                        }}
                    >
                        {/* <Text
                            style={[
                                TextStyles.Overline1,
                                {
                                    paddingVertical: Layout.screenPaddingMedium,
                                    paddingHorizontal:
                                        Layout.screenPaddingLarge,
                                },
                            ]}
                            onPress={() => navigation.navigate('RequestInfo')}
                        >
                            Interested in Indvstry?
                        </Text>
                        <Text
                            style={[
                                TextStyles.Overline1,
                                {
                                    paddingVertical: Layout.screenPaddingMedium,
                                    paddingHorizontal:
                                        Layout.screenPaddingLarge,
                                },
                            ]}
                            onPress={() => navigation.navigate('AuthHome')}
                        >
                            Inspiration Page
                        </Text> */}
                        <Text
                            style={[
                                TextStyles.Overline1,
                                {
                                    paddingVertical: Layout.screenPaddingMedium,
                                    paddingHorizontal:
                                        Layout.screenPaddingLarge,
                                    color: navigation.getParam('active', false)
                                        ? Colors.tintColor
                                        : Colors.inverseText,
                                    textTransform: 'uppercase',
                                },
                            ]}
                            onPress={() => navigation.navigate('About')}
                        >
                            About
                        </Text>
                        <Text
                            style={[
                                TextStyles.Overline1,
                                {
                                    paddingVertical: Layout.screenPaddingMedium,
                                    paddingHorizontal:
                                        Layout.screenPaddingLarge,
                                    color: navigation.getParam('active', false)
                                        ? Colors.tintColor
                                        : Colors.inverseText,
                                    textTransform: 'uppercase',
                                },
                            ]}
                            onPress={() => navigation.navigate('RequestInfo')}
                        >
                            Interested in Indvstry?
                        </Text>
                    </View>
                )}
            </View>
        ),

        headerRight: (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View style={{ flex: 1 }}>
                            {context.userExists ? (
                                <View
                                    style={{
                                        flex: 1,
                                        flexDirection: 'row',

                                        paddingVertical:
                                            Layout.screenPaddingMedium,
                                        paddingHorizontal: Layout.isMediumDevice
                                            ? Layout.screenPaddingSmall
                                            : Layout.screenPaddingLarge,
                                    }}
                                >
                                    <ButtonIcon
                                        activityColor={Colors.white}
                                        iconName={'menu'}
                                        iconColor={
                                            navigation.getParam('active', false)
                                                ? Colors.tintColor
                                                : Colors.inverseText
                                        }
                                        buttonStyle={{
                                            //alignSelf: 'center',
                                            backgroundColor: 'transparent',
                                        }}
                                        loading={false}
                                        disabled={false}
                                        onPress={() =>
                                            navigation.dispatch(
                                                DrawerActions.toggleDrawer()
                                            )
                                        }
                                    />
                                </View>
                            ) : (
                                <ButtonMini
                                    onPress={() =>
                                        navigation.dispatch(
                                            DrawerActions.toggleDrawer()
                                        )
                                    }
                                    // onPress={() =>
                                    //     context.tokenExists
                                    //         ? navigation.navigate('AuthSignUp')
                                    //         : navigation.navigate('AuthLogin')
                                    // }
                                    style={{
                                        flex: 1,
                                        paddingVertical:
                                            Layout.screenPaddingMedium,
                                        paddingHorizontal: Layout.isMediumDevice
                                            ? Layout.screenPaddingSmall
                                            : Layout.screenPaddingLarge,
                                    }}
                                    buttonStyle={{
                                        borderWidth: 1,
                                        backgroundColor:
                                            Colors.transparentBackground,
                                        borderColor: navigation.getParam(
                                            'active',
                                            false
                                        )
                                            ? Colors.tintColor
                                            : Colors.inverseText,
                                    }}
                                    textStyle={[
                                        TextStyles.Button,
                                        {
                                            color: navigation.getParam(
                                                'active',
                                                false
                                            )
                                                ? Colors.tintColor
                                                : Colors.inverseText,
                                        },
                                    ]}
                                    title={
                                        context.tokenExists
                                            ? 'Sign up'
                                            : 'Log in'
                                    }
                                    disabled={false}
                                />
                            )}
                        </View>
                    )
                }}
            </UserProfileConsumer>
        ),
    }),
}

class DrawerComponent extends React.Component {}

const PublicStack = createStackNavigator(
    {
        AuthLoading: {
            screen: AuthLoadingScreen,
        },

        AuthHome: {
            screen: AuthHomeScreen,
        },

        About: {
            screen: TeamScreen,
        },

        AuthForgotPassword: { screen: AuthForgotPasswordScreen },

        RequestInfo: {
            screen: RequestInfoScreen,
        },

        RequestConfirmation: {
            screen: RequestConfirmationScreen,
        },
    },
    StackConfig
)

export default createDrawerNavigator(
    {
        PublicStack,
    },
    //CustomDrawerContentComponent,
    DrawerConfig,
    DrawerComponent
)
