import React from 'react'
import {
    Image,
    ImageBackground,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import { Colors, TextStyles, Layout, Icons } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import FormHeaderTitle from '../../components/FormHeaderTitle'
import Footer from '../../components/Footer'
import GetInTouch from '../../components/GetInTouch'

export default class Team extends React.Component {
    // state = {
    //     windowWidth: '',
    //     windowHeight: '',
    // }
    componentDidMount() {
        this.props.navigation.setParams({ active: true })
        //window.addEventListener('onLayout', this.handleResize)
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps.isFocused !== this.props.isFocused) {
            this.props.navigation.setParams({ active: true })
            //window.addEventListener('onLayout', this.handleResize)

            //window.removeEventListener('scroll', this.onScroll)
        }
    }

    // handleResize = async event => {
    //     const windowWidth = await event.nativeEvent.layout.width
    //     const windowHeight = await event.nativeEvent.layout.height

    //     // const {
    //     //     window: { width, height, fontScale, scale },
    //     //     screen,
    //     // } = useDimensions()

    //     console.log(windowWidth, windowHeight)
    //     //console.log(event, width, height)

    //     this.setState(prevState => {
    //         return {
    //             windowWidth,
    //             windowHeight,
    //         }
    //     })
    // }

    // onScroll = async event => {
    //     const pageYOffset = await event.nativeEvent.contentOffset.y

    //     console.log(event.nativeEvent.contentOffset.y)

    //     if (pageYOffset >= 88) {
    //         this.props.navigation.setParams({ active: true })
    //     } else {
    //         this.props.navigation.setParams({ active: false })
    //     }
    // }

    renderRule = (lineColor) => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 2,
                    borderBottomColor: lineColor || Colors.tintColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSeparator = (lineColor) => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border, //lineColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSpacer() {
        return (
            <View
                style={{
                    width: '100%',
                    height: Layout.screenPaddingLarge,
                }}
            />
        )
    }

    render() {
        return (
            <UserProfileConsumer>
                {(context) => {
                    return (
                        <View
                            //onLayout={event => this.handleResize(event)}
                            style={{
                                flex: 1,
                            }}
                            ref={(ref) => {
                                this.context = context
                            }}
                        >
                            <ScrollView
                                // onScroll={event =>
                                //     this.onScroll(event)
                                // }
                                // scrollEventThrottle={16}
                                style={{
                                    flex: 1,
                                    // height: this.state.windowHeight,
                                    // width: this.state.windowWidth,
                                    backgroundColor: Colors.gray0,
                                    paddingTop: Layout.isMediumDevice
                                        ? Layout.topNavHeightMobile
                                        : Layout.topNavHeightDesktop,
                                }}
                            >
                                <View style={styles.sectionContainer}>
                                    <View style={styles.bodyContent}>
                                        <View
                                            style={{
                                                //flex: 1,
                                                paddingVertical: Layout.isMediumDevice
                                                    ? Layout.screenPaddingLarge
                                                    : Layout.screenPaddingXL,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                                // alignItems: 'center',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                    //alignSelf: 'center',
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingBottom: Layout.isMediumDevice
                                                        ? Layout.screenPaddingLarge
                                                        : 0,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading1,
                                                        {
                                                            paddingRight:
                                                                Layout.screenPaddingMedium,
                                                            //width: '50%',
                                                        },
                                                    ]}
                                                >
                                                    Craft culinary experiences
                                                    and inspiring stories that
                                                    bring diners to your venue.
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 36,
                                                        },
                                                    ]}
                                                >
                                                    Restaurants and other Food
                                                    establishments have emerged
                                                    as destinations in their own
                                                    right. We believe there is
                                                    an opportunity to reimagine
                                                    how they connect with their
                                                    audiences in a dedicated,
                                                    new ecosystem. Our novel
                                                    storytelling platform makes
                                                    it easier for venues to
                                                    reach their guests. In
                                                    addition, we’re committed to
                                                    cultivating a community that
                                                    produces elevated content.
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>

                                <ImageBackground
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            height:
                                                Layout.SCREEN_HEIGHT * 0.382,
                                        },
                                    ]}
                                    source={require('../../assets/images/jared-erondu-6Fq2U47SKtE-unsplash.jpg')}
                                    resizeMode={'cover'}
                                />

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            backgroundColor: Colors.aesop,
                                            paddingVertical: Layout.isMediumDevice
                                                ? Layout.screenPaddingLarge
                                                : Layout.screenPaddingXL,
                                        },
                                    ]}
                                >
                                    <View style={styles.bodyContent}>
                                        <FormHeaderTitle
                                            headerTitle={'Leadership'}
                                            headerTitleStyle={[
                                                TextStyles.Heading1,
                                                {
                                                    alignSelf: 'center',
                                                },
                                            ]}
                                        />

                                        <View
                                            style={{
                                                paddingVertical: Layout.isMediumDevice
                                                    ? Layout.screenPaddingLarge
                                                    : Layout.screenPaddingXL,
                                                flexDirection: 'row',
                                                flexWrap: 'wrap',
                                            }}
                                        >
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 360,
                                                    paddingRight: Layout.isMediumDevice
                                                        ? 0
                                                        : Layout.screenPaddingLarge,
                                                    paddingBottom: Layout.isMediumDevice
                                                        ? Layout.screenPaddingLarge
                                                        : 0,
                                                }}
                                            >
                                                <Image
                                                    source={require('../../assets/images/12mauricekenjiclarke.jpg')}
                                                    style={{
                                                        width: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 360,
                                                        height: 480,
                                                        marginBottom:
                                                            Layout.screenPaddingMedium,
                                                        // backgroundColor:
                                                        //     Colors.disabled,
                                                        alignItems: 'center',
                                                        alignSelf: 'center',
                                                    }}
                                                    resizeMode={
                                                        Layout.isMediumDevice
                                                            ? 'contain'
                                                            : 'cover'
                                                    }
                                                />
                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 26,
                                                            paddingBottom:
                                                                Layout.screenPaddingSmall,
                                                            alignSelf: 'center',
                                                        },
                                                    ]}
                                                    maxLength={64}
                                                >
                                                    Maurice Kenji Clarke
                                                </Text>
                                                <Text
                                                    numberOfLines={1}
                                                    style={[
                                                        TextStyles.Overline2,
                                                        {
                                                            flex: 1,
                                                            paddingBottom:
                                                                Layout.screenPaddingLarge,
                                                            alignSelf: 'center',
                                                        },
                                                    ]}
                                                >
                                                    FOUNDER AND CEO
                                                </Text>
                                            </View>
                                            <View
                                                style={{
                                                    flex: 1,
                                                    minWidth: Layout.isMediumDevice
                                                        ? '100%'
                                                        : 480,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Heading3,
                                                        {
                                                            lineHeight: 36,
                                                            maxWidth: Layout.isMediumDevice
                                                                ? '100%'
                                                                : 480,
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                        },
                                                    ]}
                                                >
                                                    At Indvstry, we believe the
                                                    future of equitable work
                                                    will be determined by how we
                                                    shape the services jobs of
                                                    today.
                                                </Text>

                                                <Text
                                                    style={[
                                                        TextStyles.Paragraph,
                                                        {
                                                            lineHeight: 36,
                                                            maxWidth: Layout.isMediumDevice
                                                                ? '100%'
                                                                : 480,
                                                        },
                                                    ]}
                                                >
                                                    What began as a passion
                                                    project evolved into a
                                                    full-time pursuit. Maurice
                                                    conceived and launched the
                                                    platform from the ground up,
                                                    filling the roles of
                                                    product-centric designer,
                                                    growth-hacker, as well as
                                                    full-stack developer.
                                                    Previously, he held roles as
                                                    Product Designer and
                                                    Entrepreneur at Google,
                                                    Freelancer at IDEO and
                                                    IDEO.org, and Art Director
                                                    at Caesars Entertainment and
                                                    TAO Group.
                                                </Text>
                                            </View>
                                        </View>
                                    </View>
                                </View>
                                <GetInTouch
                                    navigation={this.props.navigation}
                                />
                                <Footer navigation={this.props.navigation} />
                            </ScrollView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        alignItems: 'center',
        paddingVertical: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
        backgroundColor: Colors.gray0,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        paddingVertical: Layout.screenPaddingLarge,
        paddingHorizontal: Layout.screenPaddingLarge,
    },
})
