import React from 'react'
import { StyleSheet, Text, TouchableOpacity, View } from 'react-native'
import { Colors, TextStyles } from '../constants'

export default class ButtonMini extends React.Component {
    render() {
        let style = [styles.button]
        if (this.props.disabled) {
            style.push(styles.disabledButton)
        }
        return (
            <View style={[styles.container, this.props.style]}>
                <TouchableOpacity
                    style={[style, this.props.buttonStyle]}
                    disabled={this.props.disabled}
                    //onPressIn={this.props.onPressIn}
                    onPress={this.props.onPress}
                    //underlayColor={Colors.highlightColor}
                >
                    {/* <Text style={his.props.textStyle}>{this.props.title}</Text> */}
                    <Text
                        style={[
                            TextStyles.Button,
                            this.props.textStyle,
                            { lineHeight: 22 },
                        ]}
                    >
                        {this.props.title}
                    </Text>
                </TouchableOpacity>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    button: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 3,
        paddingVertical: 8,
        paddingHorizontal: 12,
        backgroundColor: Colors.secondaryBackground,
    },
    disabledButton: {
        //backgroundColor: Colors.disabled,
        opacity: 0.25,
    },
    // label: {
    //   color: Colors.tintColor,
    //   fontWeight: '700',
    //   //fontFamily: 'neuzeit-s-bold',
    // },
})
