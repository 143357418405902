import React from 'react'
import {
    Image,
    ImageBackground,
    Linking,
    ScrollView,
    StyleSheet,
    Text,
    View,
} from 'react-native'
import { Feather } from '@expo/vector-icons'
import { Colors, TextStyles, Layout, Icons } from '../../constants'
import { UserProfileConsumer } from '../../context/UserProfileContext'
import FormHeaderTitle from '../../components/FormHeaderTitle'
import Button from '../../components/Button'
import moment from 'moment'
import Footer from '../../components/Footer'
import GetInTouch from '../../components/GetInTouch'
import BadgeBeta from '../../components/BadgeBeta'

export default class GetCreatorApp extends React.Component {
    onScroll = async event => {
        const pageYOffset = await event.nativeEvent.contentOffset.y

        if (pageYOffset >= 88) {
            this.props.navigation.setParams({ active: true })
        } else {
            this.props.navigation.setParams({ active: false })
        }
    }

    renderRule = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 2,
                    borderBottomColor: Colors.tintColor,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSeparator = () => {
        return (
            <View
                style={{
                    flex: 1,
                    borderBottomWidth: 1,
                    borderBottomColor: Colors.border,
                    paddingTop: Layout.screenPaddingMedium,
                    marginBottom: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSpacer() {
        return (
            <View
                style={{
                    width: '100%',
                    height: Layout.screenPaddingMedium,
                }}
            />
        )
    }

    renderSpacerHorizontal() {
        return (
            <View
                style={{
                    width: Layout.screenPaddingMedium,
                    height: '100%',
                }}
            />
        )
    }

    render() {
        // LayoutAnimation.easeInEaseOut()

        const timestampLabel = moment().format('dddd, MMMM D, YYYY')

        return (
            <UserProfileConsumer>
                {context => {
                    return (
                        <View
                            style={{
                                flex: 1,
                            }}
                            ref={ref => {
                                this.context = context
                            }}
                        >
                            <ScrollView
                                ref={node => (this.scroll = node)}
                                decelerationRate="fast"
                                onScroll={event => this.onScroll(event)}
                                scrollEventThrottle={16}
                                //contentContainerStyle={{ flex: 1 }}
                                //automaticallyAdjustContentInsets={false}
                                style={{ flex: 1 }}
                                pagingEnabled={
                                    Layout.isMediumDevice ? false : true
                                }
                            >
                                <ImageBackground
                                    source={require('../../assets/images/lucas-gallone-Q4QjAPMpJRQ-unsplash.jpg')}
                                    style={{
                                        borderTopWidth: 6,
                                        borderTopColor: Colors.tintColor,
                                        backgroundColor: Colors.tintColor,
                                        minHeight: Layout.SCREEN_HEIGHT,
                                    }}
                                    resizeMode={'cover'}
                                >
                                    <View
                                        style={{
                                            flex: 1,
                                            alignItems: 'center',
                                            justifyContent: 'center',
                                            backgroundColor:
                                                Colors.mediumBackground,
                                        }}
                                    >
                                        <View
                                            style={{
                                                maxWidth: 960,
                                                paddingHorizontal: Layout.isMediumDevice
                                                    ? Layout.screenPaddingLarge
                                                    : Layout.screenPaddingXL,
                                                // paddingVertical: Layout.isMediumDevice
                                                //     ? Layout.screenPaddingXL
                                                //     : Layout.screenPaddingXL *
                                                //       2,
                                            }}
                                        >
                                            <Text
                                                style={[
                                                    Layout.isMediumDevice
                                                        ? TextStyles.Heading1Inverse
                                                        : TextStyles.Heading0Inverse,
                                                    {
                                                        // textAlign: Layout.isMediumDevice
                                                        //     ? 'left'
                                                        //     : 'center',
                                                        paddingBottom:
                                                            Layout.screenPaddingMedium,
                                                    },
                                                ]}
                                            >
                                                Pioneer new dining experiences
                                                with enriching stories
                                            </Text>
                                            <Button
                                                iconRight={false}
                                                iconName={'arrow-right'}
                                                iconColor={Colors.tintColor}
                                                onPress={() => {
                                                    this.scroll.scrollTo({
                                                        y: Layout.SCREEN_HEIGHT,
                                                    })
                                                }}
                                                style={{
                                                    alignSelf: 'flex-start',
                                                    // alignSelf: Layout.isMediumDevice
                                                    //     ? 'flex-start'
                                                    //     : 'center',
                                                    paddingTop:
                                                        Layout.screenPaddingMedium,
                                                }}
                                                buttonStyle={{
                                                    borderWidth: 1,
                                                    backgroundColor:
                                                        Colors.transparentBackground,
                                                    borderColor:
                                                        Colors.inverseText,
                                                }}
                                                textStyle={[
                                                    TextStyles.Button,
                                                    {
                                                        color:
                                                            Colors.inverseText,
                                                    },
                                                ]}
                                                title={'Get started today'}
                                            />
                                        </View>
                                    </View>
                                </ImageBackground>

                                <View
                                    style={[
                                        styles.sectionContainer,
                                        {
                                            backgroundColor: Colors.aesop,
                                            height: Layout.isMediumDevice
                                                ? null
                                                : Layout.SCREEN_HEIGHT,
                                        },
                                    ]}
                                >
                                    <View
                                        style={[
                                            styles.bodyContent,
                                            {
                                                alignItems: 'center',
                                                justifyContent: 'center',
                                            },
                                        ]}
                                    >
                                        <View style={styles.cardContent}>
                                            <View
                                                style={{
                                                    paddingVertical:
                                                        Layout.screenPaddingSmall,
                                                }}
                                            >
                                                <Text
                                                    style={[
                                                        TextStyles.Overline2,
                                                        {
                                                            color:
                                                                Colors.primaryText,
                                                            paddingVertical:
                                                                Layout.screenPaddingSmall,
                                                            textAlign: 'center',
                                                            textTransform:
                                                                'uppercase',
                                                        },
                                                    ]}
                                                >
                                                    Join early access
                                                </Text>
                                                {this.renderRule()}
                                            </View>

                                            <View
                                                style={{
                                                    flex: 1,
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        flex: 1,
                                                        paddingRight: Layout.isMediumDevice
                                                            ? 0
                                                            : Layout.screenPaddingXL,
                                                        paddingVertical: Layout.isMediumDevice
                                                            ? 0
                                                            : Layout.screenPaddingLarge,
                                                        width: '100%',
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 360,
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                        }}
                                                    >
                                                        <View
                                                            style={{
                                                                overflow:
                                                                    'hidden',
                                                                backgroundColor:
                                                                    Colors.tintColor,
                                                                borderRadius: 20,
                                                                // alignSelf: Layout.isMediumDevice
                                                                //     ? 'center'
                                                                //     : 'flex-start',
                                                                alignItems:
                                                                    'center',
                                                                justifyContent:
                                                                    'center',
                                                                //marginBottom: Layout.screenPaddingMedium,
                                                                padding:
                                                                    Layout.screenPaddingSmall,
                                                            }}
                                                        >
                                                            <Image
                                                                source={require('../../assets/svgs/indvstry-icon-inverse.svg')}
                                                                style={{
                                                                    width:
                                                                        Icons.XL,
                                                                    height:
                                                                        Icons.XL,
                                                                }}
                                                                resizeMode={
                                                                    'cover'
                                                                }
                                                            />
                                                        </View>
                                                        <BadgeBeta />
                                                    </View>

                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            paddingBottom:
                                                                Layout.screenPaddingMedium,
                                                        }}
                                                    >
                                                        <Text
                                                            style={[
                                                                Layout.isMediumDevice
                                                                    ? TextStyles.Heading2
                                                                    : TextStyles.Heading2,
                                                                // {
                                                                //     paddingBottom:
                                                                //         Layout.screenPaddingMedium,
                                                                // },
                                                            ]}
                                                        >
                                                            Indvstry App
                                                        </Text>
                                                    </View>
                                                    {/* {this.renderRule()} */}

                                                    <Text
                                                        style={[
                                                            TextStyles.Paragraph,
                                                            {
                                                                lineHeight: 26,
                                                                paddingBottom:
                                                                    Layout.screenPaddingLarge,
                                                                maxWidth: 480,
                                                            },
                                                        ]}
                                                    >
                                                        For Food & Hospitality
                                                        professionals with
                                                        regular or one-time
                                                        events at their
                                                        establishments, to
                                                        create and share
                                                        original stories.
                                                    </Text>
                                                </View>
                                                <View
                                                    style={{
                                                        flex: 1,
                                                        paddingVertical: Layout.isMediumDevice
                                                            ? 0
                                                            : Layout.screenPaddingLarge,
                                                        width: '100%',
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 360,
                                                        alignSelf: 'flex-start',
                                                    }}
                                                >
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.tintColor
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={
                                                                TextStyles.Paragraph
                                                            }
                                                        >
                                                            Create new events,
                                                            right on your mobile
                                                            device
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator()}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.tintColor
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={
                                                                TextStyles.Paragraph
                                                            }
                                                        >
                                                            Quickly add video
                                                            stories, with
                                                            instant themes
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator()}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.tintColor
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={
                                                                TextStyles.Paragraph
                                                            }
                                                        >
                                                            Collaborate with
                                                            others to see
                                                            stories
                                                            automatically merged
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator()}
                                                    <View
                                                        style={{
                                                            flexDirection:
                                                                'row',
                                                            alignItems:
                                                                'center',
                                                        }}
                                                    >
                                                        <Feather
                                                            style={{
                                                                alignSelf:
                                                                    'center',
                                                                paddingRight: 12,
                                                            }}
                                                            color={
                                                                Colors.tintColor
                                                            }
                                                            size={Icons.small}
                                                            name={'check'}
                                                        />
                                                        <Text
                                                            style={
                                                                TextStyles.Paragraph
                                                            }
                                                        >
                                                            Share to Instagram,
                                                            Twitter, as well as
                                                            your favorite
                                                            Messaging apps
                                                        </Text>
                                                    </View>
                                                    {this.renderSeparator()}
                                                </View>
                                            </View>

                                            <View
                                                style={{
                                                    //flex: 1,
                                                    paddingVertical:
                                                        Layout.screenPaddingSmall,
                                                    flexDirection: 'row',
                                                    flexWrap: 'wrap',
                                                }}
                                            >
                                                <View
                                                    style={{
                                                        flex: 1,
                                                        minWidth: Layout.isMediumDevice
                                                            ? '100%'
                                                            : 360,
                                                        paddingBottom: Layout.isMediumDevice
                                                            ? Layout.screenPaddingLarge
                                                            : 0,
                                                        alignItems: Layout.isMediumDevice
                                                            ? 'center'
                                                            : 'flex-start',
                                                        paddingRight: Layout.isMediumDevice
                                                            ? null
                                                            : Layout.screenPaddingXL,
                                                        alignSelf: 'flex-end',
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading1,
                                                            {
                                                                textAlign: Layout.isMediumDevice
                                                                    ? 'center'
                                                                    : 'left',
                                                                paddingBottom: 8,
                                                            },
                                                        ]}
                                                    >
                                                        Free
                                                    </Text>
                                                    <Text
                                                        style={[
                                                            TextStyles.Caption,
                                                            {
                                                                textAlign: Layout.isMediumDevice
                                                                    ? 'center'
                                                                    : 'left',
                                                            },
                                                        ]}
                                                    >
                                                        Core features included.
                                                        No long-term
                                                        commitments.
                                                    </Text>
                                                </View>

                                                <View
                                                    style={{
                                                        flex: Layout.isMediumDevice
                                                            ? null
                                                            : 1,
                                                        width: Layout.isMediumDevice
                                                            ? '100%'
                                                            : null,
                                                        // paddingRight: Layout.isMediumDevice
                                                        //     ? null
                                                        //     : Layout.screenPaddingXL,
                                                    }}
                                                >
                                                    <Text
                                                        style={[
                                                            TextStyles.Heading4,
                                                            {
                                                                textAlign: Layout.isMediumDevice
                                                                    ? 'center'
                                                                    : 'left',
                                                                paddingBottom:
                                                                    Layout.screenPaddingMedium,
                                                            },
                                                        ]}
                                                    >
                                                        Download the App
                                                    </Text>
                                                    <View
                                                        style={{
                                                            flex: 1,
                                                            minWidth: Layout.isMediumDevice
                                                                ? '100%'
                                                                : 360,
                                                            alignItems: Layout.isMediumDevice
                                                                ? 'center'
                                                                : 'flex-start',
                                                            flexDirection: Layout.isMediumDevice
                                                                ? 'column'
                                                                : 'row',
                                                            //flexWrap: 'wrap',
                                                            // justifyContent:
                                                            //     'space-between',
                                                        }}
                                                    >
                                                        <Button
                                                            iconGetApp={true}
                                                            iconName={
                                                                'app-store'
                                                            }
                                                            iconColor={
                                                                Colors.tintColor
                                                            }
                                                            onPress={() =>
                                                                this.props.navigation.navigate(
                                                                    'Contact'
                                                                )
                                                            }
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            buttonStyle={{
                                                                borderWidth: 1,
                                                                backgroundColor:
                                                                    Colors.transparentBackground,
                                                                borderColor:
                                                                    Colors.tintColor,
                                                            }}
                                                            textStyle={[
                                                                {
                                                                    color:
                                                                        Colors.tintColor,
                                                                },
                                                            ]}
                                                            title={'App Store'}
                                                        />
                                                        {Layout.isMediumDevice
                                                            ? this.renderSpacer()
                                                            : this.renderSpacerHorizontal()}
                                                        <Button
                                                            iconGetApp={true}
                                                            iconName={
                                                                'google-play'
                                                            }
                                                            iconColor={
                                                                Colors.tintColor
                                                            }
                                                            onPress={() =>
                                                                Linking.openURL(
                                                                    'https://play.google.com/apps/testing/io.indvstry.indvstry'
                                                                ).catch(error =>
                                                                    console.error(
                                                                        'An error occurred',
                                                                        error
                                                                    )
                                                                )
                                                            }
                                                            style={{
                                                                width: '100%',
                                                            }}
                                                            buttonStyle={{
                                                                borderWidth: 1,
                                                                backgroundColor:
                                                                    Colors.transparentBackground,
                                                                borderColor:
                                                                    Colors.tintColor,
                                                            }}
                                                            textStyle={[
                                                                {
                                                                    color:
                                                                        Colors.tintColor,
                                                                },
                                                            ]}
                                                            title={
                                                                'Google Play'
                                                            }
                                                        />
                                                    </View>
                                                </View>
                                            </View>
                                        </View>

                                        {/* <View
                                        style={{
                                            flex: 1,
                                            maxWidth: 720,
                                            alignSelf: 'center',
                                            alignItems: 'center',
                                            paddingVertical: Layout.isMediumDevice
                                                ? Layout.screenPaddingLarge
                                                : Layout.screenPaddingXL,
                                        }}
                                    >
                                        <Text
                                            style={[
                                                TextStyles.Heading2,
                                                {
                                                    textAlign: Layout.isMediumDevice
                                                        ? 'left'
                                                        : 'center',
                                                },
                                            ]}
                                        >
                                            Craft stories as distinctive as your
                                            dishes on Indvstry
                                        </Text>
                                    </View> */}
                                    </View>
                                </View>
                                {/* 
                            <ImageBackground
                                style={[
                                    styles.sectionContainer,
                                    {
                                        //backgroundColor: Colors.gray5,                                        height: Layout.SCREEN_HEIGHT * 0.382,
                                    },
                                ]}
                                source={require('../../assets/images/louis-hansel--pzHBEU7Tm4-unsplash.jpg')}
                                resizeMode={'cover'}
                            >
                                <View
                                    style={{
                                        flex: 1,
                                        //paddingVertical: 88,
                                        alignSelf: 'center',
                                        // height:
                                        //     Layout.SCREEN_HEIGHT * 0.382,
                                        backgroundColor:
                                            Colors.mediumBackground,
                                    }}
                                />
                            </ImageBackground> */}

                                <GetInTouch
                                    navigation={this.props.navigation}
                                    headerTitle={'We’re here to help'}
                                    //message={'Talk to an Indvstry team member who can take you through a demo of everything Indvstry has to offer.'}
                                />

                                <Footer navigation={this.props.navigation} />
                            </ScrollView>
                        </View>
                    )
                }}
            </UserProfileConsumer>
        )
    }
}

const styles = StyleSheet.create({
    sectionContainer: {
        alignItems: 'center',
        backgroundColor: Colors.gray0,
        paddingVertical: Layout.isMediumDevice
            ? Layout.screenPaddingLarge
            : Layout.screenPaddingXL,
    },

    cardContent: {
        //flex: 1, // Only for this screen and layout style
        maxWidth: 1440, // Only for this screen and layout style. 960 or 720
        marginVertical: Layout.screenPaddingMedium,
        backgroundColor: Colors.white,
        padding: Layout.screenPaddingLarge,
        shadowColor: Colors.shadowColor,
        shadowOffset: Colors.shadowOffsetDepth,
        shadowOpacity: Colors.shadowOpacityDepth,
        shadowRadius: Colors.shadowRadiusDepth,
    },

    bodyContent: {
        flex: 1,
        maxWidth: 1440,
        paddingVertical: Layout.screenPaddingLarge,
        paddingHorizontal: Layout.screenPaddingLarge,
    },

    imageContainer: {
        flex: 1,
        flexDirection: 'row',
        alignItems: 'flex-end',
        justifyContent: 'flex-end',
        padding: Layout.screenPaddingLarge,
    },
    stickyButton: {
        flex: 1,
        position: 'absolute',
        bottom: 0,
        right: 0,
        padding: Layout.isMediumDevice
            ? Layout.screenPaddingMedium
            : Layout.screenPaddingLarge,
    },
})
