import React from 'react'
import {
    ActivityIndicator,
    StyleSheet,
    Text,
    TouchableOpacity,
    View,
} from 'react-native'
import { Colors, Icons, TextStyles } from '../constants'
import { Feather, Entypo } from '@expo/vector-icons'

export default class Button extends React.Component {
    render() {
        let style = [styles.button]
        if (this.props.disabled) {
            style.push(styles.disabledButton)
        }
        return (
            <View style={[styles.container, this.props.style]}>
                <TouchableOpacity
                    style={[style, this.props.buttonStyle]}
                    disabled={this.props.disabled}
                    onPressIn={this.props.onPressIn}
                    onPress={this.props.onPress}
                    underlayColor={Colors.highlightColor}
                >
                    {this.props.loading ? (
                        <ActivityIndicator
                            style={{
                                paddingHorizontal: Icons.large,
                            }}
                            size="small"
                            color="#FFF"
                            // color={[Colors.white, this.props.indicatorColor]}
                        />
                    ) : (
                        <View
                            style={{
                                flexDirection: 'row',
                                alignItems: 'center',
                            }}
                        >
                            {this.props.iconLeft ? (
                                <Feather
                                    name={this.props.iconName}
                                    style={[
                                        this.props.iconStyle,
                                        { paddingLeft: 8 },
                                    ]}
                                    size={Icons.small}
                                    color={this.props.iconColor}
                                />
                            ) : (
                                <View />
                            )}
                            {this.props.iconGetApp ? (
                                <Entypo
                                    name={this.props.iconName}
                                    style={[
                                        this.props.iconStyle,
                                        { paddingLeft: 8 },
                                    ]}
                                    size={Icons.small}
                                    color={this.props.iconColor}
                                />
                            ) : (
                                <View />
                            )}

                            <Text
                                style={[
                                    TextStyles.Button,
                                    this.props.textStyle,
                                    { paddingHorizontal: 12 },
                                ]}
                            >
                                {this.props.title}
                            </Text>
                            {this.props.iconRight ? (
                                <Feather
                                    name={this.props.iconName}
                                    style={[
                                        this.props.iconStyle,
                                        { paddingRight: 8 },
                                    ]}
                                    size={Icons.small}
                                    color={this.props.iconColor}
                                />
                            ) : (
                                <View />
                            )}
                        </View>
                    )}
                </TouchableOpacity>
            </View>
        )
    }
}

const styles = StyleSheet.create({
    container: {
        flex: 1,
    },
    button: {
        alignItems: 'center',
        justifyContent: 'center',
        borderRadius: 0,
        paddingVertical: 16,
        paddingHorizontal: 16,
        backgroundColor: Colors.tintColor,
        //backgroundColor: Colors.deepsea,
    },
    disabledButton: {
        backgroundColor: Colors.disabled,
        opacity: 0.25,
    },
})
